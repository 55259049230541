import React from 'react';
import { SummayrIconWrapper } from '../styles';

interface SummaryIconProps {
  icon: any;
  title: string;
  subtitle: string;
  [x: string]: any;
}

const SummaryIcon: React.FC<SummaryIconProps> = ({ icon, title, subtitle, ...rest }) => {
  return (
    <SummayrIconWrapper {...rest}>
      {icon}
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </SummayrIconWrapper>
  );
};

export default SummaryIcon;
