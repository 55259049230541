import React, { useState } from 'react';
import { IonContent } from '@ionic/react';

import { Header, TableView2 } from '$gcomponents/widgets';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import ReportFilter from '$fcomponents/filters/reportFilter';
import { updatedFilter } from '$fbusiness/helpers/util';
import ReportSubheader from '$components/reportSubheader';
import { IonPageWrapper } from './styles';
import ExportButton from '$gcomponents/reusables/exportButton';
import { Print } from '@mui/icons-material';
import intl from '$intl';
import { SORT } from '$gbusiness/enums';
import { adjustFilterToRank } from './utils';

interface ReportComponentProps {
  title?: string;
  titleText?: string;
  table;
  tableConfig;
  tableSummary?;
  onTableLoad;
  columns?: any;
  columnKey?: string;
  dateFilter;
  extraForm?: any;
  extraForm2?: any;
  excludeQuery?: boolean;
  stores?: any;
  exportSetting?: any;
  includeReportType?: boolean;
  ranking?: any;
  filterWidth?: string;
}

const defaultFilter = {
  query: '',
};

const ReportComponent: React.FC<ReportComponentProps> = ({
  title,
  titleText,
  table,
  tableConfig,
  stores,
  columns,
  columnKey,
  extraForm,
  extraForm2,
  excludeQuery = true,
  onTableLoad,
  tableSummary,
  includeReportType = false,
  dateFilter,
  exportSetting,
  filterWidth = '900px',
}) => {
  const rankingToFilter = (rank) => {
    if (rank.top) {
      const [top, no] = adjustFilterToRank(rank);
      const temp = {
        ...rank,
        onePage: true,
        sortOrder: top === 'BOTTOM' ? SORT.ASC : SORT.DSC,
        sortKey: 'subtotal',
        top,
        pageSize: no || 20,
      };
      return temp;
    }
    return rank;
  };

  const [filter, setFilter] = useState<any>({
    ...defaultFilter,
    ...rankingToFilter(dateFilter),
  });

  const updateFilter = (newFilter) => {
    setFilter(
      updatedFilter(filter, {
        ...rankingToFilter(newFilter),
      }),
    );
  };

  const right = exportSetting ? (
    <>
      <ExportButton
        filter={filter}
        variant="text"
        fileName="customerReport"
        text={intl('BUTTON.EXPORT')}
        color="primary"
        exportSetting={exportSetting}
      />
      <Print onClick={() => window.print()} className="pointer no-print" color="primary" />
    </>
  ) : null;

  const hasRanking = !!filter.top;
  let adjustedTitle = titleText;
  if (filter.top === 'BOTTOM') adjustedTitle = intl('SCREEN.REPORT.TITLE_BOTTOM', { no: filter.pageSize });
  if (filter.top === 'TOP') adjustedTitle = intl('SCREEN.REPORT.TITLE_TOP', { no: filter.pageSize });

  return (
    <IonPageWrapper>
      <Header titleText={adjustedTitle} title={title} />
      <IonContent>
        <TableView2
          filter={filter}
          columns={columns}
          columnKey={columnKey}
          onTableLoad={onTableLoad}
          disableSortKey={hasRanking}
          tableConfig={tableConfig}
          header={<ReportSubheader right={right} filter={filter} left={tableSummary || null} />}
          TABLE={table}>
          <FilterSection title={null}>
            <ReportFilter
              stores={stores}
              filterWidth={filterWidth}
              includeReportType={includeReportType}
              parentFilter={dateFilter}
              extraForm={extraForm}
              extraForm2={extraForm2}
              excludeQuery={excludeQuery}
              onSearch={updateFilter}
              placeholder="Search"
            />
          </FilterSection>
        </TableView2>
      </IonContent>
    </IonPageWrapper>
  );
};

export default ReportComponent;
