import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { Header, TableView2 } from '$gcomponents/widgets';

import { IonPageWrapper } from './styles';
import intl from '$gintl';
import {
  commInvoiceExportSettings,
  COMMINVOICE_TABLE,
  COMMINVOICE_TABLE_CONFIG,
  COMMINVOICE_TABLE_WIT_CONFIG,
  NUM_PREV_COMMISSION,
} from './commInvoiceTable';
import { invoiceActions } from '$fbusiness/redux/invoice';
import { PageWrapper } from '$gstyles/wrapper';
import { userActions } from '$fbusiness/redux/user';
import { format } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import { factoryActions } from '$fbusiness/redux/factory';
import { generatePeriodDates, getPeriodReportRange, updatedFilter } from '$fbusiness/helpers/util';
import CommissionFilter from '../commissionFilter';
import PATH from '$business/enums/paths';
import ExportButton from '$gcomponents/reusables/exportButton';
import { ExportContainer } from '../../storeScreens/storesScreen/styles';
import PrintButton from '$fcomponents/printButton';
import { CLAWBACK_STATUS } from '$fbusiness/enums/options/clawbackStatus';
import { dialog } from '$gcomponents/reusables';
import { COMMREFUND_TABLE, COMMREFUND_TABLE_CONFIG, commRefundExportSettings } from './commRefundTable';

interface CommInvoicesProps {
  history;
  factory;
  match;
  commPay;
  user;
  users;
  periods;
  updateInvoice;
}

const CommInvoices: React.FC<CommInvoicesProps> = ({
  history,
  factory,
  user: reduxUser,
  users,
  commPay,
  match,
  periods,
  updateInvoice,
}) => {
  const { params, url } = match;
  const { periodId, userId, period: periodDate } = params;
  const isRefund = url.includes('refund');
  const isCredit = url.includes('credit');
  const isCommPayMode = parseInt(periodId) > 0;

  const [periodOptions, setPeriodOptions] = useState<Array<any>>([]);
  const [filter, setFilter] = useState({ forceRefresh: false, isCredit: isCredit ? 1 : 0 });

  useEffect(() => {
    if (!periods) return;
    const newPeriods = generatePeriodDates(factory, periods, NUM_PREV_COMMISSION);
    setPeriodOptions(newPeriods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods, factory?.settings]);

  if (isCommPayMode && !commPay) return <IonPageWrapper />;
  if (!isCommPayMode && !reduxUser) return <IonPageWrapper />;
  const commPayId = commPay?.id || 0;

  const hasCommPay = !!commPay;
  const user = hasCommPay ? commPay.user : reduxUser;

  let dateStart = null;
  let dateEnd = null;
  if (!isCommPayMode) [dateStart, dateEnd] = getPeriodReportRange(factory, periodDate);
  const period = isCommPayMode ? commPay?.period?.periodDate : periodDate;

  const TABLE_CONFIG = isRefund || isCredit ? COMMREFUND_TABLE_CONFIG : COMMINVOICE_TABLE_CONFIG;
  const TABLE = isRefund || isCredit ? COMMREFUND_TABLE : COMMINVOICE_TABLE;

  const tableConfig = isCommPayMode
    ? COMMINVOICE_TABLE_WIT_CONFIG(commPayId)
    : TABLE_CONFIG({ dateStart, dateEnd, userId, commission: 1 });
  const exportSettings =
    isRefund || isCredit ? commRefundExportSettings(isCredit) : commInvoiceExportSettings;

  const title = user
    ? intl('SCREEN.COMMINVOICE.TITLE' + (isRefund ? '_REFUND' : ''), { name: user?.name })
    : '';
  const tempSubtitle = intl('SCREEN.COMMINVOICE.SUBTITLE' + (isRefund ? '_REFUND' : ''), {
    dateStart: format(hasCommPay ? commPay.period.reportStartDate : dateStart, DATE_FORMATS.READABLE),
    dateEnd: format(hasCommPay ? commPay.period.reportEndDate : dateEnd, DATE_FORMATS.READABLE),
  });
  const subtitle = isCommPayMode && !hasCommPay ? '' : tempSubtitle;

  // METHODS
  const onChangeStatus = async (row, status) => {
    dialog.confirm({
      title: 'MESSAGE.ALERT',
      message: 'MESSAGE.CLAWBACK_CONFIRM',
      onPress: async () => {
        const clawBack = status === CLAWBACK_STATUS.NO ? -1 : status === CLAWBACK_STATUS.YES ? 1 : 0;
        await updateInvoice({ id: row.id, clawBack });
        setFilter(updatedFilter(filter, {}));
      },
    });
  };

  const onChangePeriod = (period) => {
    const periodObj = periodOptions.find((p) => p.period === period.period);
    const hasId = !!periodObj.id;
    if (hasId) {
      history.push(`${PATH.COMMINVOICES}/${periodObj.id}/${userId}`);
      return;
    }
    history.push(`${PATH.COMMINVOICES}/0/${userId}/${period.period}`);
  };

  const onChangeUser = (user) => {
    history.push(`${PATH.COMMINVOICES}/${periodId || 0}/${user?.userId || 0}/${periodDate || ''}`);
  };

  const generateParam = (filt) => {
    return {
      ...filt,
      dateStart,
      dateEnd,
      userId,
    };
  };

  // RENDER
  return (
    <IonPageWrapper>
      <Header titleText={title} />
      <IonContent>
        <PageWrapper>
          {periodOptions.length > 0 && (
            <CommissionFilter
              hasCommPay={hasCommPay}
              factory={factory}
              users={users}
              user={user}
              maxWidth={900}
              periods={periodOptions}
              period={period}
              onGeneratePayout={() => {}}
              onChangePeriod={onChangePeriod}
              onChangeUser={onChangeUser}
              excludePayoutButton
            />
          )}
          <div className="summary-container">{subtitle}</div>
          <TableView2 filter={filter} tableConfig={tableConfig} TABLE={TABLE(onChangeStatus)}>
            <ExportContainer>
              <PrintButton />
              <ExportButton
                filter={generateParam(filter)}
                variant="text"
                fileName="commssion"
                exportSetting={exportSettings}
              />
            </ExportContainer>
          </TableView2>
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  commPay: state.factory.commPay,
  user: state.user.user,
  users: state.user.users,
  factory: state.factory.factory,
  periods: state.factory.periods,
});

const mapDispatchToProps = (dispatch) => ({
  onHydrate: ({ periodId, userId }) => {
    dispatch(userActions.fetchUsers({ storeId: 1 }));
    parseInt(periodId) === 0
      ? dispatch(userActions.fetchUser(parseInt(userId)))
      : dispatch(factoryActions.fetchCommPayment(parseInt(periodId), parseInt(userId)));
    dispatch(factoryActions.fetchPeriods());
  },
  updateInvoice: (n) => dispatch(invoiceActions.updateInvoice(n)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CommInvoices));
