import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import {
  defaultReportColumns,
  itemReportExportSettings,
  ITEM_REPORT_TABLE,
  ITEM_REPORT_TABLE_CONFIG,
  refundItemsExportSettings,
  REFUND_ITEM_TABLE_CONFIG,
} from './itemReportTable';
import ReportComponent from '../reportComponent';
import PATH from '$business/enums/paths';
import intl from '$intl';
import { ITEM_REPORT } from '$fbusiness/enums/columns';
import SkuFilter from './skuFilter';
import SkuFilterModal from './skuFilterModal';
import { getEl } from '$gbusiness/helpers/input';

interface itemReportScreenProps {
  factory;
  history;
  match;
  stores;
  columns;
  dateFilter;
}

const ItemReportScreen: React.FC<itemReportScreenProps> = ({
  history,
  columns,
  dateFilter,
  stores,
  match,
}) => {
  const {
    params: { top, no, refund },
  } = match;
  const isRanking = top && no;
  const rank = parseInt(no || 0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [skuList, setSkuList] = useState<Array<any>>([]);
  const [selectedSkuList, setSelectedSkuList] = useState<Array<string>>([]);

  const handleRowClick = (itemId) => {
    if (refund) {
      history.push(PATH.REPORT_REFUND_DETAILS + '/1/0/0/' + itemId);
      return;
    }
    history.push(PATH.REPORT_ITEM_DETAILS + '/0/0/' + itemId);
  };

  const onTableLoad = (total, newTotal, response) => {
    if (isRanking) return;
    setSkuList(Object.entries(response.skus).map(([sku, name]) => ({ sku, name })));
    // Display Summary info
  };

  // const on;

  let titleText;
  if (top === 'bottom') titleText = intl('SCREEN.REPORT.TITLE_BOTTOM', { no: rank });
  if (top === 'top') titleText = intl('SCREEN.REPORT.TITLE_TOP', { no: rank });
  if (refund) titleText = intl('SCREEN.REPORT.TITLE_REFUND_ITEM');

  const reportColumns = columns ? columns[ITEM_REPORT] || defaultReportColumns : defaultReportColumns;

  const tableConfig = refund ? REFUND_ITEM_TABLE_CONFIG : ITEM_REPORT_TABLE_CONFIG;
  const exportSettings = refund
    ? refundItemsExportSettings(top, reportColumns)
    : itemReportExportSettings(top, reportColumns);

  const onClickFilter = () => {
    setShowFilterModal(true);
  };

  const onSubmitFilter = async (arr) => {
    setSelectedSkuList(arr);
    const el = getEl('.submit-button.filter');
    if (el) el.click();
  };

  return (
    <>
      <ReportComponent
        title="SCREEN.REPORT.TITLE_ITEM"
        titleText={titleText}
        columns={reportColumns}
        columnKey={ITEM_REPORT}
        table={ITEM_REPORT_TABLE(handleRowClick, isRanking, !!refund)}
        tableConfig={tableConfig}
        includeReportType
        tableSummary={
          <SkuFilter show={!isRanking} onClick={onClickFilter} totalCount={selectedSkuList.length} />
        }
        onTableLoad={onTableLoad}
        dateFilter={{
          ...dateFilter,
          ...(top && { top: top.toUpperCase() + no }),
          skus: selectedSkuList.length > 0 ? selectedSkuList : undefined,
        }}
        stores={stores}
        exportSetting={exportSettings}
      />
      <SkuFilterModal
        skuList={skuList}
        onSubmitFilter={onSubmitFilter}
        show={showFilterModal}
        onClose={() => setShowFilterModal(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory,
  stores: state.store.stores,
  columns: state.localStorage.columns,
});

const mapDispatchToProps = {};

const connected: any = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ItemReportScreen));
