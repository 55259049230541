export const divideList = (list, cols) => {
  const arr: Array<any> = [];
  let subArr: Array<string> = [];
  for (let i = 0; i < list.length; i++) {
    const colIndex = i % cols;
    const isLast = colIndex === cols - 1;
    subArr.push(list[i]);
    if (isLast) {
      subArr = [];
      arr.push(subArr);
    }
  }
  return arr;
};
