import React from 'react';
import intl from '$intl';

import { ModalWrapper } from '../styles';

import CurrentStateModel from '$fbusiness/models/currentState';
import RefundModel, { toRefundId } from '$fbusiness/models/refund';
import RefundDetails from './refundDetails';

interface RefundDetailsModalProps {
  show: boolean;
  currentState: CurrentStateModel;
  onApplyRefund: Function;
  refund: RefundModel;
  onClose: () => void;
}

const RefundDetailsModal: React.FC<RefundDetailsModalProps> = ({
  show,
  onApplyRefund,
  currentState,
  refund,
  onClose,
}) => {
  const subjectKey = refund?.isCredit && !refund.isRc ? 'CREDIT' : 'REFUND';
  return (
    <ModalWrapper
      titleText={intl('SCREEN.REFUNDS.TITLE_DETAILS', { subjectKey, refundId: toRefundId(refund?.id) })}
      show={show}
      minWidth="740px"
      onClose={() => onClose()}
      useCustom>
      {refund && (
        <RefundDetails
          onApplyRefund={onApplyRefund}
          currentState={currentState}
          refund={refund}
          onClose={onClose}
        />
      )}
    </ModalWrapper>
  );
};

export default RefundDetailsModal;
