import UserModel, { defaultUser, deriveRawToUser } from '$gbusiness/models/user';
import CommRuleModel, { deriveRawToCommRule } from './commRule';

export default interface SalesmanModel extends UserModel {
  commission?: CommRuleModel;
  regionIds?: Array<number>;
}

export const deriveRawToSalesman = (raw) => {
  return {
    ...deriveRawToUser(raw),
    ...(raw.commission && { commission: deriveRawToCommRule(raw.commission) }),
    regionIds: raw.regionIds,
  };
};

export const initialSalesman = {
  ...defaultUser,
};
