import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { Button, Text } from '$gcomponents/primitives';

import { TAX_FORM } from './taxForm';
import TaxModel from '$fbusiness/models/tax';
import { AppForm } from '$styles/general';
import MuiModal from '$gcomponents/reusables/modal/material';
import intl from '$intl';
import { factoryActions } from '$fbusiness/redux/factory';
import { AppModel } from '$business/redux/';

interface TaxModalScreenProps {
  tax: TaxModel;
  onClose: Function;
}

const TaxModalScreen: React.FC<TaxModalScreenProps> = ({ tax, onClose }) => {
  const dispatch = useDispatch();
  const isFinished = useSelector((state: AppModel) => state.factory.isFinished);
  const formRef = useRef<any>();

  useEffect(() => {
    if (!isFinished) return;

    dispatch(factoryActions.resetFinished());
    onClose();
  }, [isFinished, onClose, dispatch]);

  if (!tax) return null;
  const { id, ...initialValues } = tax;

  const onSubmit = values => {
    dispatch(factoryActions.saveTax(id || 0, values));
  };

  const validateForm = values => input.validateError(TAX_FORM, values);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={values => {
        onSubmit(values);
      }}>
      {formik => (
        <MuiModal
          open={!!tax}
          width="400px"
          onClose={() => onClose()}
          title={intl('SCREEN.FACTORY.TAX_TITLE')}
          footer={
            <Button fullWidth onClick={formik.handleSubmit} disabled={!(formik.isValid && formik.dirty)}>
              <Text k="BUTTON.SAVE" />
            </Button>
          }>
          <div className="content">
            <AppForm>
              <FormSection FORM={TAX_FORM} formik={formik} />
            </AppForm>
          </div>
        </MuiModal>
      )}
    </Formik>
  );
};

export default TaxModalScreen;
