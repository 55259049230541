import React, { useRef } from 'react';
import intl from '$intl';
import { Form, Formik } from 'formik';
import { IonContent, IonFooter } from '@ionic/react';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { SPACE } from '$gstyles';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { Modal } from '$gcomponents/reusables';
import { SERIAL_EDIT_FORM } from './serialForm';
import { useDispatch } from 'react-redux';
import { itemText } from '$gbusiness/helpers/util';
import { serialActions } from '$fbusiness/redux/serial';
import SerialModel from '$fbusiness/models/serial';
import StoreModel from '$fbusiness/models/store';
import { useSelector } from 'react-redux';

interface SerialEditModalProps {
  serial: SerialModel;
  show: boolean;
  onClose: Function;
  onRefresh: Function;
}

const SerialEditModal: React.FC<SerialEditModalProps> = ({ serial, show, onRefresh, onClose }) => {
  const dispatch = useDispatch();
  const formRef = useRef<any>();
  const stores: Array<StoreModel> = useSelector((state: any) => state.store.stores);

  const serialForm = SERIAL_EDIT_FORM(stores);

  const onSubmit = (values) => {
    const { store, serialNo, note } = values;

    const param = {
      id: serial.id,
      serial: serialNo,
      note,
      storeId: store?.storeId || null,
    };

    dispatch(serialActions.updateSerial(param)).then((success) => {
      if (success) {
        if (onRefresh) onRefresh();
      }
      onClose();
    });
  };

  const validateForm = (values) => {
    return input.validateError(serialForm, values);
  };

  const initialValues = serial
    ? {
        id: serial.id,
        serialNo: serial?.serial,
        store: { id: serial.storeId, name: serial.storeName },
        note: serial?.note || '',
      }
    : {};

  const title = itemText('EDIT', 'SERIAL');
  return (
    <Modal width="400px" titleText={title} show={show} onClose={() => onClose()} useCustom>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <PageWrapper>
                <Form>
                  <FormSection FORM={serialForm} formik={formik} />
                </Form>
              </PageWrapper>
            </IonContent>
            <IonFooter>
              <Flex padding={SPACE.MEDIUM} justifyContent="center">
                <Button
                  className="half"
                  disabled={!(formik.isValid && formik.dirty)}
                  onClick={formik.handleSubmit}>
                  {intl('BUTTON.SUBMIT')}
                </Button>
              </Flex>
            </IonFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default SerialEditModal;
