import { toLocal } from '$gbusiness/helpers/date';
import { INVOICE } from '../enums/transactionType';
import InvoiceModel, { deriveRawToInvoiceForInner } from './invoice';
import InvoicePaymentModel, { deriveRawToinvoicePayment } from './invoicePayment';
import InvoicePaymentGroupModel, { deriveRawToinvoicePaymentGroup } from './invoicePaymentGroup';
import RefundModel, { deriveRawToRefund } from './refund';
import StoreModel, { deriveRawToStore } from './store';

export default interface TransactionModel {
  id: number;
  storeId?: number;
  type: string;
  typeId: number;
  date: string;
  invoiceNumber?: string;
  operation: string;
  amount: number;
  openBalance?: number;
  invoice?: InvoiceModel;
  refund?: RefundModel;
  credit?: RefundModel;
  store?: StoreModel;
  payment?: InvoicePaymentModel;
  paymentGroup?: InvoicePaymentGroupModel;
  settings: any;
}

export const deriveRawToTransaction = (raw) => {
  return {
    id: raw.id,
    storeId: raw.storeId,
    ...deriveType(raw),
    invoiceNumber: raw.invoiceNumber || '',
    date: raw.transactionTime,
    operation: raw.operation,
    createdAt: toLocal(raw.createdAt),
    store: deriveRawToStore(raw.store) || null,
  };
};

export const deriveType = (raw) => {
  return {
    type: raw.type,
    typeId: raw.typeId,
    amount: raw.total || 0,
    openBalance: raw.balance || 0,
    // ...(openBalance !== undefined && { openBalance }),
    ...(raw.charge && { refund: deriveRawToRefund(raw.charge) }),
    ...(raw.refund && { refund: deriveRawToRefund(raw.refund) }),
    ...(raw.payment && { payment: deriveRawToinvoicePayment(raw.payment) }),
    ...(raw.paymentGroup && { paymentGroup: deriveRawToinvoicePaymentGroup(raw.paymentGroup) }),
    ...(raw.credit && { credit: deriveRawToRefund(raw.credit) }),
    ...(raw.invoice && {
      invoice: {
        ...deriveRawToInvoiceForInner(raw.invoice),
        ...(raw.type !== INVOICE && { payments: [] }),
      },
    }),
  };
};
