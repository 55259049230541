import { currency } from '$gbusiness/helpers/util';
import { InfoItem } from '$gcomponents/reusables';
import { TableSummaryWrapper } from './styles';

export const adjustFilterToRank = (filter, topOnly = false) => {
  const { top } = filter;
  if (top.includes('TOP')) {
    return ['TOP', parseInt(top.replace('TOP', '') || 0)];
  } else if (top.includes('BOTTOM')) {
    return ['BOTTOM', parseInt(top.replace('BOTTOM', '') || 0)];
  }
  return [undefined, undefined];
};

export const renderTableSummary = (summary) => {
  return (
    <TableSummaryWrapper>
      {summary && (
        <>
          <InfoItem className="item" label="SCREEN.REPORT.TOTAL_TAXABLE" value={currency(summary.taxable)} />
          <InfoItem className="item" label="SCREEN.REPORT.TOTAL_TOTAL" value={currency(summary.total)} />
        </>
      )}
    </TableSummaryWrapper>
  );
};
