import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import { storeReportExportSettings, STORE_REPORT_TABLE, STORE_REPORT_TABLE_CONFIG } from './storeReportTable';
import ReportComponent from '../reportComponent';
import PATH from '$business/enums/paths';
import { toNumber } from '$gbusiness/helpers/util';
import intl from '$gintl';
import { STORE_REPORT_FILTER_FORM } from './filters';
import { itemActions } from '$fbusiness/redux/item';
import { userActions } from '$fbusiness/redux/user';
import { renderTableSummary } from '../utils';
interface StoreReportScreenProps {
  factory;
  history;
  match;
  stores;
  regioins;
  items;
  users;
  dateFilter;
}

const defaultFilter = {
  query: '',
};

const StoreReportScreen: React.FC<StoreReportScreenProps> = ({
  history,
  factory,
  stores: allStores,
  users: allUsers,
  dateFilter,
  match,
  items,
}) => {
  const { params } = match;
  const { regionId, userId, itemId } = params;
  const { regions, settings } = factory;

  const [filter, setFilter] = useState<any>({
    ...dateFilter,
    ...defaultFilter,
    ...(toNumber(regionId) && { regionId: toNumber(regionId) }),
    ...(toNumber(userId) && { userId: toNumber(userId) }),
    itemId: undefined,
    ...(toNumber(itemId) && { itemId: toNumber(itemId) }),
  });
  const [reps, setReps] = useState<any>(null);
  const [stores, setStores] = useState<any>(allStores);
  const [name, setName] = useState('');
  const [tableSummary, setTableSummary] = useState<any>(null);

  useEffect(() => {
    if (items.length <= 0) return;
    let region, user, item;
    if (toNumber(regionId)) {
      region = regions.find((i) => i.id === toNumber(regionId));
    }
    if (toNumber(userId)) {
      user = allUsers.find((i) => i.userId === toNumber(userId));
    }
    if (toNumber(itemId)) {
      item = items.find((i) => i.id === toNumber(itemId));
    }
    setFilter({ ...filter, region, user, item });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length]);

  const handleRowClick = (storeId) => {
    history.push(
      `${PATH.REPORT_ITEM_DETAILS}/${filter?.userId ? filter.userId : 0}/${storeId || 0}/${
        filter?.itemId || 0
      }`,
    );
  };

  const onTableLoad = (total, newTotal, response) => {
    if (response.name) setName(intl('SCREEN.REPORT.TITLE_SALES_BY', { name: response.name }));
    else setName(intl('SCREEN.REPORT.TITLE_STORE_SALES'));

    setTableSummary(response.summary);
  };

  const onChangeRegion = async ({ value, newValue, setFieldValue }) => {
    const region = regions.find((r) => newValue?.id === r.id || value?.id === r.id);

    setReps(region?.salesReps || allUsers);
    const stores = allStores.filter((s) => s.regionId === newValue?.id);
    setStores(stores.length > 0 ? stores : allStores);

    // Adjust store and user
    setFieldValue('user', undefined);
    setFieldValue('store', undefined);
    setFilter({ ...filter, user: undefined, userId: undefined, store: undefined, storeId: undefined });
  };

  const onChangeItem = (item) => {
    setFilter({ ...filter, itemId: item?.id || 0 });
  };

  const extraForm = STORE_REPORT_FILTER_FORM({
    items,
    regions,
    users: reps ? reps : allUsers,
    onChangeRegion,
    onChangeItem,
    settings,
  });

  return (
    <ReportComponent
      titleText={name}
      table={STORE_REPORT_TABLE(handleRowClick)}
      tableConfig={STORE_REPORT_TABLE_CONFIG}
      onTableLoad={onTableLoad}
      includeReportType
      tableSummary={renderTableSummary(tableSummary)}
      dateFilter={filter}
      extraForm={extraForm}
      stores={stores}
      exportSetting={storeReportExportSettings}
    />
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  stores: state.store.stores,
  items: state.item.list,
  users: state.user.users,
});

const mapDispatchToProps = (dispatch) => ({
  onHydrate: async () => {
    await dispatch(userActions.fetchUsers({ storeUser: true, storeId: 1 }));
    dispatch(itemActions.fetchItemList());
  },
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(StoreReportScreen));
