import { configs } from '$configs';
import { SORT, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { TableModel } from '$gbusiness/models/table';

import { currency } from '$gbusiness/helpers/util';

import { format } from '$gbusiness/helpers/date';
import ExportModel from '$gbusiness/models/export';
import { deriveRawToRefund, toRefundId } from '$fbusiness/models/refund';
import { COMMINVOICE_TABLE_WIT_CONFIG } from './commInvoiceTable';

export const SN = 'SCREEN.INVOICES.';
export const SNC = SN + 'COLS.';
export const NUM_PREV_COMMISSION = 20;

export const commRefundExportSettings = (isCredit = false): ExportModel => ({
  url: configs.api.refund,
  method: 'GET',
  fileName: isCredit ? 'commission_credit' : 'commission_refund',
  deriveToModel: (row) => deriveRawToRefund(row),
  deriveTable: (item) => ({
    Customer: item.store.name,
    ID: toRefundId(item.id),
    RefundDate: format(item.refundedAt, DATE_FORMATS.SLASH),
    Status: item.status,
    ItemQty: item.qty,
    Subtotal: currency(item.taxable),
    Balance: currency(item.balance),
    Commission: currency((item.taxable * item.commRate) / 100),
  }),
  sortKey: 'id',
  sortOrder: SORT.ASC,
});

export const COMMREFUND_TABLE_CONFIG = (defaultParam): TableModel => {
  const table = COMMINVOICE_TABLE_WIT_CONFIG(0);
  return {
    ...table,
    dataSource: {
      ...table.dataSource,
      deriveToModel: deriveRawToRefund,
      endpoint: configs.api.refund,
      defaultParam,
      defaultSortKey: 'id',
    },
  };
};

export const COMMREFUND_TABLE = (): Array<CellModel> => [
  {
    label: 'SCREEN.REFUNDS.COLS.STORES',
    value: 'storeName',
    alwaysShow: true,
    width: 300,
  },
  {
    label: 'COMMON.ID',
    value: 'id',
    component: (row) => <>{toRefundId(row.id)}</>,
    alwaysShow: true,
    width: 120,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.REFUND_DATE',
    value: 'refundedAt',
    sortKey: 'refundedAt',
    align: 'center',
    component: (row) => <>{format(row.refundedAt, DATE_FORMATS.SLASH) || ''}</>,
    alwaysShow: true,
    width: 250,
    sortable: SORT.DSC,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.STATUS',
    value: 'status',
    alwaysShow: true,
    width: 220,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.ITEMS',
    value: 'qty',
    className: 'metadata',
    alwaysShow: true,
    width: 100,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.SUBTOTAL',
    value: 'total',
    className: 'center',
    sortable: SORT.ASC,
    component: (row) => <>{currency(row.taxable)}</>,
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.BALANCE',
    value: 'balance',
    className: 'center',
    sortable: SORT.ASC,
    component: (row) => <>{currency(row.balance)}</>,
    alwaysShow: true,
    width: 200,
  },
  {
    label: SNC + 'COMMISSION',
    value: 'commission',
    align: 'center',
    component: (row) => {
      return <>{currency((row.taxable * row.commRate) / 100)}</>;
    },
    sortable: SORT.DSC,
    width: 260,
  },
];
