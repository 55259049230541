import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import TaxModel from '$fbusiness/models/tax';

export const CATEGORY_FORM = (categories, taxes: Array<TaxModel> = []): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'INPUT.LABEL.CATEGORY_NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 12,
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    items: [
      {
        label: 'INPUT.LABEL.DEPARTMENT',
        value: 'deptIds',
        input: INPUT.RADIO,
        disableFastField: true,
        options: categories.map((d) => ({
          labelText: d.name,
          value: d.id,
        })),
        gridSize: 12,
        isMultiline: true,
        validation: [
          {
            condition: (v) => !v.length,
            message: 'REQUIRED',
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        label: 'INPUT.LABEL.NO_TAX',
        value: 'noTax',
        input: INPUT.MULTI_SELECT,
        options: taxes.map((t) => ({
          labelText: t.name,
          value: t.id,
        })),
        gridSize: 12,
        validation: [input.requiredValidation],
      },
    ],
  },
];

export const CATEGORY_PHOTO_FORM = [
  {
    items: [
      {
        ...INPUT_TYPES.IMAGE,
        gridSize: 12,
        gridHeight: '200px',
        gridWidth: '200px',
      },
    ],
  },
];

export default CATEGORY_FORM;
