import React from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import {
  regionReportExportSettings,
  REGION_REPORT_TABLE,
  REGION_REPORT_TABLE_CONFIG,
} from './regionReportTable';
import ReportComponent from '../reportComponent';
import PATH from '$business/enums/paths';
import REGION_FILTER_FORM from '../storeReportScreen/filters';
interface RegionReportScreenProps {
  factory;
  history;
  dateFilter;
}

const RegionReportScreen: React.FC<RegionReportScreenProps> = ({ history, dateFilter, factory }) => {
  const { settings, regions } = factory;

  const handleRowClick = (regionId) => {
    history.push(`${PATH.REPORT_STORE}/${regionId}`);
  };

  const onTableLoad = (total, newTotal, response) => {
    console.log('ON TABLE LOAD REP', total, newTotal, response);
    // Display Summary info
  };

  return (
    <ReportComponent
      title="SCREEN.REPORT.TITLE_REGION"
      table={REGION_REPORT_TABLE(handleRowClick)}
      tableConfig={REGION_REPORT_TABLE_CONFIG}
      includeReportType
      onTableLoad={onTableLoad}
      dateFilter={dateFilter}
      extraForm={REGION_FILTER_FORM(regions, () => {}, settings)}
      exportSetting={regionReportExportSettings}
    />
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
});

const mapDispatchToProps = {};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(RegionReportScreen));
