import { INPUT } from '$gbusiness/enums';
import { insertEmptyOption } from '$gbusiness/helpers/input';
import InputRowModel from '$gbusiness/models/inputRow';

const STORE2_FILTER_FORM = ({
  terms,
  shippingMethods,
  regions,
  users,
  onChangeUser,
  onChangeRegion,
  settings,
}): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.STORES.COLS.REGION',
        disableFastField: true,
        gridSize: 3,
        value: 'region',
        className: 'store-selector',
        config: {
          optionProp: {
            label: 'name',
            value: 'id',
          },
          data: regions,
        },
        onChange: (load) => {
          const { setFieldValue, value, newValue } = load;
          if (!value) setFieldValue('region', newValue);
          onChangeRegion(load);
          setFieldValue('user', undefined);
          setFieldValue('store', undefined);
        },
      },
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.STORES.COLS.SALESMAN',
        disableFastField: true,
        gridSize: 3,
        isHidden: () => !settings?.commission,
        value: 'user',
        config: {
          optionProp: {
            label: 'name',
            value: 'userId',
          },
          data: users,
        },
        onChange: ({ formValues, newValue }) => onChangeUser(formValues, newValue),
      },
      {
        input: INPUT.SELECT,
        label: 'SCREEN.STORES.COLS.PAYMENT_TERM',
        disableFastField: true,
        gridSize: 3,
        isHidden: () => !settings?.terms,
        value: 'termId',
        options: insertEmptyOption(
          (terms || []).map((m) => ({
            labelText: m.name,
            value: m.id,
          })),
          'All',
        ),
      },
      {
        input: INPUT.SELECT,
        label: 'SCREEN.STORES.COLS.SHIPPING_METHOD',
        disableFastField: true,
        gridSize: 3,
        value: 'shippingMethodId',
        options: insertEmptyOption(
          (shippingMethods || []).map((m) => ({
            labelText: m.name,
            value: m.id,
          })),
          'All',
        ),
      },
    ],
  },
];

export default STORE2_FILTER_FORM;
