import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import { DATE_FILTER_ITEMS } from '$fcomponents/filters/filterForm';
import { MOCK_PAYMENT_OPTIONS } from '$fbusiness/enums/options/mockPayment';
import { MOCK_INVOICE_OPTIONS } from '$fbusiness/enums/options/invoiceStatus';

export const MOCK_INVOICE_FORM = (stores): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'SCREEN.MOCK.LABEL.ORDER_INVOICE',
        value: 'isOrder',
        input: INPUT.RADIO,
        options: [
          { value: 1, labelText: 'Order' },
          { value: 0, labelText: 'Invoice' },
        ],
        gridSize: 4,
      },
      {
        label: 'SCREEN.MOCK.LABEL.INVOICE_COUNT',
        value: 'invoiceCount',
        input: INPUT.NUMBER,
        gridSize: 3,
      },
      {
        label: '',
        value: '',
        input: INPUT.HIDDEN,
        gridSize: 5,
      },
      ...DATE_FILTER_ITEMS,

      {
        label: 'SCREEN.MOCK.LABEL.STORES',
        value: 'selectedStores',
        input: INPUT.MULTI_SELECT,
        options: stores.map((s) => ({
          labelText: s.name,
          value: s.id,
        })),
        // isMultiline: true,
        gridSize: 12,
        validation: [input.requiredValidation],
      },
    ],
  },
];

export const MOCK_PAYMENT_FORM = (): Array<InputRowModel> => [
  {
    items: [
      ...DATE_FILTER_ITEMS,
      {
        label: 'SCREEN.MOCK.LABEL.PAYMENT_TIME',
        value: 'paymentDays',
        input: INPUT.NUMBER,
        postfix: 'days',
        gridSize: 3,
      },
      {
        label: 'SCREEN.MOCK.LABEL.PAYMENT_OPTIONS',
        value: 'paymentOptions',
        input: INPUT.MULTI_SELECT,
        options: MOCK_PAYMENT_OPTIONS,
        // isMultiline: true,
        gridSize: 8,
        validation: [input.requiredValidation],
      },
    ],
  },
];

export const MOCK_STATUS_FORM = (): Array<InputRowModel> => [
  {
    items: [
      ...DATE_FILTER_ITEMS,
      {
        label: 'SCREEN.MOCK.LABEL.STATUS_TIME',
        value: 'statusDays',
        input: INPUT.NUMBER,
        postfix: 'days',
        gridSize: 3,
      },
      {
        label: 'SCREEN.MOCK.LABEL.INVOICE_STATUS',
        value: 'status',
        input: INPUT.MULTI_SELECT,
        options: MOCK_INVOICE_OPTIONS,
        // isMultiline: true,
        gridSize: 8,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.MOCK.LABEL.STATUS_RATE',
        value: 'statusRate',
        input: INPUT.NUMBER,
        postfix: '% of Invoices',
        gridSize: 3,
      },
    ],
  },
];
