import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, defaultTable, TableModel } from '$gbusiness/models/table';
import { INVOICE_PAYMENTS } from '$fbusiness/enums/options/paymentType';

import { deriveRawToinvoicePayment } from '$fbusiness/models/invoicePayment';
import { CheckCircle, Delete, Edit, RadioButtonUnchecked } from '@mui/icons-material';
import { INVOICE_PAYMENTS_TABLE_MIN } from '$fscreens/accountScreen/invoicesScreen/innerTable';
import { format } from '$gbusiness/helpers/date';
import { currency, toNumber } from '$gbusiness/helpers/util';
import { INVOICE_TABLE_CONFIG } from '../invoicesTable';
import CurrencyInput from '$gcomponents/primitives/currencyInput';

export const INVOICE_PAYMENTS_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.invoice.payment,
    deriveToModel: deriveRawToinvoicePayment,
    method: 'GET',
    defaultSortKey: 'updatedAt',
    defaultSortOrder: SORT.DSC,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 700,
    maxWidth: 1200,
    cellPadding: `2px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 30,
    setRowClass: (row) =>
      row.type === INVOICE_PAYMENTS.DISCOUNT || row.type === INVOICE_PAYMENTS.CREDITS ? 'discount-row' : '',
  },
};

export const INVOICE_PAYMENTS_TABLE = (onEditPayment, onDeletePayment, currentIndex): Array<CellModel> => [
  ...INVOICE_PAYMENTS_TABLE_MIN(),
  {
    label: '',
    value: '',
    className: 'action',
    component: (row, actions, i) => (
      <>
        {currentIndex !== i && row.type !== INVOICE_PAYMENTS.CREDITS && (
          <>
            <Edit onClick={() => onEditPayment(row, i)} className="pointer" />
            <Delete onClick={() => onDeletePayment(row, i)} className="pointer" />
          </>
        )}
        {currentIndex === i && row.type !== INVOICE_PAYMENTS.CREDITS && (
          <span onClick={() => onEditPayment(row, i)} className="pointer">
            Cancel
          </span>
        )}
      </>
    ),
    minWidth: 60,
    width: 60,
  },
];

export const INVOICE_SIMPLE_TABLE_CONFIG = (
  paymentGroupId,
  invoiceId,
  currentPayment,
  paymentGroupMode,
): TableModel => ({
  ...INVOICE_TABLE_CONFIG,
  dataSource: {
    ...INVOICE_TABLE_CONFIG.dataSource,
    autoRefreshInterval: undefined,
    pageSize: 100,
  },
  styles: {
    ...INVOICE_TABLE_CONFIG.styles,
    responsive: false,
    headerHeight: 32,
    rowHeight: 20,
    stickyHeader: true,
    cellPadding: `2px 2px`,
    defaultCollapse: !paymentGroupMode,
    setRowClass: (row) => {
      let className = '';
      if (row.id === invoiceId) className += ' active ';
      // if (selectedInvoices.find((i) => i.id == row.id)) className += ' selected ';
      return className;
    },
  },
  innerConfig: {
    ...defaultTable,
    ...INVOICE_TABLE_CONFIG.innerConfig,
    styles: {
      ...defaultStyles,
      minWidth: 700,
      maxWidth: 900,
      fontSize: '0.8em',
      cellPadding: '2px 2px',
      isStripped: false,
      rowHeight: 20,
      color: 'transparent',
      headerHeight: 24,
      setRowClass: (row) => {
        let className = '';
        if (row.rejectedAt) className += 'cancelled';
        if (row.dirty || paymentGroupId === row.paymentGroupId) className += ' new-item ';
        if (row.id === currentPayment?.id) className += ' selected';
        return className;
      },
    },
  },
});

export const INVOICE_SIMPLE_TABLE = (
  onSelectInvoice,
  onChangeMultiPayment,
  selectedIndexes,
  leftover,
  amount,
  onPaymentInputError,
  validPayment = false,
  paymentGroupId = 0,
): Array<CellModel> => [
  {
    label: '',
    value: '',
    component: (row, actions, i) => (
      <>
        {selectedIndexes.includes(i) ? (
          <CheckCircle
            className={`checked link ${!validPayment && 'disabled'}`}
            onClick={(e) => onSelectInvoice(e, i, true)}
          />
        ) : (
          <RadioButtonUnchecked
            className={`unchecked link ${!validPayment && 'disabled'}`}
            onClick={(e) => onSelectInvoice(e, i)}
          />
        )}
      </>
    ),
    maxWidth: 60,
    width: 60,
  },
  {
    label: 'SCREEN.INVOICES.COLS.CREATED',
    value: 'createdAt',
    component: (row) => <>{row ? format(row.createdAt, DATE_FORMATS.SLASH) || '' : ''}</>,
    // sortable: SORT.ASC,
    width: 180,
  },
  {
    label: 'SCREEN.INVOICES.COLS.INVOICE_NO',
    value: 'invoiceNumber',
    alwaysShow: true,
    className: 'main',
    // sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.INVOICES.COLS.TOTAL',
    value: 'total',
    align: 'center',
    component: (row) => <>{currency(row.totals.finalTotal)}</>,
    // sortable: SORT.ASC,
    alwaysShow: true,
    width: 150,
  },
  {
    label: 'SCREEN.INVOICES.COLS.BALANCE',
    value: 'balance',
    align: 'center',
    component: (row) => (
      <div
        className={`outlined ${row.totals?.balance === 0 && 'success bold'} ${
          row.totals?.balance < 0 && 'danger bold'
        }`}>
        {currency(row.totals.balance || 0)}
      </div>
    ),
    // sortable: SORT.ASC,
    sortKey: 'balance',
    width: 150,
  },
  {
    label: selectedIndexes.length > 0 ? 'SCREEN.INVOICE_PAYMENT.DISTRIBUTION' : 'EMPTY',
    value: 'balance',
    align: 'center',
    component: (row, active, index) => {
      if (!amount || selectedIndexes.length < 1) return <></>;
      const isActive = selectedIndexes.includes(index);
      if (!isActive) return <></>;

      const balance = row.totals?.finalBalance || row.balance;

      const maxInput = Math.min(balance || 0.0001, amount || 0.0001);
      const formAmount = row.newPayment?.amount || '';

      return (
        <CurrencyInput
          label=""
          size="xsmall"
          id={row.id}
          className="multi-payment-input"
          value={formAmount}
          onChange={(value, newValue, prevValue) => {
            if (!paymentGroupId && toNumber(value) !== prevValue && value > maxInput) {
              onPaymentInputError(
                row,
                maxInput,
                value > amount ? 'MESSAGE.MAX_EXCEED_RECEIVED' : 'MESSAGE.MAX_EXCEED_BALANCE',
              );
            }
            onChangeMultiPayment(row, value);
          }}
        />
      );
    },
    width: 150,
    minWidth: 150,
  },
];
