import styled from 'styled-components';

export const WrapperItem = styled.div`
  display: block;
  padding-left: ${(p) => (p.level > 1 ? p.level * 16 : 0)}px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;

  .MuiCheckbox-root {
    padding: 2px 8px;
  }

  .child-arrow {
    position: relative;
    margin: -8px 4px;
    padding: 0px;
    top: -4px;
    opacity: 0.8;
  }
`;
