import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';
import { insertEmptyOption } from '$gbusiness/helpers/input';
import InputRowModel from '$gbusiness/models/inputRow';
import { parseISO } from 'date-fns';

export const SN = 'SCREEN.INVOICES.';
export const SNC = 'SCREEN.INVOICES.COLS.';

export const initialStatementChargeForm = () => ({
  id: 0,
  invoiceNumber: '',
  noteFactory: '',
  store: null,
  invoiceDate: new Date(),
  status: '',
});

export const invoiceToForm = (invoice, isEdit = false) => {
  const { id, invoiceNumber, noteFactory, createdAt, termId, status } = invoice;
  return {
    id,
    invoiceNumber,
    noteFactory,
    ...(isEdit && { invoiceDate: parseISO(createdAt) }),
    termId,
    status,
  };
};

export const STATEMENT_CHARGE_FORM = ({
  stores,
  terms,
  charges,
  onStoreChange,
  onChangeCharge,
  isCustomInvoice = false,
  settings,
}): Array<InputRowModel> => [
  {
    lineSpace: 8,
    items: [
      {
        input: INPUT.DATE,
        label: SNC + 'DATE',
        value: 'invoiceDate',
        size: 'small',
        gridSize: 6,
      },
      {
        label: SNC + 'CHARGE',
        value: 'chargeId',
        disableFastField: true,
        input: INPUT.SELECT,
        gridSize: 6,
        size: 'small',
        options: (charges || []).map((c) => ({
          labelText: c.name,
          value: c.id,
        })),
        onChange: onChangeCharge,
        validation: [input.requiredValidation],
      },
      {
        label: SNC + 'STORE',
        value: 'store',
        disableFastField: true,
        input: INPUT.AUTOCOMPLETE,
        gridSize: 8,
        config: {
          optionProp: {
            label: 'name',
            // labelText: (o) => `${o.name || ''} ${o.code ? `(${o.code})` : ''}`,
            value: 'id',
          },
          data: stores,
        },
        size: 'small',
        options: (stores || []).map((s) => ({
          labelText: s.name,
          value: s.id,
        })),
        validation: [input.requiredValidation],
        onChange: (e) => {
          onStoreChange(e);
        },
      },
      {
        label: SNC + 'TERM',
        value: 'termId',
        isDisabled: (values) => !values.store,
        disableFastField: true,
        input: INPUT.SELECT,
        isHidden: () => !settings?.terms,
        gridSize: 6,
        size: 'small',
        options: insertEmptyOption(
          (terms || []).map((s) => ({
            labelText: s.name,
            value: s.id,
          })),
          'None',
          '0',
        ),
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.AMOUNT,
        label: 'COMMON.AMOUNT',
        value: 'total',
        disableFastField: true,
        gridSize: 6,
        size: 'small',
        validation: [input.requiredValidation, input.amountValidation],
      },
      {
        label: 'INPUT.LABEL.NOTES',
        value: 'noteFactory',
        className: 'ship-input',
        input: INPUT.TEXTAREA,
        gridSize: 12,
        rows: 5,
        size: 'small',
      },
    ],
  },
];

export const INVOICE_CHARGE_FORM = (): Array<InputRowModel> => [
  {
    lineSpace: 8,
    items: [
      {
        input: INPUT.DATE,
        label: SNC + 'DATE',
        value: 'invoiceDate',
        size: 'small',
        gridSize: 6,
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.AMOUNT,
        label: 'COMMON.AMOUNT',
        value: 'total',
        disableFastField: true,
        gridSize: 6,
        size: 'small',
        validation: [input.requiredValidation, input.amountValidation],
      },
      {
        label: 'INPUT.LABEL.NOTES',
        value: 'note',
        className: 'ship-input',
        input: INPUT.TEXTAREA,
        gridSize: 12,
        rows: 5,
        size: 'small',
      },
    ],
  },
];

export default STATEMENT_CHARGE_FORM;
