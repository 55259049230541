import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { SPACE, WEIGHT } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .pop-container button {
    justify-content: flex-start;
  }

  .draggable-row {
    padding-left: 0px !important;
  }
`;

export const WrapperItem = styled.div`
  ${p => (p.isFilteredOut === true ? 'opacity: 0.5; pointer-events: none !important;' : '')}

  ion-item::part(native) {
    min-height: initial;
  }
  ion-icon {
    font-size: 24px;
    margin-right: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  ion-icon.transparent {
    color: transparent;
  }
  ion-label {
    ${p => p.markBold && `font-weight: ${WEIGHT.BOLD};`})}
  }
  .children-indicator {
    margin-left: ${p => p.level * 30 + 'px'};
  }
  .pointer {
    margin-left: ${SPACE.SMALL};
  }
`;
