import React, { useEffect } from 'react';
import { IonLoading } from '@ionic/react';
import { connect } from 'react-redux';

import * as loadAppAction from '$business/redux/init/actions';
import LoadingModel from '$gbusiness/models/loading';
import { dialog } from '$gcomponents/reusables';
import { NormalLayout } from '$components/layouts';
import intl from '$intl';
import LoginModal from '$gcomponents/reusables/loginModal';

interface AppInitProps {
  initSuccess: boolean;
  loadApp: Function;
  loading: LoadingModel;
}

/* Perform necessary action when the app is loaded before UI gets shown */
const AppInit: React.FC<AppInitProps> = (props) => {
  const { loadApp, initSuccess, loading } = props;

  useEffect(() => {
    loadApp();
  }, [loadApp]);

  const { isLoading, loadingText, hasError } = loading;

  if (initSuccess === false && hasError) {
    dialog.alert({
      title: 'ERROR.NETWORK',
      message: 'MESSAGE.INIT_FAIL',
    });
    return null;
  }

  const Router: any = NormalLayout;

  return (
    <>
      {isLoading && <IonLoading isOpen={true} animated={false} message={intl(loadingText)} />}
      <LoginModal />
      {initSuccess && <Router />}
    </>
  );
};

const mapStateToProps = (state) => ({
  initSuccess: state.init.initSuccess,
  loading: state.loading,
});

const mapDispatchToProps = {
  loadApp: loadAppAction.loadApp,
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(AppInit);

export default connected;
