import { Div } from '$gstyles';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .last-column {
    align-items: center;
    button {
      top: 4px;
    }
  }
  .MuiTableRow-root {
    &.danger {
      background-color: var(--ion-color-danger);
      .MuiTableCell-root {
        color: var(--ion-color-danger-contrast-bw);
        > * {
          color: var(--ion-color-danger-contrast-bw);
        }
      }
    }
    &.warning {
      background-color: #ffee99;
    }
    &.light {
      background-color: #f5f5f5;
    }

    .expiration {
      font-size: 0.9em;
      font-style: italic;
    }
  }

  .summary-section {
    max-width: 200px;
  }
  .export-container {
    position: absolute;
    top: 0px;
    right: 32px;
    display: flex;
  }
`;

export const FilterWrapper = styled(Div)`
  position: relative;
`;
