export const KEYS = {
  SALES: 'SALES',
  // ITEMS: 'ITEMS',
  // STORES: 'STORES',
  // FILES: 'FILES',
  // USERS: 'USERS',
  // SETTINGS: 'SETTINGS',
  // ALL: 'ALL',
};

export const DATE_TYPE = KEYS;

const LIST = Object.keys(KEYS);

export default LIST.map((item) => ({
  label: `DATA_TYPE.${item}`,
  value: item,
}));

export type DATA_TYPE_TYPE = typeof LIST[number] | undefined;
