import React, { useEffect, useState } from 'react';
import intl from '$intl';
import { IonContent, IonFooter, IonHeader } from '@ionic/react';

import { SPACE } from '$gstyles';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { useDispatch } from 'react-redux';
import { SerialSelectModalWrapper } from './styles';
import SerialSelector from './serialSelector';
import ItemBatchModel from '$fbusiness/models/itemBatch';
import { batchActions } from '$fbusiness/redux/batch';
import { Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import { BATCH_QTY_FORM } from '../batchesScreen/addSerialForm';

interface SerialRemoveModalProps {
  show: boolean;
  onClose: Function;
  onRefresh: Function;
  batch: ItemBatchModel;
}

const SerialRemoveModal: React.FC<SerialRemoveModalProps> = ({ show, batch, onRefresh, onClose }) => {
  const dispatch = useDispatch();
  const [serials, setSerials] = useState<Array<any>>([]);
  const item = batch?.item;
  const titleText = item ? `${item.name} - Receiving #${batch.receiveNo}` : 'Remove Products';

  const selections = serials.filter((s) => s.selected);

  useEffect(() => {
    if (!batch) return;
    setSerials(batch.serials);
  }, [batch]);

  const onChangeSerials = (newSerials) => {
    setSerials(newSerials);
  };

  const onFinish = (values) => {
    // onSubmit(selections.map((s) => s.serial));
    const selectedSerials = selections.map((s) => s.id);
    dispatch(batchActions.removeProducts(batch.id, values.quantity, selectedSerials)).then(() => {
      onRefresh();
      onClose();
    });
  };

  const qtyForm = BATCH_QTY_FORM();

  return (
    <SerialSelectModalWrapper titleText={titleText} show={show} onClose={() => onClose()} useCustom>
      <Formik
        enableReinitialize={true}
        initialValues={{ quantity: batch?.quantity || 1 }}
        onSubmit={(values) => {
          onFinish(values);
        }}>
        {(formik) => {
          const { values } = formik;
          return (
            <>
              <IonHeader>
                <div className="qty-header">
                  <FormSection FORM={qtyForm} formik={formik} marginBottom="0" />
                </div>
              </IonHeader>
              <IonContent scrollY={false}>
                <PageWrapper className="full-height">
                  <SerialSelector
                    serials={serials}
                    selections={selections}
                    maxQty={values?.quantity}
                    onChangeSerials={onChangeSerials}
                    selectText="Serials to Remove"
                  />
                </PageWrapper>
              </IonContent>
              <IonFooter>
                <Flex padding={SPACE.MEDIUM} justifyContent="space-around">
                  <Button variant="outlined" onClick={() => onClose()}>
                    {intl('BUTTON.CANCEL')}
                  </Button>
                  <Button className="third" onClick={formik.handleSubmit}>
                    {intl('BUTTON.OK')}
                  </Button>
                </Flex>
              </IonFooter>
            </>
          );
        }}
      </Formik>
    </SerialSelectModalWrapper>
  );
};

export default SerialRemoveModal;
