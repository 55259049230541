import React from 'react';
import intl from '$intl';

import { ModalWrapper } from './styles';

import CommRuleModel from '$fbusiness/models/commRule';
import CommRuleDetails from './commRuleDetails';

interface CommRuleModalProps {
  show: boolean;
  onClose: Function;
  commRule: CommRuleModel;
}

const CommRuleModal: React.FC<CommRuleModalProps> = ({ show, commRule, onClose }) => {
  const titleText = intl('ITEM.' + (commRule?.id ? 'EDIT' : 'NEW'), { item: intl('COMMON.COMMISSION_RULE') });

  return (
    <ModalWrapper titleText={titleText} show={show} onClose={() => onClose()} useCustom>
      {commRule && <CommRuleDetails commRule={commRule} onClose={onClose} />}
    </ModalWrapper>
  );
};

export default CommRuleModal;
