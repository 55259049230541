import React from 'react';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import CellModel from '$gbusiness/models/cell';
import { deriveRawToSalesman } from '$fbusiness/models/salesman';
import { percentage } from '$gbusiness/helpers/util';
import ExportModel from '$gbusiness/models/export';
import { deriveRawToUser } from '$gbusiness/models/user';

export const salesmenExportSettings: ExportModel = {
  url: configs.api.salesmen,
  method: 'GET',
  deriveToModel: deriveRawToUser,
  deriveTable: (item) => ({
    FirstName: item.firstName,
    LastName: item.lastName,
    Territories: (item.other?.regions || []).map((r) => r.name).join(','),
    Email: item.email,
    CommissionRate: item.commission ? percentage(item.commission.rate) : percentage(0),
  }),
  sortKey: 'last_name',
  sortOrder: SORT.ASC,
};

export const SALESMAN_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.salesmen,
    method: 'GET',
    defaultParam: { storeId: 1 },
    deriveToModel: deriveRawToSalesman,
    defaultSortKey: 'last_name',
    defaultSortOrder: SORT.ASC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 300,
    maxWidth: 800,
    cellPadding: ` 8px 4px`,
    isStripped: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const SALESMAN_TABLE = (onClickRow): Array<CellModel> => [
  {
    label: 'SCREEN.SALESMEN.COLS.FIRST_NAME',
    value: 'first_name',
    component: (row) => (
      <div className="link" onClick={() => onClickRow(row)}>
        {row.firstName}
      </div>
    ),
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.SALESMEN.COLS.LAST_NAME',
    value: 'last_name',
    component: (row) => (
      <div className="link" onClick={() => onClickRow(row)}>
        {row.lastName}
      </div>
    ),
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.REGIONS.TITLE',
    value: 'regionIds',
    component: (row) => {
      return (
        <div>
          {(row.other?.regions || []).map((r) => (
            <div>{r.name}</div>
          ))}
        </div>
      );
    },
    width: 180,
  },
  {
    label: 'SCREEN.SALESMEN.COLS.EMAIL',
    value: 'email',
    className: 'desktop',
    sortable: SORT.ASC,
    width: 300,
  },
  {
    label: 'SCREEN.SALESMEN.COLS.RATE',
    value: 'rate',
    align: 'center',
    component: (row) => <div>{row.commission ? percentage(row.commission.rate) : percentage(0)}</div>,
    width: 200,
  },
];
