import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import intl from '$intl';
import { IonContent } from '@ionic/react';
import { Formik } from 'formik';

import { PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$gbusiness/helpers';
import Footer from '$gcomponents/widgets/footer';
import { deriveDatetime } from '$gbusiness/helpers/date';
import StoreModel from '$fbusiness/models/store';

import { INVOICE_CHARGE_FORM, STATEMENT_CHARGE_FORM } from './statementChargeForm';
import { toNumber } from '$gbusiness/helpers/util';
import FactoryModel from '$fbusiness/models/factory';
import { INVOICE_NUMBER } from '$fbusiness/enums/options/invoiceNumber';
import { invoiceActions } from '$fbusiness/redux/invoice';

interface StatementChargeDetailsProps {
  onSaved: Function;
  store: StoreModel;
  stores: Array<StoreModel>;
  statementCharge: any;
  factory: FactoryModel;
  onClose: Function;
}

const StatementChargeDetails: React.FC<StatementChargeDetailsProps> = ({
  statementCharge,
  factory,
  store,
  stores,
  onSaved,
  onClose,
}) => {
  const formRef = useRef<any>();
  const dispatch = useDispatch();

  const { terms, charges = [], settings } = factory;
  const isCustomInvoice = factory.settings.invoiceStyle === INVOICE_NUMBER.CUSTOM;

  const defaultCharge: any = statementCharge?.chargeId
    ? (charges || []).find((c) => c.id === statementCharge?.chargeId) || charges[0]
    : charges[0];

  const initialValues = {
    ...statementCharge,
    invoiceDate: new Date(),
    ...(store && {
      store,
      storeId: store?.id,
      termId: store?.termId || undefined,
    }),
    ...(!statementCharge?.id && {
      chargeId: defaultCharge?.id,
      total: defaultCharge.defaultAmount,
      noteFactory: '',
    }),
    ...(statementCharge?.isInvoiceCharge && {
      termId: statementCharge?.termId,
      total: 0,
    }),
  };

  const onStoreChange = (result) => {
    const { value, formik, formValues } = result;
    if (!value) {
      return;
    }
    const newStoreId = toNumber(value.id);
    const newStore = stores.find((s) => s.id === newStoreId);
    const newValues = {
      store: newStore,
      ...(newStore?.termId && {
        termId: newStore?.termId,
        term: terms.find((t) => t.id === newStore?.termId),
      }),
    };
    formik?.setValues({ ...formValues, ...newValues });
  };

  const onChangeCharge = ({ newValue, value, setFieldValue }) => {
    const charge = charges.find((c) => toNumber(newValue || value) === c.id);
    if (!charge) return;
    setFieldValue('total', charge.defaultAmount);
  };

  const statementChargeForm = statementCharge?.isInvoiceCharge
    ? INVOICE_CHARGE_FORM()
    : STATEMENT_CHARGE_FORM({
        stores,
        terms,
        charges,
        onStoreChange,
        isCustomInvoice,
        onChangeCharge,
        settings,
      });

  const onSubmitCharge = async (values) => {
    const { total, invoiceDate, chargeId, noteFactory, storeId, termId } = values;
    const param = {
      storeId: storeId || null,
      chargeId: chargeId || null,
      created_at: deriveDatetime(invoiceDate),
      total: toNumber(total),
      termId: termId || null,
      balance: toNumber(total),
      noteFactory,
      isInvoiceCharge: statementCharge?.isInvoiceCharge,
      id: statementCharge?.id || 0,
    };

    if (statementCharge?.id) {
      await dispatch(invoiceActions.updateInvoice(param));
      onSaved();
      return;
    }
    await dispatch(invoiceActions.createInvoice(param, true));
    onSaved();
  };

  const onSubmitInvoiceCharge = async (values) => {
    const { total, note, storeId } = values;
    const param = {
      storeId: storeId || null,
      invoiceId: statementCharge?.invoiceId,
      total: toNumber(total),
      note,
      id: statementCharge?.id || 0,
    };

    // console.log('PARAM', param, statementCharge);
    // return;

    if (statementCharge?.id) {
      await dispatch(invoiceActions.updateInvoiceCharge(param));
      onSaved();
      return;
    }

    await dispatch(invoiceActions.createInvoiceCharge(param));
    onSaved();
  };

  const validateForm = (values) => {
    return input.validateError(statementChargeForm, values);
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        if (statementCharge?.isInvoiceCharge) onSubmitInvoiceCharge(values);
        else onSubmitCharge(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <PageWrapper>
              <FormSection FORM={statementChargeForm} formik={formik} />
            </PageWrapper>
          </IonContent>
          <Footer justifyContent="space-around">
            <Button className="third" variant="outlined" onClick={() => onClose()}>
              {intl('BUTTON.CANCEL')}
            </Button>
            <Button className="half" onClick={formik.handleSubmit} disabled={!formik.isValid}>
              {intl('BUTTON.SUBMIT')}
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default StatementChargeDetails;
