import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$fcomponents/hoc';
import { TableView2, Header } from '$gcomponents/widgets';

import { REGION_TABLE_CONFIG, REGION_TABLE } from './regionTable';
import { IonPageWrapper } from './styles';
import FactoryModel from '$fbusiness/models/factory';
import CurrentStateModel from '$fbusiness/models/currentState';
import RegionEditModal from './regionEditModal';
import ItemActionModel from '$gbusiness/models/itemAction';
import { factoryActions } from '$fbusiness/redux/factory';
import { dialog } from '$gcomponents/reusables';
import { configs } from '$configs';
import { initialRegion } from '$fbusiness/models/region';
import FabButton from '$fcomponents/fabButton';

interface RegionsScreenProps {
  factory: FactoryModel;
  currentState: CurrentStateModel;
  deleteRegion;
}

const RegionsScreen: React.FC<RegionsScreenProps> = ({ factory, deleteRegion }) => {
  const [region, setRegion] = useState<any>(null);

  if (!factory) return <IonPageWrapper />;
  const { regions } = factory;

  const onCloseModal = async () => {
    setRegion(null);
  };

  const itemActions: Array<ItemActionModel> = [
    {
      label: 'BUTTON.EDIT',
      eventName: 'editItem',
      onClick: setRegion,
    },
    {
      label: 'BUTTON.DELETE',
      eventName: 'deleteItem',
      onClick: (row) => {
        dialog.confirm({
          message: 'MESSAGE.DELETE_WARNING',
          onPress: async () => {
            await deleteRegion(row.id, configs.api.region, factoryActions.fetchRegionsF);
          },
        });
      },
    },
  ];

  return (
    <IonPageWrapper>
      <Header title="SCREEN.REGIONS.TITLE" />
      <IonContent>
        <TableView2
          data={regions}
          tableConfig={REGION_TABLE_CONFIG}
          TABLE={REGION_TABLE()}
          itemActions={itemActions}
        />
      </IonContent>
      <RegionEditModal region={region} onClose={onCloseModal} />
      <FabButton onClick={() => setRegion(initialRegion)} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: () => factoryActions.fetchRegions,
  deleteRegion: factoryActions.deleteObj,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(RegionsScreen));
