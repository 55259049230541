import { configs } from '$configs';
import { checkStockHelper } from '$fbusiness/redux/cart/utils';
import { DATE_FORMATS } from '$gbusiness/enums';
import { dateToString } from '$gbusiness/helpers/date';
import { formatAddress, toNumber } from '$gbusiness/helpers/util';
import { deriveRawToUser } from '$gbusiness/models/user';
import { fetchApi } from '$gbusiness/services/api';
import { addDays } from 'date-fns';

export function getDiscountRate(discounts: Array<any> = [], id) {
  const discount = discounts.find((d) => d.id === id);
  if (!id || !discount) return [0, 0];
  const rate = discount.isFlat ? 0 : toNumber(discount.amount);
  const flat = discount.isFlat ? toNumber(discount.amount) : 0;
  return [rate, flat];
}

export function generateShipFrom(factory) {
  const { formatted } = factory;
  return formatAddress(formatted, '\n');
}

export function generateShipAddress(store) {
  const { shippingAddress } = store;
  return formatAddress(shippingAddress?.formatted || '', '\n');
}

export function getDueDate(param: any = {}) {
  const { terms = [], termId = 0, invoiceDate = new Date(), dateFormat = DATE_FORMATS.DATE } = param;
  const term: any = terms.find((t: any) => t?.id === termId);
  const dueDays = term?.dueDays || 1;
  const dueDate = addDays(invoiceDate, dueDays);
  return dateToString(dueDate, dateFormat);
}

export function checkStockInvoice(invoice, item, key = 'qtySent') {
  const { quantity, itemId } = item;
  return checkStockHelper(invoice.items, itemId, quantity, key);
}

export async function fetchUser(userId) {
  const response = await fetchApi({
    url: configs.api.users.one + userId,
    method: 'GET',
  });

  if (!response || !response.data) return null;
  return deriveRawToUser(response.data);
}

export function getCommissionRate(invoice, commission) {
  if (!commission || !invoice) return 0;
  if (invoice.totalDiscount > 0) {
    const { settings } = commission;
    if (!invoice.discountRate) return toNumber(commission.discountRate);
    if (settings && settings.enableDiscount) {
      const { commission1, discountRate1, commission2, discountRate2 } = settings;
      if (!invoice.discountRate) return commission.rate;
      if (invoice.discountRate <= discountRate1) return toNumber(commission1);
      if (invoice.discountRate >= discountRate2) return toNumber(commission2);
    }
    return toNumber(commission.discountRate);
  }
  return toNumber(commission.rate);
}

export function getCommissionAmt(invoice, commission) {
  if (!invoice) return 0;
  const { taxable } = invoice;
  const commRate = getCommissionRate(invoice, commission);
  return Math.round(taxable * commRate) / 100;
}
