import { SORT } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { emailLink, phoneLink } from '$gcomponents/utils/general';

export const VENDOR_TABLE_CONFIG: TableModel = {
  dataSource: defaultDataSource,
  styles: {
    ...defaultStyles,
    fontSize: '1.05em',
    responsive: true,
    minWidth: 500,
    maxWidth: 1300,
    cellPadding: ` 8px 4px`,
    borderColor: 'var(--ion-border-color)',
    isStripped: false,
    disableSort: false,
  },
};

export const VENDOR_TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.FACTORY.VENDOR.NAME',
    value: 'name',
    className: 'main',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.FACTORY.VENDOR.CODE',
    value: 'settings.code',
    className: 'desktop',
    width: 120,
  },
  {
    label: 'SCREEN.FACTORY.VENDOR.PHONE_EMAIL',
    value: '',
    component: (row) => (
      <div>
        {row.settings.phone && <div>{phoneLink(row.settings.phone)}</div>}
        {row.settings.email && <div>{emailLink(row.settings.email)}</div>}
      </div>
    ),
    width: 300,
  },
  {
    label: 'SCREEN.FACTORY.VENDOR.ADDRESS',
    value: 'settings.address',
    component: (row) => {
      if (!row.settings?.address) return null;
      const {
        address: { street, state, city, zip },
      } = row.settings;
      return (
        <>
          {street && (
            <div>
              <div>{street}</div>
              <div>{`${city}, ${state} ${zip}`}</div>
            </div>
          )}
        </>
      );
    },
    className: 'desktop',
    width: 250,
  },
  {
    label: 'SCREEN.FACTORY.VENDOR.SALES',
    value: 'settings.code',
    component: (row) => {
      const { salesEmail, salesRep } = row.settings;
      return (
        <div>
          {salesRep && <div>{salesRep}</div>}
          {salesEmail && <div>{emailLink(salesEmail)}</div>}
        </div>
      );
    },
    className: 'desktop',
    width: 300,
  },
  {
    label: 'SCREEN.ITEMS.COLS.ACTION',
    value: '',
    className: 'action nowrap',
    align: 'center',
    component: (row, actions) => {
      return (
        <Button
          className="left"
          color="secondary"
          fullWidth
          onClick={(e) => actions.handlePopoverOpen(e, row)}>
          <MoreHorizIcon />
        </Button>
      );
    },
    width: 100,
  },
];
