import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { isAfter } from 'date-fns';
import { SN } from './commPayTable';

export const GENERATE_PAY_FORM = (): Array<InputRowModel> => [
  {
    items: [
      // {
      //   label: SN + 'PERIOD_START',
      //   value: 'periodStartDate',
      //   disabled: true,
      //   input: INPUT.TEXT,
      //   gridSize: 6,
      // },
      // {
      //   label: SN + 'PERIOD',
      //   value: 'periodDate',
      //   disabled: true,
      //   input: INPUT.TEXT,
      //   gridSize: 6,
      // },
      {
        label: SN + 'PAY_DATE',
        value: 'payDate',
        disabled: true,
        input: INPUT.TEXT,
        gridSize: 6,
      },
      {
        input: INPUT.HIDDEN,
        label: '',
        value: '',
      },
    ],
  },
  {
    items: [
      {
        label: SN + 'START_DATE',
        value: 'reportStartDate',
        input: INPUT.DATE,
        maxDate: new Date(),
        onChange: ({ value, formValues, setFieldValue }) => {
          if (isAfter(value, formValues.reportEndDate)) {
            setFieldValue('reportEndDate', value);
          }
        },
        gridSize: 6,
      },
      {
        label: SN + 'END_DATE',
        value: 'reportEndDate',
        input: INPUT.DATE,
        maxDate: new Date(),
        onChange: ({ value, formValues, setFieldValue }) => {
          if (isAfter(formValues.reportStartDate, value)) {
            setFieldValue('reportStartDate', value);
          }
        },
        gridSize: 6,
      },
    ],
  },
];
