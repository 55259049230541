import { Modal } from '$gcomponents/reusables';
import { Div, FONT, SPACE, WEIGHT } from '$gstyles';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .export-wrapper {
    text-align: right;
    > div {
      display: inline-block;
    }
  }
  .MuiTableHead-root {
    border-bottom: 1px solid #ccc;
  }
  .metadata {
    .title {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .memo {
      font-size: ${FONT.SMALL};
      opacity: 0.8;
    }
  }
  .MuiTableRow-root.processed {
    background-color: #eeffee;
  }
`;

export const ModalWrapper = styled(Modal)`
  .MuiTableCell-head {
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  .MuiTableRow-head {
    .MuiTableCell-head {
      background-color: #eee;
    }
  }
  .notes {
    width: 100%;
    resize: none;
    margin-top: 8px;
    height: 60px;
  }
`;

export const StoreSelectorWrapper = styled(Div)`
  &.oneline {
    display: flex;
    height: 47px;
    align-items: center;
    padding: 0 ${SPACE.LARGE};

    .store-selector {
      min-width: 300px;
    }
    ion-item {
      --min-height: 42px;
      ion-label {
        margin-top: -10px !important;
      }
    }
    ion-select {
      padding: 4px 0;
    }
  }
`;
