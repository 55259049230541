import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { Button, Text } from '$gcomponents/primitives';
import { AppForm } from '$styles/general';
import { factoryActions } from '$fbusiness/redux/factory';
import { AppModel } from '$business/redux/';

import { TERM_FORM } from './termForm';
import TermModel from '$fbusiness/models/term';
import { configs } from '$configs';
import { IonContent } from '@ionic/react';
import Footer from '$gcomponents/widgets/footer';

interface TermDetailsProps {
  term: TermModel;
  onClose: Function;
}

const TermDetails: React.FC<TermDetailsProps> = ({ term, onClose }) => {
  const dispatch = useDispatch();
  const isFinished = useSelector((state: AppModel) => state.factory.isFinished);
  const formRef = useRef<any>();

  useEffect(() => {
    if (!isFinished) return;
    dispatch(factoryActions.resetFinished());
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished, dispatch, onClose]);

  if (!term) return null;
  const { id, ...initialValues } = term;

  const onSubmit = (values) => {
    const newValues = {
      ...values,
    };
    dispatch(factoryActions.saveObj(id || 0, newValues, configs.api.term, factoryActions.fetchTermsF));
  };

  const validateForm = (values) => {
    return input.validateError(TERM_FORM, values);
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <div className="content">
              <AppForm>
                <FormSection FORM={TERM_FORM} formik={formik} />
              </AppForm>
            </div>
          </IonContent>
          <Footer justifyContent="center">
            <Button className="half" onClick={formik.handleSubmit} disabled={!formik.isValid}>
              <Text k="BUTTON.SAVE" />
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default TermDetails;
