import { INVOICE_PAYMENT_TYPE, CASH } from '../enums/invoicePayment';
import { INVOICE_PAYMENTS } from '../enums/options/paymentType';
import BankModel from './bank';
import InvoicePaymentModel, { deriveRawToinvoicePayment } from './invoicePayment';

export default interface InvoicePaymentGroupModel {
  id: number;
  storeId: number;
  fromName: string;
  type: INVOICE_PAYMENT_TYPE;
  amount: number;
  creditAmount?: any;
  balance: number;
  consumed?: number;
  bankId: number;
  bank?: BankModel;
  paymentDate: string | null;
  payments?: Array<InvoicePaymentModel>;
  memo: string;
  rejectedAt?: string;
  settings?: any;
}

export const deriveRawToinvoicePaymentGroup = (raw) => {
  return {
    id: raw.id,
    fromName: raw.fromName,
    storeId: raw.storeId,
    type: raw.type || 'OTHER',
    ...(raw.type === INVOICE_PAYMENTS.CREDITS
      ? { creditAmount: raw.amount, amount: 0 }
      : { creditAmount: 0, amount: raw.amount }),
    consumed: raw.amount - raw.balance,
    balance: raw.balance,
    memo: raw.memo,
    bankId: raw.bankId || 0,
    ...(raw.bank && { bank: raw.bank }),
    paymentDate: raw.paymentDate,
    payments: raw.payments.map(deriveRawToinvoicePayment),
    settings: {
      ...raw.settings,
    },
    rejectedAt: raw.rejectedAt,
  };
};

export const initialInvoicePaymentGroup: InvoicePaymentGroupModel = {
  id: 0,
  storeId: 0,
  fromName: '',
  memo: '',
  type: CASH,
  amount: 0,
  balance: 0,
  bankId: 0,
  payments: [],
  paymentDate: null,
};
