import SerialModel from '../../models/serial';

export const SERIAL_FAILURE = 'SERIAL_FAILURE';
export const FETCH_SERIAL_SUCCESS = 'FETCH_SERIAL_SUCCESS';
export const FETCH_SERIALS_SUCCESS = 'FETCH_DEPARMENTS_SUCCESS';
export const CREATE_SERIAL_SUCCESS = 'SAVE_SERIAL_SUCCESS';
export const UPDATE_SERIAL_SUCCESS = 'SAVE_SERIAL_SUCCESS';
export const DELETE_SERIAL_SUCCESS = 'DELETE_SERIAL_SUCCESS';
export const CLEAN_SERIAL = 'CLEAN_SERIAL';

// Action Types
export interface typeOnlyType {
  type:
    | typeof CREATE_SERIAL_SUCCESS
    | typeof UPDATE_SERIAL_SUCCESS
    | typeof CLEAN_SERIAL
    | typeof DELETE_SERIAL_SUCCESS;
}

export interface failureType {
  type: typeof SERIAL_FAILURE;
  err: string;
}

export interface FetchSerialSuccessType {
  type: typeof FETCH_SERIAL_SUCCESS;
  serial: SerialModel;
}

export interface FetchSerialsSuccessType {
  type: typeof FETCH_SERIALS_SUCCESS;
  serials: Array<SerialModel>;
}

export interface SerialReducerType {
  serials: Array<SerialModel>;
  serial: SerialModel | null;
  isFinished: boolean;
  refresh: boolean;
}

export type SerialActionTypes = typeOnlyType | failureType | FetchSerialSuccessType | FetchSerialsSuccessType;

export const SERIAL_INIT_STATE: SerialReducerType = {
  serials: [],
  serial: null,
  isFinished: false,
  refresh: true,
};
