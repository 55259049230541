import React from 'react';
import intl from '$intl';
import { Button } from '$gcomponents/primitives';
import { Print } from '@mui/icons-material';

interface PrintButtonProps {}

const PrintButton: React.FC<PrintButtonProps> = () => {
  return (
    <Button variant="text" color="secondary" icon={<Print />} onClick={() => window.print()}>
      {intl('BUTTON.PRINT')}
    </Button>
  );
};

export default PrintButton;
