import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$fcomponents/hoc';
import { TableView2, Header } from '$gcomponents/widgets';
import OptionModel from '$gbusiness/models/option';

import { REFUND_TABLE_CONFIG, REFUND_TABLE, refundExportSettings } from './refundTable';
import { IonPageWrapper } from './styles';
import FactoryModel from '$fbusiness/models/factory';
import CurrentStateModel from '$fbusiness/models/currentState';
import RefundDetailsModal from './refundDetailsModal';
import FabButton from '$fcomponents/fabButton';
import PATH from '$business/enums/paths';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import StoreModel from '$fbusiness/models/store';
import ReportFilter from '$fcomponents/filters/reportFilter';
// import REFUND_FILTER_FORM from './filter';
import { userActions } from '$fbusiness/redux/user';
import ExportButton from '$gcomponents/reusables/exportButton';
import { ExportContainer } from '../storeScreens/storesScreen/styles';
import PrintButton from '$fcomponents/printButton';
import { invoiceActions } from '$fbusiness/redux/invoice';

interface RefundsScreenProps {
  factory: FactoryModel;
  currentState: CurrentStateModel;
  stores: Array<StoreModel>;
  applyRefund: Function;
  match;
  users;
  dateFilter;
}

const RefundsScreen: React.FC<RefundsScreenProps> = ({
  stores,
  factory,
  users,
  match,
  dateFilter,
  applyRefund,
  currentState,
}) => {
  const {
    params: { processed },
  } = match;
  const initialIndex = processed ? 1 : 0;
  const TTABS: Array<OptionModel> = [
    { label: 'SCREEN.REFUNDS.STATUS.PENDING', value: 'PENDING' },
    { label: 'SCREEN.REFUNDS.STATUS.PROCESSED', value: 'PROCESSED' },
  ];

  const [filter, setFilter] = useState<any>({
    ...dateFilter,
    query: '',
    userId: undefined,
    tableTabIndex: initialIndex,
    status: TTABS[initialIndex].value,
    forceRefresh: false,
  });
  const [refund, setRefund] = useState<any>(null);

  const updateFilter = (newFilter = {}) => {
    setFilter({
      ...filter,
      ...newFilter,
      forceRefresh: !filter.forceRefresh,
    });
  };

  const tableTabs = {
    TABS: TTABS,
    hasBadge: false,
    index: filter?.tableTabIndex,
    onChange: (newTableIndex) => {
      updateFilter({ tableTabIndex: newTableIndex, status: TTABS[newTableIndex].value });
    },
    variant: 'outlined',
    color: 'primary' as 'primary' | 'secondary',
    itemWidth: '60px',
  };

  // const extraFilterForm = REFUND_FILTER_FORM({
  //   users,
  //   settings: factory.settings,
  // });

  const onDetails = (row) => {
    setRefund(row);
  };

  const onApplyRefund = async (id) => {
    await applyRefund(id);
    updateFilter({});
  };

  // const onChangeStatus = async (row, newValue) => {
  //   if (newValue === ORDER_STATUS.DELIVERED || newValue === ORDER_STATUS.COMPLETED) {
  //     await updateOrder({ id: row.id, status: newValue });
  //     updateFilter({});
  //   }
  // };

  const onCloseModal = async () => {
    setRefund(null);
  };

  // const onTableLoad = (totalSize, newLength, response) => {
  //   const summary: any = response?.summary;
  //   if (!summary) {
  //     setSummary([]);
  //     return;
  //   }
  //   setSummary(TTABS.map(tab => Number(summary[tab.value.toString().toLowerCase()])));
  // };

  return (
    <IonPageWrapper>
      <Header title="SCREEN.REFUNDS.TITLE" />
      <IonContent>
        <TableView2
          filter={filter}
          // onTableLoad={onTableLoad}
          tableConfig={REFUND_TABLE_CONFIG}
          TABLE={REFUND_TABLE(onDetails)}
          tabs={tableTabs}>
          <>
            <FilterSection title={null}>
              <ReportFilter
                includeReportType={false}
                stores={stores}
                parentFilter={filter}
                onSearch={updateFilter}
                placeholder="Search"
              />
            </FilterSection>
            <ExportContainer>
              <PrintButton />
              <ExportButton
                filter={filter}
                variant="text"
                fileName="refundRequest"
                exportSetting={refundExportSettings}
              />
            </ExportContainer>
          </>
        </TableView2>
      </IonContent>
      <RefundDetailsModal
        currentState={currentState}
        onApplyRefund={onApplyRefund}
        refund={refund}
        show={!!refund}
        onClose={onCloseModal}
      />
      <FabButton route={PATH.EDIT_REFUND} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  stores: state.store.stores,
  resetOnRoute: true,
  users: state.user.users,
});

const mapDispatchToProps = {
  onHydrate: () => userActions.fetchUsers({ storeUser: true, storeId: 1 }),
  applyRefund: invoiceActions.applyRefund,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(RefundsScreen));
