import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonHeader,
  IonToolbar,
  IonImg,
  IonFooter,
} from '@ionic/react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { configs } from '$configs';
import { Text } from '$intl';

import { Wrapper } from './styles';
import { AppVersion } from '$gcomponents/reusables';
import { Div, SPACE } from '$gstyles';
import UserModel from '$gbusiness/models/user';
import SideMenuItem from './sideMenuItem';
import FactoryModel from '$fbusiness/models/factory';
import FactorySelector from './factorySelector';
import { useDispatch } from 'react-redux';
import { cuserActions } from '$gbusiness/redux/currentUser';

interface MenuProps extends RouteComponentProps {
  logo?: string;
  user: UserModel;
  factories?: Array<FactoryModel>;
  isLoggedIn: boolean;
  currentRoute: string;
  onLogout: Function;
}

const Menu: React.FC<MenuProps> = React.memo(
  ({ logo, factories, currentRoute, user, isLoggedIn, onLogout, ...rest }) => {
    const dispatch = useDispatch();
    const onChangeFactory = async (e) => {
      await dispatch(cuserActions.editProfile({ ...user, factoryId: e.target.value }));
      window.location.reload();
    };

    return (
      <Wrapper
        contentId="main"
        menuId="start"
        disabled={!isLoggedIn}
        isProd={process.env.REACT_APP_ENV === 'prod'}
        {...rest}>
        {configs.display.layout !== 'FIXED_HEADER' && (
          <IonHeader>
            <IonToolbar>
              <IonImg class="logo" src={logo} />
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent class="outer-content">
          <IonList>
            <SideMenuItem currentRoute={currentRoute} />
            <IonItem lines="none" class="pointer" onClick={() => onLogout()} detail={false}>
              <ExitToAppIcon />
              <IonLabel>
                <Text k="MENU.LOG_OUT" />
              </IonLabel>
            </IonItem>
          </IonList>
          <div className="user">
            <IonLabel>
              <div className="name">{`${user.firstName} ${user.lastName}`}</div>
              <div className="email">{user.email}</div>
            </IonLabel>
            {user.userLevel === 0 && (
              <Div padding={SPACE.LARGE}>
                <FactorySelector
                  factories={factories}
                  selectedId={user?.other?.factoryId || 0}
                  onChange={onChangeFactory}
                />
              </Div>
            )}
          </div>
        </IonContent>
        <IonFooter>
          <Div height="32px">
            <AppVersion oneLine />
          </Div>
        </IonFooter>
      </Wrapper>
    );
  },
  (pp, np) => pp.currentRoute === np.currentRoute && pp.isLoggedIn === np.isLoggedIn,
);

export default withRouter(Menu);
