import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToItemBatch, initialBatch } from '../../models/itemBatch';
import {
  BatchActionTypes,
  FETCH_BATCH_SUCCESS,
  CLEAN_BATCH,
  UPDATE_BATCH_SUCCESS,
  BATCH_FAILURE,
  DELETE_BATCH_SUCCESS,
  FETCH_BATCHES_SUCCESS,
} from './types';

export function fetchBatches(): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.batch.general,
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, BATCH_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_BATCHES_SUCCESS,
      batches: response.list.map((d) => deriveRawToItemBatch(d)),
    });
  };
}

export function fetchBatch(batchId, resolve = false): any {
  return async (dispatch: Dispatch) => {
    if (!batchId || isNaN(batchId)) {
      dispatch({
        type: FETCH_BATCH_SUCCESS,
        batch: initialBatch,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.batch.general + '/' + batchId,
      method: 'GET',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, BATCH_FAILURE, response, 'ERROR.SAVE', true);
      return;
    }

    dispatch({
      type: FETCH_BATCH_SUCCESS,
      batch: deriveRawToItemBatch(response.data),
    });

    return Promise.resolve(response.data);
  };
}

export function saveBatch(batchId, batch): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.batch.general + (batchId ? '/' + batchId : ''),
      param: {
        ...(batchId && { ...batchId }),
        ...batch,
      },
      method: batchId ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, BATCH_FAILURE, response, 'ERROR.SAVE', true);
      return Promise.resolve(false);
    } else {
      handleApiSuccess(dispatch, UPDATE_BATCH_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
      return Promise.resolve(true);
    }
  };
}

export function removeProducts(batchId, quantity, serials): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.batch.removeProducts,
      param: {
        batchId,
        quantity,
        serials,
      },
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, BATCH_FAILURE, response, 'ERROR.SAVE', true);
      return Promise.resolve(false);
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.SAVE_SUCCESS', 'small');
      return Promise.resolve(true);
    }
  };
}

export function deleteBatch(batchId): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.batch.general + (batchId ? '/' + batchId : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, BATCH_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_BATCH_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

export function dehydrate(): BatchActionTypes {
  return { type: CLEAN_BATCH };
}
