import React from 'react';
import { IonHeader, IonBackButton, IonButtons, IonMenuButton, IonTitle } from '@ionic/react';

import { configs } from '$configs';
import intl from '$intl';
import UserModel from '$gbusiness/models/user';

import { Toolbar } from './styles';
interface HeaderProps {
  title?: string;
  titleParam?: object;
  titleText?: string;
  rightButton?: React.ReactNode;
  excludeHeader?: boolean;
  disableBack?: boolean;
  user?: UserModel;
}

const Header: React.FC<HeaderProps> = ({
  title,
  titleText,
  titleParam = {},
  disableBack = false,
  excludeHeader = false,
}) => {
  if (configs.display.layout === 'FIXED_HEADER') return null;
  const derivedTitle = intl(title, titleParam) || titleText || '';

  const el = (
    <Toolbar color={configs.theme.headerColor || undefined}>
      {!disableBack && (
        <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
      )}
      <IonButtons slot="start">
        <IonMenuButton />
      </IonButtons>
      <IonTitle>{derivedTitle}</IonTitle>
    </Toolbar>
  );

  return excludeHeader ? <>{el}</> : <IonHeader>{el}</IonHeader>;
};

export default Header;
