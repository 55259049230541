import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { searchSharp } from 'ionicons/icons';

export const SERIAL_FORM = (): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.TEXT,
        label: 'SCREEN.STORES.COLS.ITEM',
        disableFastField: true,
        disabled: true,
        gridSize: 12,
        value: 'itemName',
      },
      {
        label: 'SCREEN.SERIAL.COLS.SERIALS',
        value: 'serials',
        rows: 6,
        input: INPUT.TEXTAREA,
        placeholder: 'Enter serial numbers here. One per line',
        gridSize: 9,
      },
    ],
  },
];

export const SERIAL_EDIT_FORM = (stores): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.SERIAL.COLS.STORE',
        disableFastField: true,
        gridSize: 12,
        value: 'store',
        config: {
          optionProp: {
            label: 'name',
            value: 'id',
          },
          data: [],
        },
      },
      {
        input: INPUT.TEXT,
        label: 'SCREEN.SERIAL.COLS.SERIALNO',
        gridSize: 12,
        value: 'serialNo',
      },
      {
        label: 'SCREEN.SERIAL.COLS.NOTE',
        value: 'note',
        rows: 5,
        input: INPUT.TEXTAREA,
        placeholder: 'Enter serial numbers here. One per line',
        gridSize: 12,
      },
    ],
  },
];

export const displayItem = (o) => {
  return `${o.sku ? `(${o.sku})` : ''} ${o.name || ''}`;
};

export const SERIAL_FILTER_FORM = ({
  items,
  stores,
  serialStatusOptions,
  onChangeItem,
  categories,
  onChangeStore,
  onChangeCategory,
  onChangeQuery,
  onChangeStatuses,
  isItemPage = false,
}): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'INPUT.LABEL.SEARCH',
        placeholder: 'Search Serial',
        input: INPUT.TEXT,
        gridSize: 3,
        icon: searchSharp,
        enterToSubmit: true,
        value: 'query',
        onChange: onChangeQuery,
      },
      ...(!isItemPage
        ? [
            {
              input: INPUT.AUTOCOMPLETE,
              label: 'SCREEN.SERIAL.COLS.CATEGORY',
              disableFastField: true,
              gridSize: 4,
              value: 'category',
              config: {
                optionProp: {
                  label: 'name',
                  labelText: (o) => `${o.name || ''}`,
                  value: 'id',
                },
                data: categories,
              },
              onChange: onChangeCategory,
            },
            {
              input: INPUT.AUTOCOMPLETE,
              label: 'SCREEN.SERIAL.COLS.ITEM',
              disableFastField: true,
              gridSize: 4,
              value: 'item',
              config: {
                optionProp: {
                  label: 'name',
                  labelText: displayItem,
                  value: 'id',
                },
                data: items,
              },
              onChange: onChangeItem,
            },
            {
              input: INPUT.AUTOCOMPLETE,
              label: 'SCREEN.SERIAL.COLS.STORE',
              disableFastField: true,
              gridSize: 4,
              value: 'store',
              config: {
                optionProp: {
                  label: 'name',
                  value: 'id',
                },
                data: stores,
              },
              onChange: onChangeStore,
            },
          ]
        : []),
      ...(serialStatusOptions && serialStatusOptions.length
        ? [
            {
              input: INPUT.MULTI_SELECT,
              label: 'SCREEN.SERIAL.STATUS',
              value: 'statuses',
              position: 'outlined',
              options: serialStatusOptions,
              validation: [
                {
                  condition: (v) => !v.length,
                  message: 'REQUIRED',
                },
              ],
              gridSize: 6,
              onChange: ({ newValue }) => {
                onChangeStatuses(newValue);
              },
            },
          ]
        : []),
    ],
  },
];

export default SERIAL_FORM;
