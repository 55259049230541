import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import ExportModel from '$gbusiness/models/export';
import { reportCurrency } from '$fcomponents/helpers/utils';

export const repReportExportSettings: ExportModel = {
  url: configs.api.report.rep,
  method: 'POST',
  fileName: 'salesRepReport',
  getFileName: (filter) => `salesRepReport_${filter.dateStart || 'beginning'}-${filter.dateEnd || 'present'}`,
  deriveToModel: (row) => row,
  deriveTable: (item) => ({
    SalesRep: item.name,
    BeforeTax: item.taxable,
    AfterTax: item.total,
  }),
  sortKey: 'total',
  sortOrder: SORT.DSC,
};

export const REP_REPORT_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.report.rep,
    // mockData,
    method: 'POST',
    defaultSortKey: 'total',
    defaultSortOrder: SORT.DSC,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 600,
    maxWidth: 900,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 44,
  },
};

export const REP_REPORT_TABLE = (handleClick): Array<CellModel> => [
  {
    label: 'SCREEN.REPORT.COLS.REP',
    value: 'name',
    alwaysShow: true,
    className: 'main',
    component: (row) => (
      <div className="link" onClick={() => handleClick(row.id)}>
        {row.name}
      </div>
    ),
    sortable: SORT.ASC,
    width: 400,
  },
  {
    label: 'SCREEN.REPORT.COLS.TAXABLE',
    value: 'taxable',
    component: (row) => reportCurrency(row.taxable),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.AFTER_TAX',
    value: 'total',
    component: (row) => reportCurrency(row.total),
    sortable: SORT.DSC,
    width: 200,
  },
];
