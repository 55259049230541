import { FONT, Row, SPACE, WEIGHT } from '$gstyles';
import styled from 'styled-components';

export const SubheaderWrapper = styled(Row)`
  font-weight: ${WEIGHT.SEMI_BOLD};
  font-size: ${FONT.MEDIUM};
  margin: ${SPACE.LARGE} 0;
  text-align: center;

  > * {
    align-items: center;
  }
  .right {
    justify-content: flex-end;
  }
  .title {
    justify-content: center;
  }
`;
