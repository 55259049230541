import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { Header } from '$gcomponents/widgets';

import { MockScreenPage } from './styles';
import { itemActions } from '$fbusiness/redux/item';
import InvoiceSection from './invoiceSection';
// import PaymentSection from './paymentSection';
// import StatusSection from './statusSection';
import { PageWrapper } from '$gstyles/wrapper';

interface MockScreenProps {
  onHydrate;
  onDehydrate;
  factory;
  items;
  stores;
}

const MockScreen: React.FC<MockScreenProps> = ({ factory, stores, items }) => {
  return (
    <MockScreenPage>
      <Header title="SCREEN.MOCK.TITLE" />
      <IonContent>
        <PageWrapper maxWidth="1000px">
          <InvoiceSection factory={factory} stores={stores} items={items} />
          {/* <PaymentSection factory={factory} />
          <StatusSection factory={factory} /> */}
        </PageWrapper>
        <PageWrapper>&nbsp;</PageWrapper>
        <PageWrapper>&nbsp;</PageWrapper>
      </IonContent>
    </MockScreenPage>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  stores: state.store.stores,
  items: state.item.mockItems,
});

const mapDispatchToProps = {
  onHydrate: () => itemActions.fetchMockItems(),
  onDehydrate: itemActions.cleanMock,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MockScreen));
