import { IonContent } from '@ionic/react';
import styled from 'styled-components';

export const IonContentWrapper = styled(IonContent)`
  .payment-table {
    .cancelled {
      .MuiTableCell-root {
        background-color: #eee;
      }
    }
  }
`;
