import React, { useEffect, useState } from 'react';

import { Header, TableView2 } from '$gcomponents/widgets';
import OptionModel from '$gbusiness/models/option';
import { ORDER_STATUS } from '$fbusiness/enums/options/orderStatus';

import { ORDER_TABLE_CONFIG, ORDER_TABLE } from './orderTable';
import { Wrapper } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import OrderShippingModal from './orderShippingModal';
import { orderActions } from '$fbusiness/redux/order';
import ItemActionModel from '$gbusiness/models/itemAction';
import { invoiceActions } from '$fbusiness/redux/invoice';
import { factoryActions } from '$fbusiness/redux/factory';
import StoreDetailsModal from './storeDetailsModal';
import { DATE_RANGE } from '$fbusiness/enums/options/dateRange';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';

import { mergeOrderItems } from './utils';
import { IonFab } from '@ionic/react';
import { Button } from '$gcomponents/primitives';
import MergedModal from './mergedModal';
import intl from '$intl';
import ReportFilter from '$fcomponents/filters/reportFilter';
import { generateInvoiceNo, updatedFilter } from '$fbusiness/helpers/util';
import OrderDetailsModal from '$fscreens/accountScreen/historyScreen/orderDetailsModal';
import { deriveItemPrice } from '$fbusiness/models/price';
import InvoiceDetailsModal from '$fscreens/accountScreen/invoicesScreen/invoiceDetailsModal';
import OrderUpdateModal from './orderUpdateModal';
import { toNumber } from '$gbusiness/helpers/util';
import { dialog } from '$gcomponents/reusables';

interface OrderStoreScreenProps {
  currentState;
  dateFilter;
  history;
}

const OrderStoreScreen: React.FC<OrderStoreScreenProps> = React.memo(
  ({ currentState, dateFilter }) => {
    const [filter, setFilter] = useState<any>({
      tableTabIndex: 0,
      query: '',
      ...dateFilter,
    });
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [mergedItems, setMergedItems] = useState<any>([]);
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);
    const [showShipping, setShowShipping] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [summary, setSummary] = useState<Array<any>>([]);
    const [showStore, setShowStore] = useState(false);
    const dispatch = useDispatch();
    const factory = useSelector((state: any) => state.factory.factory);
    const store = useSelector((state: any) => state.factory.store);
    const order = useSelector((state: any) => state.order.order);
    const invoice = useSelector((state: any) => state.invoice.invoice);
    const isInvoiceFinished = useSelector((state: any) => state.invoice.isFinished);

    const { settings } = factory;

    const TTABS: Array<OptionModel> = [
      { label: 'SCREEN.ORDERS.STATUS.ALL', value: '' },
      { label: 'SCREEN.ORDERS.STATUS.PREPARING', value: 'PREPARING' },
      ...(settings?.pickupOnly
        ? [{ label: 'SCREEN.ORDERS.STATUS.READY', value: 'READY' }]
        : [
            { label: 'SCREEN.ORDERS.STATUS.SHIPPED', value: 'SHIPPED' },
            { label: 'SCREEN.ORDERS.STATUS.DELIVERED', value: 'DELIVERED' },
          ]),
      { label: 'SCREEN.ORDERS.STATUS.COMPLETED', value: 'COMPLETED' },
      { label: 'SCREEN.ORDERS.STATUS.FLAGGED', value: 'FLAGGED' },
      { label: 'SCREEN.ORDERS.STATUS.CANCELLED', value: 'CANCELLED' },
    ];

    useEffect(() => {
      if (isInvoiceFinished) {
        processAfterShipping();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInvoiceFinished]);

    const processAfterShipping = async () => {
      await updateFilter({});
      setShowShipping(false);
    };

    const updateFilter = (newFilter) => {
      setFilter(updatedFilter(filter, newFilter));
    };

    const tableTabs = {
      TABS: TTABS.map((t, i): OptionModel => {
        return {
          ...t,
          count: summary[i],
        };
      }),
      hasBadge: true,
      index: filter?.tableTabIndex,
      onChange: (newTableIndex) => {
        updateFilter({ tableTabIndex: newTableIndex, status: TTABS[newTableIndex].value });
      },
      color: 'primary' as 'primary' | 'secondary',
      itemWidth: '60px',
    };

    const onChangeStatus = async (row, newValue) => {
      if (newValue === ORDER_STATUS.CANCELLED) {
        dialog.confirm({
          message: 'MESSAGE.ORDER_CANCEL_WARINIG',
          onPress: async () => {
            await dispatch(orderActions.updateOrder({ id: row.id, status: newValue }));
            updateFilter({});
          },
        });
        return;
      }
      if (newValue === ORDER_STATUS.SHIPPED || newValue === ORDER_STATUS.READY) {
        await dispatch(orderActions.fetchOrder(row.id));
        await dispatch(factoryActions.fetchNextInvoiceNo());
        setShowShipping(true);
        return;
      }
      await dispatch(orderActions.updateOrder({ id: row.id, status: newValue }));
      updateFilter({});
    };

    const onCancelOrder = (row) => {
      onChangeStatus(row, ORDER_STATUS.CANCELLED);
    };

    const onClickRow = async (row) => {
      // history.push(`${PATH.ORDERS}/${row.id}`);
      await dispatch(orderActions.fetchOrder(row.id));
      if (row.status === 'PREPARING' || !invoice) setShowOrderDetails(true);
      else setShowInvoiceDetails(true);
    };

    const onShowStore = async (row) => {
      await dispatch(factoryActions.fetchStore(row.storeId));
      setShowStore(true);
    };

    const onCloseModal = async (preventDehydration = false) => {
      if (!preventDehydration) await dispatch(orderActions.dehydrate());
      await dispatch(invoiceActions.dehydrate());
      setShowOrderDetails(false);
      setShowInvoiceDetails(false);
      setShowShipping(false);
      setShowStore(false);
      setShowUpdate(false);
    };

    const onSubmitShip = async ({ items, pricing, termId, noteFactory }) => {
      const newItems = items.map((i) => ({
        ...i,
        ...deriveItemPrice(i),
        id: 0,
        qtySent: parseInt(i.qtySent || 0),
        totalSent: order.status === ORDER_STATUS.SHIPPED ? parseInt(i.qtySent || 0) : parseInt(i.totalSent),
        totalReceived: order.status === ORDER_STATUS.SHIPPED ? 0 : toNumber(i.totalReceived),
      }));
      const revision = invoice ? invoice.revision + 1 : '';
      const invoiceParam = {
        storeId: order.storeId,
        refundId: order.refundId || 0,
        userId: order.userId,
        orderId: order.id,
        revision,
        termId,
        commRate: order.commRate,
        discountRate: order.discountRate,
        isReship: order.status === ORDER_STATUS.SHIPPED,
        invoiceNumber: generateInvoiceNo({ ...order, factory }, revision),
        noteFactory,
        ...pricing,
        status: settings?.pickupOnly ? ORDER_STATUS.READY : ORDER_STATUS.SHIPPED,
        items: newItems,
        // qtySent, totalSent, totalReceived, etc are derived from back-end
      };
      await dispatch(invoiceActions.createInvoice(invoiceParam, true));
    };

    const onSubmitUpdate = async (order) => {
      if (!order?.invoiceNumber) await dispatch(orderActions.updateOrder(order, false, true));
      setShowUpdate(false);
    };

    const itemActions: Array<ItemActionModel> = [
      {
        label: 'SCREEN.ORDERS.ACTIONS.STORE_INFO',
        eventName: 'storeInfo',
        onClick: onShowStore,
      },
      {
        label: 'SCREEN.ORDERS.ACTIONS.CANCEL',
        eventName: 'cancel',
        onClick: onCancelOrder,
        disabled: (row) => row?.status === ORDER_STATUS.CANCELLED,
      },
      {
        label: 'SCREEN.ORDERS.ACTIONS.EDIT',
        eventName: 'edit',
        onClick: async (row) => {
          await dispatch(orderActions.fetchOrder(row.id));
          setShowShipping(true);
        },
        hide: (row) =>
          !(
            row.status === ORDER_STATUS.SHIPPED ||
            row.status === ORDER_STATUS.RESHIP ||
            row.status === ORDER_STATUS.CLOSED
          ),
      },
      {
        label: 'SCREEN.ORDERS.ACTIONS.UPDATE',
        eventName: 'update',
        onClick: async (row) => {
          await dispatch(orderActions.fetchOrder(row.id));
          setShowUpdate(true);
        },
      },
    ];

    const onTableLoad = (totalSize, newLength, response) => {
      const summary: any = response?.summary;
      if (!summary) {
        setSummary([]);
        return;
      }
      setSummary(TTABS.map((tab) => Number(summary[tab.value.toString().toLowerCase()])));
    };

    const onSelectItems = (orders) => {
      setSelectedOrders(orders.filter((o) => o));
    };

    const onMergeItems = () => {
      setMergedItems(mergeOrderItems(selectedOrders));
    };

    return (
      <Wrapper>
        <Header title="SCREEN.ORDERS.TITLE" />
        <TableView2
          filter={filter}
          tableConfig={ORDER_TABLE_CONFIG}
          TABLE={ORDER_TABLE({
            onChangeStatus,
            onDetails: onClickRow,
            onReceiving: () => {},
            isSalesman: false,
            settings,
            isFactory: true,
            hidePrice: currentState?.hidePrice,
          })}
          tabs={tableTabs}
          onTableLoad={onTableLoad}
          onSelection={onSelectItems}
          itemActions={itemActions}>
          <FilterSection title={null}>
            <ReportFilter
              parentFilter={filter}
              defaultRange={DATE_RANGE.TODAY}
              onSearch={updateFilter}
              placeholder="INPUT.PLACEHOLDER.ORDER_SEARCH"
            />
          </FilterSection>
        </TableView2>
        {selectedOrders.length > 0 && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <Button onClick={onMergeItems}>{intl('BUTTON.MERGE_ITEMS')}</Button>
          </IonFab>
        )}
        <OrderShippingModal
          order={order}
          invoice={invoice}
          show={(invoice || order) && showShipping}
          onClose={onCloseModal}
          terms={factory.terms}
          currentState={currentState}
          onSubmitOrder={onSubmitShip}
        />
        <OrderUpdateModal
          order={order}
          show={(invoice || order) && showUpdate}
          onClose={onCloseModal}
          onSubmitOrder={onSubmitUpdate}
        />
        <InvoiceDetailsModal
          currentState={currentState}
          invoice={invoice}
          factory={factory}
          show={invoice && showInvoiceDetails}
          onClose={onCloseModal}
        />
        <OrderDetailsModal
          currentState={currentState}
          order={order}
          factory={factory}
          show={order && showOrderDetails}
          onClose={onCloseModal}
        />

        <MergedModal
          currentState={currentState}
          show={!!mergedItems && mergedItems.length > 0}
          items={mergedItems}
          startDate={filter.dateStart}
          endDate={filter.dateEnd}
          onClose={() => setMergedItems([])}
        />
        <StoreDetailsModal show={store && showStore} store={store} onClose={onCloseModal} />
      </Wrapper>
    );
  },
  (pp, np) => true,
);

export default OrderStoreScreen;
