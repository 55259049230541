import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { Button, Text } from '$gcomponents/primitives';

import { CHARGE_FORM } from './chargeForm';
import { AppForm } from '$styles/general';
import MuiModal from '$gcomponents/reusables/modal/material';
import intl from '$intl';
import { factoryActions } from '$fbusiness/redux/factory';
import { AppModel } from '$business/redux/';
import { configs } from '$configs';
import ChargeModel from '$fbusiness/models/charge';

interface ChargeModalProps {
  charge: ChargeModel;
  onClose: Function;
}

const ChargeModal: React.FC<ChargeModalProps> = ({ charge, onClose }) => {
  const dispatch = useDispatch();
  const isFinished = useSelector((state: AppModel) => state.factory.isFinished);
  const formRef = useRef<any>();

  useEffect(() => {
    if (!isFinished) return;

    dispatch(factoryActions.resetFinished());
    onClose();
  }, [isFinished, onClose, dispatch]);

  if (!charge) return null;
  const { id, ...initialValues } = charge;

  const onSubmit = (values) => {
    dispatch(factoryActions.saveObj(id || 0, values, configs.api.charge, factoryActions.fetchCharges));
  };

  const validateForm = (values) => input.validateError(CHARGE_FORM, values);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <MuiModal
          open={!!charge}
          width="400px"
          onClose={() => onClose()}
          title={intl('SCREEN.FACTORY.CHARGE_TITLE')}
          footer={
            <Button fullWidth onClick={formik.handleSubmit} disabled={!(formik.isValid && formik.dirty)}>
              <Text k="BUTTON.SAVE" />
            </Button>
          }>
          <div className="content">
            <AppForm>
              <FormSection FORM={CHARGE_FORM} formik={formik} />
            </AppForm>
          </div>
        </MuiModal>
      )}
    </Formik>
  );
};

export default ChargeModal;
