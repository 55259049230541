import React, { useState } from 'react';
import intl from '$intl';
import { IonContent, IonFooter, IonHeader } from '@ionic/react';
import { useDropzone } from 'react-dropzone';
import { TextField } from '@mui/material';

import { Div, SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { dialog, Table } from '$gcomponents/reusables';

import { ModalWrapper } from '../styles';
import FILE_PREVIEW_TABLE, { FILE_PREIVEW_STYLE } from './filePreviewTable';
import { useDispatch } from 'react-redux';
import { fileActions } from '$fbusiness/redux/file';
import StoreModel from '$fbusiness/models/store';
import StoreSelector from './storeSelector';

interface FileModalProps {
  show: boolean;
  onClose: Function;
  onUpdateFile: Function;
  stores: Array<StoreModel>;
}

const MAX_FILE_SIZE = 5000000000;

const FileModal: React.FC<FileModalProps> = ({ show, onClose, stores, onUpdateFile }) => {
  const [files, setFiles] = useState<Array<any>>([]);
  const [selectStores, setSelectStores] = useState<any>(null);
  const [youtubeLink, setYoutubeLink] = useState('');
  const dispatch = useDispatch();

  const closeModal = () => {
    setFiles([]);
    setYoutubeLink('');
    onClose();
  };

  const addYoutube = () => {
    setFiles([
      ...files,
      {
        name: '',
        description: '',
        youtubeLink,
      },
    ]);
  };

  const onChangeField = ({ name, description, index }) => {
    setFiles(
      files.map((f, i) => {
        if (i !== index) return f;
        return {
          ...f,
          ...(name !== undefined && { name }),
          ...(description !== undefined && { description }),
          ...(selectStores && { storeIds: selectStores }),
        };
      }),
    );
  };

  const onDeleteRow = (index) => {
    setFiles(files.filter((f, i) => index !== i));
  };

  const onSubmitFiles = async () => {
    await dispatch(fileActions.addFiles(files));
    onUpdateFile();
  };

  const onStoreSelect = (values) => {
    setSelectStores(values);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) {
        dialog.alert({
          text: intl('ERROR.FILE_UPLOAD'),
        });
        return;
      }
      const newFiles = acceptedFiles
        .filter((file) => file.size < MAX_FILE_SIZE)
        .map((file) => ({
          description: 'test',
          fileName: file.name,
          fileType: file.type,
          fileSize: file.size,
          file,
        }));

      setFiles([...files, ...newFiles]);
    },
  });

  const table = FILE_PREVIEW_TABLE(onChangeField, onDeleteRow);
  return (
    <ModalWrapper
      width="800px"
      titleText={intl('ITEM.ADD', { item: 'Files' })}
      show={show}
      onClose={closeModal}
      useCustom>
      <IonHeader className="modal-header">
        <IonContent scrollY={false}>
          <Flex className="stores-wrapper">
            <StoreSelector
              className="oneline"
              stores={stores}
              onChange={onStoreSelect}
              values={selectStores}
            />
          </Flex>
          <div className="dropzone-wrapper">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>{intl('MESSAGE.FILE_UPLOAD_LABEL')}</p>
            </div>
          </div>
          <Flex className="youtube-wrapper">
            <div>Youtube Link: </div>
            <Div flex={1}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                onChange={(e) => setYoutubeLink(e.target.value)}
              />
            </Div>
            <div>
              <Button onClick={addYoutube} color="secondary">
                {intl('BUTTON.ADD')}
              </Button>
            </div>
          </Flex>
        </IonContent>
      </IonHeader>
      <IonContent className="preview-table">
        <Table styles={FILE_PREIVEW_STYLE} TABLE={table} data={files} />
      </IonContent>
      <IonFooter>
        <Flex padding={SPACE.MEDIUM} justifyContent="flex-end">
          <Button onClick={onSubmitFiles}>{intl('BUTTON.SUBMIT')}</Button>
        </Flex>
      </IonFooter>
    </ModalWrapper>
  );
};

export default FileModal;
