import styled from 'styled-components';
import { IonPage } from '@ionic/react';

export const IonPageWrapper = styled(IonPage)`
  .item-list {
    max-width: 400px;
    font-size: 1.2em;
  }
  .main-table-wrapper {
    margin-bottom: 50px;
  }

  .indent .label {
    margin-left: 30px;
  }
`;

export const TableSummaryWrapper = styled.div`
  min-width: 190px;
  li {
    font-size: 14px;
    padding: 0;
  }
`;
