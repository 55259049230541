import React from 'react';
import OrderModel from '$fbusiness/models/order';

import { ModalWrapper } from './styles';
import { itemText } from '$gbusiness/helpers/util';
import OrderUpdateDetails from './orderUpdateDetails';
import InvoiceModel from '$fbusiness/models/invoice';

interface OrderUpdateModalProps {
  show: boolean;
  order?: OrderModel;
  invoice?: InvoiceModel;
  onSubmitOrder: Function;
  onClose: () => void;
}

const OrderUpdateModal: React.FC<OrderUpdateModalProps> = ({
  show,
  order,
  invoice,
  onClose,
  onSubmitOrder,
}) => {
  return (
    <ModalWrapper
      titleText={itemText(
        'EDIT',
        invoice ? `Invoice ${invoice?.invoiceNumber}` : `Order ${order?.orderNumber}`,
      )}
      show={show}
      width="740px"
      onClose={() => onClose()}
      useCustom>
      {(order || invoice) && (
        <OrderUpdateDetails order={order} invoice={invoice} onClose={onClose} onSubmitOrder={onSubmitOrder} />
      )}
    </ModalWrapper>
  );
};

export default OrderUpdateModal;
