import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const SHIPPING_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'INPUT.LABEL.SHIPPING_NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'INPUT.LABEL.SHIPPING_DESCRIPTION',
        value: 'description',
        input: INPUT.TEXT,
        gridSize: 12,
      },
      {
        ...INPUT_TYPES.AMOUNT,
        label: 'INPUT.LABEL.SHIPPING_COST',
        value: 'deliveryCost',
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        input: INPUT.NUMBER,
        label: 'INPUT.LABEL.MINIMUM_SHIPPING_DAYS',
        value: 'days',
        gridSize: 6,
        postfix: 'INPUT.PLACEHOLDER.BUSINESS_DAYS',
        validation: [input.requiredValidation],
      },
    ],
  },
];
