import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail } from '$gbusiness/services/api';
import {
  CLEAN_REPORT,
  FETCH_SNAPSHOT_SUCCESS,
  FETCH_SUMMARY_SUCCESS,
  ReportActionTypes,
  REPORT_FAILURE,
} from './types';
import { deriveRawToSnapshot, deriveRawToSummary } from '../../models/report';

export function fetchFactorySnapshot(filter): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.report.snapshot + '?' + new URLSearchParams(filter).toString(),
      method: 'GET',
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, REPORT_FAILURE, response, 'ERROR.SERVER');
      dispatch({ type: CLEAN_REPORT });
      return;
    } else {
      dispatch({
        type: FETCH_SNAPSHOT_SUCCESS,
        snapshot: deriveRawToSnapshot(response.data),
      });
    }
  };
}

export function fetchSalesSummary(filter): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.report.summary + '?' + new URLSearchParams(filter).toString(),
      method: 'GET',
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, REPORT_FAILURE, response, 'ERROR.SERVER');
      dispatch({ type: CLEAN_REPORT });
      return;
    } else {
      dispatch({
        type: FETCH_SUMMARY_SUCCESS,
        summary: deriveRawToSummary(response.data),
      });
    }
  };
}

export function dehydrate(): ReportActionTypes {
  return { type: CLEAN_REPORT };
}
