export const mergeOrderItems = orders => {
  const mergedItems = orders.reduce((acc, order) => {
    const newItems = order.items.reduce(
      (acc2, item) => {
        const foundIndex = findSameItem(acc2, item);
        if (foundIndex === -1) acc2.push(item);
        else
          acc2[foundIndex] = {
            ...acc2[foundIndex],
            qty: (acc2[foundIndex].qty || 0) + item.qty,
          };
        return acc2;
      },
      [...acc],
    );
    return newItems;
  }, []);

  return mergedItems;
};

const findSameItem = (list, item) => {
  return list.findIndex(listItem => {
    if (listItem.itemId !== item.itemId) return false;
    const distinguisher = modifierDistinguisher(listItem, item);
    if (distinguisher) return false;
    return true;
  });
};

export const modifierDistinguisher = (item1, item2) => {
  if (item1.modifiers?.length !== item2.modifiers?.length) return null;
  const diffModifier = item1.modifiers.find((m, i) => {
    const m2 = item2.modifiers[i];
    if (m.modifierId !== m2.modifierId || m.qty !== m2.qty || m.price !== m2.price) return true;
    return false;
  });
  if (diffModifier) return diffModifier?.name;
  if (item1.instruction !== item2.instruction) return 'instruction';
  return '';
};
