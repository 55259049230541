export const KEYS = {
  BAKERY: 'BAKERY',
  ECOMMERCE: 'ECOMMERCE',
  OTHER: 'OTHER',
};

export const FACTORY_TYPE = KEYS;

const LIST = Object.keys(KEYS);

export default LIST.map((item) => ({
  label: `FACTORY_TYPE.${item}`,
  value: item,
}));

export type FACTORY_TYPE_TYPE = typeof LIST[number] | undefined;
