import { SPACE, WEIGHT } from '$gstyles';
import { IonPage } from '@ionic/react';

import { mobileStyle } from '$gstyles/utils';

import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .submit-button {
    min-width: 200px;
    width: 40%;
    margin-top: ${SPACE.LARGE};
    margin-left: ${SPACE.LARGE};
    margin-right: ${SPACE.LARGE};
  }
  .form-group {
    margin: 0 ${SPACE.SMALL};
    .col {
      padding: 0px 8px !important;
    }
    .row {
      margin-left: -${SPACE.LARGE};
      margin-right: -${SPACE.LARGE};
    }
  }

  .tax-section {
    .MuiIconButton-root {
      padding: ${SPACE.SMALL};
    }
  }

  .item-name {
    font-weight: ${WEIGHT.SEMI_BOLD};
    font-size: 1.1em;
  }
  .item-description {
    margin: ${SPACE.MEDIUM};
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .vendor.item-row {
    input {
      min-width: 200px;
    }
  }

  .invoice-number > div:first-child {
    margin-top: 20px !important;
  }

  ${mobileStyle(`
    .item-row.tax {
        padding-bottom: ${SPACE.XLARGE};
        padding-top: ${SPACE.LARGE};
        border-bottom: 1px solid var(--ion-border-color);
    }
  `)}
`;
