import React from 'react';
import { IonItem, IonLabel, IonList, IonMenuToggle } from '@ionic/react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Div } from '$gstyles';
import { SIDE_MENU } from '$business/enums/routes';
import intl from '$gintl';
import { accessString } from '$gbusiness/helpers/util';

interface SideMenuItemProps {
  currentRoute: string;
  disableToggle?: boolean;
}

const SideMenuItem: React.FC<SideMenuItemProps> = ({ disableToggle, currentRoute }) => {
  const ToggleWrapper = disableToggle ? Div : IonMenuToggle;

  const renderItem = (menu, i, level = 0, isSelected = false, isAccordion = false) => {
    const rootClass = level === 0 ? '' : 'sub-category';
    const itemProps = {
      ...(menu.href
        ? { href: menu.href, target: '_blank' }
        : menu.path && { routerLink: menu.path, routerDirection: 'root' }),
    };

    return (
      <ToggleWrapper
        {...(isAccordion && menu.path && { onClick: (e) => e.stopPropagation() })}
        key={i}
        autoHide={false}
        className={`menu-item ${rootClass} ${accessString(menu.route?.accessLevel, menu.route?.feature)}`}>
        <IonItem
          className={isSelected ? 'selected pointer' : 'pointer'}
          {...itemProps}
          lines="none"
          detail={false}>
          {menu.icon ? <menu.icon /> : <span className="MuiSvgIcon-root empty" />}
          <IonLabel>{intl(menu.title)}</IonLabel>
        </IonItem>
      </ToggleWrapper>
    );
  };
  return (
    <IonList>
      {SIDE_MENU.map((menu, index) => {
        const hasChildren = menu.submenu?.length;
        const isSelected = currentRoute.includes(menu.route?.path || 'NOWAY');
        const childrenEl = menu.submenu?.map((sm, j) =>
          renderItem(sm, j, 1, currentRoute.includes(sm.route?.path || '')),
        );
        const AccordionProps = {
          ...(menu.path && {
            onClick: () => {},
          }),
        };
        return hasChildren ? (
          <Accordion
            key={index}
            {...AccordionProps}
            className={`outer ${isSelected && 'selected'}`}
            defaultExpanded>
            <AccordionSummary onClick={() => {}} expandIcon={<ExpandMore className="expand-icon" />}>
              {renderItem(menu, index, 0, isSelected, true)}
            </AccordionSummary>
            <AccordionDetails>{childrenEl}</AccordionDetails>
          </Accordion>
        ) : (
          <div key={index} className="outer">
            {renderItem(menu, index, 0, isSelected)}
          </div>
        );
      })}
    </IonList>
  );
};

export default SideMenuItem;
