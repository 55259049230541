import React, { useEffect, useState } from 'react';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';
import { AutoSizer, List } from 'react-virtualized';
import Selecto from 'react-selecto';

import { SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { ModalWrapper, SelectableItem } from './styles';
import { getEl } from '$gbusiness/helpers/input';
import { Checkbox, FormControlLabel } from '@mui/material';
import { divideList } from './utils';

interface SkuFilterModalProps {
  show: boolean;
  onClose: Function;
  onSubmitFilter: Function;
  skuList: Array<any>;
}

const defaultDimension = { height: 0, width: 0 };

const SkuFilterModal: React.FC<SkuFilterModalProps> = ({ show, onClose, onSubmitFilter, skuList = [] }) => {
  const [selected, setSelected] = useState<Array<any>>([]);
  const [dimensions, setDimensions] = useState<any>(defaultDimension);
  const [showDescription, setShowDescription] = useState(false);
  const onSubmit = () => {
    onSubmitFilter(selected.map((i) => skuList[i]));
    onClose();
  };
  const ROW_HEIGHT = 20;
  const COLS = 5;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!show) {
      setSelected([]);
      setDimensions(defaultDimension);
      return;
    }
    setTimeout(handleResize, 300);
  }, [show]);

  function handleResize() {
    const { innerWidth: width, innerHeight: height } = window;
    if (!width || !height) return;
    const wd = { width, height };
    const modal = getEl('#ion-react-wrapper');
    const md = { width: modal?.offsetWidth, height: modal?.offsetHeight };
    if (!md.width) return;
    setDimensions({
      width: Math.floor(wd.width - md.width) / 2,
      height: Math.floor(wd.height - md.height) / 2,
    });
  }

  const list = showDescription ? skuList : divideList(skuList, COLS);
  const dataSize = list.length;

  const renderRow = ({ index, style }) => {
    const row = skuList[index];
    return (
      <div
        className={`item ${selected.includes(index) ? 'selected' : ''}`}
        key={index}
        data-id={index}
        style={style}>
        {`${row.sku} - ${row.name}`}
      </div>
    );
  };

  const renderRowArr = ({ index, style }) => {
    const row = list[index];
    return (
      <>
        {row.map((col, j) => {
          const i = index * COLS + j;
          return (
            <SelectableItem
              className={`item inline ${selected.includes(i) ? 'selected' : ''}`}
              key={j}
              cols={COLS}
              index={j}
              data-id={i}
              style={style}>
              {col.sku}
            </SelectableItem>
          );
        })}
      </>
    );
  };

  return (
    <ModalWrapper
      className="sku-modal"
      title="SCREEN.REPORT.SELECT_ITEM_FILTER"
      show={show}
      cols={COLS}
      height="800px"
      onClose={() => onClose()}
      dimensions={dimensions}
      useCustom>
      <IonContent className="ion-content" scrollY={false}>
        <div className="full-screen container">
          <Selecto
            dragContainer={'#root-app'}
            selectableTargets={['.sku-list .item']}
            hitRate={20}
            selectByClick={true}
            selectFromInside={true}
            continueSelect={true}
            toggleContinueSelect={['shift']}
            ratio={0}
            onSelectEnd={(e) => {
              const addList: Array<number> = [];
              const removeList: Array<number> = [];

              e.added.forEach((el, cls) => {
                const data = el.dataset.id;
                addList.push(parseInt(data || ''));
              });
              e.removed.forEach((el) => {
                const data = el.dataset.id;
                removeList.push(parseInt(data || ''));
              });
              const newList = [...selected, ...addList].filter((i) => !removeList.includes(i));
              setSelected(newList);
            }}></Selecto>
          <AutoSizer className="sku-container">
            {({ height, width }) => (
              <List
                className="List sku-list"
                width={width}
                height={height}
                rowHeight={ROW_HEIGHT}
                rowCount={dataSize}
                rowRenderer={showDescription ? renderRow : renderRowArr}
              />
            )}
          </AutoSizer>
        </div>
      </IonContent>
      <IonFooter>
        <Flex padding={SPACE.MEDIUM} justifyContent="space-between">
          <FormControlLabel
            control={
              <Checkbox onChange={() => setShowDescription(!showDescription)} value={showDescription} />
            }
            label="Show Item Name"
          />
          <Button className="" variant="outlined" onClick={() => setSelected([])}>
            {intl('BUTTON.CLEAR')}
          </Button>
          <Button className="third" onClick={onSubmit}>
            {intl('BUTTON.SUBMIT')}
          </Button>
        </Flex>
      </IonFooter>
    </ModalWrapper>
  );
};

export default SkuFilterModal;
