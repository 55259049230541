import React from 'react';
import FactoryModel from '$fbusiness/models/factory';

import { ModalWrapper } from './styles';
import InvoiceModel from '$fbusiness/models/invoice';
import CurrentStateModel from '$fbusiness/models/currentState';
import InvoiceDetails from './invoiceDetails';

interface InvoiceDetailsModalProps {
  show: boolean;
  invoice?: InvoiceModel;
  itemId?: number;
  currentState: CurrentStateModel;
  onRefresh?: Function;
  factory: FactoryModel;
  onClose: Function;
}

const InvoiceDetailsModal: React.FC<InvoiceDetailsModalProps> = ({
  currentState,
  itemId,
  show,
  onRefresh = () => {},
  factory,
  invoice,
  onClose,
}) => {
  return (
    <ModalWrapper show={show} width="900px" onClose={() => onClose()} useCustom>
      {invoice && (
        <InvoiceDetails
          currentState={currentState}
          itemId={itemId}
          invoice={invoice}
          onRefresh={onRefresh}
          factory={factory}
          onClose={onClose}
        />
      )}
    </ModalWrapper>
  );
};

export default InvoiceDetailsModal;
