export const KEYS = {
  INVOICE: 'INVOICE',
  PAYMENT: 'PAYMENT',
  CREDIT: 'CREDIT',
  REFUND: 'REFUND',
  CHARGE: 'CHARGE',
};

export const CREDIT_REFUND = 'CREDIT_REFUND';

export const TRANSACTION_TYPE = KEYS;

const convertToOption = (item) => ({
  label: `TRANSACTION_TYPE.${item}`,
  value: item,
});

const LIST = Object.keys(KEYS);

export const TRANSACTION_LIST = LIST;

export const TRANSACTION_FILTER_LIST = [KEYS.INVOICE, KEYS.PAYMENT, CREDIT_REFUND, KEYS.CHARGE];

export const TRANSACTION_TYPE_OPTIONS = TRANSACTION_FILTER_LIST.map(convertToOption);
