import React, { useState } from 'react';

import { ModalWrapper } from '../styles';
import InvoiceModel from '$fbusiness/models/invoice';
import StoreModel from '$fbusiness/models/store';
// import InvoicePaymentModel from '$fbusiness/models/invoicePayment';
import intl from '$gintl';
import BankModel from '$fbusiness/models/bank';
import PaymentDetails from './paymentDetails';
import CurrentStateModel from '$fbusiness/models/currentState';
import InvoicePaymentGroupModel from '$fbusiness/models/invoicePaymentGroup';
import { itemText } from '$gbusiness/helpers/util';

interface PaymentModalProps {
  currentState: CurrentStateModel;
  show: boolean;
  paymentGroup?: InvoicePaymentGroupModel;
  onChangePaymentGroup: Function;
  invoice?: InvoiceModel;
  banks: Array<BankModel>;
  onRefreshInvoice: Function;
  store: StoreModel;
  stores: Array<StoreModel>;
  onClose: () => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  currentState,
  show,
  paymentGroup,
  invoice,
  onRefreshInvoice,
  onChangePaymentGroup,
  banks,
  onClose: onCloseModal,
  store,
  stores,
}) => {
  const titleText = itemText(paymentGroup ? 'EDIT' : 'NEW', intl('COMMON.INVOICE_PAYMENT'));
  const [touched, setTouched] = useState(false);

  const onClose = async () => {
    onCloseModal();
    // if (!touched || forceClose) {
    //   onCloseModal();
    //   return;
    // }
    // dialog.confirm({
    //   title: 'MESSAGE.WAIT',
    //   message: 'MESSAGE.PAYMENT_EXIT_WARNING',
    //   onPress: () => {
    //     setTouched(false);
    //     onCloseModal();
    //   },
    // });
  };

  const renderPaymentDetails = (invoice) => {
    return (
      <PaymentDetails
        banks={banks}
        stores={stores}
        invoice={invoice}
        onRefreshInvoice={onRefreshInvoice}
        currentState={currentState}
        paymentGroup={paymentGroup}
        onChangePaymentGroup={onChangePaymentGroup}
        store={store}
        onClose={onClose}
        touched={touched}
        setTouched={setTouched}
      />
    );
  };

  return (
    <ModalWrapper
      titleText={titleText}
      show={show}
      width="900px"
      height="800px"
      onClose={onCloseModal}
      onPressClose={onClose}
      backdropDismiss={!touched}
      useCustom>
      {store && renderPaymentDetails(invoice)}
    </ModalWrapper>
  );
};

export default PaymentModal;
