import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const DISCOUNT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'SCREEN.DISCOUNTS.COLS.NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 12,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.DISCOUNTS.COLS.FIXED',
        value: 'isFlat',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        ...INPUT_TYPES.AMOUNT,
        value: 'amount',
        gridSize: 6,
        validation: [input.requiredValidation],
        isHidden: vals => !vals?.isFlat,
      },
      {
        ...INPUT_TYPES.PERCENT,
        value: 'amount',
        label: 'SCREEN.DISCOUNTS.COLS.RATE',
        gridSize: 6,
        validation: [input.requiredValidation],
        isHidden: vals => vals?.isFlat,
      },
    ],
  },
];
