import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { Modal } from '$gcomponents/reusables';

export const IonPageWrapper = styled(IonPage)`
  .content {
  }
`;

export const ModalWrapper = styled(Modal)`
  &.sku-modal {
  }
  .full-screen {
    height: 100%;
    position: relative;
    padding: 16px;
  }
  ion-content {
    .sku-list {
      .item.selected {
        color: #000;
        opacity: 1;
      }
      .item:before {
        content: ' ';
        position: relative;
        display: inline-block;
        position: relative;
        width: 16px;
      }
      .item.selected:before {
        content: '✓';
      }
      .item {
        position: relative;
        opacity: 0.5;
        align-items: center;
        min-width: 80px !important;
        cursor: pointer;
        width: auto !important;
      }
      overflow-y: auto;
      height: 100%;
    }
    .selecto-selection {
      ${(p) => p.dimensions.height > 0 && `top: -${p.dimensions.height}px !important;`}
      ${(p) => p.dimensions.width > 0 && `left: -${p.dimensions.width}px !important;`}
    }
  }
`;

export const SelectableItem = styled.div`
  &.item.inline {
    min-width: 30px !important;
    ${(p) => {
      const percent = Math.floor(100 / (p.cols || 5));
      const margin = p.index * percent;
      return `
        width: ${percent}% !important;
        margin-left: ${margin}%;
      `;
    }}
  }
`;
