import React from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { Div, SPACE } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import Footer from '$gcomponents/widgets/footer';

import { ModalWrapper } from './styles';

import OrderItemModel from '$fbusiness/models/orderDetails';
import { ORDER_DETAILS_TABLE, ORDER_DETAILS_TABLE_CONFIG } from '$fcomponents/orderDetails/orderDetailsTable';
import ExportButton from '$gcomponents/reusables/exportButton';
import { Link } from '$gstyles/general';
import { Table } from '$gcomponents/reusables';
import { format, today } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import CurrentStateModel from '$fbusiness/models/currentState';

interface MergedModalProps {
  show: boolean;
  currentState: CurrentStateModel;
  items: Array<OrderItemModel>;
  startDate?: string;
  endDate?: string;
  onClose: () => void;
}

const MergedModal: React.FC<MergedModalProps> = ({
  show,
  currentState,
  startDate = '',
  endDate = today(),
  items,
  onClose,
}) => {
  const { hidePrice } = currentState;
  const dateStr =
    startDate === endDate
      ? format(startDate, DATE_FORMATS.READABLE)
      : !startDate && endDate
      ? `Beginning to ${format(endDate, DATE_FORMATS.READABLE)}`
      : `${format(startDate, DATE_FORMATS.READABLE)} - ${format(endDate, DATE_FORMATS.READABLE)}`;

  const onPrint = () => window.print();

  const renderMerged = (items) => {
    const itemsTable = ORDER_DETAILS_TABLE({
      isInvoice: false,
      isFlagged: false,
      isRegenerated: false,
      hidePrice,
    });
    const tableProps = {
      styles: ORDER_DETAILS_TABLE_CONFIG.styles,
      TABLE: itemsTable,
      data: items,
    };

    const exportData = items.map((row) => ({
      item: row.itemName,
      modifiers: row.modifiers?.length > 0 ? row.modifiers.map((i) => i.name).join(', ') : '',
      note: row.note,
      dept: row.deptStr,
      category: row.categoryStr,
      upc: row.barcode,
      sku: row.sku,
      qtyOrdered: row.qty,
    }));
    const fileName = 'merged_items';
    return (
      <>
        <IonContent>
          <Div padding={SPACE.LARGE}>
            <div className="no-print export-wrapper">
              <ExportButton
                data={exportData}
                fileName={fileName}
                component={<Link>{intl('BUTTON.EXPORT_ITEMS')}</Link>}
              />
            </div>
            <Table {...tableProps} />
          </Div>
        </IonContent>
        <Footer justifyContent="space-around" className="no-print">
          <Button className="third" variant="text" onClick={onClose}>
            {intl('BUTTON.CLOSE')}
          </Button>
          <Button className="third" onClick={onPrint}>
            {intl('BUTTON.PRINT')}
          </Button>
        </Footer>
      </>
    );
  };

  return (
    <ModalWrapper show={show} title={dateStr} width="720px" onClose={() => onClose()} useCustom>
      {items && items.length > 0 && renderMerged(items)}
    </ModalWrapper>
  );
};

export default MergedModal;
