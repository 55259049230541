import { FONT, SPACE } from '$gstyles';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';
import { mobileStyle } from '$gstyles/utils';

export const IonPageWrapper = styled(IonPage)`
  .submit-button {
    min-width: 200px;
    margin-left: ${SPACE.XLARGE};
    margin-right: ${SPACE.XLARGE};
  }

  .flex-start {
    justify-content: flex-start;
  }

  .modifier-section {
    padding: ${SPACE.MEDIUM};
    .modifier-group {
      padding: ${SPACE.SMALL} 0;
      border-bottom: 1px solid var(--ion-border-color);
      min-height: 36px;
      justify-content: space-between;
      .mg-name {
        font-size: ${FONT.MEDIUM};
      }
      .modifiers {
        margin-left: ${SPACE.MEDIUM};
        opacity: 0.7;
        font-size: ${FONT.SMALL};
      }
    }

    .MuiIconButton-root {
      padding: 8px;
    }
  }

  .form-section {
    padding: 16px;
    background-color: #f6f6f6;
    margin: 8px;
    border-radius: 5px;
  }

  .MuiCheckbox-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .other-images {
    margin: ${SPACE.MEDIUM};
    margin-top: -${SPACE.SMALL};
    justify-content: center;
    .thumb {
      position: relative;
      margin: 0 ${SPACE.SMALL};
      .cancel-button {
        position: absolute;
        top: -${SPACE.MEDIUM};
        right: -${SPACE.MEDIUM};
        z-index: 1;
        font-size: 20px;
        background-color: #000;
        color: #fff;
        padding: 2px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }

  .tableview2 {
    padding: 16px 0;
    height: auto;
    min-height: 400px;
    .MuiTableCell-root {
      font-size: 14px;
    }
  }

  ${mobileStyle(`
    .modifier-section .MuiIconButton-root {
      padding: 4px;
    }
  `)}
`;
