import { SPACE, WEIGHT } from '$gstyles';
import { IonToolbar } from '@ionic/react';
import styled from 'styled-components';

export const IonToolbarWrapper = styled(IonToolbar)`
  /* ul {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    max-width: 400px;
    li {
      cursor: pointer;
      opacity: 0.5;
      font-weight: ${WEIGHT.SEMI_BOLD};
      padding: ${SPACE.LARGE} 0;
      border-bottom: 2px solid transparent;
    }
    ${p => `li:nth-child(${p.index + 1}) {
      border-bottom: 2px solid var(--ion-color-primary);
      opacity: 1;
    }`}
  } */
`;
