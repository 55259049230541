import PAYMENT_OPTIONS from '$fbusiness/enums/options/paymentType';
import RefundModel from '$fbusiness/models/refund';
import { INPUT } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';
import { insertEmptyOption } from '$gbusiness/helpers/input';
import InputRowModel from '$gbusiness/models/inputRow';

export const SN = 'SCREEN.INVOICES.';
export const SNC = 'SCREEN.INVOICES.COLS.';

export const initialRefundForm = {
  id: 0,
  noteFactory: '',
  userId: 0,
  store: null,
  status: '',
};

export const invoiceToRefundForm = (invoice) => {
  const { invoiceNumber, noteFactory, user, termId, status } = invoice;
  return {
    id: 0,
    invoiceNumber,
    user,
    noteFactory,
    termId,
    status,
  };
};

export const refundToForm = (refund: RefundModel) => {
  const { requestDate, id, user, note, payment } = refund;
  return { id, refundDate: requestDate, user, payment, note };
};

export const REFUND_FORM = (
  stores,
  discounts,
  reps,
  onStoreChange,
  shouldConvert = false,
  isCredit = false,
  settings,
): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.DATE,
        label: SNC + 'DATE',
        value: 'refundDate',
        size: 'small',
        gridSize: 3,
      },
      {
        label: SNC + 'STORE',
        value: 'store',
        disableFastField: true,
        input: INPUT.AUTOCOMPLETE,
        // disabled: shouldConvert,
        gridSize: 4,
        config: {
          optionProp: {
            label: 'name',
            labelText: (o) => `${o.name || ''} ${o.code ? `(${o.code})` : ''}`,
            value: 'id',
          },
          data: stores,
        },
        size: 'small',
        options: (stores || []).map((s) => ({
          labelText: s.name,
          value: s.id,
        })),
        validation: [input.requiredValidation],
        onChange: (e) => {
          onStoreChange(e);
        },
      },
      ...(isCredit
        ? []
        : [
            {
              input: INPUT.SELECT,
              label: 'SCREEN.REFUNDS.COLS.PAYMENT',
              value: 'payment',
              size: 'small',
              options: PAYMENT_OPTIONS,
              ...(!isCredit && { validation: [input.requiredValidation] }),
              gridSize: 3,
            },
          ]),
    ],
  },
  {
    items: [
      {
        label: SNC + 'DISCOUNT',
        value: 'discountId',
        disableFastField: true,
        isDisabled: (values) => !values.store,
        // disabled: shouldConvert,
        isHidden: () => !settings?.discounts,
        input: INPUT.SELECT,
        gridSize: 3,
        size: 'small',
        options: insertEmptyOption(
          (discounts || []).map((s) => ({
            labelText: s.name,
            value: s.id,
          })),
          'None',
          '0',
        ),
      },
      {
        label: SNC + 'SALES_REP',
        value: 'user',
        isDisabled: (values) => !values.store,
        disableFastField: true,
        // disabled: shouldConvert,
        isHidden: () => !settings?.commission,
        input: INPUT.AUTOCOMPLETE,
        config: {
          optionProp: {
            label: 'name',
            value: 'userId',
          },
          data: reps,
        },
        gridSize: 4,
        size: 'small',
        onChange: ({ formik, value }) => {
          formik.setFieldValue('userId', value?.userId || 0);
        },
      },
      {
        label: SNC + 'TAX',
        value: 'noTax',
        // disabled: shouldConvert,
        disableFastField: true,
        isDisabled: (values) => !values.store,
        input: INPUT.SELECT,
        gridSize: 2,
        size: 'small',
        options: [
          {
            labelText: 'Tax',
            value: '0',
          },
          {
            labelText: 'No Tax',
            value: '1',
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        label: SN + 'NOTES',
        value: 'note',
        className: 'ship-input',
        input: INPUT.TEXTAREA,
        gridSize: 4,
        rows: 2,
        size: 'small',
      },
    ],
  },
];

export default REFUND_FORM;
