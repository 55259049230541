import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { currency, percentage } from '$gbusiness/helpers/util';
import { Select } from '@mui/material';
import { generateSelectOptions } from '$gcomponents/utils/input';
import { format } from '$gbusiness/helpers/date';
import { deriveRawToInvoice } from '$fbusiness/models/invoice';
import ExportModel from '$gbusiness/models/export';
import { CLAWBACK_STATUS, CLAWBACK_STATUS_OPTIONS } from '$fbusiness/enums/options/clawbackStatus';

export const SN = 'SCREEN.INVOICES.';
export const SNC = SN + 'COLS.';
export const NUM_PREV_COMMISSION = 20;

export const commInvoiceExportSettings: ExportModel = {
  url: configs.api.invoice.general,
  method: 'GET',
  fileName: 'commission',
  deriveToModel: (row) => row,
  deriveTable: (item) => ({
    Customer: item.store.name,
    LastName: item.invoiceNumber,
    InvoiceDate: format(item.createdAt, DATE_FORMATS.SLASH),
    DueDate: format(item.dueDate, DATE_FORMATS.SLASH),
    Subtotal: currency(item.taxable),
    DiscountRate: percentage(item.discountRate),
    Commission: currency((item.taxable * item.commRate) / 100),
    ClawBack: item.clawBack,
  }),
  sortKey: 'invoiceNumber',
  sortOrder: SORT.ASC,
};

export const COMMINVOICE_TABLE_WIT_CONFIG = (commPayId): TableModel => ({
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.commission.payment + '/' + commPayId,
    deriveToModel: deriveRawToInvoice,
    method: 'GET',
    defaultSortKey: 'invoiceNumber',
    defaultSortOrder: SORT.ASC,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.MEDIUM,
    fontSize: '1.05em',
    minWidth: 400,
    maxWidth: 1200,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 36,
    // setRowClass: (row) => (VALID_INVOICES.includes(row.status) ? '' : 'cancelled'),
  },
});

export const COMMINVOICE_TABLE_CONFIG = (defaultParam): TableModel => {
  const table = COMMINVOICE_TABLE_WIT_CONFIG(0);
  return {
    ...table,
    dataSource: {
      ...table.dataSource,
      endpoint: configs.api.invoice.general,
      defaultParam,
    },
  };
};

export const COMMINVOICE_TABLE = (onChangeStatus, isClawbackPeriod = false): Array<CellModel> => [
  {
    label: SNC + 'STORE',
    value: 'store.name',
    alwaysShow: true,
    className: 'main',
    width: 300,
  },
  {
    label: SNC + 'INVOICE_NO',
    value: 'invoiceNumber',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 300,
  },
  {
    label: SNC + 'STATUS',
    value: 'status',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 300,
  },
  {
    label: SNC + 'CREATED',
    value: 'createdAt',
    alwaysShow: true,
    component: (row) => <>{format(row.createdAt, DATE_FORMATS.SLASH)}</>,
    width: 300,
  },
  {
    label: SNC + 'DUE_DATE',
    value: 'dueDate',
    alwaysShow: true,
    component: (row) => <>{format(row.dueDate, DATE_FORMATS.SLASH)}</>,
    className: 'main',
    width: 200,
  },
  {
    label: SNC + 'SUBTOTAL',
    value: 'subtotal',
    align: 'center',
    component: (row) => <>{currency(row.taxable)}</>,
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: SNC + 'BALANCE',
    value: 'balance',
    align: 'center',
    component: (row) => <>{currency(row.balance)}</>,
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: SNC + 'DISCOUNT',
    value: 'discountRate',
    align: 'center',
    component: (row) => <>{percentage(row.discountRate)}</>,
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: SNC + 'COMMISSION',
    value: 'commission',
    align: 'center',
    component: (row) => {
      return <>{currency((row.taxable * row.commRate) / 100)}</>;
    },
    sortable: SORT.DSC,
    width: 260,
  },
  {
    label: SNC + 'CLAWBACK',
    value: 'clawBack',
    component: (row) => {
      let value = row.clawBack;
      if (isClawbackPeriod && row.clawBack === CLAWBACK_STATUS.NA) value = CLAWBACK_STATUS.YES;
      const renderOption = generateSelectOptions(
        CLAWBACK_STATUS_OPTIONS,
        true,
        row.clawBack !== CLAWBACK_STATUS.NA,
      );
      return (
        <Select
          size="small"
          className="xsmall"
          variant="outlined"
          value={value}
          onChange={(e) => onChangeStatus(row, e.target.value)}>
          {renderOption}
        </Select>
      );
    },
    width: 250,
  },
];
