import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { currency } from '$gbusiness/helpers/util';
import { Button } from '$gcomponents/primitives';
import intl from '$gintl';
import { deriveRawToRefund, toRefundId } from '$fbusiness/models/refund';
import { format } from '$gbusiness/helpers/date';
import { REFUND_STATUS } from '$fbusiness/enums/options/refundStatus';
import { getSalesman } from '$fbusiness/helpers/util';
import ExportModel from '$gbusiness/models/export';

export const refundExportSettings: ExportModel = {
  url: configs.api.refund,
  method: 'GET',
  fileName: 'refundRequests',
  deriveToModel: deriveRawToRefund,
  deriveTable: (item) => ({
    Id: `R${item.id}`,
    Customer: item.storeName,
    DateRequested: format(item.requestDate, DATE_FORMATS.SLASH) || '',
    Status: item.status,
    InvoiceNo: item.invoiceNumber,
    PO_No: item.poNo,
    SalesRep: getSalesman(item),
    Qty: item.qty,
    Total: currency(item.total),
    Balance: currency(item.balance),
  }),
  sortKey: 'refundedAt',
  sortOrder: SORT.DSC,
};

export const REFUND_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.refund,
    method: 'GET',
    deriveToModel: deriveRawToRefund,
    defaultSortKey: 'refundedAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 600,
    maxWidth: 1100,
    cellPadding: ` 2px 4px`,
    isStripped: true,
    responsive: true,
    setRowClass: (row) => (row.status === REFUND_STATUS.PROCESSED ? 'processed' : ''),
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const REFUND_TABLE = (onDetails): Array<CellModel> => [
  {
    label: 'COMMON.ID',
    value: 'id',
    component: (row) => <>{toRefundId(row.id)}</>,
    alwaysShow: true,
    width: 120,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.STORES',
    value: 'storeName',
    alwaysShow: true,
    width: 300,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.DATE',
    value: 'requestDate',
    sortKey: 'refundDate',
    align: 'center',
    component: (row) => <>{format(row.requestDate, DATE_FORMATS.SLASH) || ''}</>,
    alwaysShow: true,
    width: 250,
    sortable: SORT.DSC,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.STATUS',
    value: 'status',
    alwaysShow: true,
    width: 220,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.INVOICENO',
    value: 'invoiceNumber',
    alwaysShow: true,
    width: 220,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.PONO',
    value: 'poNo',
    alwaysShow: true,
    width: 150,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.SALESMAN',
    value: 'user',
    component: (row) => {
      return <>{getSalesman(row)}</>;
    },
    alwaysShow: true,
    width: 260,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.ITEMS',
    value: 'qty',
    className: 'metadata',
    alwaysShow: true,
    width: 100,
  },
  // {
  //   label: 'SCREEN.REFUNDS.COLS.SUBTOTAL',
  //   value: 'subtotal',
  //   className: 'center',
  //   component: (row) => <>{currency(row.total)}</>,
  //   sortable: SORT.ASC,
  //   width: 200,
  // },
  // {
  //   label: 'SCREEN.REFUNDS.COLS.DISCOUNT',
  //   value: 'totalDiscount',
  //   className: 'center',
  //   sortable: SORT.ASC,
  //   component: (row) => <>{currency(row.totalDiscount)}</>,
  //   alwaysShow: true,
  //   width: 170,
  // },
  // {
  //   label: 'SCREEN.REFUNDS.COLS.TAX',
  //   value: 'tax',
  //   className: 'center',
  //   component: (row) => <>{currency(row.tax)}</>,
  //   sortable: SORT.ASC,
  //   width: 150,
  // },
  {
    label: 'SCREEN.REFUNDS.COLS.TOTAL',
    value: 'total',
    className: 'center',
    sortable: SORT.ASC,
    component: (row) => <>{currency(row.total)}</>,
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.BALANCE',
    value: 'balance',
    className: 'center',
    sortable: SORT.ASC,
    component: (row) => <>{currency(row.balance)}</>,
    alwaysShow: true,
    width: 200,
  },
  {
    label: '',
    value: 'details',
    className: 'action',
    component: (row) => (
      <Button color="secondary" size="small" onClick={() => onDetails(row)}>
        {intl('SCREEN.REFUNDS.COLS.DETAILS')}
      </Button>
    ),
    alwaysShow: true,
    minWidth: 70,
    width: 100,
  },
];
