import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import {
  itemsReportExportSettings,
  ITEMS_REPORT_TABLE,
  ITEMS_REPORT_TABLE_CONFIG,
  refundDetailsExportSettings,
  REFUND_DETAILS_TABLE_CONFIG,
} from './itemsReportTable';
import ReportComponent from '../reportComponent';
import { factoryActions } from '$fbusiness/redux/factory';
import intl from '$gintl';
import InvoiceDetailsModal from '$fscreens/accountScreen/invoicesScreen/invoiceDetailsModal';
import { invoiceActions } from '$fbusiness/redux/invoice';
interface itemsReportScreenProps {
  match;
  screenState;
  currentState;
  factory;
  fetchInvoice;
  history;
  dateFilter;
}

const ItemsReportScreen: React.FC<itemsReportScreenProps> = ({
  match,
  factory,
  fetchInvoice,
  currentState,
  screenState,
  dateFilter,
}) => {
  const [invoice, setInvoice] = useState<any>(null);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const {
    params: { storeId = 0, userId = 0, itemId = 0, refund, termId, shippingMethodId, regionId },
  } = match;

  const handleRowClick = (id, itemId) => {
    setSelectedItemId(itemId);
    fetchInvoice(id).then((inv) => setInvoice(inv));
    // history.push(PATH.INVOICES + '/' + storeId);
  };

  const onTableLoad = (total, newTotal, response) => {
    console.log('ON TABLE LOAD ITEMS', total, newTotal, response);
    // Display Summary info
  };

  const tableConfig = refund ? REFUND_DETAILS_TABLE_CONFIG : ITEMS_REPORT_TABLE_CONFIG;
  const exportSettings = refund ? refundDetailsExportSettings : itemsReportExportSettings;

  let titleText = intl('SCREEN.REPORT.TITLE_ITEM_DETAILS', { name: screenState });
  if (refund) titleText = intl('SCREEN.REPORT.TITLE_REFUND_DETAILS', { name: screenState });

  return (
    <>
      <ReportComponent
        title={titleText}
        table={ITEMS_REPORT_TABLE(handleRowClick, !!refund)}
        tableConfig={tableConfig}
        includeReportType
        onTableLoad={onTableLoad}
        dateFilter={{
          ...dateFilter,
          storeId: Number(storeId) || undefined,
          userId: Number(userId) || undefined,
          itemId: Number(itemId) || undefined,
          regionId: Number(regionId) || undefined,
          termId: Number(termId) || undefined,
          shippingMethodId: Number(shippingMethodId) || undefined,
        }}
        exportSetting={exportSettings}
      />
      <InvoiceDetailsModal
        currentState={currentState}
        invoice={invoice}
        itemId={selectedItemId}
        factory={factory}
        show={!!invoice}
        onClose={() => setInvoice(null)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  screenState: state.routing.screenState,
});

const mapDispatchToProps = {
  onHydrate: (params) => factoryActions.fetchName(params),
  fetchInvoice: invoiceActions.fetchInvoice,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ItemsReportScreen));
