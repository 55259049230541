import React, { useEffect, useState } from 'react';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';

import { SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { CREDIT_LIST, CREDIT_LIST_CONFIG } from './creditList';
import { TableView2 } from '$gcomponents/widgets';
import { ModalWrapper } from '$fcomponents/creditHistoryModal/styles';
import { currency } from '$gbusiness/helpers/util';
import { InfoItem } from '$gcomponents/reusables';
import StoreModel from '$fbusiness/models/store';

interface CreditDropdownProps {
  show: boolean;
  onClose: Function;
  disabledCredits: Array<number>;
  store: StoreModel;
  onFinish: Function;
}

const CreditDropdown: React.FC<CreditDropdownProps> = ({
  show,
  onClose,
  onFinish,
  store,
  disabledCredits,
}) => {
  const [selectedCredit, setSelectedCredit] = useState<any>(null);
  const [selectedPayment, setSelectedPayment] = useState<any>(null);

  const total = selectedPayment?.balance || selectedCredit?.balance || 0;

  const filter = {
    storeId: store.id,
    used: 0,
  };

  useEffect(() => {
    resetChecks();
  }, [disabledCredits]);

  const resetChecks = () => {
    // setSelectedIds([]);
    // setTotalSelected(0);
  };

  const onCheckCredit = (row) => {
    setSelectedPayment(null);
    setSelectedCredit(row);
  };

  const onCheckPayment = (row) => {
    setSelectedPayment(row);
    setSelectedCredit(null);
  };

  const onSubmit = () => {
    onFinish(selectedCredit?.id || 0, selectedPayment?.id || 0, total);
    onClose();
  };

  const creditListConfig = CREDIT_LIST_CONFIG(store.id, disabledCredits, store.openBalance);
  const creditTable = CREDIT_LIST({ selectedCredit, selectedPayment, onCheckCredit, onCheckPayment });

  // console.log('SELECTEDIDS', selectedIds);

  return (
    <ModalWrapper
      title="SCREEN.INVOICE_PAYMENT.MODAL.TITLE_CREDIT"
      show={show}
      onClose={() => onClose()}
      useCustom>
      {show && (
        <>
          <IonContent>
            <TableView2 filter={filter} TABLE={creditTable} tableConfig={creditListConfig} />
          </IonContent>
          <IonFooter>
            <Flex padding={SPACE.MEDIUM} justifyContent="space-between">
              <InfoItem
                alignCenter
                className={`total ${total ? 'success' : ''}`}
                width="210px"
                label={intl('SCREEN.INVOICE_PAYMENT.MODAL.TOTAL_SELECTED')}
                value={currency(total)}
              />
              <Button className="third" disabled={!total} onClick={onSubmit}>
                {intl('BUTTON.CONTINUE')}
              </Button>
            </Flex>
          </IonFooter>
        </>
      )}
    </ModalWrapper>
  );
};

export default CreditDropdown;
