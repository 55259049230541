export const KEYS = {
  NA: 'NA',
  YES: 'YES',
  NO: 'NO',
};

export const CLAWBACK_STATUS = KEYS;

const LIST = Object.keys(KEYS);

export const CLAWBACK_STATUS_OPTIONS = LIST.map((item) => ({
  label: `CLAWBACK.${item}`,
  value: item,
  disabled: item === KEYS.NA,
}));

export default CLAWBACK_STATUS_OPTIONS;

export type COMMPAY_STATUS_TYPE = typeof LIST[number] | undefined;
