import React, { useEffect, useState } from 'react';
import { IonContent, IonFooter } from '@ionic/react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import { input } from '$gbusiness/helpers';
import intl from '$intl';
import FileModel from '$fbusiness/models/file';
import { SPACE } from '$gstyles';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';

import FILE_EDIT_FORM from './fileEditForm';
import { FormSection } from '$gcomponents/reusables';
import { fileActions } from '$fbusiness/redux/file';
import StoreModel from '$fbusiness/models/store';
import StoreSelector from './storeSelector';

interface FileEditDetailsProps {
  file: FileModel;
  onClose: Function;
  onUpdateFile: Function;
  stores: Array<StoreModel>;
}

const FileEditDetails: React.FC<FileEditDetailsProps> = ({ stores, file, onClose, onUpdateFile }) => {
  const dispatch = useDispatch();
  const [selectStores, setSelectStores] = useState<any>(null);

  useEffect(() => {
    if (file) setSelectStores(file.storeIds || null);
  }, [file]);

  if (!file) return null;

  const initialValues = {
    id: file?.id,
    name: file?.name,
    description: file?.description,
  };
  const onSubmitEdit = async values => {
    await dispatch(
      fileActions.updateFile({
        ...values,
        storeIds: selectStores,
      }),
    );
    onUpdateFile();
  };

  const onStoreSelect = values => {
    setSelectStores(values);
  };

  const validateForm = values => input.validateError(FILE_EDIT_FORM, values);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={values => {
        onSubmitEdit(values);
      }}>
      {formik => (
        <>
          <IonContent>
            <PageWrapper>
              <StoreSelector stores={stores} onChange={onStoreSelect} values={selectStores} />
              <FormSection formik={formik} FORM={FILE_EDIT_FORM} />
            </PageWrapper>
          </IonContent>
          <IonFooter>
            <Flex padding={SPACE.MEDIUM} justifyContent="flex-end">
              <Button onClick={formik.handleSubmit}>{intl('BUTTON.SUBMIT')}</Button>
            </Flex>
          </IonFooter>
        </>
      )}
    </Formik>
  );
};

export default FileEditDetails;
