import React from 'react';

import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultStyles } from '$gbusiness/models/table';
import { Delete } from '@mui/icons-material';

export const FILE_PREIVEW_STYLE = {
  ...defaultStyles,
  color: COLORS.LIGHT,
  fontSize: '1em',
  minWidth: 600,
  maxWidth: 900,
  cellPadding: ` 8px 4px`,
  isStripped: false,
};

const FILE_PREVIEW_TABLE = (onChangeField, onDeleteRow): Array<CellModel> => [
  {
    label: 'SCREEN.FILES.COLS.NAME',
    value: 'name',
    component: (row, action, index) => (
      <input
        className="name"
        onChange={e => onChangeField({ name: e.target.value, index })}
        value={row.name}
      />
    ),
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.FILES.COLS.DESCRIPTION',
    value: 'description',
    component: (row, action, index) => (
      <input
        className="description"
        onChange={e => onChangeField({ description: e.target.value, index })}
        value={row.description}
      />
    ),
    alwaysShow: true,
    width: 300,
  },
  {
    label: 'SCREEN.FILES.COLS.FILE_NAME',
    value: 'fileName',
    component: row => <>{row.file ? row.fileName : row.youtubeLink}</>,
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 220,
  },
  // {
  //   label: 'SCREEN.FILES.COLS.FILE_TYPE',
  //   value: 'fileType',
  //   sortable: SORT.ASC,
  //   alwaysShow: true,
  //   width: 140,
  // },
  {
    label: 'SCREEN.FILES.COLS.FILE_SIZE',
    value: 'fileSize',
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 120,
  },
  {
    label: '',
    value: '',
    component: (row, action, index) => <Delete onClick={() => onDeleteRow(index)} />,
    width: 60,
  },
];

export default FILE_PREVIEW_TABLE;
