import React, { useEffect, useRef } from 'react';
import intl from '$intl';
import { Form, Formik } from 'formik';
import { IonContent, IonFooter } from '@ionic/react';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { SPACE } from '$gstyles';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { Modal } from '$gcomponents/reusables';
import { SERIAL_FORM } from './serialForm';
import FactoryModel from '$fbusiness/models/factory';
import ItemModel from '$fbusiness/models/item';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { itemActions } from '$fbusiness/redux/item';
import { itemText } from '$gbusiness/helpers/util';
import ItemBatchModel, { initialBatch } from '$fbusiness/models/itemBatch';
import { serialActions } from '$fbusiness/redux/serial';

interface SerialModalProps {
  factory?: FactoryModel;
  item?: ItemModel;
  batch?: ItemBatchModel;
  show: boolean;
  onRefresh?: Function;
  onClose: Function;
}

const SerialModal: React.FC<SerialModalProps> = ({
  factory,
  item,
  batch = initialBatch,
  show,
  onRefresh,
  onClose,
}) => {
  const dispatch = useDispatch();
  const items = useSelector((state: any) => state.item.list);
  const formRef = useRef<any>();

  const serialForm = SERIAL_FORM();

  useEffect(() => {
    if (!items.length) {
      dispatch(itemActions.fetchItemList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = (values) => {
    const { serials } = values;
    const param = {
      itemId: item?.id,
      ...(batch && { batchId: batch?.id || null }),
      serials: (serials || '').trim().split(/[\n,]/),
    };
    dispatch(serialActions.saveSerials(param)).then((success) => {
      if (success) {
        if (onRefresh) onRefresh();
        if (item?.id) dispatch(itemActions.fetchItem(item?.id));
        onClose();
      }
    });
  };

  const validateForm = (values) => {
    return input.validateError(serialForm, values);
  };

  const initialValues = { itemName: item?.name || '' };

  const title = batch?.id ? itemText('EDIT', 'SERIAL') : itemText('ADD_NEW', 'SERIAL');

  return (
    <Modal width="500px" titleText={title} show={show} onClose={() => onClose()} useCustom>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <PageWrapper>
                <Form>
                  <FormSection FORM={serialForm} formik={formik} />
                </Form>
              </PageWrapper>
            </IonContent>
            <IonFooter>
              <Flex padding={SPACE.MEDIUM} justifyContent="flex-end">
                <Button disabled={!(formik.isValid && formik.dirty)} onClick={formik.handleSubmit}>
                  {intl('BUTTON.SUBMIT')}
                </Button>
              </Flex>
            </IonFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default SerialModal;
