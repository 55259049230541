import React from 'react';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { currency } from '$gbusiness/helpers/util';
import { Select } from '@mui/material';
import { generateSelectOptions } from '$gcomponents/utils/input';
import COMMPAY_OPTIONS from '$fbusiness/enums/options/commPayStatus';
import ExportModel from '$gbusiness/models/export';
import { INVOICE_STATUS } from '$fbusiness/enums/options/invoiceStatus';

export const SN = 'SCREEN.COMMISSIONS.';
export const SNC = SN + 'COLS.';

export const commissionExportSettings: ExportModel = {
  url: configs.api.commission.general,
  method: 'GET',
  fileName: 'commission',
  deriveToModel: (row) => row,
  deriveTable: (item) => ({
    FirstName: item.user.firstName,
    LastName: item.user.lastName,
    SalesTotal: currency(item.total),
    Commission: currency(item.commission),
    ClawBackTotal: currency(item.clawbackTotal),
  }),
  sortKey: 'total',
  sortOrder: SORT.DSC,
};

export const COMMPAY_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.commission.payment,
    method: 'GET',
    defaultSortKey: 'commission',
    defaultSortOrder: SORT.DSC,
    pageSize: 30,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.MEDIUM,
    fontSize: '1.05em',
    minWidth: 600,
    maxWidth: 1300,
    cellPadding: ` 2px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 36,
  },
};

export const COMMPAY_PREVIEW_TABLE_CONFIG: TableModel = {
  ...COMMPAY_TABLE_CONFIG,
  dataSource: {
    ...COMMPAY_TABLE_CONFIG.dataSource,
    endpoint: configs.api.commission.general,
  },
};

export const COMMPAY_TABLE = (handleClick, onChangeStatus, isGenerated = false): Array<CellModel> => [
  {
    label: SNC + 'FIRST_NAME',
    value: 'user.firstName',
    alwaysShow: true,
    className: 'main',
    component: (row) => <>{row.user.firstName}</>,
    width: 220,
  },
  {
    label: SNC + 'LAST_NAME',
    value: 'user.lastName',
    alwaysShow: true,
    className: 'main',
    component: (row) => <>{row.user.lastName}</>,
    width: 220,
  },
  {
    label: SNC + 'TOTAL',
    value: 'total',
    align: 'center',
    component: (row) => (
      <>
        {row.total > 0 ? (
          <div className="link" onClick={() => handleClick(row)}>
            {currency(row.total)}
          </div>
        ) : (
          currency(row.total)
        )}
      </>
    ),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: SNC + 'REFUNDED',
    value: 'refunded',
    align: 'center',
    component: (row) => (
      <span className={`${row.refunds > 0 && 'error link'}`} onClick={() => handleClick(row, 'REFUND')}>
        {currency(row.refunds)}
      </span>
    ),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: SNC + 'CREDITS',
    value: 'credits',
    align: 'center',
    component: (row) => (
      <span className={row.refunds > 0 ? 'error link' : ''} onClick={() => handleClick(row, 'CREDIT')}>
        {currency(row.credits)}
      </span>
    ),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: SNC + 'COMMISSION_SALES',
    value: 'commissionSales',
    align: 'center',
    component: (row) => <span>{currency(row.commissionSales)}</span>,
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: SNC + 'COMMISSION',
    value: 'commission',
    align: 'center',
    component: (row) => <span>{currency(row.commission)}</span>,
    sortable: SORT.DSC,
    width: 220,
  },
  {
    label: SNC + 'CLAWBACKS',
    value: 'clawbacks',
    align: 'center',
    component: (row) => (
      <div>
        <div className="clawback-table">
          {row.clawbacks.map((c, i) => (
            <React.Fragment key={i}>
              <div className="no">{c.invoiceNumber}</div>
              <div className="clawback">{currency(c.commission)}</div>
              <div className="total">{currency(c.taxable)}</div>
            </React.Fragment>
          ))}
        </div>
      </div>
    ),
    width: 300,
    minWidth: 290,
  },
  {
    label: SNC + 'TOTAL_CLAWBACK',
    value: 'clawbackTotal',
    align: 'center',
    component: (row) => <>{currency(row.clawbackTotal)}</>,
    width: 200,
  },
  {
    label: SNC + 'TOTAL_COMMISSION',
    value: 'totalCommission',
    align: 'center',
    component: (row) => <>{currency(row.commission - row.clawbackTotal)}</>,
    width: 200,
  },
  {
    isHidden: !isGenerated,
    label: SNC + 'STATUS',
    value: 'status',
    component: (row) => {
      return row.status === INVOICE_STATUS.OPEN ? (
        <Select
          size="small"
          className="xsmall"
          variant="outlined"
          value={row.clawBack}
          onChange={(e) => onChangeStatus(row, e.target.value)}>
          {generateSelectOptions(COMMPAY_OPTIONS, row.clawBack)}
        </Select>
      ) : (
        'No'
      );
    },
    width: 200,
  },
];
