import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import CARD_OPTIONS from '$fbusiness/enums/options/cardType';
import { insertEmptyOption } from '$gbusiness/helpers/input';
import { INVOICE_PAYMENTS } from '$fbusiness/enums/options/paymentType';
import { toNumber } from '$gbusiness/helpers/util';

export const PAYMENT_FORM = (
  paymentType,
  maxCredits,
  onChangePayment,
  stores,
  onClickCreditDropdown,
  onChangeStore,
  onPressPayment = (e) => {},
): Array<InputRowModel> => {
  const isCredits = paymentType === INVOICE_PAYMENTS.CREDITS;
  const isDiscount = paymentType === INVOICE_PAYMENTS.DISCOUNT;
  return [
    {
      items: [
        {
          ...INPUT_TYPES.AMOUNT,
          label: 'SCREEN.INVOICE_PAYMENT.MODAL.CREDIT_AMOUNT',
          value: 'creditAmount',
          isHidden: () => !isCredits,
          disableFastField: true,
          size: 'small',
          className: !!onClickCreditDropdown ? 'pointer' : '',
          readonly: !!onClickCreditDropdown,
          onClick: onClickCreditDropdown,
          validation: [
            input.amountValidation,
            {
              condition: (v) => {
                return toNumber(v) > maxCredits;
              },
              message: 'CREDIT_EXCEED',
            },
          ],
          onChange: ({ formValues, value }) => onChangePayment(formValues, value),
          onKeyPress: (code, value) => {
            onPressPayment(value);
          },
          gridSize: 3,
        },
        {
          ...INPUT_TYPES.AMOUNT,
          isHidden: () => isCredits,
          label: 'SCREEN.INVOICE_PAYMENT.MODAL.' + (isDiscount ? 'DISCOUNT_AMOUNT' : 'AMOUNT'),
          value: 'amount',
          disableFastField: true,
          enterToSubmit: true,
          size: 'small',
          validation: [input.amountValidation],
          onChange: ({ formValues, value }) => onChangePayment(formValues, value),
          onKeyPress: (code, value) => {
            onPressPayment(value);
          },
          gridSize: 3,
        },

        {
          ...INPUT_TYPES.DOB,
          size: 'small',
          label: 'SCREEN.INVOICE_PAYMENT.MODAL.DATE',
          value: 'paymentDate',
          gridSize: 3,
          enterToSubmit: true,
          validation: [input.requiredValidation],
        },
        {
          input: INPUT.AUTOCOMPLETE,
          label: 'SCREEN.STORES.COLS.STORE',
          size: 'small',
          disableFastField: true,
          gridSize: 4,
          value: 'store',
          config: {
            optionProp: {
              label: 'name',
              labelText: (o) => `${o.name || ''} ${o.code ? `(${o.code})` : ''}`,
              value: 'id',
            },
            disableClear: true,
            data: stores,
          },
          onChange: ({ formValues, newValue }) => onChangeStore(formValues, newValue),
        },
        {
          isHidden: () => paymentType === INVOICE_PAYMENTS.OTHER,
          label: 'SCREEN.INVOICE_PAYMENT.MODAL.MEMO',
          value: 'memo',
          size: 'small',
          input: INPUT.TEXT,
          gridSize: 2,
        },

        // {
        //   label: 'SCREEN.INVOICE_PAYMENT.MODAL.FROM',
        //   value: 'fromName',
        //   input: INPUT.TEXT,
        //   enterToSubmit: true,
        //   size: 'small',
        //   gridSize: 4,
        // },
      ],
    },
  ];
};

export const PAYMENT_CASH_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'SCREEN.INVOICE_PAYMENT.MODAL.REFNO',
        value: 'settings.refNo',
        enterToSubmit: true,
        size: 'small',
        input: INPUT.TEXT,
      },
    ],
  },
];

export const PAYMENT_MEMO_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'SCREEN.INVOICE_PAYMENT.MODAL.MEMO',
        value: 'memo',
        size: 'small',
        input: INPUT.TEXTAREA,
        rows: 2,
        gridSize: 8,
      },
    ],
  },
];

export const PAYMENT_CHECK_FORM = (banks, isAch = false): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'SCREEN.INVOICE_PAYMENT.MODAL.BANK',
        value: 'bankId',
        size: 'small',
        input: INPUT.SELECT,
        native: true,
        disableFastField: true,
        options: insertEmptyOption(
          banks.map((b) => ({
            labelText: b.name,
            value: b.id,
          })),
          'N/A',
        ),
        gridSize: 7,
      },
      {
        label: 'SCREEN.INVOICE_PAYMENT.MODAL.CHECKNO',
        value: 'settings.checkNo',
        input: INPUT.TEXT,
        enterToSubmit: true,
        size: 'small',
        gridSize: 5,
      },
    ],
  },
  {
    isHidden: (r) => !isAch,
    items: [
      {
        label: 'SCREEN.INVOICE_PAYMENT.MODAL.ROUTING_NUMBER',
        value: 'settings.routingNo',
        input: INPUT.TEXT,
        enterToSubmit: true,
        size: 'small',
        gridSize: 6,
      },
      {
        label: 'SCREEN.INVOICE_PAYMENT.MODAL.ACCOUNT_NUMBER',
        value: 'settings.accountNo',
        input: INPUT.TEXT,
        enterToSubmit: true,
        size: 'small',
        gridSize: 6,
      },
    ],
  },
];

export const EMPTY_FORM: Array<InputRowModel> = [
  {
    items: [],
  },
];

export const PAYMENT_CREDIT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'SCREEN.INVOICE_PAYMENT.MODAL.CARD_TYPE',
        value: 'settings.cardType',
        native: true,
        shrink: true,
        size: 'small',
        input: INPUT.SELECT,
        options: insertEmptyOption(CARD_OPTIONS, '', ''),
        gridSize: 4,
      },
      {
        label: 'SCREEN.INVOICE_PAYMENT.MODAL.CARD_NUMBER',
        value: 'settings.cardNumber',
        prefix: 'xxxxxxxxx',
        maxLength: 4,
        size: 'small',
        enterToSubmit: true,
        input: INPUT.NUMBERTEXT,
        gridSize: 4,
      },
      {
        ...INPUT_TYPES.EXPIRY,
        value: 'settings.expiry',
        size: 'small',
        enterToSubmit: true,
        gridSize: 2,
      },
    ],
  },
];
