import styled from 'styled-components';
import { Modal } from '$gcomponents/reusables';

export const ModalWrapper = styled(Modal)`
  .body {
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }
  .tab-content {
    flex: 1;
    width: 100%;
  }
  .input-mode {
    padding: 50px;
    height: 100%;
  }

  .dropzone.photo {
    background-color: #ffffdd;
  }

  .MuiTableRow-root.new,
  .MuiListItem-root.new {
    background-color: #ddffdd;
  }
  .MuiTableRow-root.update,
  .MuiListItem-root.update {
    background-color: #ffffdd;
  }

  .MuiTableRow-root.invalid {
    background-color: #ffeeee;
  }

  .MuiTableRow-root.invalid .MuiTableCell-root .error {
    color: #ff0000;
  }

  .chunks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .chunk {
      width: 150px;
      height: 170px;
      min-width: 150px;
      min-height: 170px;
      margin: 5px;
      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
      }
      .caption {
        text-align: center;
        width: 100%;
        height: 20px;
        font-size: 12px;
        opacity: 0.8;
        font-family: arial;
      }
    }
  }
`;
