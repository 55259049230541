import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { format } from '$gbusiness/helpers/date';
import ExportModel from '$gbusiness/models/export';
import { reportCurrency } from '$fcomponents/helpers/utils';

export const itemsReportExportSettings: ExportModel = {
  url: configs.api.report.items,
  method: 'POST',
  fileName: 'itemsReport',
  deriveToModel: (row) => row,
  deriveTable: (item) => ({
    ItemName: item.itemName,
    SKU: item.sku,
    Date: format(item.createdDate, DATE_FORMATS.DATE_WEEKDAY) || '',
    CustomerName: item.storeName,
    InvoiceNo: item.invoiceNumber,
    Qty: item.qty,
    Subtotal: item.subtotal,
    Discount: item.discount,
    BeforeTax: item.taxable,
    AfterTax: item.total,
  }),
  sortKey: 'createdAt',
  sortOrder: SORT.DSC,
};

export const refundDetailsExportSettings = {
  ...itemsReportExportSettings,
  fileName: 'refundItemDetails',
  url: configs.api.report.refundDetails,
  deriveTable: (item) => ({
    ItemName: item.itemName,
    SKU: item.sku,
    Date: format(item.createdDate, DATE_FORMATS.DATE_WEEKDAY) || '',
    CustomerName: item.storeName,
    Qty: item.qty,
    Subtotal: item.subtotal,
    Discount: item.discount,
    BeforeTax: item.taxable,
    AfterTax: item.total,
  }),
};

export const ITEMS_REPORT_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.report.items,
    // mockData,
    method: 'POST',
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 600,
    maxWidth: 1400,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 44,
  },
};

export const REFUND_DETAILS_TABLE_CONFIG: TableModel = {
  ...ITEMS_REPORT_TABLE_CONFIG,
  dataSource: {
    ...ITEMS_REPORT_TABLE_CONFIG.dataSource,
    endpoint: configs.api.report.refundDetails,
  },
};

export const ITEMS_REPORT_TABLE = (handleClick, isRefund = false): Array<CellModel> => [
  {
    label: 'SCREEN.REPORT.COLS.ITEM_NAME',
    value: 'itemName',
    alwaysShow: true,
    className: 'main',
    sortable: SORT.ASC,
    width: 300,
  },
  {
    label: 'SCREEN.REPORT.COLS.SKU',
    value: 'sku',
    align: 'center',
    className: 'main',
    sortable: SORT.ASC,
    width: 160,
  },
  {
    label: 'SCREEN.REPORT.COLS.BARCODE',
    value: 'barcode',
    align: 'center',
    sortable: SORT.ASC,
    width: 260,
  },
  {
    label: 'SCREEN.REPORT.COLS.DATE',
    value: 'createdDate',
    component: (row) => <>{format(row.createdDate, DATE_FORMATS.DATE_WEEKDAY) || ''}</>,
    sortable: SORT.DSC,
    sortKey: 'created_at',
    width: 230,
  },
  {
    label: 'SCREEN.REPORT.COLS.STORE_NAME',
    value: 'storeName',
    // sortable: SORT.ASC,
    // sortKey: 'invoice.store.name',
    width: 300,
  },
  {
    isHidden: isRefund,
    label: 'SCREEN.REPORT.COLS.INVOICENO',
    value: 'invoiceNumber',
    component: (row) => (
      <span className="link" onClick={() => handleClick(row.invoiceId, row.itemId)}>
        {row.invoiceNumber}
      </span>
    ),
    width: 230,
  },
  {
    label: 'SCREEN.REPORT.COLS.QTY',
    value: 'qty',
    align: 'center',
    sortable: SORT.DSC,
    width: 90,
  },
  {
    label: 'SCREEN.REPORT.COLS.SUBTOTAL',
    value: 'subtotal',
    align: 'center',
    component: (row) => reportCurrency(row.subtotal),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.DISCOUNT',
    value: 'discount',
    align: 'center',
    component: (row) => reportCurrency(row.discount),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.TAXABLE',
    value: 'taxable',
    align: 'center',
    component: (row) => reportCurrency(row.taxable),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.AFTER_TAX',
    value: 'total',
    align: 'center',
    component: (row) => reportCurrency(row.total),
    sortable: SORT.DSC,
    width: 200,
  },
  // {
  //   label: 'SCREEN.REPORT.COLS.TAX',
  //   value: 'tax',
  //   align: 'center',
  //   component: (row) => <>{currency(row.total)}</>,
  //   sortable: SORT.DSC,
  //   width: 150,
  // },
  // {
  //   label: 'SCREEN.REPORT.COLS.TOTAL',
  //   value: 'total',
  //   align: 'center',
  //   component: (row) => <>{currency(row.total)}</>,
  //   sortable: SORT.DSC,
  //   width: 150,
  // },
];
