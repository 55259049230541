import { SPACE } from '$gstyles';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .submit-button {
    min-width: 200px;
    width: 40%;
    margin-left: ${SPACE.XLARGE};
    margin-right: ${SPACE.XLARGE};
  }
  .form-group {
    margin: 0 ${SPACE.SMALL};
    .MuiCheckbox-root {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .row.space-down {
    margin-bottom: 20px;
  }
  .col {
    padding: 8px 8px !important;
  }
`;
