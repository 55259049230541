export const generateRandomSentence = (short = false) => {
  let verbs, nouns, adjectives, adverbs, preposition;
  nouns = ['bird', 'clock', 'boy', 'plastic', 'duck', 'teacher', 'old lady', 'professor', 'hamster', 'dog'];
  verbs = ['kicked', 'ran', 'flew', 'dodged', 'sliced', 'rolled', 'died', 'breathed', 'slept', 'killed'];
  adjectives = [
    'beautiful',
    'lazy',
    'professional',
    'lovely',
    'dumb',
    'rough',
    'soft',
    'hot',
    'vibrating',
    'slimy',
  ];
  adverbs = [
    'slowly',
    'elegantly',
    'precisely',
    'quickly',
    'sadly',
    'humbly',
    'proudly',
    'shockingly',
    'calmly',
    'passionately',
  ];
  preposition = ['down', 'into', 'up', 'on', 'upon', 'below', 'above', 'through', 'across', 'towards'];

  function sentence() {
    var rand1 = Math.floor(Math.random() * 10);
    var rand2 = Math.floor(Math.random() * 10);
    var rand3 = Math.floor(Math.random() * 10);
    var rand4 = Math.floor(Math.random() * 10);
    var rand5 = Math.floor(Math.random() * 10);
    var rand6 = Math.floor(Math.random() * 10);
    var content = short
      ? 'The ' + nouns[rand2] + ' ' + nouns[rand1]
      : 'The ' +
        adjectives[rand1] +
        ' ' +
        nouns[rand2] +
        ' ' +
        adverbs[rand3] +
        ' ' +
        verbs[rand4] +
        ' because some ' +
        nouns[rand1] +
        ' ' +
        adverbs[rand1] +
        ' ' +
        verbs[rand1] +
        ' ' +
        preposition[rand1] +
        ' a ' +
        adjectives[rand2] +
        ' ' +
        nouns[rand5] +
        ' which, became a ' +
        adjectives[rand3] +
        ', ' +
        adjectives[rand4] +
        ' ' +
        nouns[rand6] +
        '.';

    return content;
  }

  return sentence();
};
