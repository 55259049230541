import React from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import {
  shippingReportExportSettings,
  SHIPPING_REPORT_TABLE,
  SHIPPING_REPORT_TABLE_CONFIG,
} from './shippingReportTable';
import ReportComponent from '../reportComponent';
import PATH from '$business/enums/paths';
interface ShippingReportScreenProps {
  factory;
  history;
  dateFilter;
}

const ShippingReportScreen: React.FC<ShippingReportScreenProps> = ({ history, dateFilter }) => {
  const handleRowClick = (userId) => {
    history.push(PATH.REPORT_ITEM_DETAILS2 + '/0/0/' + userId);
  };

  const onTableLoad = (total, newTotal, response) => {
    console.log('ON TABLE LOAD REP', total, newTotal, response);
    // Display Summary info
  };

  return (
    <ReportComponent
      title="SCREEN.REPORT.TITLE_SHIPPING"
      table={SHIPPING_REPORT_TABLE(handleRowClick)}
      tableConfig={SHIPPING_REPORT_TABLE_CONFIG}
      includeReportType
      onTableLoad={onTableLoad}
      excludeQuery={false}
      dateFilter={dateFilter}
      exportSetting={shippingReportExportSettings}
    />
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory,
});

const mapDispatchToProps = {};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ShippingReportScreen));
