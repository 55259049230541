import { FONT, SPACE, WEIGHT } from '$gstyles';
import { IonFab } from '@ionic/react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .report-filter {
    max-width: 1200px;
  }

  .main-table-wrapper {
    margin-bottom: 60px;
    .MuiTableCell-root {
    }
  }

  .MuiTableRow-head {
    .MuiTableCell-head {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
  }

  .MuiTableRow-root {
    &.overdue {
      background-color: #ffe3e3;
    }
    &.success {
      background-color: #eeffee;
    }
    &.cancelled {
      background-color: #eee;
    }
    &.void {
      background-color: #eee;
      opacity: 0.7;
    }
    .MuiTableCell-root.action {
      button.ellipsis {
        min-width: 10px;
        width: 42px;
      }
      justify-content: flex-end;
    }
  }

  .receive-cell {
    justify-content: space-around;
  }

  .MuiTable-root {
    .MuiSelect-select {
      font-size: ${FONT.SMALL};
    }
  }
  .payment-button {
    max-width: 80px;
  }

  .virtualized-table {
    .MuiTableRow-root {
      border-top-color: #ccc;
    }
    .inner-table {
      .MuiTableRow-root {
        border-top-color: #eee;
      }
      .refund {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .invoices-table {
    .MuiTableCell-root {
      font-size: ${FONT.SMALL};
    }
    .inner-table-table {
      min-width: 600px;
    }
    .inner-table {
      .MuiTableCell-root {
        font-size: ${FONT.XSMALL};
      }
    }
  }

  .MuiTableRow-root.inner-table {
    padding: 0 30px;
    border-top: none;
    .MuiTableCell-body {
      margin: auto;
    }
    .MuiTableRow-head {
      background-color: transparent;
      .MuiTableCell-head {
        background-color: transparent;
      }
    }
  }
`;

export const TableSummarySection = styled.div`
  height: 40px;
  margin-top: -${SPACE.LARGE};
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  .summary {
    > div {
      margin-right: ${SPACE.LARGE};
    }
  }
  .value {
    margin-left: ${SPACE.SMALL};
    font-weight: ${WEIGHT.SEMI_BOLD};
    font-size: 1.1em;
  }
}`;

export const IonFabWrapper = styled(IonFab)`
  ion-fab-list {
    right: 0;
    button {
      margin: 2px;
      background-color: #fff;
      width: 258px;
      justify-content: flex-end;
    }
    button:hover {
      background-color: #fff;
    }
  }
`;
