import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import TAX_TYPE_OPTIONS, { TAX_TYPE } from '$fbusiness/enums/options/taxType';
import { input } from '$gbusiness/helpers';

export const TAX_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'INPUT.LABEL.TAX',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 12,
        validation: [input.requiredValidation],
      },
      {
        label: 'INPUT.LABEL.TAX_TYPE',
        value: 'taxType',
        input: INPUT.SELECT,
        options: TAX_TYPE_OPTIONS,
        gridSize: 7,
      },
      {
        isHidden: (vals) => vals.taxType !== TAX_TYPE.UNIVERSAL,
        ...INPUT_TYPES.PERCENT,
        label: 'INPUT.LABEL.TAX_RATE',
        decimalPlaces: 4,
        value: 'amount',
        gridSize: 5,
      },
      // {
      //   isHidden: vals => vals.unit === 'PERCENT',
      //   ...INPUT_TYPES.AMOUNT,
      //   label: 'INPUT.LABEL.AMOUNT',
      //   value: 'amount',
      //   gridSize: 6,
      // },
      // {
      //   label: 'INPUT.LABEL.TAX_UNIT',
      //   value: 'unit',
      //   input: INPUT.SELECT,
      //   options: TAX_UNITS,
      //   gridSize: 6,
      // },
    ],
  },
];
