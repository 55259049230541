import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import Header from '$components/header';

import { IonPageWrapper } from './styles';
import { factoryActions } from '$fbusiness/redux/factory';
import { TableView2 } from '$gcomponents/widgets';
import { DISCOUNT_TABLE, DISCOUNT_TABLE_CONFIG } from './discountTable';
import DiscountModel, { initialDiscount } from '$fbusiness/models/discount';
import DiscountEditModal from './discountEditModal';
import FabButton from '$fcomponents/fabButton';

interface DiscountsScreenProps {
  onHydrate;
  discounts: Array<DiscountModel>;
}

const DiscountsScreen: React.FC<DiscountsScreenProps> = ({ discounts }) => {
  const [discount, setDiscount] = useState<any>(null);

  const onAddDiscount = () => {
    setDiscount(initialDiscount);
  };
  const onRowClick = (row) => {
    setDiscount(row);
  };

  return (
    <IonPageWrapper>
      <Header title="SCREEN.DISCOUNTS.TITLE" />
      <IonContent>
        <TableView2 data={discounts} tableConfig={DISCOUNT_TABLE_CONFIG} TABLE={DISCOUNT_TABLE(onRowClick)} />
      </IonContent>
      <FabButton onClick={onAddDiscount} />
      <DiscountEditModal show={!!discount} discount={discount} onClose={() => setDiscount(null)} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  discounts: state.factory.factory.discounts,
});

const mapDispatchToProps = {
  onHydrate: () => factoryActions.fetchDiscounts(),
  updateDiscount: factoryActions.saveDiscount,
  deleteDiscount: factoryActions.deleteDiscount,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(DiscountsScreen));
