import React from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import { termReportExportSettings, TERM_REPORT_TABLE, TERM_REPORT_TABLE_CONFIG } from './termReportTable';
import ReportComponent from '../reportComponent';
import PATH from '$business/enums/paths';
interface TermReportScreenProps {
  factory;
  history;
  dateFilter;
}

const TermReportScreen: React.FC<TermReportScreenProps> = ({ history, dateFilter }) => {
  const handleRowClick = (userId) => {
    history.push(PATH.REPORT_ITEM_DETAILS2 + '/0/' + userId + '/0');
  };

  const onTableLoad = (total, newTotal, response) => {
    console.log('ON TABLE LOAD REP', total, newTotal, response);
    // Display Summary info
  };

  return (
    <ReportComponent
      title="SCREEN.REPORT.TITLE_TERM"
      table={TERM_REPORT_TABLE(handleRowClick)}
      tableConfig={TERM_REPORT_TABLE_CONFIG}
      includeReportType
      excludeQuery={false}
      onTableLoad={onTableLoad}
      dateFilter={dateFilter}
      exportSetting={termReportExportSettings}
    />
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory,
});

const mapDispatchToProps = {};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(TermReportScreen));
