import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$fcomponents/hoc';
import { TableView2, Header } from '$gcomponents/widgets';

import { TERM_TABLE_CONFIG, TERM_TABLE } from './termTable';
import { IonPageWrapper } from './styles';
import FactoryModel from '$fbusiness/models/factory';
import CurrentStateModel from '$fbusiness/models/currentState';
import TermEditModal from './termEditModal';
import ItemActionModel from '$gbusiness/models/itemAction';
import { factoryActions } from '$fbusiness/redux/factory';
import { dialog } from '$gcomponents/reusables';
import { configs } from '$configs';
import { initialTerm } from '$fbusiness/models/term';
import FabButton from '$fcomponents/fabButton';

interface TermsScreenProps {
  factory: FactoryModel;
  currentState: CurrentStateModel;
  deleteTerm;
}

const TermsScreen: React.FC<TermsScreenProps> = ({ factory, deleteTerm }) => {
  const [term, setTerm] = useState<any>(null);

  if (!factory) return <IonPageWrapper />;
  const { terms } = factory;

  const onCloseModal = async () => {
    setTerm(null);
  };

  const itemActions: Array<ItemActionModel> = [
    {
      label: 'BUTTON.EDIT',
      eventName: 'editItem',
      onClick: setTerm,
    },
    {
      label: 'BUTTON.DELETE',
      eventName: 'deleteItem',
      onClick: (row) => {
        dialog.confirm({
          message: 'MESSAGE.DELETE_WARNING',
          onPress: async () => {
            await deleteTerm(row.id, configs.api.term, factoryActions.fetchTermsF);
          },
        });
      },
    },
  ];

  return (
    <IonPageWrapper>
      <Header title="SCREEN.TERMS.TITLE" />
      <IonContent>
        <TableView2
          data={terms}
          tableConfig={TERM_TABLE_CONFIG}
          TABLE={TERM_TABLE()}
          itemActions={itemActions}
        />
      </IonContent>
      <TermEditModal term={term} onClose={onCloseModal} />
      <FabButton onClick={() => setTerm(initialTerm)} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: () => factoryActions.fetchTerms,
  deleteTerm: factoryActions.deleteObj,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(TermsScreen));
