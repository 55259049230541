import React from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import { dialog } from '$gcomponents/reusables';
import { Header, TableView2 } from '$gcomponents/widgets';
import ItemActionModel from '$gbusiness/models/itemAction';
import { factoryActions } from '$fbusiness/redux/factory';

import FACTORY_TABLE, { FACTORY_TABLE_CONFIG } from './table';
import { IonPageWrapper } from './styles';
import PATH from '$business/enums/paths';
import FabButton from '$fcomponents/fabButton';

interface FactoryScreenProps {
  refresh: boolean;
  deleteFactory: Function;
}

const FactoriesScreen: React.FC<FactoryScreenProps> = ({ refresh, deleteFactory }) => {
  const itemActions: Array<ItemActionModel> = [
    {
      label: 'BUTTON.DELETE',
      eventName: 'deleteItem',
      onClick: (row) => {
        dialog.confirm({
          message: 'MESSAGE.DELETE_WARNING',
          onPress: () => {
            deleteFactory(row.factoryId);
          },
        });
      },
    },
  ];

  return (
    <IonPageWrapper>
      <Header title="SCREEN.FACTORIES.TITLE" />
      <TableView2
        tableConfig={FACTORY_TABLE_CONFIG}
        TABLE={FACTORY_TABLE}
        filter={{
          forceRefresh: refresh,
        }}
        itemActions={itemActions}
      />
      <FabButton route={PATH.FACTORIES + '/0'} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  resetOnRoute: true,
  refresh: state.factory.refresh,
});

const mapDispatchToProps = {
  deleteFactory: factoryActions.deleteFactory,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(FactoriesScreen));
