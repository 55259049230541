import styled from 'styled-components';
import { Modal } from '$gcomponents/reusables';
import { SPACE } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';

export const ModalWrapper = styled(Modal)`
  form {
    padding: ${SPACE.MEDIUM} ${SPACE.LARGE};

    .modifiers-section {
      .MuiFormControl-root {
      }
    }

    ${mobileStyle(`
      .modifiers-section {
        .row {
          padding-bottom: ${SPACE.XLARGE};
          border-bottom: 1px solid var(--ion-border-color);
        }
      }
    `)}
  }
`;
