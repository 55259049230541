import { Dispatch } from 'redux';

import { LOADING } from '$gbusiness/redux/loading/types';
import { LOAD_STORAGE } from '$gbusiness/redux/localStorage/types';
import { apiService, localStorage, theme } from '$gbusiness/services';
import { ROUTES } from '$business/enums';
// import mockData from '$gbusiness/mocks/auth/init.json';

import { AppModel } from '../';
import intl from 'react-intl-universal';
import { INIT_SUCCESS } from './types';
import { configs } from '$configs';
import { SET_CUSER } from '$gbusiness/redux/currentUser/types';
import UserModel, { defaultUser, deriveRawToUser, LoginModel } from '$gbusiness/models/user';
import { deriveRawToFactory, initialFactory } from '$fbusiness/models/factory';
import {
  FETCH_FACTORIES_SUCCESS,
  FETCH_MY_FACTORY_SUCCESS,
  FETCH_ROLES_SUCCESS,
} from '$fbusiness/redux/factory/types';
import { FETCH_DEPARTMENTS_SUCCESS } from '$fbusiness/redux/department/types';
import { deriveRawToDepartment } from '$fbusiness/models/department';
import { FETCH_CATEGORIES_SUCCESS } from '$fbusiness/redux/category/types';
import { FETCH_ITEMS_SUCCESS, FETCH_MG_SUCCESS } from '$fbusiness/redux/item/types';
import { deriveRawToCategory } from '$fbusiness/models/category';
import { deriveRawToItem } from '$fbusiness/models/item';
import { deriveRawToModifierGroup } from '$fbusiness/models/modifierGroup';
import { setStorageObj } from '$gbusiness/services/local.storage';
import { AuthActionTypes, LOGGING_IN, LOGIN_SUCCESS } from '$gbusiness/redux/auth/types';
import { LOAD_CARDS_SUCCESS } from '$gbusiness/redux/card/types';
import { handleFail, storeAuth } from '$gbusiness/redux/auth/actions';
import { FETCH_STORES_SUCCESS } from '$fbusiness/redux/store/types';
import { deriveRawToStore } from '$fbusiness/models/store';

function restoreTheme(localTheme) {
  theme.switchTheme(localTheme);
}

async function loadStorage(dispatch) {
  const storageData = await localStorage.getStorageData();
  const columns = storageData.columns ? JSON.parse(storageData.columns) : [];
  const pageSize = storageData.pageSize ? JSON.parse(storageData.pageSize) : [];
  const dateFilter = storageData.dateFilter ? JSON.parse(storageData.dateFilter) : undefined;

  dispatch({
    type: LOAD_STORAGE,
    localStorage: {
      ...storageData,
      columns,
      pageSize,
      dateFilter,
    },
  });
}

async function authToken(dispatch) {
  const response = await apiService.fetchApi({
    url: configs.api.token,
    param: {},
    method: 'POST',
  });

  if (!response || !response?.user?.id) {
    localStorage.clearAuth();
    return;
  }

  const user: UserModel = deriveRawToUser(response.user) || defaultUser;

  return Promise.resolve(user);
}

const loadFactoryDetails = async (
  dispatch,
  { factories, factory, categories, roles, departments, items, stores },
) => {
  const myFactory = deriveRawToFactory(factory) || initialFactory;

  if (myFactory && myFactory.settings.storeToCustomer) {
    intl.load({ 'en-US': require('$intl/en_cnl.json') });
  }

  if (factories) {
    dispatch({
      type: FETCH_FACTORIES_SUCCESS,
      factories: factories.map(deriveRawToFactory),
    });
  }
  dispatch({
    type: FETCH_MY_FACTORY_SUCCESS,
    factory: myFactory,
  });
  dispatch({
    type: FETCH_ROLES_SUCCESS,
    roles,
  });
  dispatch({
    type: FETCH_DEPARTMENTS_SUCCESS,
    departments: (departments || []).map(deriveRawToDepartment),
  });
  dispatch({
    type: FETCH_CATEGORIES_SUCCESS,
    categories: (categories || []).map(deriveRawToCategory),
  });
  dispatch({
    type: FETCH_ITEMS_SUCCESS,
    items: (items || []).map(deriveRawToItem),
  });

  const derivedStores = (stores || []).map(deriveRawToStore);
  dispatch({
    type: FETCH_STORES_SUCCESS,
    stores: derivedStores,
  });

  /* get features */
  for (const key in myFactory.settings) {
    const feature = myFactory.settings[key];
    if (typeof feature === 'boolean' && feature) {
      document.body.classList.add('body_' + key || '');
    }
  }
  if (myFactory.settings) {
    await setStorageObj('features', myFactory.settings);
  }

  const response2 = await apiService.fetchApi({
    url: configs.api.modifierGroup,
    param: {},
    method: 'GET',
  });

  if (!response2 || !response2.list) {
    return;
  }

  dispatch({
    type: FETCH_MG_SUCCESS,
    modifierGroups: response2.list.map((m) => deriveRawToModifierGroup(m)),
  });
};

async function fetchFactory(dispatch, myItems, forceFetch = false) {
  if (myItems.length && !forceFetch) return;

  const response = await apiService.fetchApi({
    url: configs.api.myfactory,
    param: {},
    method: 'GET',
  });

  if (!response || !response?.data?.factory) {
    return;
  }

  loadFactoryDetails(dispatch, response.data);
}

const shouldForcePublic = () => {
  const path = window.location.pathname;

  const thisRoute = ROUTES.PUBLIC_LIST.find((route) => path.includes(route.path));
  return thisRoute ? true : false;
};

export function fetchMyFactory(forceFetch = false): any {
  return async (dispatch: Dispatch, getState: () => AppModel) => {
    const { item } = getState();
    await fetchFactory(dispatch, item.items, forceFetch);
  };
}

export function loadApp(): any {
  return async (dispatch: Dispatch, getState: () => AppModel) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.INITIALIZING',
    });

    if (!shouldForcePublic()) {
      // Load LOCAL STORAGE
      await loadStorage(dispatch);
    }

    // Load Theme
    const { localStorage, item } = getState();

    restoreTheme(localStorage.theme);

    if (!shouldForcePublic()) {
      authToken(dispatch).then(async (user) => {
        await fetchFactory(dispatch, item.items);
        if (user) {
          dispatch({
            type: SET_CUSER,
            user,
          });
        }
        dispatch({
          type: INIT_SUCCESS,
        });
      });

      return;
    }

    dispatch({
      type: INIT_SUCCESS,
    });
  };
}

export function login(param: LoginModel): any {
  return async (dispatch: Dispatch<AuthActionTypes>, getState) => {
    dispatch({
      type: LOGGING_IN,
      loadingText: 'PROGRESS.LOGGING_IN',
    });

    const response = await apiService.fetchApi({
      url: configs.api.login,
      param,
      isPublic: true,
      // mockData: loginMock,
    });

    if (!response || !response.user || !response.accessToken) {
      handleFail(dispatch, response?.message, 'MESSAGE.LOGIN_FAIL', 'large');
      return;
    }

    const user: UserModel = deriveRawToUser(response.user) || defaultUser;
    await storeAuth(response, user);

    loadFactoryDetails(dispatch, response.data);

    dispatch({
      type: LOGIN_SUCCESS,
      accessToken: response.accessToken,
      user,
    });

    dispatch({
      type: LOAD_CARDS_SUCCESS,
      cards: response.cards,
    });
  };
}
