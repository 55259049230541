import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonFooter } from '@ionic/react';

import intl from '$intl';
import { screen } from '$fcomponents/hoc';
import { Div, SPACE } from '$gstyles';
import { Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import { input } from '$gbusiness/helpers';
import { Flex } from '$gstyles/wrapper';
import Header from '$components/header';
import { departmentActions } from '$fbusiness/redux/department';
import DepartmentModel from '$fbusiness/models/department';

import { DEPARTMENT_FORM, DEPARTMENT_PHOTO_FORM } from './departmentForm';
import { IonPageWrapper } from './styles';
import { JPEG_TAG } from '$gbusiness/enums/general';
import { AppForm } from '$styles/general';
import PATH from '$business/enums/paths';

interface DepartmentDetailsScreenProps {
  history: any;
  department: DepartmentModel | null;
  fetchDepartments: Function;
  saveDepartment: Function;
  isFinished: boolean;
}

const DepartmentDetailsScreen: React.FC<DepartmentDetailsScreenProps> = ({
  history,
  department,
  fetchDepartments,
  saveDepartment,
  isFinished,
}) => {
  useEffect(() => {
    if (!isFinished) return;
    fetchDepartments();
    closeScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  if (!department) return <IonPageWrapper />;
  const { id, createdAt, updatedAt, ...inititalValues } = department;
  const titleKey = id ? 'EDIT' : 'CREATE_NEW';

  const onSubmit = (values) => {
    const { image, email, address_place, ...newValues } = values;
    saveDepartment(id, {
      ...newValues,
      email: email || '',
      ...(image?.length > 100 && { image: image.replace(JPEG_TAG, '') }),
      ...(address_place && { placeId: address_place.placeId }),
    });
  };
  const closeScreen = () => {
    history.push(PATH.DEPARTMENTS);
  };

  const validateForm = (values) => {
    return input.validateError([...DEPARTMENT_FORM, ...DEPARTMENT_PHOTO_FORM], values);
  };

  return (
    <IonPageWrapper>
      <Header titleText={intl('ITEM.' + titleKey, { item: intl('COMMON.DEPARTMENT') })} />

      <Formik
        enableReinitialize={true}
        initialValues={inititalValues}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <AppForm>
                <Div maxWidth="500px" className="left">
                  <FormSection
                    className="form-section"
                    title="SCREEN.ITEM.DEPARTMENT_INFO"
                    marginBottom="0"
                    FORM={DEPARTMENT_FORM}
                    formik={formik}
                  />
                  <FormSection
                    className="form-section"
                    marginBottom="0"
                    title="SCREEN.ITEM.DEPARTMENT_PHOTO"
                    FORM={DEPARTMENT_PHOTO_FORM}
                    formik={formik}
                  />
                </Div>
              </AppForm>
            </IonContent>
            <IonFooter>
              <Flex padding={SPACE.MEDIUM} justifyContent="space-between">
                <Button variant="outlined" onClick={closeScreen}>
                  {intl('BUTTON.CANCEL')}
                </Button>
                <Button
                  disabled={!formik.isValid || !formik.dirty}
                  color="primary"
                  className="submit-button"
                  variant="contained"
                  onClick={formik.handleSubmit}>
                  {intl('BUTTON.SUBMIT')}
                </Button>
              </Flex>
            </IonFooter>
          </>
        )}
      </Formik>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  department: state.department.department,
  isFinished: state.department.isFinished,
});

const mapDispatchToProps = {
  onHydrate: (params) => departmentActions.fetchDepartment(parseInt(params.departmentId)),
  onDehydrate: departmentActions.dehydrate,
  fetchDepartments: departmentActions.fetchDepartments,
  saveDepartment: departmentActions.saveDepartment,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(DepartmentDetailsScreen));
