import React from 'react';
import intl from '$intl';
import OrderModel from '$fbusiness/models/order';

import { ModalWrapper } from './styles';
import InvoiceModel from '$fbusiness/models/invoice';
import OrderShippingDetails from './orderShippingDetails';
import CurrentStateModel from '$fbusiness/models/currentState';
import TermModel from '$fbusiness/models/term';

interface OrderModalProps {
  show: boolean;
  order?: OrderModel;
  invoice?: InvoiceModel;
  onSubmitOrder: Function;
  terms: Array<TermModel>;
  currentState: CurrentStateModel;
  onClose: () => void;
}

const OrderModal: React.FC<OrderModalProps> = ({
  show,
  order,
  currentState,
  invoice,
  terms,
  onClose,
  onSubmitOrder,
}) => {
  const titleKey = invoice ? 'TITLE_REGENERATE' : 'TITLE';

  return (
    <ModalWrapper
      titleText={intl('SCREEN.SHIPPING_MODAL.' + titleKey, { orderId: order?.orderId })}
      show={show}
      width="740px"
      onClose={() => onClose()}
      useCustom>
      {(order || invoice) && (
        <OrderShippingDetails
          order={order}
          invoice={invoice}
          terms={terms}
          onClose={onClose}
          currentState={currentState}
          onSubmitOrder={onSubmitOrder}
        />
      )}
    </ModalWrapper>
  );
};

export default OrderModal;
