import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToDepartment, initialValue } from '../../models/department';
import {
  DepartmentActionTypes,
  FETCH_DEPARTMENT_SUCCESS,
  CLEAN_DEPARTMENT,
  UPDATE_DEPARTMENT_SUCCESS,
  DEPARTMENT_FAILURE,
  DELETE_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENTS_SUCCESS,
  REORDER_DEPARTMENT,
} from './types';

export function fetchDepartments(): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.department.general,
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, DEPARTMENT_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_DEPARTMENTS_SUCCESS,
      departments: response.list.map((d) => deriveRawToDepartment(d)),
    });
  };
}

export function fetchDepartment(departmentId): any {
  return async (dispatch: Dispatch) => {
    if (!departmentId || isNaN(departmentId)) {
      dispatch({
        type: FETCH_DEPARTMENT_SUCCESS,
        department: initialValue,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.department.general + '/' + departmentId,
      method: 'GET',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, DEPARTMENT_FAILURE, response, 'ERROR.SAVE', true);
      return;
    }

    dispatch({
      type: FETCH_DEPARTMENT_SUCCESS,
      department: deriveRawToDepartment(response.data),
    });
  };
}

export function saveDepartment(departmentId, department, isRanking): any {
  return async (dispatch: Dispatch) => {
    if (!isRanking) dispatchLoading(dispatch, 'PROGRESS.SAVING');
    else {
      dispatch({
        type: REORDER_DEPARTMENT,
        deptId: departmentId,
        rank: department.rank,
      });
    }

    const response = await fetchApi({
      url: configs.api.department.general + (departmentId ? '/' + departmentId : ''),
      param: {
        ...(departmentId && { ...departmentId }),
        ...department,
      },
      method: departmentId ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, DEPARTMENT_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      if (!isRanking) handleApiSuccess(dispatch, UPDATE_DEPARTMENT_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function deleteDepartment(departmentId): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.department.general + (departmentId ? '/' + departmentId : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, DEPARTMENT_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_DEPARTMENT_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

export function reorder(sourceId, destIndex) {
  return {
    type: REORDER_DEPARTMENT,
    sourceId,
    destIndex,
  };
}

export function dehydrate(): DepartmentActionTypes {
  return { type: CLEAN_DEPARTMENT };
}
