import styled from 'styled-components';

export const HistorySectionWrapper = styled.div`
  .title {
    font-size: 1em;
  }
  .tableview2 {
    .MuiTableCell-root {
      font-size: 0.97em;
    }
    .history-content {
      font-size: 0.9em;
      line-height: 12px;
    }
  }
`;
