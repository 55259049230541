import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { usePapaParse } from 'react-papaparse';
import { ArrowBack } from '@mui/icons-material';

import intl from '$intl';
import { dialog, Table, TabSimple } from '$gcomponents/reusables';
import { IonContent } from '@ionic/react';
import Footer from '$gcomponents/widgets/footer';
import { Button } from '$gcomponents/primitives';

import { ModalWrapper } from './styles';
import PREVIEW_TABLE, { previewStyles, validateRow } from './previewTable';
import { itemActions } from '$fbusiness/redux/item';
import { initActions } from '$business/redux/init';
import OptionModel from '$gbusiness/models/option';
import { Flex } from '$gstyles/wrapper';
import FactoryModel from '$fbusiness/models/factory';

interface ImportItemModalProps {
  factory: FactoryModel;
  refreshTable?: Function;
  show: boolean;
  onClose: Function;
}

const TABS: Array<OptionModel> = [
  { label: 'BUTTON.IMPORT_ITEMS', value: 0 },
  { label: 'BUTTON.IMPORT_PHOTOS', value: 1 },
];

const ImportItemModal: React.FC<ImportItemModalProps> = ({ factory, show, onClose, refreshTable }) => {
  const [newItems, setNewItems] = useState<Array<any>>([]);
  const [photos, setPhotos] = useState<Array<any>>([]);
  const [photoMode, setPhotoMode] = useState(0);
  const inputMode = photoMode ? !photos || photos.length === 0 : !newItems || newItems.length === 0;
  const dispatch = useDispatch();
  const { readString } = usePapaParse();

  const maxSize = 10000000;
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize,
    accept: {
      'text/csv': [],
    },
    onDrop: (acceptedFiles: any) => {
      if (!acceptedFiles.length) {
        dialog.alert({
          text: intl('ERROR.CSV_FILE'),
        });
        return;
      }
      const reader = new FileReader();
      reader.readAsText(acceptedFiles[0]);
      reader.onload = () => {
        const csvContent: any = reader.result;
        readString(csvContent.toString(), {
          worker: true,
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            const groomed = result.data.map((line) => {
              const row: any = line;
              const acc = {};
              const terms = ['cost', 'price'];
              if (!row) return {};
              for (const key in row) {
                const trimmed = row[key].trim();
                const lowerKey = key.toLowerCase();
                if (terms.some((term) => lowerKey.includes(term))) {
                  acc[key] = trimmed.replace('$', '');
                } else {
                  acc[key] = trimmed;
                }
              }
              return acc;
            });
            if (result && result.data && result.data.length) {
              setNewItems(groomed);
            } else {
              dialog.alert({
                text: intl('ERROR.CSV_FILE_CORRUPT'),
              });
            }
          },
        });
      };
    },
  });

  const { getRootProps: photoProps, getInputProps: photoInputProps } = useDropzone({
    maxFiles: 1000,
    maxSize,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    onDrop: async (acceptedFiles: any) => {
      if (!acceptedFiles.length) {
        dialog.alert({
          text: intl('ERROR.IMAGE_FILE'),
        });
        return;
      }
      setPhotos(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            file: file,
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const onSubmitImport = async () => {
    const filtered = newItems.filter(validateRow);
    const param = filtered.map((row) => ({
      id: row.id || 0,
      sku: row.sku,
      barcode: row.barcode,
      tax: row.tax,
      name: row.itemName,
      quantity: row.quantity,
      retailPrice: row.retailPrice.replace('$', ''),
      wholesalePrice: row.wholesalePrice.replace('$', ''),
      rawCost: row.rawCost.replace('$', ''),
      ...(row.dept && { dept: row.dept }),
      ...(row.ebrochure && { ebrochure: row.ebrochure }),
      ...(row.promo && { promo: row.promo }),
      ...(row.packing && { packing: row.packing }),
      ...(row.skid && { skid: row.skid }),
      ...(row.lotNo && { lotNo: row.lotNo }),
      ...(row.binNo && { binNo: row.binNo }),
      category: row.category,
      ...(row.subcategory && { subcategory: row.subcategory }),
    }));
    await dispatch(itemActions.importItems(param));
    if (refreshTable) refreshTable();
    else dispatch(initActions.fetchMyFactory(true));
    onClose();
  };

  const onSubmitPhotos = async () => {
    const param = photos.map((row) => row.file);
    await dispatch(itemActions.importPhotos(param));
    if (refreshTable) refreshTable();
    else dispatch(initActions.fetchMyFactory(true));
    onClose();
  };

  const onCloseModal = () => {
    setNewItems([]);
    onClose();
  };

  const onReset = () => {
    setNewItems([]);
    setPhotos([]);
  };

  return (
    <ModalWrapper
      width="1000px"
      title={photoMode ? 'MESSAGE.IMPORT_PHOTO' : 'MESSAGE.IMPORT_ITEM'}
      show={show}
      onClose={onCloseModal}
      useCustom>
      <IonContent>
        <Flex className="body">
          <TabSimple index={photoMode} TABS={TABS} onChangeIndex={setPhotoMode} />
          <div className="tab-content">
            {inputMode ? (
              photoMode ? (
                <div className="input-mode">
                  <div {...photoProps({ className: 'dropzone photo' })}>
                    <input {...photoInputProps()} />
                    <p>{intl('MESSAGE.IMPORT_ITEM_PHOTO')}</p>
                  </div>
                </div>
              ) : (
                <div className="input-mode">
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>{intl('MESSAGE.IMPORT_ITEM_LABEL')}</p>
                  </div>
                </div>
              )
            ) : photoMode ? (
              <div className="data-mode">
                <div className="chunks">
                  {photos.map((photo, i) => (
                    <div className="chunk" key={i}>
                      <img src={photo.preview} alt={photo.file.name} />
                      <div className="caption">{photo.file.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="data-mode">
                <Table styles={previewStyles} TABLE={PREVIEW_TABLE(factory)} data={newItems} />
              </div>
            )}
          </div>
        </Flex>
      </IonContent>
      <Footer justifyContent="space-around">
        {!inputMode ? (
          <>
            <Button startIcon={<ArrowBack />} variant="outlined" color="secondary" onClick={onReset}>
              {intl('BUTTON.IMPORT_RESET')}
            </Button>
            <Button className="third" onClick={photoMode ? onSubmitPhotos : onSubmitImport}>
              {intl('BUTTON.SUBMIT')}
            </Button>
          </>
        ) : (
          <Button className="third" onClick={onCloseModal} variant="outlined">
            {intl('BUTTON.CANCEL')}
          </Button>
        )}
      </Footer>
    </ModalWrapper>
  );
};

export default ImportItemModal;
