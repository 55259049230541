import React from 'react';
import intl from '$intl';

import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { InputAdornment } from '@mui/material';

interface CurrencyInputProps {
  label: string;
  value: string;
  id?: number;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  maxAmount?: number;
  onKeyUp?: Function;
  helperText?: string;
  size?: string;
  onChange?: Function;
  variant?: string;
  isPercent?: boolean;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  label,
  value,
  id,
  readonly = false,
  disabled = false,
  className = '',
  helperText = '',
  placeholder = '',
  onKeyUp = (e) => {},
  maxAmount,
  size = 'small',
  onChange = (e) => {},
  variant = 'outlined',
  isPercent = false,
}) => {
  const pattern = '[0-9]*';
  const postfix = isPercent ? 'CURRENCY.PERCENT' : 'CURRENCY.SYMBOL';
  const endAdornment = isPercent ? (
    <InputAdornment position="end">{intl(postfix)}</InputAdornment>
  ) : undefined;
  const maxInput = maxAmount ? maxAmount : isPercent ? 100 : 9999999;
  const inputSize = size === 'small' || size === 'xsmall' ? 'small' : 'medium';

  const onHandleChange = (e) => {
    onChange(e.target.value, value, value);
  };

  const otherProps = {
    ...(id && { 'data-id': id }),
  };

  return (
    <CurrencyTextField
      label={label}
      className={`currency ${className} ${readonly && 'readonly'} ${!postfix && 'left'} ${
        size === 'xsmall' && 'xsmall'
      }`}
      inputProps={{
        pattern: pattern,
      }}
      InputProps={{
        endAdornment,
      }}
      {...otherProps}
      type="text"
      data-id={id}
      maximumValue={maxInput?.toString()}
      decimalPlaces={isPercent ? 1 : 2}
      modifyValueOnWheel={false}
      disabled={disabled}
      placeholder={intl(placeholder) || undefined}
      onKeyUp={onKeyUp}
      size={inputSize}
      // onChange={onHandleChange}
      onBlur={onHandleChange}
      checked={!!value}
      variant={variant}
      value={value}
      helperText={helperText}
      inputMode="text"
      currencySymbol={intl(postfix === 'CURRENCY.PERCENT' ? '' : 'CURRENCY.SYMBOL')}
    />
  );
};

export default CurrencyInput;
