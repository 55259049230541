import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { formatDatetime, timestamp } from '$gbusiness/helpers/date';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { deriveRawToCategory } from '$fbusiness/models/category';
import Img from '$gcomponents/primitives/img';
import PATH from '$business/enums/paths';

export const CATEGORY_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.category.general,
    deriveToModel: deriveRawToCategory,
    method: 'GET',
    defaultSortKey: 'updatedAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    rowHeight: 64,
    minWidth: 500,
    maxWidth: 800,
    cellPadding: ` 8px 4px`,
    borderColor: 'var(--ion-border-color)',
    responsive: true,
    isStripped: false,
    disableSort: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const CATEGORY_TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.CATEGORIES.COLS.PHOTO',
    value: 'factoryId',
    align: 'center',
    className: 'image',
    component: (row) => (
      <Img
        src={row.image}
        cache={timestamp(row.updatedAt)}
        height="64px"
        width="64px"
        placeholder="No Image"
      />
    ),
    alwaysShow: true,
    width: 120,
  },
  {
    label: 'SCREEN.CATEGORIES.COLS.NAME',
    value: 'name',
    className: 'main',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 300,
  },
  {
    label: 'SCREEN.CATEGORIES.COLS.DEPARTMENT',
    value: 'deptName',
    component: (row) => row.depts && row.depts.map((d) => <div key={d.id}>{d.name}</div>),
    alwaysShow: true,
    className: 'vertical',
    align: 'left',
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.CATEGORIES.COLS.UPDATED',
    value: 'updatedAt',
    className: 'desktop',
    component: (row) => <>{row.updatedAt ? formatDatetime(row.updatedAt, DATE_FORMATS.DATETIME) : ''}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.CATEGORIES.COLS.ACTION',
    value: '',
    align: 'center',
    className: 'action',
    component: (row, actions) => {
      return (
        <Button
          className="left"
          fullWidth
          onClick={(e) => actions.handlePopoverOpen(e, { ...row, route: PATH.CATEGORIES })}>
          <MoreHorizIcon />
        </Button>
      );
    },
    width: 100,
  },
];
