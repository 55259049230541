export const KEYS = {
  FULL_PAYMENT: 'FULL_PAYMENT',
  PARTIAL_PAYMENT: 'PARTIAL_PAYMENT',
  NO_PAYMENT: 'NO_PAYMENT',
};

export const MOCK_PAYMENT = KEYS;

const LIST = Object.keys(KEYS);

export const MOCK_PAYMENT_OPTIONS = LIST.map((item) => ({
  label: `MOCK_PAYMENT.${item}`,
  value: item,
}));

export type MOCK_PAYMENT_TYPE = typeof LIST[number] | undefined;
