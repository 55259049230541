import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$gcomponents/hoc';
import { Header } from '$gcomponents/widgets';
import { userActions } from '$fbusiness/redux/user';

import { Formik } from 'formik';

import { input } from '$gbusiness/helpers';

import { initialRefundForm, invoiceToRefundForm, refundToForm, REFUND_FORM } from './refundForm';

import { invoiceActions } from '$fbusiness/redux/invoice';
import { itemText, toNumber } from '$gbusiness/helpers/util';
import EditRefundDetails from './editRefundDetails';
import { orderActions } from '$fbusiness/redux/order';
import { dateToString, deriveDatetime } from '$gbusiness/helpers/date';
import { IonPageWrapper } from '../../editInvoiceScreen/styles';
import { deriveRawToUser } from '$gbusiness/models/user';
import RefundModel from '$fbusiness/models/refund';
import { parseISO } from 'date-fns';

interface EditRefundScreenProps {
  onHydrate;
  onDehydrate;
  requestRefund;
  applyRefund;
  refund: RefundModel;
  isFinished;
  invoice;
  factory;
  history;
  stores;
  match;
  users;
}

const EditRefundScreen: React.FC<EditRefundScreenProps> = ({
  factory,
  stores: reduxStores,
  match,
  invoice,
  history,
  refund,
  users,
  requestRefund,
  applyRefund,
}) => {
  const stores = [...reduxStores];
  const { params, url } = match;
  const { storeId, shouldConvert } = params;
  const isCredit = refund ? refund?.isCredit : url.includes('credit');
  const isEdit = url.includes('edit-');
  // const subject = isCredit ? 'Credit' : 'Refund';
  const subjectKey = isCredit ? 'CREDIT' : 'REFUND';

  const initialValue = {
    ...initialRefundForm,
    refundDate: new Date(),
  };

  const [initVal, setInitVal] = useState<any>({});
  const [reps, setReps] = useState(users);

  useEffect(() => {
    if (!stores.length || !storeId) return;
    const store = stores.find((s) => s.id === parseInt(storeId));
    const newReps = (store?.salesmen.length ? store.salesmen : users).map(deriveRawToUser);
    setReps(newReps.length ? newReps : users);

    setTimeout(() => {
      setInitVal({
        store,
      });
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores.length]);

  useEffect(() => {
    if (!invoice?.id && !refund?.id) return;

    const updateVal = async () => {
      // if (!stores?.length) await sleep(200);
      const isRefund = refund?.id;
      const obj = isRefund ? refund : invoice;
      const { storeId, discountRate = 0, otherDiscount = 0, userId } = obj;
      const store = stores.find((s) => s.id === storeId);
      if (!store) return;
      let discount = store.discounts.find((d) => d.amount === discountRate && !d.isFlat);
      if (!discount && otherDiscount)
        discount = store.discounts.find((d) => d.amount === otherDiscount && d.isFlat);

      const newReps = (store?.salesmen.length ? store.salesmen : users).map(deriveRawToUser);
      const user = newReps.find((s) => s.userId === userId);
      setReps(newReps.length ? newReps : users);

      setInitVal({
        ...(isRefund ? refundToForm(refund) : invoiceToRefundForm(invoice)),
        refundDate: isEdit && obj?.requestDate ? parseISO(obj?.requestDate) : new Date(),
        store,
        user,
        diff: isEdit ? obj.total - (obj.balance || 0) : 0,
        noTax: obj?.tax ? '0' : '1',
        discountId: discount?.id || '0',
      });
    };

    updateVal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.id, refund?.id + stores?.length]);

  if (!factory) return null;

  const onStoreChange = ({ value }) => {
    if (!value) {
      setInitVal({ ...initVal, store: null });
      return;
    }
    const newStoreId = toNumber(value.id);
    const newStore = stores.find((s) => s.id === newStoreId);
    setInitVal({
      ...initVal,
      store: newStore || null,
    });
    setReps(value?.salesmen?.length ? value?.salesmen : users);
  };

  const invoiceForm = REFUND_FORM(
    stores,
    initVal?.store?.discounts || [],
    reps,
    onStoreChange,
    shouldConvert,
    isCredit,
    factory.settings,
  );
  const onSubmit = (values) => {
    const { store, user, refundDate, ...rest } = values;
    const param = {
      ...rest,
      ...(shouldConvert && { shouldConvert: invoice.id }),
      storeId: store.id,
      ...(values?.user && { userId: values?.user?.userId || values?.user?.id || 0 }),
      qty: rest.items.length || 0,
      refundDate: dateToString(refundDate),
      refundedAt: deriveDatetime(refundDate),
      isCredit,
      ...{ created_at: deriveDatetime(refundDate) },
    };

    // console.log('PARAM', param);
    // return;

    requestRefund(param?.id || 0, param).then((refund) => {
      // if (!isCredit && factory.settings.autoRefund && refund) {
      //   applyRefund(refund.id);
      //   history.goBack();
      //   return;
      // }
      history.goBack();
    });
  };

  const validateForm = (values) => {
    return input.validateError(invoiceForm, values);
  };

  // const title = shouldConvert
  //   ? intl('SCREEN.REFUNDS.TITLE_CONVERT', {
  //       subject,
  //       invoiceNumber: invoice ? `(#${invoice?.invoiceNumber})` : '',
  //     })
  //   : itemText(id ? 'EDIT' : 'ADD', subjectKey);

  const title = itemText(isEdit ? 'EDIT' : 'ADD', subjectKey);

  // console.log('INIT VAL', initVal);

  return (
    <IonPageWrapper>
      <Header titleText={title} />
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <EditRefundDetails
            initVal={initVal}
            onSubmit={onSubmit}
            factory={factory}
            isCredit={isCredit}
            isEdit={isEdit}
            invoice={{
              ...invoice,
              ...(!shouldConvert && !isEdit && { items: [] }),
              ...(refund && refund),
            }}
            shouldConvert={shouldConvert === '1'}
            FORM={invoiceForm}
            formik={formik}
          />
        )}
      </Formik>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  invoice: state.invoice.invoice,
  users: state.user.users,
  stores: state.store.stores,
  refund: state.order.refund,
  resetOnRoute: true,
});

const mapDispatchToProps = (dispatch) => ({
  onHydrate: async ({ invoiceId, refundId }) => {
    await dispatch(userActions.fetchUsers({ storeUser: true, storeId: 1 }));
    if (refundId) await dispatch(orderActions.fetchRefund(toNumber(refundId)));
    else if (invoiceId) await dispatch(invoiceActions.fetchInvoice(parseInt(invoiceId || 0)));
  },
  onDehydrate: () => {
    dispatch(invoiceActions.dehydrate());
    dispatch(orderActions.dehydrate());
  },
  requestRefund: (n, o) => dispatch(orderActions.requestRefund(n, o, true)),
  applyRefund: (n) => dispatch(invoiceActions.applyRefund(n)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(EditRefundScreen));
