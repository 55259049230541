import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { reportCurrency } from '$fcomponents/helpers/utils';

export const BALANCE_REPORT_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.report.balance,
    method: 'POST',
    defaultSortKey: 'balance',
    defaultSortOrder: SORT.DSC,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 300,
    maxWidth: 800,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 44,
  },
};

export const BALANCE_REPORT_TABLE = (handleClick): Array<CellModel> => [
  {
    label: 'SCREEN.REPORT.COLS.STORE_NAME',
    value: 'name',
    alwaysShow: true,
    className: 'main',
    component: (row) => (
      <div className="link" onClick={() => handleClick(row.id)}>
        {row.storeName}
      </div>
    ),
    sortable: SORT.ASC,
    width: 400,
  },
  {
    label: 'SCREEN.REPORT.COLS.BALANCE',
    value: 'balance',
    align: 'center',
    component: (row) => reportCurrency(row.balance),
    sortable: SORT.ASC,
    width: 200,
  },
];
