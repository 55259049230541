import React from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { PageWrapper } from '$gstyles/wrapper';
import { InfoItem } from '$gcomponents/reusables';
import { ModalWrapper } from './styles';
import { TableView2 } from '$gcomponents/widgets';
import { List } from '@mui/material';
import { currency } from '$gbusiness/helpers/util';
import StoreModel from '$fbusiness/models/store';
import { CREDIT_TABLE, CREDIT_TABLE_CONFIG } from '../creditList/creditTable';

interface CreditHistoryModalProps {
  show: boolean;
  onClose: Function;
  width: string;
  store: StoreModel;
}

const CreditHistoryModal: React.FC<CreditHistoryModalProps> = ({ show, onClose, width = '760px', store }) => {
  return (
    <ModalWrapper
      title={intl('SCREEN.CREDIT.TITLE')}
      show={show}
      onClose={() => onClose()}
      width={width}
      useCustom>
      {store && (
        <IonContent>
          <PageWrapper>
            <List className="credit-list">
              <InfoItem label="SCREEN.INVOICE_PAYMENT.STORE_CREDIT" value={currency(store?.credits)} />
              {/* <InfoItem
                            label="SCREEN.INVOICE_PAYMENT.MAX_AMOUNT"
                            value={currency(autoFillAmount)}
                          /> */}
            </List>

            <TableView2
              filter={{ storeId: store?.id || 0 }}
              TABLE={CREDIT_TABLE}
              tableConfig={CREDIT_TABLE_CONFIG(store.id)}
            />
          </PageWrapper>
        </IonContent>
      )}
    </ModalWrapper>
  );
};

export default CreditHistoryModal;
