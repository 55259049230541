import React from 'react';
import intl from '$intl';

import { ModalWrapper } from './styles';
import CategoryModel from '$fbusiness/models/category';
import CategoryDetails from './main';
import FactoryModel from '$fbusiness/models/factory';

interface CategoryModalProps {
  category: CategoryModel;
  categories: Array<CategoryModel>;
  factory: FactoryModel;
  show: boolean;
  onClose: Function;
}

const CategoryModal: React.FC<CategoryModalProps> = ({ category, factory, categories, show, onClose }) => {
  const titleText = intl('SCREEN.CATEGORIES.TITLE_' + (category?.id ? 'EDIT' : 'NEW'));

  return (
    <ModalWrapper titleText={titleText} show={show} onClose={() => onClose()} useCustom>
      {category && (
        <CategoryDetails factory={factory} category={category} categories={categories} onClose={onClose} />
      )}
    </ModalWrapper>
  );
};

export default CategoryModal;
