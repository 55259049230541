import styled from 'styled-components';
import { Modal } from '$gcomponents/reusables';
import { FONT, SPACE, WEIGHT } from '$gstyles';

export const ModalWrapper = styled(Modal)`
  --backdrop-opacity: 0.2 !important;
  --height: calc(600px - 30px);
  --width: calc(800px - 30px);
  .credit-list {
    .list-item {
      padding-left: 0;
    }
    font-size: 16px;
    font-weight: 600;
    max-width: 320px;
  }
  .credit-fill {
    margin-left: 8px;
    margin-top: 16px;
  }
  .remaining-button {
    margin-top: -28px;
    margin-bottom: 16px;
    &.danger {
      margin-left: ${SPACE.LARGE};
    }
  }
  .MuiToggleButton-root {
    text-transform: initial;
    font-weight: ${WEIGHT.SEMI_BOLD};
    border-color: #999;
    .MuiSvgIcon-root {
      margin-right: ${SPACE.MEDIUM};
    }
  }
  .discount-selection {
    .MuiNativeSelect-root {
      margin: 0 ${SPACE.MEDIUM};
      select {
        text-align: center;
      }
    }
  }
  .total {
    .value {
      font-size: ${FONT.MEDIUM};
    }
    &.success {
      .value {
        color: var(--ion-color-success);
        font-weight: ${WEIGHT.SEMI_BOLD};
      }
    }
  }
`;
