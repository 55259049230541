export interface SalesSummaryModel {
  subtotal: number;
  discounts: number;
  creditApplied: number;
  tax: number;
  netSales: number;
}

export interface FactorySnapshotModel {
  subtotal: number;
  tax: number;
  discounts: number;
  receivable: number;
  poTotal: number;
  payments: number;
  paymentCash: number;
  paymentCredit: number;
  paymentAch: number;
  paymentCheck: number;
  paymentOther: number;
  refunds: number;
  credits: number;
  charges: number;
}

export const deriveRawToSummary = (raw) => {
  return {
    subtotal: raw.subtotal || 0,
    discounts: raw.discount || 0,
    creditApplied: raw.creditAmount || 0,
    tax: raw.tax || 0,
    netSales: raw.netSales || 0,
  };
};

export const deriveRawToSnapshot = (raw) => {
  const { total, cash, creditCard, ach, check, other } = raw.payments;
  return {
    subtotal: raw.subtotal || 0,
    discounts: raw.totalDiscount || 0,
    tax: raw.tax || 0,
    receivable: raw.receivable || 0,
    poTotal: raw.poTotal || 0,
    payments: total || 0,
    paymentCash: cash || 0,
    paymentCredit: creditCard || 0,
    paymentAch: ach || 0,
    paymentCheck: check || 0,
    paymentOther: other || 0,
    refunds: raw.refunds || 0,
    credits: raw.availableCredits || 0,
    charges: raw.charges || 0,
  };
};

export const initialSalesSummary: SalesSummaryModel = {
  subtotal: 0,
  discounts: 0,
  creditApplied: 0,
  tax: 0,
  netSales: 0,
};

export const initialFactorySnapshot: FactorySnapshotModel = {
  subtotal: 0,
  tax: 0,
  discounts: 0,
  receivable: 0,
  poTotal: 0,
  payments: 0,
  paymentCash: 0,
  paymentCredit: 0,
  paymentAch: 0,
  paymentCheck: 0,
  paymentOther: 0,
  refunds: 0,
  credits: 0,
  charges: 0,
};
