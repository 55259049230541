import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { mobileStyle } from '$gstyles/utils';

export const IonPageWrapper = styled(IonPage)`
  .content {
  }

  .radio-group {
    label.MuiFormControlLabel-root:not(.select-all) {
      width: 49%;
    }
    .select-all {
      width: 100%;
    }
  }

  ${mobileStyle(`
  .radio-group {
    label.MuiFormControlLabel-root {
      width: 100%;
    }
  }
  `)}
`;
