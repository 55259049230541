import React, { useState } from 'react';
import { TableView2 } from '$gcomponents/widgets';
import { HISTORY_TABLE, HISTORY_TABLE_CONFIG } from './historyTable';
import { HistorySectionWrapper } from './styles';
import { updatedFilter } from '$fbusiness/helpers/util';

interface HistorySectionProps {
  itemId?: number;
  isItemPage?: boolean;
}

const HistorySection = React.forwardRef<HistorySectionProps, any>(
  ({ itemId = 0, isItemPage = true }, ref) => {
    React.useImperativeHandle(ref, () => ({
      itemId,
      isItemPage,
      refreshTable,
    }));

    const [filter, setFilter] = useState({ itemId: itemId || 0 });

    const refreshTable = () => {
      setFilter(updatedFilter(filter));
    };

    const historyTable = HISTORY_TABLE({
      showItemCol: !isItemPage,
      showCreated: true,
      onDeleteInvoiceCharge: () => {},
    });

    return (
      <HistorySectionWrapper>
        <TableView2
          filter={filter}
          TABLE={historyTable}
          tableConfig={HISTORY_TABLE_CONFIG(isItemPage ? 10 : 20)}
        />
      </HistorySectionWrapper>
    );
  },
);

export default HistorySection;
