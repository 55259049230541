import React, { useEffect, useState } from 'react';

import { Flex } from '$gstyles/wrapper';
import Div from '$gstyles/div';
import { Checkbox, InputAdornment, TextField } from '@mui/material';
import { Close, Remove, Search } from '@mui/icons-material';
import { dialog } from '$gcomponents/reusables';
import { format } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';

interface SerialSelectorProps {
  serials: Array<any>;
  selections: Array<any>;
  onChangeSerials: Function;
  maxQty?: number;
  selectText?: string;
}

const SerialSelector: React.FC<SerialSelectorProps> = ({
  serials,
  selections,
  selectText = 'Selected Serials',
  onChangeSerials: setSerials,
  maxQty = 1,
}) => {
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setSerials(
      serials.map((s) => {
        if (filter && !s.serial.includes(filter)) return { ...s, hide: true };
        return { ...s, hide: false };
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onSelectSerial = (index) => {
    if (serials[index].selected) {
      onUnselect(serials[index].serial);
      return;
    }
    const numSelected = selections.length;
    if (numSelected >= maxQty) {
      dialog.alert({
        title: 'MESSAGE.ALERT',
        text: `You cannot select over ${maxQty} serials. To add more serials close this and increase the item qty`,
      });
      return;
    }
    setSerials(serials.map((s, i) => (i === index ? { ...s, selected: 1 } : s)));
  };
  const onUnselect = (value) => {
    setSerials(serials.map((s) => (value === s.serial ? { ...s, selected: 0 } : s)));
  };
  const onFilter = (e) => {
    const str = e.target.value;
    setFilter(str);
  };

  // console.log('SELECTION', serials);
  return (
    <Flex className="full-height">
      <Div className="left-container">
        <TextField
          variant="outlined"
          label="Filter"
          size="small"
          onInput={onFilter}
          value={filter}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Close className="pointer" onClick={() => setFilter('')} />
              </InputAdornment>
            ),
          }}
        />
        <div className="list-container">
          {serials.map((obj, i) => {
            const serial = obj.serial || '';
            return (
              <div key={i} className={obj.hide ? 'hide' : ''}>
                <Checkbox edge="start" checked={!!obj.selected} onChange={() => onSelectSerial(i)} />
                <Flex className="space-between">
                  <div>{serial}</div>
                  {obj.expireDate && (
                    <div className="expire">exp@ {format(obj.expireDate, DATE_FORMATS.SLASH_SHORT)}</div>
                  )}
                </Flex>
              </div>
            );
          })}
        </div>
      </Div>
      <Div className="right-container">
        <div className="title">{selectText}</div>
        <div className="list-container">
          {selections.map((obj, i) => {
            const { serial, expireDate } = obj;
            return (
              <div key={i} className="item">
                <Flex className="space-between">
                  <div>{serial}</div>
                  {expireDate && (
                    <div className="expire">({format(expireDate || '', DATE_FORMATS.SLASH_SHORT)})</div>
                  )}
                </Flex>
                <Remove className="pointer" onClick={() => onUnselect(serial)} />
              </div>
            );
          })}
        </div>
      </Div>
    </Flex>
  );
};

export default SerialSelector;
