import { INVOICE_PAYMENTS } from '$fbusiness/enums/options/paymentType';
import { toNumber } from '$gbusiness/helpers/util';
import { calculateTotal } from '../utils';

export const generateDiscountSelection = () => {
  const arr: Array<any> = [];
  for (let i = 0; i <= 100; i = i + 5) {
    arr.push(i);
  }
  return arr.map((a, i) => (
    <option value={a} key={i}>
      {a}%
    </option>
  ));
};

export const prepareOriginalInvoices = (invoices, store, paymentGroup, gPayment) => {
  const indexes: any = [];
  const newInvoices = invoices.map((inv, index) => {
    const newPayment = findMyPayment(inv, paymentGroup?.id);
    if (newPayment) indexes.push(index);
    return {
      ...inv,
      ...(newPayment && { newPayment }),
      totals: calculateTotal(inv, store, inv.payments, true, true),
    };
  });
  return {
    invoices: newInvoices,
    selectedIndexes: indexes,
    leftover: gPayment.amount - calculateConsumed(newInvoices),
  };
};

export const autoDistribute = (invoices, selectedIndexes, payment) => {
  const { amount, paymentType } = payment;
  if (paymentType === INVOICE_PAYMENTS.CREDITS || paymentType === INVOICE_PAYMENTS.DISCOUNT)
    return { invoices, selectedIndexes, leftover: 0 };

  let leftover = amount;
  const newInvoices = invoices.map((inv) => {
    if (!inv.newPayment) return inv;
    if (inv.newPayment)
      if (leftover > 0) {
        const invoiceBalance = inv.totals?.balance || inv.balance || 0;
        const applied = leftover >= invoiceBalance ? invoiceBalance : leftover;
        leftover = leftover - toNumber(applied);
        return {
          ...inv,
          newPayment: { ...payment, amount: applied },
        };
      } else {
        return {
          ...inv,
          newPayment: { ...payment, amount: 0 },
        };
      }
    return inv;
  });

  return {
    invoices: newInvoices,
    selectedIndexes,
    leftover,
  };
};

export const addOrSubstractNewPayment = ({ invoices, index, store, shouldAdd, paymentGroup, gPayment }) => {
  const invoice = invoices[index];
  const consumed = calculateConsumed(invoices, index);
  const leftover = gPayment.amount - consumed;

  const invoiceBalance = invoice.totals?.finalBalance || invoice.balance || 0;
  const amount = Math.min(leftover, invoiceBalance);

  const newInvoices = invoices.map((inv, i) => {
    if (i !== index) return inv;
    const id = inv.deletePaymentId || 0;
    const newPayment = shouldAdd ? { ...gPayment, amount, id } : undefined;
    const newInvoice = {
      ...inv,
      newPayment,
      deletePaymentId: !shouldAdd && !!inv.newPayment.id ? inv.newPayment.id : 0,
    };
    return syncNewPayment(newInvoice, paymentGroup?.id);
  });

  return {
    invoices: recalculateInvoiceTotals(newInvoices, store),
    leftover: gPayment.amount - calculateConsumed(newInvoices),
  };
};

export const onChangePaymentForm = (invoices, values, paymentGroup) => {
  const newInvoices = invoices.map((inv) => {
    const myPaymentIndex = findMyPaymentIndex(inv, paymentGroup?.id);
    if (myPaymentIndex < 0) return inv;
    return {
      ...inv,
      payments: inv.payments.map((p, i) => {
        if (i !== myPaymentIndex) return p;
        const { amount, ...rest } = values;
        return {
          ...p,
          ...rest,
        };
      }),
    };
  });
  return newInvoices;
};

export const onChangeNewPaymentAmount = ({ invoices, invoice, value, paymentGroup, gPayment, store }) => {
  const newInvoices = invoices.map((inv) => {
    if (invoice.id !== inv.id) return inv;
    const amount = invoice.id === inv.id ? toNumber(value) : toNumber(inv.newPayment?.amount || 0);
    const newPayment = { ...inv.newPayment, amount };
    const newInvoice = {
      ...inv,
      newPayment,
    };
    return syncNewPayment(newInvoice, paymentGroup?.id);
  });

  const finalInvoices = recalculateInvoiceTotals(newInvoices, store);
  const leftover = gPayment.amount - calculateConsumed(finalInvoices);

  return {
    invoices: finalInvoices,
    leftover,
  };
};

export const adjustPaymentAmount = (invoices, store, gPayment) => {
  const { originalAmount } = gPayment;
  const newInvoices = invoices.map((inv) => {
    if (!inv.newPayment) return inv;
    const payments = inv.payments.map((p) => {
      if (!p.id || (p.paymentGroupId && inv.newPayment.paymentGroupId === p.paymentGroupId))
        return { ...p, originalAmount };
      return p;
    });
    return {
      ...inv,
      payments,
      totals: calculateTotal(inv, store, inv.payments, true, true),
    };
  });

  const leftover = gPayment.amount - calculateConsumed(newInvoices);
  return {
    invoices: newInvoices,
    leftover,
  };
};

export const findMyPayment = (invoice, paymentGroupId = 0) => {
  if (!invoice) return null;
  return invoice.payments.find((p) => (paymentGroupId ? p.paymentGroupId === paymentGroupId : !p.id));
};

export const findMyPaymentIndex = (invoice, paymentGroupId = 0) => {
  if (!invoice) return -1;
  return invoice.payments.findIndex((p) => (paymentGroupId ? p.paymentGroupId === paymentGroupId : !p.id));
};

const syncNewPayment = (inv, paymentGroupId) => {
  const myPaymentIndex = findMyPaymentIndex(inv, paymentGroupId);
  if (!inv.newPayment) return { ...inv, payments: inv.payments.filter((p, i) => myPaymentIndex !== i) };
  const payments =
    myPaymentIndex >= 0
      ? inv.payments.map((p, i) => (i === myPaymentIndex ? inv.newPayment : p))
      : [...inv.payments, inv.newPayment];
  return {
    ...inv,
    payments,
  };
};

export const calculateConsumed = (invoices, indexException = -1) => {
  return invoices.reduce((acc, inv, i) => acc + ((indexException !== i && inv.newPayment?.amount) || 0), 0);
};

export const recalculateInvoiceTotals = (invoices, store) => {
  return invoices.map((inv) => ({ ...inv, totals: calculateTotal(inv, store, inv.payments, true, true) }));
};

export const resetPayments = (invoices, store, paymentGroupId = 0) => {
  const newInvoices = invoices.map((inv) => {
    const payments = inv.payments.filter((p) => p.id || (paymentGroupId && paymentGroupId !== p.id));
    return {
      ...inv,
      payments,
      newPayment: undefined,
      totals: calculateTotal(inv, store, payments, true, true),
    };
  });
  return {
    leftover: 0,
    invoices: newInvoices,
    selectedIndexes: [],
  };
};

export const getSelectedInvoices = (invoices, selectedIndexes) => {
  return selectedIndexes.reduce((acc, i) => {
    acc.push(invoices[i]);
    return acc;
  }, []);
};

// export const getSelectedPayments = (invoices, selectedIndexes, paymentGroupId = 0) => {
//   const selectedInvoices = getSelectedInvoices(invoices, selectedIndexes);
//   return selectedInvoices.map((inv) => {
//     const payment = paymentGroupId
//       ? inv.payments.find((p) => p.paymentGroupId === paymentGroupId)
//       : inv.payments.find((p) => !p.id);
//     return payment;
//   });
// };

export const convertPaymentToGroup = (payment) => {
  if (!payment) return null;
  const {
    paymentGroupId: id,
    creditAmount,
    type,
    originalAmount: amount,
    originalBalance: balance,
    bankId,
    paymentDate,
    memo,
    storeId,
    settings,
  } = payment;

  return {
    id,
    storeId,
    type,
    balance,
    bankId,
    paymentDate,
    memo,
    settings,
    ...(type === INVOICE_PAYMENTS.CREDITS
      ? { creditAmount: amount, amount: 0, originalBalance: creditAmount || amount }
      : { creditAmount: 0, amount: amount }),
  };
};
