import React from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { Div, H2, SPACE, WEIGHT } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import Footer from '$gcomponents/widgets/footer';
import { phone as formatPhone } from '$gbusiness/helpers/mask';

import { ModalWrapper } from './styles';

import { Flex } from '$gstyles/wrapper';

import StoreModel from '$fbusiness/models/store';

interface StoreDetailsModalProps {
  show: boolean;
  store: StoreModel;
  onClose: () => void;
}

const StoreDetailsModal: React.FC<StoreDetailsModalProps> = ({ show, store, onClose }) => {
  const renderStore = thisStore => {
    const { name, formatted, phone, email } = thisStore;
    return (
      <>
        <IonContent>
          <Div padding={SPACE.LARGE}>
            <H2 padding={`${SPACE.LARGE} 0 ${SPACE.MEDIUM} 0`} fontWeight={WEIGHT.BOLD}>
              <div>{name}</div>
            </H2>

            <div className="info-container">
              {formatted && (
                <Flex justifyContent="space-between">
                  <div className="label">Address:</div>
                  <div className="value">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`http://maps.google.com/?q=${formatted.replace(/ /g, '+')}`}>
                      {formatted}
                    </a>
                  </div>
                </Flex>
              )}
              {phone && (
                <Flex justifyContent="space-between">
                  <div className="label">Phone:</div>
                  <div className="value">
                    <a href={`tel:${phone}`}>{formatPhone(phone)}</a>
                  </div>
                </Flex>
              )}
              {email && (
                <Flex justifyContent="space-between">
                  <div className="label">Email:</div>
                  <div className="value">
                    <a href={`mailto:${email}`}>{email}</a>
                  </div>
                </Flex>
              )}
            </div>
          </Div>
        </IonContent>
        <Footer justifyContent="space-around" className="no-print">
          <Button className="half" onClick={onClose}>
            {intl('BUTTON.CLOSE')}
          </Button>
        </Footer>
      </>
    );
  };

  return (
    <ModalWrapper show={show} width="500px" height="400px" onClose={() => onClose()} useCustom>
      {store && renderStore(store)}
    </ModalWrapper>
  );
};

export default StoreDetailsModal;
