import React from 'react';
import intl from '$intl';

import StoreModel from '$fbusiness/models/store';

import { ModalWrapper } from './styles';
import StatementChargeDetails from './statementChargeDetails';
import FactoryModel from '$fbusiness/models/factory';

interface StatementChargeModalProps {
  show: boolean;
  store: StoreModel;
  stores: Array<StoreModel>;
  factory: FactoryModel;
  onClose: Function;
  statementCharge: any;
  onSaved: Function;
}

const StatementChargeModal: React.FC<StatementChargeModalProps> = ({
  factory,
  show,
  store,
  stores,
  statementCharge,
  onClose,
  onSaved,
}) => {
  let titleText = intl('ITEM.' + (statementCharge?.id ? 'EDIT' : 'ADD'), { item: intl('COMMON.CHARGE') });
  if (statementCharge?.isInvoiceCharge) titleText += ` (for Invoice #${statementCharge.invoiceNumber})`;
  else if (store?.name) titleText += ` (${store.name})`;

  return (
    <ModalWrapper titleText={titleText} show={show} onClose={() => onClose()} useCustom>
      {statementCharge && (
        <StatementChargeDetails
          factory={factory}
          onClose={onClose}
          store={store}
          stores={stores}
          statementCharge={statementCharge}
          onSaved={onSaved}
        />
      )}
    </ModalWrapper>
  );
};

export default StatementChargeModal;
