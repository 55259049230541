import React from 'react';
import { ModalWrapper } from './styles';
import TermDetails from './termDetails';
import TermModel from '$fbusiness/models/term';
import { itemText } from '$gbusiness/helpers/util';

interface TermEditModalProps {
  term: TermModel;
  onClose: Function;
}

const TermEditModal: React.FC<TermEditModalProps> = ({ term, onClose }) => {
  const titleText = itemText(term?.id ? 'EDIT' : 'CREATE', 'TERM');

  return (
    <ModalWrapper show={!!term} onClose={() => onClose()} titleText={titleText} useCustom>
      {term && <TermDetails term={term} onClose={onClose} />}
    </ModalWrapper>
  );
};

export default TermEditModal;
