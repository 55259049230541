import {
  SalesSummaryModel,
  FactorySnapshotModel,
  initialFactorySnapshot,
  initialSalesSummary,
} from '../../models/report';

export const REPORT_FAILURE = 'REPORT_FAILURE';
export const FETCH_SNAPSHOT_SUCCESS = 'FETCH_SNAPSHOT_SUCCESS';
export const FETCH_SUMMARY_SUCCESS = 'FETCH_SUMMARY_SUCCESS';
export const RESET_FINISHED = 'RESET_FINISHED';
export const CLEAN_REPORT = 'CLEAN_REPORT';

// Action Types
export interface typeOnlyType {
  type: typeof RESET_FINISHED | typeof CLEAN_REPORT;
}

export interface factorySnapshotFetchType {
  type: typeof FETCH_SNAPSHOT_SUCCESS;
  snapshot: FactorySnapshotModel;
}

export interface salesSummaryFetchType {
  type: typeof FETCH_SUMMARY_SUCCESS;
  summary: SalesSummaryModel;
}

export interface failureType {
  type: typeof REPORT_FAILURE;
  err: string;
}

export interface ReportReducerType {
  snapshot: FactorySnapshotModel;
  summary: SalesSummaryModel;
}

export type ReportActionTypes = typeOnlyType | failureType | factorySnapshotFetchType | salesSummaryFetchType;

export const REPORT_INIT_STATE: ReportReducerType = {
  snapshot: initialFactorySnapshot,
  summary: initialSalesSummary,
};
