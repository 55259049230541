import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button } from '@mui/material';
import { percentage } from '$gbusiness/helpers/util';
import { deriveRawToTerm } from '$fbusiness/models/term';

export const TERM_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.term,
    method: 'GET',
    deriveToModel: deriveRawToTerm,
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 300,
    maxWidth: 800,
    cellPadding: ` 8px 4px`,
    isStripped: true,
    responsive: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const TERM_TABLE = (): Array<CellModel> => [
  {
    label: 'SCREEN.TERMS.COLS.NAME',
    value: 'name',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.TERMS.COLS.DUE_DAYS',
    value: 'dueDays',
    align: 'center',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 120,
  },
  {
    label: 'SCREEN.TERMS.COLS.DISCOUNT_RATE',
    value: 'discountRate',
    align: 'center',
    component: (row) => <>{percentage(row.discountRate || 0)}</>,
    sortable: SORT.ASC,
    width: 150,
  },
  {
    label: 'SCREEN.TERM.COLS.DISCOUNT_DAYS',
    value: 'discountDays',
    align: 'center',
    sortable: SORT.ASC,
    width: 120,
  },
  {
    label: '',
    value: '',
    className: 'action',
    align: 'center',
    component: (row, actions) => {
      return (
        <Button
          className="left"
          color="secondary"
          fullWidth
          onClick={(e) => actions.handlePopoverOpen(e, row)}>
          <MoreHorizIcon />
        </Button>
      );
    },
    width: 100,
  },
];
