import UserModel, { deriveRawToUser } from '$gbusiness/models/user';
import CommPeriodModel, { deriveRawToCommPeriod } from './commPeriod';

export default interface CommPayModel {
  id: number;
  period: CommPeriodModel;
  user: UserModel;
  total: number;
  refunds: number;
  commissionSales: number;
  commission: number;
  clawbacks?: Array<any>;
  clawbackTotal: number;
  status: string;
  settings?: any;
}

export const deriveRawToCommPay = (raw) => {
  return {
    id: raw.id,
    period: deriveRawToCommPeriod(raw.period),
    user: deriveRawToUser(raw.user),
    total: raw.total,
    refunds: raw.refunds,
    commissionSales: raw.commissionSales,
    commission: raw.commission,
    status: raw.status,
    clawbacks: raw.clawbacks || [],
    clawbackTotal: raw.clawbackTotal,
    settings: {
      ...raw.settings,
    },
  };
};
