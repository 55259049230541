import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const CHARGE_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'INPUT.LABEL.CHARGE',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 12,
        validation: [input.requiredValidation],
        // onChange: (form) => {
        //   const { value, setFieldValue } = form;
        //   const abbr = ((value || '').match(/\b([A-Z])/g) || []).join('');
        //   setFieldValue('description', abbr);
        // },
      },
      {
        label: 'COMMON.ABBR',
        value: 'description',
        input: INPUT.TEXT,
        gridSize: 6,
        placeholder: 'INPUT.PLACEHOLDER.ABBR',
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.AMOUNT,
        label: 'INPUT.LABEL.DEFAULT_AMOUNT',
        value: 'defaultAmount',
        gridSize: 6,
        validation: [input.requiredValidation, input.amountValidation],
      },
    ],
  },
];
