import React from 'react';
import { Formik } from 'formik';

import { input as inputHelper } from '$gbusiness/helpers';
import intl from '$intl';
import { FilterWrapper } from './styles';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import ExportButton from '$gcomponents/reusables/exportButton';
import { Print } from '@mui/icons-material';
import { BATCH_FILTER_FORM } from './batchForm';
import VendorModel from '$fbusiness/models/vendor';
import { batchExportSettings } from './batchTable';
import { ExportContainer } from '../../storeScreens/storesScreen/styles';
import { CUSTOM } from '$fbusiness/enums/periods';
import { dateToString } from '$gbusiness/helpers/date';
import FactoryModel from '$fbusiness/models/factory';
import { addDays } from 'date-fns';

interface BatchFilterProps {
  onSearch: Function;
  vendors: Array<VendorModel>;
  items: Array<any>;
  columns: any;
  factory: FactoryModel;
  parentFilter?: any;
  isExpiration?: boolean;
}

export const defaultBatchFilter = () => ({
  forceRefresh: true,
  dateEnd: new Date(),
  datePeriod: CUSTOM,
  vendorId: 0,
  item: null,
});

export const getExpirationThresDate = (settings, parse = false) => {
  const expireDate = addDays(new Date(), Math.max(settings?.expireDays || 0, settings?.expireDays2 || 0, 60));
  if (parse) return dateToString(expireDate);
  return expireDate;
};

export const defaultExpirationFilter = (settings) => {
  // const [dateStart, dateEnd] = util.getDateRangeFromPeriod(THIS_MONTH);
  return {
    forceRefresh: true,
    dateEnd: getExpirationThresDate(settings),
    dateStart: null,
    datePeriod: CUSTOM,
  };
};

export const filterToParam = (values) => {
  const { item, dateStart, dateEnd } = values;
  return {
    ...values,
    item: undefined,
    itemId: item?.id || undefined,
    dateStart: dateStart ? dateToString(dateStart) : '',
    dateEnd: dateEnd ? dateToString(dateEnd) : '',
  };
};

const BatchFilter: React.FC<BatchFilterProps> = ({
  factory,
  columns,
  items,
  vendors,
  onSearch,
  isExpiration = false,
}) => {
  const initialValues = {
    ...(isExpiration ? defaultExpirationFilter(factory.settings) : defaultBatchFilter()),
  };

  const onSubmit = (values) => {
    onSearch(filterToParam(values));
  };
  const onChangeItem = (values, value) => {
    // onSearch({ ...values, itemId: value?.id || null });
  };

  const filterForm = BATCH_FILTER_FORM(items, vendors, onChangeItem, isExpiration);

  const validateForm = (values) => {
    return inputHelper.validateError(filterForm, values);
  };

  const onPrint = () => {
    window.print();
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <FilterWrapper>
          <FormSection
            FORM={filterForm}
            formik={formik}
            className="submit-container"
            lastColumn={
              <Button
                onClick={formik.handleSubmit}
                className="submit-button"
                variant="contained"
                color="secondary">
                {intl('BUTTON.SUBMIT')}
              </Button>
            }
          />

          <ExportContainer>
            <div className="export-container">
              <Button variant="text" color="secondary" icon={<Print />} onClick={onPrint}>
                {intl('BUTTON.PRINT')}
              </Button>
              {columns && !isExpiration && (
                <ExportButton
                  filter={filterToParam(formik.values)}
                  variant="text"
                  fileName={intl('COMMON.BATCH')}
                  exportSetting={batchExportSettings(columns)}
                />
              )}
            </div>
          </ExportContainer>
        </FilterWrapper>
      )}
    </Formik>
  );
};

export default BatchFilter;
