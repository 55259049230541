import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { Button, Text } from '$gcomponents/primitives';
import { AppForm } from '$styles/general';
import { factoryActions } from '$fbusiness/redux/factory';
import { AppModel } from '$business/redux/';

import { REGION_FORM } from './regionForm';
import RegionModel from '$fbusiness/models/region';
import { configs } from '$configs';
import { IonContent } from '@ionic/react';
import Footer from '$gcomponents/widgets/footer';

interface RegionDetailsProps {
  region: RegionModel;
  onClose: Function;
}

const RegionDetails: React.FC<RegionDetailsProps> = ({ region, onClose }) => {
  const dispatch = useDispatch();
  const isFinished = useSelector((state: AppModel) => state.factory.isFinished);
  const formRef = useRef<any>();

  useEffect(() => {
    if (!isFinished) return;
    dispatch(factoryActions.resetFinished());
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished, dispatch, onClose]);

  if (!region) return null;
  const { id, ...initialValues } = region;

  const onSubmit = (values) => {
    const newValues = {
      name: values.name,
    };
    dispatch(factoryActions.saveObj(id || 0, newValues, configs.api.region, factoryActions.fetchRegionsF));
  };

  const validateForm = (values) => {
    return input.validateError(REGION_FORM, values);
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <div className="content">
              <AppForm>
                <FormSection FORM={REGION_FORM} formik={formik} />
              </AppForm>
            </div>
          </IonContent>
          <Footer justifyContent="center">
            <Button className="half" onClick={formik.handleSubmit} disabled={!formik.isValid}>
              <Text k="BUTTON.SAVE" />
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default RegionDetails;
