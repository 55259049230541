import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, SPACE } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .no-setting {
    text-align: center;
    font-size: ${FONT.MEDIUM};
    > div {
      margin: ${SPACE.LARGE};
    }
  }
  .summary-container {
    font-size: 0.9em;
  }
  .main-table-wrapper {
    .MuiOutlinedInput-root {
      font-size: 1.3rem;
    }
  }
  .clawback-table {
    display: grid;
    font-size: 0.9em;
    grid-template-columns: 140px 70px 68px;
  }
  .tableview2 {
    padding-top: 0;
  }
`;

export const FilterWrapper = styled.div`
  ${(p) => p.maxWidth && `max-width: ${p.maxWidth}px;`}
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    flex: 1;
  }
  > button {
    max-height: 48px;
  }
`;
