import {
  CLEAN_SERIAL,
  CREATE_SERIAL_SUCCESS,
  DELETE_SERIAL_SUCCESS,
  SerialActionTypes,
  SerialReducerType,
  SERIAL_INIT_STATE,
  FETCH_SERIAL_SUCCESS,
  FETCH_SERIALS_SUCCESS,
  UPDATE_SERIAL_SUCCESS,
} from './types';

export default function serialReducer(
  state: SerialReducerType = SERIAL_INIT_STATE,
  action: SerialActionTypes,
): SerialReducerType {
  switch (action.type) {
    case FETCH_SERIAL_SUCCESS:
      return {
        ...state,
        serial: action.serial,
      };
    case FETCH_SERIALS_SUCCESS:
      return {
        ...state,
        serials: action.serials,
      };
    case UPDATE_SERIAL_SUCCESS:
    case CREATE_SERIAL_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case DELETE_SERIAL_SUCCESS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case CLEAN_SERIAL:
      return {
        ...SERIAL_INIT_STATE,
        serials: state.serials,
        refresh: state.refresh,
      };
    default:
      return state;
  }
}
