export const KEYS = {
  ALL: 'ALL',
  OPEN: 'OPEN',
};

export const OPEN_FILTER = KEYS;

const convertToOption = (item) => ({
  label: `OPEN_FILTER.${item}`,
  value: item,
});

const LIST = Object.keys(KEYS);

export const OPEN_FILTER_LIST = LIST;

export const OPEN_FILTER_OPTIONS = LIST.map(convertToOption);
