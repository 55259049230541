import {
  CLEAN_REPORT,
  ReportReducerType,
  REPORT_INIT_STATE,
  ReportActionTypes,
  FETCH_SNAPSHOT_SUCCESS,
  FETCH_SUMMARY_SUCCESS,
} from './types';

export default function reportReducer(
  state: ReportReducerType = REPORT_INIT_STATE,
  action: ReportActionTypes,
): ReportReducerType {
  switch (action.type) {
    case FETCH_SNAPSHOT_SUCCESS:
      return {
        ...state,
        snapshot: action.snapshot,
      };
    case FETCH_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.summary,
      };
    case CLEAN_REPORT:
      return {
        ...REPORT_INIT_STATE,
      };
    default:
      return state;
  }
}
