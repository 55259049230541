import React from 'react';
import { Button } from '$gcomponents/primitives';

interface SkuFilterProps {
  totalCount: number;
  onClick: Function;
  show: boolean;
}

const SkuFilter: React.FC<SkuFilterProps> = ({ totalCount, onClick, show }) => {
  if (!show) return null; // Turned off
  const label = totalCount ? `${totalCount} items selected` : 'Filter Items';
  return (
    <Button color="secondary" size="small" onClick={() => onClick()}>
      {label}
    </Button>
  );
};

export default SkuFilter;
