export default interface CommPeriodModel {
  id: number;
  periodDate: string;
  periodStartDate: string;
  reportStartDate: string;
  reportEndDate: string;
  payDate: string;
}

export const deriveRawToCommPeriod = (raw) => {
  return {
    id: raw.id,
    periodDate: raw.periodDate,
    periodStartDate: raw.periodStartDate,
    reportStartDate: raw.reportStartDate,
    reportEndDate: raw.reportEndDate,
    payDate: raw.payDate,
  };
};
