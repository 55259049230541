import styled from 'styled-components';
import { IonPage } from '@ionic/react';

export const IonPageWrapper = styled(IonPage)`
  .summary-container {
    font-size: 0.9em;
  }
  .tableview2 {
    padding-top: 0;

  .MuiTableRow-root.cancelled {
    background-color: #ccc;
    opacity: 0.5;
    pointer-events: none;
  }
`;
