import { OPEN_FILTER_OPTIONS } from '$fbusiness/enums/options/openFilter';
import { TRANSACTION_TYPE_OPTIONS } from '$fbusiness/enums/options/transactionTypes';
import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';

const TRANSACTION_FILTER = (): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.MULTI_SELECT,
        label: 'SCREEN.TRANSACTIONS.FILTER',
        value: 'types',
        position: 'outlined',
        options: TRANSACTION_TYPE_OPTIONS,
        validation: [
          {
            condition: (v) => !v.length,
            message: 'REQUIRED',
          },
        ],
        gridSize: 6,
      },
      {
        input: INPUT.RADIO,
        label: 'SCREEN.TRANSACTIONS.OPEN_FILTER',
        value: 'customFilter',
        position: 'outlined',
        options: OPEN_FILTER_OPTIONS,
        validation: [
          {
            condition: (v) => !v.length,
            message: 'REQUIRED',
          },
        ],
        gridSize: 3,
      },
    ],
  },
];

export default TRANSACTION_FILTER;
