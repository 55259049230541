import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

import { ROUTES } from '$business/enums';

import PrivateRoute from './privateRoute';
import { canAccess } from '$gbusiness/helpers/util';
import CurrentStateModel from '$fbusiness/models/currentState';
import DateFilterModel from '$fbusiness/models/dateFilter';

interface RouterProps {
  isLoggedIn: boolean;
  userLevel?: number;
  dateFilter: DateFilterModel;
  currentState: CurrentStateModel;
}

const AppOutlet: React.FC<RouterProps> = ({ dateFilter, currentState, isLoggedIn, userLevel }) => {
  return (
    <IonRouterOutlet id="main" animated={false}>
      {ROUTES.LIST.map((menu, i) => (
        <PrivateRoute
          key={i}
          currentState={currentState}
          exact={menu.exact || true}
          path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          dateFilter={dateFilter}
          isAccessible={canAccess(menu.accessLevel, userLevel)}
          feature={menu.feature}
        />
      ))}
      {ROUTES.PUBLIC_LIST.filter((m) => m.path !== ROUTES.PATH.LOGIN).map((menu, i) => (
        <Route
          key={i}
          path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          exact={menu.exact || true}
        />
      ))}

      <Route
        path={ROUTES.DEFAULT_PUBLIC_PATH}
        component={isLoggedIn ? undefined : ROUTES.DEFAULT_PUBLIC.component}
        render={isLoggedIn ? () => <Redirect to={ROUTES.DEFAULT_APP_PATH} exact /> : undefined}
        exact
      />
    </IonRouterOutlet>
  );
};

export default AppOutlet;
