import React, { useEffect, useState } from 'react';
import intl from '$intl';
import InputRowModel from '$gbusiness/models/inputRow';
import { IonContent } from '@ionic/react';
import { PageWrapper } from '$gstyles/wrapper';
import { Form } from 'formik';
import { FormSection, InfoItem } from '$gcomponents/reusables';
import Footer from '$gcomponents/widgets/footer';
import { ColAuto, Row } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import { initialPrice } from '$fbusiness/models/price';
import { OrderSummary } from '$fcomponents';
import InvoiceModel, { recalculateInvoice } from '$fbusiness/models/invoice';
import { deriveItemToInvoiceItem, deriveRawItemToInvoiceItem } from '$fbusiness/models/invoiceItem';
import { TableView2 } from '$gcomponents/widgets';
import { INVOICE_ITEM_TABLE, INVOICE_ITEM_TABLE_CONFIG, SNC } from '../../editInvoiceScreen/invoiceItemTable';
import ItemPicker from '../../editInvoiceScreen/itemPicker';
import { getTaxRate } from '$fbusiness/helpers/util';
import FactoryModel from '$fbusiness/models/factory';
import {
  checkStockInvoice,
  fetchUser,
  getCommissionAmt,
  getCommissionRate,
  getDiscountRate,
} from '../../editInvoiceScreen/utils';
import { deriveRawToItem } from '$fbusiness/models/item';
import { currency, focusInputEl, percentage, sleep } from '$gbusiness/helpers/util';
import ScanBackdrop from '../../editInvoiceScreen/scanBackdrop';
import { useDispatch } from 'react-redux';
import { cartActions } from '$fbusiness/redux/cart';
import { getEl } from '$gbusiness/helpers/input';
import CreditHistoryModal from '$fcomponents/creditHistoryModal';
import { toastDanger } from '$gbusiness/redux/toaster/actions';
import { useHistory } from 'react-router';

interface EditInvoiceDetailsProps {
  formik: any;
  shouldConvert?: boolean;
  factory: FactoryModel;
  isEdit?: boolean;
  isCredit: boolean;
  FORM: Array<InputRowModel>;
  initVal: any;
  onSubmit: Function;
  invoice?: InvoiceModel;
}

const EditInvoiceDetails: React.FC<EditInvoiceDetailsProps> = ({
  invoice: fetchedInvoice,
  shouldConvert = false,
  isEdit = false,
  formik,
  onSubmit,
  isCredit,
  factory,
  initVal,
  FORM,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { values } = formik;
  const { store, user, diff } = initVal;
  const [invoice, setInvoice] = useState<any>({
    ...initialPrice,
    ...values,
    items: [],
  });
  const balance = isCredit ? invoice.total - diff || 0 : 0;
  const [showScan, setShowScan] = useState(false);
  const [qtyMode, setQtyMode] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [scanItem, setScanItem] = useState<any>(undefined);
  const [commission, setCommission] = useState<any>(null);
  const [showCredits, setShowCredits] = useState(false);
  const [deleteIds, setDeleteIds] = useState<Array<number>>([]);

  const subject = isCredit ? 'Credit' : 'Refund';
  const {
    settings: { itemCombine, fullRefundOnly, serial: serialEnabled },
  } = factory;

  const recalculate = (inv = invoice, checkItem: any = null) => {
    const taxRate = getTaxRate(store, factory);
    const newInvoice = recalculateInvoice(
      {
        ...inv,
        taxRate,
      },
      { fixedQty: true, mutable: true, noTax: values.noTax === '1', preventCombine: !itemCombine },
    );
    if (checkItem && !checkStockInvoice(newInvoice, { ...checkItem, qtySent: checkItem.quantiy })) {
      cartActions.handleOOS(dispatch, checkItem.quantity);
      return;
    }
    setInvoice({
      ...newInvoice,
      commRate: getCommissionRate(newInvoice, commission),
    });
  };

  useEffect(() => {
    if (!fetchedInvoice?.id) return;
    // console.log('USEEFFECT invoice id', fetchedInvoice.id);
    recalculate({
      ...fetchedInvoice,
      items: (fetchedInvoice?.items || []).map(deriveItemToInvoiceItem),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedInvoice?.id]);

  // When changing store it could change the tax rate
  useEffect(() => {
    if (!store?.id) return;
    // console.log('USEEFFECT store id', store.id);
    recalculate({
      ...invoice,
      items: (invoice?.items || []).map((m) => ({
        ...deriveItemToInvoiceItem(m, store),
      })),
    });
    const newValues = {
      ...formik.values,
      ...initVal,
      ...(!shouldConvert && !isEdit && { discountId: store?.settings?.defaultDiscount || '0' }),
    };
    setTimeout(() => {
      formik.setValues(newValues);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.id]);

  // Changing discount could impact item discount as well as flat other discount
  useEffect(() => {
    // console.log('USEEFFECT discount itax', values.discountId, values.noTax);
    const [discountRate, otherDiscount] = getDiscountRate(factory.discounts, values.discountId);
    recalculate({
      ...invoice,
      discountRate,
      otherDiscount,
      items: (invoice?.items || []).map((m) => ({
        ...deriveItemToInvoiceItem(m),
        discountRate,
      })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.discountId, values.noTax]);

  useEffect(() => {
    // console.log('USEEFFECT user id', values.userId, user);
    const id = values?.userId || user?.userId;
    const getCommission = async () => {
      const user = id ? await fetchUser(id) : 0;
      setCommission(user?.other?.commission || null);
    };
    getCommission();
  }, [values.userId, user?.userId]);

  const onSelectItem = (item) => {
    if (!item) return;
    const discountRate = invoice?.discountRate || 0;
    const newItem = {
      ...deriveRawItemToInvoiceItem(item, store, discountRate),
      qtySent: 1,
      ...(!item.itemId && !item.id && { qtySent: 0 }),
      id: 0,
      ...(serialEnabled &&
        item?.settings?.serial && { serials: [...(item?.serials || []), item?.settings?.serial] }),
    };
    recalculate({ ...invoice, discountRate, items: [...invoice.items, newItem] }, item);
    const index = invoice.items.length;
    if (!item.id && item.name === 'TEXT') {
      focusInputEl('.blank-field[data-index="' + index + '"]', 200);
    } else {
      focusQuantity(item.id, index);
    }
  };
  const focusQuantity = async (id, index, sleepTime = 200) => {
    await sleep(sleepTime);
    const selector = itemCombine
      ? '.counter-field[data-id="' + id + '"]'
      : '.MuiTableRow-root[data-index="' + index + '"] .counter-field';
    const el: any = getEl(selector);
    if (el) {
      el.select();
      el.focus();
    }
  };
  const onDeleteItem = (row, index) => {
    recalculate({ ...invoice, items: invoice.items.filter((p, i) => i !== index) });
    if (isEdit && row?.id) setDeleteIds([...deleteIds, row?.id]);
  };
  const onEnter = async (val) => {
    if (!showScan) return;
    onResetInput();
  };
  const onInvalid = async () => {
    dispatch(toastDanger({ text: 'Invalid quantity value', className: 'medium' }));
  };
  const onResetInput = async () => {
    setQtyMode(false);
    focusOnInput();
  };
  const onChangePrice = (index, unitPrice) => {
    const item = invoice.items[index];
    if (isNaN(unitPrice)) return;
    recalculate(
      {
        ...invoice,
        items: invoice.items.map((p, i) => (i === index ? { ...p, unitPrice } : p)),
      },
      item,
    );
  };
  const onChangeQty = (index, qty) => {
    const item = invoice.items[index];
    const isItemText = !item.itemId && !item.unitPrice;
    const minQty = Math.max(serialEnabled ? item.serials?.length || 0 : 0);
    if (qty < minQty) return;
    if (qty < 0 && !isItemText) {
      onDeleteItem(item, index);
      onResetInput();
      return;
    }
    // if (qty <= 0 || !qty) focusQuantity(item.itemId);
    recalculate(
      {
        ...invoice,
        items: invoice.items.map((p, i) => (i === index ? { ...p, qtySent: qty, qty, totalSent: qty } : p)),
      },
      item,
    );
  };
  const focusOnInput = async () => {
    const el = getEl('.react-autosuggest__input');
    el.focus();
  };
  const onScanMode = async () => {
    await onResetInput();
    setQtyMode(false);
    setShowScan(true);
    setScanItem(undefined);
  };
  const onScanFetch = async (result, barcode) => {
    setBarcode(barcode);
    if (!result?.data) {
      setScanItem(null);
    } else {
      const item = deriveRawToItem(result.data);
      setScanItem(item);
      onSelectItem(item);
      if (item.quantity !== 0) {
        setQtyMode(true);
      }
    }
    await sleep(2000);
    setScanItem(undefined);
  };
  const onViewCredits = () => {
    setShowCredits(true);
  };
  const onChangeBlankText = (e, index) => {
    const itemName = e.target.value;
    setInvoice({
      ...invoice,
      items: invoice.items.map((item, i) => (i === index ? { ...item, itemName } : item)),
    });
  };

  const invoiceTotal = invoice.total + (invoice?.totals?.invoiceChargeSum || 0);

  // const totalItems = invoice?.items ? invoice.items.reduce((acc, r) => acc + r.qtySent, 0) : 0;

  // console.log('commission', invoice);
  return (
    <>
      <IonContent>
        <PageWrapper className="content-wrapper">
          <Form>
            <FormSection FORM={FORM} formik={formik} />
          </Form>
          <h3 className="item-title">
            {intl('SCREEN.ITEMS.TITLE')}
            {/* {totalItems > 1 && <span className="item-quantity"> {`(${totalItems} items)`}</span>} */}
            {store && (
              <span className="credit-section">
                <div className="credit-balance">
                  {intl('SCREEN.CREDIT.COLS.BALANCE')}: {currency(store.credits)}
                </div>
                <div className="history-link link" onClick={onViewCredits}>
                  {intl('SCREEN.CREDIT.TITLE')}
                </div>
              </span>
            )}
          </h3>
          <TableView2
            className="invoice-items"
            data={invoice.items}
            TABLE={INVOICE_ITEM_TABLE({
              onChangeQty,
              onDeleteItem,
              onInvalid,
              onEnter,
              onChangePrice,
              serialEnabled,
              // disableItemPriceEdit: shouldConvert,
            })}
            tableConfig={INVOICE_ITEM_TABLE_CONFIG(onChangeBlankText, onEnter)}
          />
          {(!fullRefundOnly || !shouldConvert) && (
            <ItemPicker
              disabled={!values.store}
              scanMode={showScan}
              onScanFetch={onScanFetch}
              onSelectItem={onSelectItem}
              onClickScan={onScanMode}
              enableBlank
            />
          )}
          <div className="order-summary">
            <OrderSummary
              price={{
                ...invoice,
                ...(isCredit ? { isCredit: 1, refundAmount: 0, refund: undefined } : { isRefund: 1 }),
              }}
              isHidden={false}
            />
          </div>
        </PageWrapper>
        <ScanBackdrop
          qtyMode={qtyMode}
          item={scanItem}
          barcode={barcode}
          show={showScan}
          onClose={() => setShowScan(false)}
        />
      </IonContent>
      <Footer justifyContent="space-around">
        <Row width="100%">
          <ColAuto className="invoice-summary">
            <InfoItem
              alignCenter
              className="invoice-total"
              label={intl(SNC + 'INVOICE_TOTAL')}
              value={currency(invoiceTotal)}
            />
            {commission && (
              <InfoItem
                alignCenter
                className=""
                label={intl(SNC + 'COMM', {
                  comm: `(${percentage(commission?.rate || invoice.commRate)})`,
                })}
                value={currency(getCommissionAmt(invoice, commission))}
              />
            )}
            {isEdit && isCredit && (
              <InfoItem
                alignCenter
                className={balance < 0 ? 'error bold' : ''}
                label={intl(SNC + 'BALANCE')}
                value={currency(balance)}
              />
            )}
          </ColAuto>
          <ColAuto className="buttons row">
            <Button onClick={() => history.goBack()} variant="outlined">
              {intl('BUTTON.CANCEL')}
            </Button>
            <Button
              className="half"
              onClick={() =>
                onSubmit({
                  ...invoice,
                  ...values,
                  balance,
                  ...(isEdit && { deleteIds }),
                })
              }
              disabled={!(formik.isValid && balance >= 0 && invoice?.items?.length)}>
              {intl('BUTTON.' + (shouldConvert ? 'SAVE' : 'SAVE'), { subject })}
            </Button>
          </ColAuto>
        </Row>
      </Footer>
      <CreditHistoryModal
        width="760px"
        show={showCredits}
        onClose={() => setShowCredits(false)}
        store={store}
      />
    </>
  );
};

export default EditInvoiceDetails;
