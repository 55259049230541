import React, { useState } from 'react';
import intl from '$intl';
import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';

import { currency } from '$gbusiness/helpers/util';

import { PaymentSummaryWrapper } from './styles';
import { InvoicePaymentTotalType } from './utils';

interface PaymentSummaryProps {
  total: InvoicePaymentTotalType;
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({ total }) => {
  const {
    subtotal,
    balance,
    otherDiscount,
    totalDiscount,
    discounts,
    taxable,
    tax,
    invoiceTotal,
    originalTotal,
    refundCreditSum = 0,
    refunds,
    payments,
    credits,
  } = total;
  const [openBreakdown, setOpenBreakdown] = useState(false);
  const hasDiscount = totalDiscount > 0;
  const hasInvoiceDiscount = discounts > 0;
  const breakdown = (
    <>
      {hasDiscount && (
        <div className="inner">
          <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.SUBTOTAL')}: </span>
          <span className="price">{currency(subtotal)}</span>
        </div>
      )}
      {otherDiscount > 0 && (
        <div className="inner">
          <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.OTHER_DISCOUNT')}: </span>
          <span className="price">{currency(-otherDiscount)}</span>
        </div>
      )}
      {discounts > 0 && (
        <div className="inner bold discount">
          <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.DISCOUNTS')}: </span>
          <span className="price">{currency(-discounts)}</span>
        </div>
      )}
      <div className="inner">
        <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.TAXABLE')}: </span>
        <span className="price">{currency(taxable)}</span>
      </div>
      <div className="inner">
        <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.TAX')}: </span>
        <span className="price">{currency(tax)}</span>
      </div>
      {refundCreditSum > 0 && (
        <div className="inner">
          <span className="label">{intl('SCREEN.ORDER.SUMMARY.REFUND_CREDITS')}: </span>
          <span className="price">{currency(refundCreditSum)}</span>
        </div>
      )}
      <hr />
      <div className="inner bold total">
        <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.TOTAL')}: </span>
        <span className="price">{currency(invoiceTotal + refundCreditSum)}</span>
      </div>
    </>
  );
  return (
    <PaymentSummaryWrapper>
      <div className="outer">
        {hasInvoiceDiscount ? (
          <>
            <div className="inner">
              <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.ORIGINAL_TOTAL')}: </span>
              <span className="strikethrough">{currency(originalTotal + refundCreditSum)}</span>
            </div>
            <div className="inner">
              <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.DISCOUNTED_TOTAL')}: </span>
              <span className="bold">{currency(invoiceTotal + refundCreditSum)}</span>
            </div>
          </>
        ) : (
          <div className="inner">
            <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.TOTAL')}: </span>
            <span className="">{currency(invoiceTotal + refundCreditSum)}</span>
          </div>
        )}
        {refunds > 0 && (
          <div className="inner">
            <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.REFUNDS')}: </span>
            <span className="price">{currency(refunds)}</span>
          </div>
        )}
        <div className="inner">
          <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.PAYMENTS')}: </span>
          <span className="price">{currency(payments)}</span>
        </div>
        <div className="inner credits">
          <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.CREDITS')}: </span>
          <span className="price">{currency(credits)}</span>
        </div>
        <div>
          <span className="inner">
            <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.BALANCE')}: </span>
            <span className={`price outlined ${balance === 0 ? 'success' : 'danger'}`}>
              {currency(balance)}
            </span>
          </span>
        </div>
      </div>
      <div className="right">
        <ClickAwayListener onClickAway={() => setOpenBreakdown(false)}>
          <Tooltip
            className="total-tooltip"
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  minWidth: '230px',
                  fontSize: '13px',
                },
                '.inner': {
                  display: 'flex',
                  justifyContent: 'space-between',
                },
                '.inner.bold': {
                  fontWeight: '600',
                },
                '.inner.discount': {
                  color: '#ffe5aa',
                },
                hr: {
                  backgroundColor: '#999',
                  margin: '4px 0px',
                },
              },
            }}
            placement="top-end"
            arrow
            open={openBreakdown}
            onClose={() => setOpenBreakdown(false)}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={breakdown}>
            <IconButton onClick={() => setOpenBreakdown(true)}>
              <Info />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      </div>
    </PaymentSummaryWrapper>
  );
};

export default PaymentSummary;
