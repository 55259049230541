import { Modal } from '$gcomponents/reusables';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .MuiTableHead-root {
    border-bottom: 1px solid #ccc;
  }
`;

export const ModalWrapper = styled(Modal)``;
