import React, { useEffect, useRef } from 'react';
import intl from '$intl';
import { Form, Formik } from 'formik';
import { IonContent, IonFooter } from '@ionic/react';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { SPACE } from '$gstyles';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { Modal } from '$gcomponents/reusables';
import { ADD_SERIAL_FORM } from './addSerialForm';
import ItemModel from '$fbusiness/models/item';
import { getNextIdF } from '$fbusiness/redux/factory/actions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { itemActions } from '$fbusiness/redux/item';
import { itemText } from '$gbusiness/helpers/util';

import ItemBatchModel, { initialBatch } from '$fbusiness/models/itemBatch';
import { parseISO } from 'date-fns/esm';
import { serialActions } from '$fbusiness/redux/serial';

interface AddSerialModalProps {
  item?: ItemModel;
  batch?: ItemBatchModel;
  show: boolean;
  onRefresh?: Function;
  onClose: Function;
}

const AddSerialModal: React.FC<AddSerialModalProps> = ({
  item,
  batch = initialBatch,
  show,
  onRefresh,
  onClose,
}) => {
  const dispatch = useDispatch();
  const items = useSelector((state: any) => state.item.list);
  const formRef = useRef<any>();

  const batchForm = ADD_SERIAL_FORM();

  useEffect(() => {
    if (!show) return;
    getNextIdF('item_batches').then((id) => {
      if (formRef?.current) formRef.current.setFieldValue('receiveNo', id);
    });
    if (!items.length) {
      dispatch(itemActions.fetchItemList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = (values) => {
    const { serials } = values;
    const param = {
      ...(batch && { batchId: batch?.id, itemId: batch?.item?.id }),
      ...(item && { itemId: item?.id }),
      serials: (serials || '').trim().split(/[\n,]/),
    };
    dispatch(serialActions.saveSerials(param)).then((success) => {
      if (success) {
        if (onRefresh) onRefresh();
        onClose();
      }
    });
  };

  const validateForm = (values) => {
    return input.validateError(batchForm, values);
  };

  const initialValues = {
    item,
    ...(batch.id
      ? {
          ...batch,
          ...(batch.receiveDate && { receiveDate: parseISO(batch.receiveDate) }),
          ...(batch.sellByDate && { sellByDate: parseISO(batch.sellByDate) }),
          ...(batch.expirationDate && { expirationDate: parseISO(batch.expirationDate) }),
          serials: '',
        }
      : { receiveDate: new Date() }),
  };

  const title = batch?.id ? batch?.item?.name : itemText('ADD_NEW', 'BATCH');

  return (
    <Modal width="500px" titleText={title} show={show} onClose={() => onClose()} useCustom>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <PageWrapper>
                <div>Receiving #{batch?.receiveNo || ''}</div>
                <Form>
                  <FormSection FORM={batchForm} formik={formik} />
                </Form>
              </PageWrapper>
            </IonContent>
            <IonFooter>
              <Flex padding={SPACE.MEDIUM} justifyContent="flex-end">
                <Button disabled={!(formik.isValid && formik.dirty)} onClick={formik.handleSubmit}>
                  {intl('BUTTON.SUBMIT')}
                </Button>
              </Flex>
            </IonFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default AddSerialModal;
