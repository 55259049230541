import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const REGION_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'SCREEN.REGIONS.COLS.NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 12,
        validation: [input.requiredValidation],
      },
    ],
  },
];
