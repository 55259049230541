import React from 'react';
import { COLORS } from '$gbusiness/enums';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

// import Img from '$gcomponents/primitives/img';
import CellModel from '$gbusiness/models/cell';
import { currency } from '$gbusiness/helpers/util';

export const REFUND_DETAILS_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    minWidth: 300,
    maxWidth: 800,
    cellPadding: ` 4px 8px`,
    responsive: false,
    isStripped: false,
    rowHeight: 44,
    headerHeight: 44,
  },
};

export const REFUND_DETAILS_TABLE = (): Array<CellModel> => [
  {
    label: 'SCREEN.ORDER.COLS.ITEM',
    value: 'itemName',
    className: 'main',
    component: (row) => (
      <div>
        <div>{row.itemName}</div>
        {row.modifiers?.length > 0 && (
          <div className="mod">({row.modifiers.map((i) => i.name).join(', ')})</div>
        )}
      </div>
    ),
    alwaysShow: true,
    width: 300,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.SKU',
    value: 'sku',
    align: 'center',
    alwaysShow: true,
    width: 100,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.BARCODE',
    value: 'barcode',
    align: 'center',
    alwaysShow: true,
    width: 150,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.QTY',
    value: 'qty',
    align: 'center',
    alwaysShow: true,
    width: 100,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.SUBTOTAL',
    value: 'subtotal',
    align: 'right',
    component: (row) => <div>{currency(row.subtotal)}</div>,
    className: 'desktop',
    width: 120,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.TAX',
    value: 'tax',
    align: 'right',
    component: (row) => <div>{currency(row.tax)}</div>,
    className: 'desktop',
    width: 120,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.DISCOUNT',
    value: 'tax',
    align: 'right',
    component: (row) => <div>{currency(row.totalDiscount)}</div>,
    className: 'desktop',
    width: 120,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.TOTAL',
    value: 'total',
    align: 'right',
    component: (row) => <div>{currency(row.total)}</div>,
    className: 'desktop',
    width: 120,
  },
];
