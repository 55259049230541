import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { currency, percentage } from '$gbusiness/helpers/util';
import ExportModel from '$gbusiness/models/export';
import { reportCurrency } from '$fcomponents/helpers/utils';

export const defaultReportColumns = [
  'sku',
  'barcode',
  'qty',
  'rawCost',
  'subtotal',
  'discount',
  'taxable',
  'total',
  'profit',
  'profitRate',
];

export const itemReportExportSettings = (top, columns): ExportModel => {
  const cols: any = columns.reduce((acc, c) => ((acc[c] = true), acc), {}); // eslint-disable-line no-sequences
  return {
    url: configs.api.report.item,
    method: 'POST',
    getFileName: (filter) =>
      `${top === 'top' ? 'topSellers' : top === 'bottom' ? 'bottomSellers' : 'item'}Report_${
        filter.dateStart || 'beginning'
      }-${filter.dateEnd || 'present'}`,
    deriveToModel: (row) => row,
    deriveTable: (item) => ({
      ItemName: item.itemName,
      ...(cols['sku'] && { SKU: item.sku }),
      ...(cols['barcode'] && { Barcode: item.barcode }),
      ...(cols['qty'] && { Qty: item.qty }),
      ...(cols['rawCost'] && { RawCost: currency(item.rawCost) }),
      ...(cols['subtotal'] && { Subtotal: currency(item.subtotal) }),
      ...(cols['discount'] && { Discount: currency(item.discount) }),
      ...(cols['taxable'] && { BeforeTax: currency(item.taxable) }),
      ...(cols['total'] && { AfterTax: currency(item.total) }),
      ...(cols['profit'] && { Profit: currency(item.profit) }),
      ...(cols['profitRate'] && { ProfitPercent: percentage(item.profitRate) }),
    }),
    sortKey: 'total',
    sortOrder: SORT.DSC,
  };
};

export const refundItemsExportSettings = (top, columns) => ({
  ...itemReportExportSettings(top, columns),
  fileName: 'refundedItems',
  url: configs.api.report.refund,
});

export const ITEM_REPORT_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.report.item,
    // mockData,
    method: 'POST',
    defaultSortKey: 'total',
    defaultSortOrder: SORT.DSC,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 600,
    maxWidth: 1300,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 44,
  },
};

export const REFUND_ITEM_TABLE_CONFIG: TableModel = {
  ...ITEM_REPORT_TABLE_CONFIG,
  dataSource: {
    ...ITEM_REPORT_TABLE_CONFIG.dataSource,
    endpoint: configs.api.report.refund,
  },
};

export const ITEM_REPORT_TABLE = (handleClick, isRanking = false, isRefund): Array<CellModel> => [
  {
    label: 'COMMON.RANK',
    value: 'rank',
    align: 'center',
    component: (row, action, i) => <>{i + 1}</>,
    isHidden: !isRanking,
    width: 50,
  },
  {
    label: 'SCREEN.REPORT.COLS.ITEM_NAME',
    value: 'invoice_items.itemName',
    alwaysShow: true,
    className: 'main',
    component: (row) =>
      isRefund ? (
        row.itemName
      ) : (
        <div className="link" onClick={() => handleClick(row.itemId)}>
          {row.itemName}
        </div>
      ),
    sortable: SORT.ASC,
    width: 400,
  },
  {
    label: 'SCREEN.REPORT.COLS.SKU',
    value: 'sku',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.BARCODE',
    value: 'barcode',
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.REPORT.COLS.QTY',
    value: 'qty',
    align: 'center',
    sortable: SORT.DSC,
    width: 150,
  },
  {
    label: 'SCREEN.REPORT.COLS.RAW_COST',
    value: 'rawCost',
    align: 'center',
    component: (row) => reportCurrency(row.rawCost),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.SUBTOTAL',
    value: 'subtotal',
    align: 'center',
    component: (row) => reportCurrency(row.subtotal),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.DISCOUNT',
    value: 'discount',
    align: 'center',
    component: (row) => reportCurrency(row.discount),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.TAXABLE',
    value: 'taxable',
    align: 'center',
    component: (row) => reportCurrency(row.taxable),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.AFTER_TAX',
    value: 'total',
    align: 'center',
    component: (row) => reportCurrency(row.total),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.PROFIT',
    value: 'profit',
    align: 'center',
    component: (row) => reportCurrency(row.profit),
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.PROFIT_RATE',
    value: 'profitRate',
    align: 'center',
    component: (row) => <>{percentage(row.profitRate)}</>,
    sortable: SORT.DSC,
    width: 200,
  },
];
