export const KEYS = {
  OPEN: 'OPEN',
  PAID: 'PAID',
};

export const COMMPAY_STATUS = KEYS;

const LIST = Object.keys(KEYS);

export default LIST.map((item) => ({
  label: `COMMPAY_STATUS.${item}`,
  value: item,
}));

export type COMMPAY_STATUS_TYPE = typeof LIST[number] | undefined;
