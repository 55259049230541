import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';
import { List, ListItem } from '@mui/material';

import { SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import ModifierGroupModel from '$fbusiness/models/modifierGroup';

import { ModalWrapper } from './styles';
import { itemActions } from '$fbusiness/redux/item';

interface ImportModalProps {
  modifierGroups: Array<ModifierGroupModel>;
  show: boolean;
  onClose: Function;
  onImport: Function;
}

const ImportModal: React.FC<ImportModalProps> = ({ modifierGroups, show, onClose, onImport }) => {
  const [index, setIndex] = useState(-1);
  const dispatch = useDispatch();
  const onSubmit = () => {
    const modifierGroup = modifierGroups[index];
    dispatch(
      itemActions.updateModifierGroup({
        ...modifierGroup,
        modifiers: modifierGroup.modifiers.map(m => ({
          ...m,
          id: 0,
        })),
        id: 0,
      }),
    );
    onImport();
    closeModal();
  };
  const closeModal = () => {
    setIndex(-1);
    onClose();
  };

  return (
    <ModalWrapper title="MESSAGE.SELECT_OPTIONS" show={show} onClose={closeModal} useCustom>
      <IonContent>
        <List>
          {modifierGroups.map((mg, i) => {
            const className = i === index ? 'selected' : '';
            const modifiers = mg.modifiers.filter(m => !m.deleted).map(m => m.name);
            const modifiersStr = modifiers.join(', ');
            return (
              <ListItem key={i} className={className} onClick={() => setIndex(i)}>
                <div className="item-name">{mg.itemName}</div>
                <div className="name">{mg.name}</div>
                <div className="modifiers">({modifiersStr})</div>
              </ListItem>
            );
          })}
        </List>
      </IonContent>
      <IonFooter>
        <Flex padding={SPACE.MEDIUM} justifyContent="flex-end">
          <Button fullWidth onClick={onSubmit}>
            {intl('BUTTON.IMPORT_SELECTED')}
          </Button>
        </Flex>
      </IonFooter>
    </ModalWrapper>
  );
};

export default ImportModal;
