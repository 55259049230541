import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import Header from '$components/header';

import { IonPageWrapper } from './styles';
import { TableView2 } from '$gcomponents/widgets';
import { SALESMAN_TABLE, SALESMAN_TABLE_CONFIG, salesmenExportSettings } from './salesmanTable';
import SalesmanModel, { initialSalesman } from '$fbusiness/models/salesman';
import { userActions } from '$fbusiness/redux/user';
import SalesmanEditModal from './salesmanModal';
import FactoryModel from '$fbusiness/models/factory';
import { factoryActions } from '$fbusiness/redux/factory';
import FabButton from '$fcomponents/fabButton';
import ExportButton from '$gcomponents/reusables/exportButton';

interface SalesmenScreenProps {
  onHydrate;
  factory: FactoryModel;
  salesmen: Array<SalesmanModel>;
}

const SalesmenScreen: React.FC<SalesmenScreenProps> = ({ salesmen, factory }) => {
  const [salesman, setSalesman] = useState<any>(null);
  const [filter, setFilter] = useState<any>({ forceRefresh: true });

  const { settings } = factory;
  const regionEnabled = settings?.regions || false;

  const onAddSalesman = () => {
    setSalesman(initialSalesman);
  };
  const onRowClick = (row) => {
    setSalesman(row);
  };
  const updateFilter = (newFilter = {}) => {
    setFilter({
      ...filter,
      ...newFilter,
      forceRefresh: !filter.forceRefresh,
    });
  };

  return (
    <IonPageWrapper>
      <Header title="SCREEN.SALESMEN.TITLE" />
      <IonContent>
        <ExportButton
          filter={{ storeId: 1 }}
          variant="text"
          fileName="salesmen"
          exportSetting={salesmenExportSettings}
        />
        <TableView2 tableConfig={SALESMAN_TABLE_CONFIG} filter={filter} TABLE={SALESMAN_TABLE(onRowClick)} />
      </IonContent>
      <SalesmanEditModal
        show={!!salesman}
        regions={regionEnabled ? factory.regions || [] : []}
        commRules={factory.commRules || []}
        salesman={salesman}
        onRefresh={updateFilter}
        onClose={() => setSalesman(null)}
      />
      <FabButton onClick={onAddSalesman} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
});

const mapDispatchToProps = {
  onHydrate: () => factoryActions.fetchCommRules(),
  updateUser: userActions.saveUser,
  deleteUser: userActions.deleteUser,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(SalesmenScreen));
