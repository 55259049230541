import React, { useEffect, useState } from 'react';
import { IonContent } from '@ionic/react';
import { connect } from 'react-redux';

import { screen } from '$components/hoc';
import { Header } from '$gcomponents/widgets';
import { IonPageWrapper } from '../styles';
import ExportButton from '$gcomponents/reusables/exportButton';
import { PageWrapper } from '$gstyles/wrapper';
import { reportActions } from '$fbusiness/redux/report';
import { SalesSummaryModel } from '$fbusiness/models/report';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import ReportFilter from '$fcomponents/filters/reportFilter';
import { updatedFilter } from '$fbusiness/helpers/util';
import { InfoItem } from '$gcomponents/reusables';
import { currency } from '$gbusiness/helpers/util';
import intl from '$intl';

interface SalesSummaryScreenProps {
  factory;
  history;
  summary: SalesSummaryModel;
  dateFilter;
  fetchSalesSummary;
}

const SalesSummaryScreen: React.FC<SalesSummaryScreenProps> = ({
  summary,
  fetchSalesSummary,
  dateFilter,
}) => {
  const [filter, setFilter] = useState<any>({ ...dateFilter });
  const [exportData, setExportData] = useState<Array<any>>([]);

  const updateFilter = (newFilter) => {
    setFilter(updatedFilter(filter, newFilter));
  };

  useEffect(() => {
    if (!filter) return;
    fetchSalesSummary(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (!summary) return;
    setExportData(Object.entries(summary));
  }, [summary]);

  const fileName = `salesSummary_${filter.dateStart || 'beginning'}-${filter.dateEnd || 'present'}`;

  const right = (
    <ExportButton data={exportData} color="primary" variant="text" fileName={fileName} filter={filter} />
  );

  return (
    <IonPageWrapper>
      <Header title="SCREEN.REPORT.TITLE_SUMMARY" />
      <IonContent>
        <PageWrapper>
          <FilterSection title={null}>
            <ReportFilter
              includeReportType
              excludeQuery
              parentFilter={filter}
              onSearch={updateFilter}
              placeholder="Search"
            />
          </FilterSection>
          <div className="item-list">
            <div className="right">{right}</div>
            <InfoItem label={intl('SCREEN.REPORT.COLS.SUBTOTALS')} value={currency(summary.subtotal)} />
            <InfoItem label={intl('SCREEN.REPORT.COLS.DISCOUNTS')} value={currency(summary.discounts)} />
            <InfoItem
              label={intl('SCREEN.REPORT.COLS.CREDIT_APPLIED')}
              value={currency(summary.creditApplied)}
            />
            <InfoItem label={intl('SCREEN.REPORT.COLS.TAX')} value={currency(summary.tax)} />
            <InfoItem label={intl('SCREEN.REPORT.COLS.NET_SALES')} value={currency(summary.netSales)} />
          </div>
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  summary: state.report.summary,
});

const mapDispatchToProps = {
  fetchSalesSummary: reportActions.fetchSalesSummary,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(SalesSummaryScreen));
