import React, { useState } from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { Div, SPACE } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import OrderModel from '$fbusiness/models/order';
import Footer from '$gcomponents/widgets/footer';
import InvoiceModel from '$fbusiness/models/invoice';

interface OrderUpdateDetailsProps {
  order?: OrderModel;
  invoice?: InvoiceModel;
  onSubmitOrder: Function;
  onClose: () => void;
}

const OrderUpdateDetails: React.FC<OrderUpdateDetailsProps> = ({
  order,
  invoice,
  onClose,
  onSubmitOrder,
}) => {
  const [note, setNote] = useState(order?.note || invoice?.noteFactory || '');

  if (!order && !invoice) return null;

  const onChangeNotes = (e) => {
    setNote(e.target.value);
  };

  const onSubmit = () => {
    onSubmitOrder({ id: order?.id || invoice?.id, note });
  };

  return (
    <>
      <IonContent>
        <Div className="content-wrapper">
          <Div padding={SPACE.LARGE}>
            <textarea
              onInput={onChangeNotes}
              value={note}
              className="notes"
              placeholder={intl('INPUT.PLACEHOLDER.NOTES')}
            />
          </Div>
        </Div>
      </IonContent>
      <Footer>
        <Button onClick={onClose} variant="outlined">
          {intl('BUTTON.CANCEL')}
        </Button>
        <Button onClick={onSubmit}>{intl('BUTTON.UPDATE')}</Button>
      </Footer>
    </>
  );
};

export default OrderUpdateDetails;
