import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import {
  commissionExportSettings,
  COMMPAY_PREVIEW_TABLE_CONFIG,
  COMMPAY_TABLE,
  COMMPAY_TABLE_CONFIG,
  SN,
} from './commPayTable';
import PATH from '$business/enums/paths';
import {
  generatePeriodDates,
  getClawbackRange,
  getCurrentPeriodDate,
  getPeriodReportRange,
  updatedFilter,
} from '$fbusiness/helpers/util';
import { Header, TableView2 } from '$gcomponents/widgets';
import { IonPageWrapper } from './styles';
import { IonContent } from '@ionic/react';
import { PageWrapper } from '$gstyles/wrapper';
import intl from '$gintl';
import { Button } from '$gcomponents/primitives';
import CommissionFilter from './commissionFilter';
import { factoryActions } from '$fbusiness/redux/factory';
import GeneratePayModal from './generatePayModal';
import { configs } from '$configs';
import { format } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import { deriveRawToUser } from '$gbusiness/models/user';
import ExportButton from '$gcomponents/reusables/exportButton';
import { Print } from '@mui/icons-material';
import { ExportContainer } from '../storeScreens/storesScreen/styles';
import { NUM_PREV_COMMISSION } from './commInvoicesScreen/commInvoiceTable';

const defaultCommPayFilter: any = {
  period: null,
};

interface CommissionsScreenProps {
  onHydrate;
  factory;
  periods;
  history;
  onSaveCommPay;
  match;
}

const CommissionsScreen: React.FC<CommissionsScreenProps> = ({
  periods,
  onHydrate,
  factory,
  history,
  onSaveCommPay,
  match,
}) => {
  const {
    params: { period: operiod },
  } = match;
  const [filter, setFilter] = useState<any>({ ...defaultCommPayFilter });
  const [periodOptions, setPeriodOptions] = useState<Array<any>>([]);
  const [users, setUsers] = useState<Array<any>>([]);
  const [showModal, setShowModal] = useState(false);
  const hasCommPay = filter?.id > 0;
  const periodSelected = !!filter.period;

  let reportStartDate, reportEndDate;
  if (periodSelected) {
    if (hasCommPay) {
      const period = periods.find((p) => p.periodDate === filter.period);
      reportStartDate = period.reportStartDate;
      reportEndDate = period.reportEndDate;
    } else {
      if (filter.period) [reportStartDate, reportEndDate] = getPeriodReportRange(factory, filter.period);
    }
  }

  useEffect(() => {
    if (!periods) return;
    const newPeriods = generatePeriodDates(factory, periods, NUM_PREV_COMMISSION);
    setPeriodOptions(newPeriods);
    if (!operiod) {
      const thisPeriod = getCurrentPeriodDate(factory);
      setPeriod(thisPeriod, newPeriods);
    } else {
      setPeriod(operiod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods?.length, factory?.settings]);

  useEffect(() => {
    if (!operiod) return;
    if (periodOptions) setPeriod(operiod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operiod]);

  const setPeriod = (period, pOptions = periodOptions) => {
    const index = pOptions.findIndex((p) => p.period === period);
    const currentPeriod = pOptions[index];
    const id = currentPeriod?.id || 0;
    const newFilter = {
      id,
      period,
      periodIndex: index,
      ...(!id && {}),
    };
    updateFilter(newFilter);
  };

  const onChangePeriod = (val) => {
    history.replace(PATH.COMMISSIONS + '/' + val.period);
  };

  if (!factory) return <IonPageWrapper />;

  const { settings } = factory;
  const { commPay } = settings;
  const hasCommPaySetting = commPay && commPay?.freq && commPay.lag !== undefined;

  const handleRowClick = (row, type = '') => {
    const folderPath =
      row.periodId && row.id ? `/${row.periodId}/${row.userId}` : `/0/${row.userId}/${filter.period}`;
    if (type === 'CREDIT') {
      history.push(`${PATH.COMMCREDITS}${folderPath}`);
    } else if (type === 'REFUND') {
      history.push(`${PATH.COMMREFUNDS}${folderPath}`);
    } else history.push(`${PATH.COMMINVOICES}${folderPath}`);
  };

  const updateFilter = (newFilter = {}) => {
    setFilter(updatedFilter(filter, newFilter));
  };

  const onTableLoad = (total, total2, response) => {
    if (!response?.list || users.length) return;
    setUsers(response.list.map((m) => deriveRawToUser(m.user)));
  };
  const onCloseModal = () => {
    setShowModal(false);
  };
  const onChangeUser = (val) => {
    updateFilter({ userId: val?.userId || 0 });
  };
  const onChangeStatus = async (row, status) => {
    await onSaveCommPay(row.id, { status }, configs.api.commission.payment);
    updateFilter();
  };
  const handlePayout = () => {
    setShowModal(true);
  };
  const onGeneratePayout = async (values) => {
    await onSaveCommPay(0, values, configs.api.commission.period);
    onCloseModal();
    await onHydrate();
  };

  const generateParam = (filt) => {
    if (!hasCommPaySetting) return {};
    if (!hasCommPay && !periodSelected) return {};

    const { id, periodIndex, period, ...rest } = filt;
    if (id) return { ...rest, periodId: id };
    const [dateStart, dateEnd] = getPeriodReportRange(factory, period);
    const [clawbackStartDate, clawbackEndDate] = getClawbackRange(factory, period);
    return {
      ...rest,
      dateStart,
      dateEnd,
      clawbackStartDate,
      clawbackEndDate,
    };
  };

  const paymentTable = COMMPAY_TABLE(handleRowClick, onChangeStatus, hasCommPay);

  const renderExport = (
    <ExportContainer>
      <Button variant="text" color="secondary" icon={<Print />} onClick={() => window.print()}>
        {intl('BUTTON.PRINT')}
      </Button>
      <ExportButton
        filter={generateParam(filter)}
        variant="text"
        fileName="commssion"
        exportSetting={commissionExportSettings}
      />
    </ExportContainer>
  );

  return (
    <IonPageWrapper>
      <Header title={SN + 'TITLE'} />
      <IonContent>
        <PageWrapper>
          {hasCommPaySetting ? (
            <>
              {periodOptions.length > 0 && (
                <CommissionFilter
                  hasCommPay={hasCommPay}
                  factory={factory}
                  users={users}
                  period={operiod}
                  periods={periodOptions}
                  onGeneratePayout={handlePayout}
                  onChangePeriod={onChangePeriod}
                  onChangeUser={onChangeUser}
                />
              )}
              {periodSelected && (
                <div className="summary-container">
                  {`Commissions from ${format(reportStartDate, DATE_FORMATS.READABLE)} - 
                  ${format(reportEndDate, DATE_FORMATS.READABLE)}`}
                </div>
              )}
              {hasCommPay && ( // Already paid data from commm pay
                <TableView2
                  filter={generateParam(filter)}
                  onTableLoad={onTableLoad}
                  tableConfig={COMMPAY_TABLE_CONFIG}
                  TABLE={paymentTable}>
                  {periodSelected && renderExport}
                </TableView2>
              )}
              {!hasCommPay &&
                periodSelected && ( // Not yet paid data. Preview
                  <TableView2
                    filter={generateParam(filter)}
                    onTableLoad={onTableLoad}
                    tableConfig={COMMPAY_PREVIEW_TABLE_CONFIG}
                    TABLE={paymentTable}>
                    {renderExport}
                  </TableView2>
                )}
            </>
          ) : (
            <div className="no-setting">
              <div>{intl('MESSAGE.PAYOUT_SETTING')}</div>
              <div>
                <Button onClick={() => history.push(PATH.COMMRULES)}>
                  {intl('SCREEN.COMMRULES.TITLE_PAYOUT')}
                </Button>
              </div>
            </div>
          )}
        </PageWrapper>
        <GeneratePayModal
          show={showModal}
          factory={factory}
          prevPeriod={periodOptions[(filter.index || 0) + 1] || ''}
          period={filter.period}
          onClose={onCloseModal}
          onGeneratePayout={onGeneratePayout}
        />
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  periods: state.factory.periods,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: factoryActions.fetchPeriods,
  onSaveCommPay: factoryActions.saveObj,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CommissionsScreen));
