import { flex } from '$gstyles/mixins';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .filter-section {
    .row,
    .col {
      align-items: flex-end;
    }
    button {
      max-height: 36px;
      margin-bottom: 4px;
    }
  }
`;

export const FilterWrapper = styled.div`
  position: relative;
  max-width: 1100px;

  .export-container {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;

    button.MuiButton-root {
      padding: 2px 10px;
    }
  }
`;

export const ExportContainer = styled.div`
  ${flex}
  align-items: center;
  justify-content: flex-end;
  position: relative;

  @media print {
    display: none !important;
  }
`;
