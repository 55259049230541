import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$fcomponents/hoc';
// import OptionModel from '$gbusiness/models/option';

// import { ROUTES } from '$business/enums';
import { IonPageWrapper } from './styles';
import OrderStoreScreen from './orderStoreScreen';
import DateFilterModel from '$fbusiness/models/dateFilter';

interface OrderScreenProps {
  history;
  currentState;
  dateFilter: DateFilterModel;
  match;
}

// const TABS: Array<OptionModel> = [
//   { label: 'SCREEN.ORDERS.TABS.OVERVIEW', value: '' },
//   { label: 'SCREEN.ORDERS.TABS.STORE', value: 'store' },
//   { label: 'SCREEN.ORDERS.TABS.DEPARTMENT', value: 'departments' },
// ];

const OrderScreen: React.FC<OrderScreenProps> = React.memo(
  ({ history, match, dateFilter, currentState }) => {
    // const {
    //   params: { tabIndex },
    // } = match;
    // const [currentTabIndex, setCurrentTabIndex] = useState(parseInt(tabIndex));
    // const handleRouteChange = index => {
    //   setCurrentTabIndex(index);
    //   history.replace(PATH.ORDER + '/' + index || '');
    // };
    // let component;
    // switch (currentTabIndex) {
    //   case 2:
    //     component = <OrderStoreScreen history={history} />;
    //     break;
    //   default:
    //     component = <OrderStoreScreen history={history} />;
    //     break;
    // }

    const component = (
      <OrderStoreScreen dateFilter={dateFilter} currentState={currentState} history={history} />
    );

    return (
      <IonPageWrapper>
        {/* <ItemsHeader TABS={TABS} index={currentTabIndex} onChangeIndex={handleRouteChange} /> */}
        <IonContent>{component}</IonContent>
      </IonPageWrapper>
    );
  },
  (pp, np) => true,
);

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  invoice: state.invoice.invoice,
  resetOnRoute: true,
});

const mapDispatchToProps = {};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(OrderScreen));
