import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import { percentage } from '$gbusiness/helpers/util';

export const SALESMAN_FORM = (commissions, isNew): Array<InputRowModel> => [
  {
    items: [
      {
        ...INPUT_TYPES.FIRST_NAME,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.LAST_NAME,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.EMAIL,
      },
      {
        ...INPUT_TYPES.PASSWORD,
        validation: [input.passwordValidation],
      },
      {
        label: 'SCREEN.SALESMEN.COLS.COMMISSION',
        value: 'commId',
        input: INPUT.RADIO,
        isMultiline: true,
        options: commissions.reduce(
          (acc, c) => {
            acc.push({
              labelText: `${c.name} (${percentage(c.rate)} / ${percentage(c.discountRate)})`,
              value: c.id,
            });
            return acc;
          },
          [{ labelText: 'None', value: '0' }],
        ),
        gridSize: 12,
      },
    ],
  },
];

export const SALESMAN_REGION_FORM = (regions) => [
  {
    items: [
      {
        label: 'INPUT.LABEL.REGIONS',
        value: 'regionIds',
        input: INPUT.MULTI_SELECT,
        isMultiline: true,
        options: regions.map((r) => ({
          labelText: r.name,
          value: r.id,
        })),
        gridSize: 12,
      },
    ],
  },
];
