import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { Header } from '$gcomponents/widgets';
import { userActions } from '$gbusiness/redux/user';

import { IonPageWrapper } from './styles';
import CurrentStateModel from '$fbusiness/models/currentState';
import FactoryModel from '$fbusiness/models/factory';
import StoreModel from '$fbusiness/models/store';
import { updatedFilter } from '$fbusiness/helpers/util';
import InvoiceTableView from '$components/transactionModals/invoiceTableView';
import { TRANSACTION_FILTER_LIST } from '$fbusiness/enums/options/transactionTypes';
import DateFilterModel from '$fbusiness/models/dateFilter';
import { DATE_RANGE } from '$fbusiness/enums/options/dateRange';
import CreateNewFabs from '$components/transactionModals/createNewFabs';
import { INVOICES } from '$fbusiness/enums/columns';
import { getDefaultInvoiceColumns } from '../invoicesScreen/invoicesTable';
import { OPEN_FILTER } from '$fbusiness/enums/options/openFilter';
import PATH from '$business/enums/paths';

interface TransactionsScreenProps {
  history;
  match;
  columns;
  dateFilter;
  onHydrate;
  onDehydrate;
  factory: FactoryModel;
  stores: Array<StoreModel>;
  currentState: CurrentStateModel;
}

const TransactionsScreen: React.FC<TransactionsScreenProps> = ({
  history,
  match,
  dateFilter,
  columns,
  factory,
  stores,
  currentState,
}) => {
  const { params, url } = match;
  const storeId = params?.storeId ? parseInt(params.storeId) : 0;

  const isTransaction = url.includes(PATH.TRANSACTIONS);
  const basePath = isTransaction ? PATH.TRANSACTIONS : PATH.BALANCE_DETAILS;

  const transactionsDefaultFilter: DateFilterModel = {
    dateEnd: null,
    dateStart: null,
    datePeriod: DATE_RANGE.ALL_TIME,
  };

  const [filter, setFilter] = useState<any>({
    ...dateFilter,
    tableTabIndex: 0,
    ...transactionsDefaultFilter,
    customFilter: OPEN_FILTER.ALL,
    types: TRANSACTION_FILTER_LIST,
  });

  useEffect(() => {
    const store = storeId ? stores.find((s) => s.id === storeId) : undefined;
    updateFilter({ storeId, store });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId]);

  const updateFilter = (newFilter) => {
    setFilter(updatedFilter(filter, newFilter));
  };

  const invoiceColumns = columns ? columns[INVOICES] || getDefaultInvoiceColumns(isTransaction) : [];
  // const invoiceColumns = defaultInvoiceColumns;

  const { user } = currentState;
  const titleKey = 'SCREEN.TRANSACTIONS.TITLE' + (!isTransaction ? '_BALANCE' : '');

  return (
    <IonPageWrapper>
      <Header title={titleKey} />
      <IonContent>
        {filter?.storeId === storeId && (
          <InvoiceTableView
            factory={factory}
            stores={stores}
            history={history}
            storeId={storeId}
            currentState={currentState}
            filter={filter}
            updateFilter={updateFilter}
            user={user}
            invoiceColumns={invoiceColumns}
            mode="TRANSACTION"
            basePath={basePath}
            isTransaction={isTransaction}
          />
        )}
      </IonContent>
      <CreateNewFabs factory={factory} store={filter.store} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  stores: state.store.stores,
  columns: state.localStorage.columns,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onDehydrate: userActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(TransactionsScreen));
