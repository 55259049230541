import React, { useState } from 'react';
import InvoiceDetailsModal from '$fscreens/accountScreen/invoicesScreen/invoiceDetailsModal';
import PaymentModal from '../../screens/invoicesScreen/paymentModal';
import CreditModal from '$fcomponents/creditList/creditModal';
import RefundDetailsModal from '../../screens/refundsScreen/refundDetailsModal';
import CurrentStateModel from '$fbusiness/models/currentState';
import FactoryModel from '$fbusiness/models/factory';
import { useDispatch } from 'react-redux';
import { invoiceActions } from '$fbusiness/redux/invoice';
import StoreModel from '$fbusiness/models/store';
import OrderUpdateModal from '../../screens/orderScreens/orderStoreScreen/orderUpdateModal';
import { dialog } from '$gcomponents/reusables';
import { replaceAll } from '$gbusiness/helpers/util';
import { initialCredit } from '$fbusiness/models/credit';
import InvoicePaymentGroupModel from '$fbusiness/models/invoicePaymentGroup';
import StatementChargeModal from '../../screens/invoicesScreen/statementChargeModal';

interface TransactionModalsProps {
  currentState: CurrentStateModel;
  factory: FactoryModel;
  paymentGroup: InvoicePaymentGroupModel;
  onChangePaymentGroup: Function;
  onCloseModals: Function;
  updateFilter?: Function;
  stores: Array<StoreModel>;
}

const TransactionModals = React.forwardRef<TransactionModalsProps, any>(
  (
    {
      factory,
      updateFilter = (f) => {},
      currentState,
      stores,
      paymentGroup,
      onCloseModals,
      onChangePaymentGroup,
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    React.useImperativeHandle(ref, () => ({
      currentState,
      factory,
      updateFilter,
      onCloseModals,
      stores,
      showRefund,
      showInvoice,
      showStatementCharge,
      showPayment,
      showCredit,
      emailInvoice,
      paymentGroup,
      onChangePaymentGroup,
    }));

    const [store, setStore] = useState<any>(null);
    const [credit, setCredit] = useState<any>(null);
    const [invoice, setInvoice] = useState<any>(null);
    const [refund, setRefund] = useState<any>(null);
    const [statementCharge, setStatementCharge] = useState<any>(null);

    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [showStatementChargeModal, setShowStatementChargeModal] = useState(false);

    const showRefund = async (row) => {
      setRefund(row);
      setShowRefundModal(true);
    };

    const showStatementCharge = async (invoice, store, shouldAdd = false) => {
      const isInvoiceCharge = !!invoice?.isInvoiceCharge;
      await setStore(invoice?.store || store || undefined);
      if (invoice) {
        dispatch(invoiceActions.fetchInvoice(invoice.id)).then((result) => {
          setStatementCharge({
            ...result,
            ...(shouldAdd && { id: 0, invoiceId: invoice.id }),
            ...(isInvoiceCharge && { isInvoiceCharge }),
          });
          setShowStatementChargeModal(true);
        });
      } else {
        setStatementCharge({ id: 0, storeId: store?.id });
        setShowStatementChargeModal(true);
      }
    };

    const showInvoice = async (invoice) => {
      dispatch(invoiceActions.fetchInvoice(invoice.id)).then((result) => {
        setInvoice(result);
        setShowInvoiceModal(true);
      });

      // const id = row.typeId || row.id;
      // await dispatch(invoiceActions.fetchInvoice(id)).then((invoice) => {
      //   setInvoice(deriveRawToInvoice(invoice));
      //   setShowInvoiceModal(true);
      // });
    };

    const showPayment = async (invoice, store) => {
      await setInvoice(invoice);
      await setStore(invoice?.store || store);
      setShowPaymentModal(true);
      // const id = row.invoiceId || row.id;
      // await setStore(stores.find((s) => row.storeId === s.id));
      // await dispatch(invoiceActions.fetchInvoice(id)).then((invoice) => {
      //   setInvoice(deriveRawToInvoice(invoice));
      //   setShowPaymentModal(true);
      // });
    };

    const showCredit = async (storeId, credit) => {
      await setStore(stores.find((s) => storeId === s.id));
      await setCredit({
        ...initialCredit,
        ...(credit && credit),
        storeId,
      });
    };

    const emailInvoice = async (inv) => {
      const { id, store } = inv;
      const invoiceEmail = store?.settings?.invoiceEmail || '';
      if (!invoiceEmail) {
        dialog.alert({
          title: 'MESSAGE.SORRY',
          message: 'MESSAGE.INVOICE_EMAIL_MISSING',
          key: { name: store?.name || '' },
        });
        return;
      }
      const trimmedEmail = replaceAll(invoiceEmail, { '\n': '', ' ': '' });
      dispatch(invoiceActions.emailInvoice(id, trimmedEmail));
    };

    const onCloseModal = async () => {
      await dispatch(invoiceActions.dehydrate());
      onCloseModals();
      setShowInvoiceModal(false);
      setShowUpdateModal(false);
      setShowPaymentModal(false);
      setShowRefundModal(false);
      setStatementCharge(false);
    };

    const onSubmitInvoice = async (invoice) => {
      await invoiceActions.updateInvoice({ ...invoice, note: '', noteFactory: invoice.note });
      setShowUpdateModal(false);
    };

    const onSaveCredit = () => {
      setCredit(null);
      updateFilter({});
    };

    const onSaveStatementCharge = () => {
      setStatementCharge(null);
      updateFilter({});
    };

    const onApplyRefund = async (id) => {
      await dispatch(invoiceActions.applyRefund(id));
      onCloseModal();
      updateFilter();
    };

    return (
      <>
        <InvoiceDetailsModal
          currentState={currentState}
          invoice={invoice}
          factory={factory}
          onRefresh={updateFilter}
          show={!!invoice && showInvoiceModal}
          onClose={onCloseModal}
        />
        <PaymentModal
          show={!!store && showPaymentModal}
          invoice={invoice}
          currentState={currentState}
          onChangePaymentGroup={onChangePaymentGroup}
          onRefreshInvoice={updateFilter}
          banks={factory.banks || []}
          store={store}
          stores={stores}
          paymentGroup={paymentGroup}
          onClose={onCloseModal}
        />
        <OrderUpdateModal
          invoice={invoice}
          show={invoice && showUpdateModal}
          onClose={onCloseModal}
          onSubmitOrder={onSubmitInvoice}
        />
        <CreditModal
          show={!!credit}
          credit={credit}
          store={store}
          onClose={() => setCredit(null)}
          onSaved={onSaveCredit}
        />
        <RefundDetailsModal
          currentState={currentState}
          refund={refund}
          show={!!refund && showRefundModal}
          onClose={onCloseModal}
          onApplyRefund={onApplyRefund}
        />
        <StatementChargeModal
          store={store}
          statementCharge={statementCharge}
          show={!!showStatementChargeModal && !!statementCharge}
          onClose={onCloseModal}
          factory={factory}
          stores={stores}
          onSaved={onSaveStatementCharge}
        />
      </>
    );
  },
);

export default TransactionModals;
