import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const TERM_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'SCREEN.TERMS.COLS.NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.TERMS.COLS.DISABLED',
        value: 'isDisabled',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.TERMS.COLS.DUE_DAYS',
        value: 'dueDays',
        input: INPUT.NUMBERTEXT,
        gridSize: 6,
        postfix: 'days',
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.PERCENT,
        label: 'SCREEN.TERMS.COLS.DISCOUNT_RATE',
        value: 'discountRate',
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.TERMS.COLS.DISCOUNT_DAYS',
        value: 'discountDays',
        input: INPUT.NUMBERTEXT,
        gridSize: 6,
        postfix: 'days',
      },
    ],
  },
];
