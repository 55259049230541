import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonFooter } from '@ionic/react';

import intl from '$intl';
import { screen } from '$fcomponents/hoc';
import { Div, SPACE } from '$gstyles';
import { Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import { input } from '$gbusiness/helpers';
import { Flex } from '$gstyles/wrapper';
import Header from '$components/header';
import { categoryActions } from '$fbusiness/redux/category';
import CategoryModel from '$fbusiness/models/category';

import { CATEGORY_FORM, CATEGORY_PHOTO_FORM } from './categoryForm';
import { IonPageWrapper } from './styles';
import DepartmentModel from '$fbusiness/models/department';
import { JPEG_TAG } from '$gbusiness/enums/general';
import { AppForm } from '$styles/general';
import PATH from '$business/enums/paths';
import FactoryModel from '$fbusiness/models/factory';

interface CategoryDetailsScreenProps {
  history: any;
  category: CategoryModel | null;
  factory: FactoryModel;
  departments: Array<DepartmentModel>;
  fetchCategories: Function;
  saveCategory: Function;
  isFinished: boolean;
}

const CategoryDetailsScreen: React.FC<CategoryDetailsScreenProps> = ({
  history,
  category,
  factory,
  departments,
  fetchCategories,
  saveCategory,
  isFinished,
}) => {
  useEffect(() => {
    if (!isFinished) return;
    fetchCategories();
    closeScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  const { taxes } = factory;

  if (!category) return <IonPageWrapper />;
  const { id, email, depts, createdAt, updatedAt, image, ...inititalValues } = category;
  const titleKey = id ? 'EDIT' : 'CREATE_NEW';

  const onSubmit = (values) => {
    const { image, deptId, depts, deptIds, ...newValues } = values;
    saveCategory(id, {
      ...newValues,
      ...(image?.length > 100 && { image: image.replace(JPEG_TAG, '') }),
      departments: deptIds,
    });
  };
  const closeScreen = () => {
    history.push(PATH.CATEGORIES);
  };

  const validateForm = (values) => {
    return input.validateError([...CATEGORY_FORM(departments, taxes), ...CATEGORY_PHOTO_FORM], values);
  };

  return (
    <IonPageWrapper>
      <Header titleText={intl('ITEM.' + titleKey, { item: intl('COMMON.CATEGORY') })} />
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...inititalValues,
          image: image?.fileUrl,
        }}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <AppForm>
                <Div maxWidth="500px" className="left">
                  <FormSection
                    className="form-section"
                    title="SCREEN.ITEM.CATEGORY_INFO"
                    marginBottom="0"
                    FORM={CATEGORY_FORM(departments, taxes)}
                    formik={formik}
                  />
                  <FormSection
                    title="SCREEN.ITEM.CATEGORY_INFO"
                    className="form-section"
                    marginBottom="0"
                    FORM={CATEGORY_PHOTO_FORM}
                    formik={formik}
                  />
                </Div>
              </AppForm>
            </IonContent>
            <IonFooter>
              <Flex padding={SPACE.MEDIUM} justifyContent="space-between">
                <Button variant="outlined" onClick={closeScreen}>
                  {intl('BUTTON.CANCEL')}
                </Button>
                <Button
                  disabled={!formik.isValid || !formik.dirty}
                  color="primary"
                  className="submit-button"
                  variant="contained"
                  onClick={formik.handleSubmit}>
                  {intl('BUTTON.SUBMIT')}
                </Button>
              </Flex>
            </IonFooter>
          </>
        )}
      </Formik>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  category: state.category.category,
  departments: state.department.departments,
  isFinished: state.category.isFinished,
});

// const mapDispatchToProps = dispatch => ({
//   onHydrate: params => {
//     dispatch(categoryActions.fetchCategory(parseInt(params.categoryId)));
//     dispatch(departmentActions.fetchDepartments());
//   },
//   onDehydrate: () => dispatch(categoryActions.dehydrate()),
//   saveCategory: (n, o) => dispatch(categoryActions.saveCategory(n, o)),
// });

const mapDispatchToProps = {
  onHydrate: (params) => categoryActions.fetchCategory(parseInt(params.categoryId)),
  onDehydrate: categoryActions.dehydrate,
  fetchCategories: categoryActions.fetchCategories,
  saveCategory: categoryActions.saveCategory,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CategoryDetailsScreen));
