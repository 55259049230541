import _ from 'lodash';
import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';

export const MODIFIER_FORM = (i, multiple): Array<InputRowModel> => {
  return [
    {
      items: [
        {
          label: 'INPUT.LABEL.MODIFIER_NAME',
          value: 'modifiers[' + i + '].name',
          input: INPUT.TEXT,
          gridSize: multiple ? 7 : 7,
          onChange: ({ setFieldValue }) => setFieldValue('modifiers[' + i + '].updated', true),
        },
        {
          ...INPUT_TYPES.AMOUNT,
          label: 'INPUT.LABEL.PRICE',
          value: 'modifiers[' + i + '].price',
          gridSize: multiple ? 5 : 5,
          onChange: ({ setFieldValue }) => setFieldValue('modifiers[' + i + '].updated', true),
        },
        // {
        //   isHidden: () => !multiple,
        //   label: 'INPUT.LABEL.MAX_SELECTION',
        //   labelText: 'Unlimited',
        //   shrink: true,
        //   value: 'modifiers[' + i + '].maxQuantity',
        //   input: INPUT.QUANTITY,
        //   gridSize: 3,
        //   onChange: ({ setFieldValue }) => setFieldValue('modifiers[' + i + '].updated', true),
        // },
      ],
    },
  ];
};

export const validateModifier = values => {
  return values.modifiers.reduce((acc, m, i) => {
    if (!m.name) {
      _.set(acc, `modifiers[${i}].name`, 'REQUIRED');
      return acc;
    }
    return acc;
  }, {});
};

export default MODIFIER_FORM;
