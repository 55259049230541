import React from 'react';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';
import { Form, Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';

import { SPACE } from '$gstyles';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Modal } from '$gcomponents/reusables';
import FactoryModel from '$fbusiness/models/factory';
import { DELETE_DATA_FORM } from './deleteDataForm';
import { useDispatch } from 'react-redux';
import { factoryActions } from '$fbusiness/redux/factory';

interface DeleteDataModalProps {
  factory: FactoryModel;
  show: boolean;
  onClose: Function;
}

const DeleteDataModal: React.FC<DeleteDataModalProps> = ({ factory, show, onClose }) => {
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    // warning on delete
    const param = {
      id: factory?.factoryId || 0,
      type: 'SALES',
    };
    dispatch(factoryActions.deleteData(param)).then((result) => {
      if (!result) return;
      // window.location.reload();
    });
  };

  const validateForm = (values) => input.validateError(DELETE_DATA_FORM, values);

  return (
    <Modal title="SCREEN.FACTORY.DELETE_DATA_TITLE" show={show} onClose={() => onClose()} useCustom>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <PageWrapper>
                <Form>
                  <FormSection FORM={DELETE_DATA_FORM} formik={formik} />
                </Form>
              </PageWrapper>
            </IonContent>
            <IonFooter>
              <Flex padding={SPACE.MEDIUM} justifyContent="center">
                <Button
                  color="error"
                  className="half"
                  onClick={onSubmit}
                  disabled={!formik.isValid || !formik.dirty}>
                  {intl('BUTTON.DELETE')}
                </Button>
              </Flex>
            </IonFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default DeleteDataModal;
