import React from 'react';
import intl from '$intl';
import FactoryModel from '$fbusiness/models/factory';
import StoreModel from '$fbusiness/models/store';
import ItemModel from '$fbusiness/models/item';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { input } from '$gbusiness/helpers';
import { MOCK_INVOICE_FORM } from './mockForm';
import { startOfToday, subDays } from 'date-fns';
import { DATE_RANGE } from '$fbusiness/enums/options/dateRange';
import { prepareRandomInvoice } from '$fbusiness/helpers/mock';
import { createInvoice } from '$fbusiness/redux/invoice/actions';
import { saveOrder } from '$fbusiness/redux/order/actions';
import { factoryActions } from '$fbusiness/redux/factory';
import { PageWrapper } from '$gstyles/wrapper';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import { loadingActions } from '$gbusiness/redux/loading';
import { sleep } from '$gbusiness/helpers/util';
import { convertInvoiceToOrder } from '$fbusiness/models/invoice';

interface InvoiceSectionProps {
  factory: FactoryModel;
  stores: Array<StoreModel>;
  items: Array<ItemModel>;
}

const defaultDateFilter = () => ({
  dateEnd: new Date(),
  dateStart: subDays(startOfToday(), 180),
  datePeriod: DATE_RANGE.CUSTOM,
});

const InvoiceSection: React.FC<InvoiceSectionProps> = ({ factory, stores, items }) => {
  const dispatch = useDispatch();

  const mockForm = MOCK_INVOICE_FORM(stores);
  const initialValues = {
    ...defaultDateFilter(),
    invoiceCount: 1,
    isOrder: '0',
  };

  const onSubmit = async (values) => {
    const { selectedStores, dateStart, dateEnd, invoiceCount, isOrder } = values;
    const {
      settings: { invoiceStarting },
    } = factory;

    // loop invoices
    for (let i = 0; i < invoiceCount; i++) {
      const param = prepareRandomInvoice({
        factory,
        stores,
        items,
        selectedStores,
        dateStart,
        dateEnd,
        nextInvoice: parseInt(invoiceStarting || 0) + i,
      });
      if (isOrder === '1') {
        const order = convertInvoiceToOrder(factory, param);
        await dispatch(loadingActions.loading(`Creating order ${i + 1} of ${invoiceCount}`));
        await dispatch(saveOrder(0, order));
        await sleep(100);
        await dispatch(loadingActions.loaded());
      } else {
        await dispatch(loadingActions.loading(`Creating invoice ${i + 1} of ${invoiceCount}`));
        await dispatch(createInvoice(param, false, true));
        await sleep(100);
        await dispatch(loadingActions.loaded());
      }
    }
    dispatch(factoryActions.fetchNextInvoiceNo());
  };

  const validateForm = (values) => input.validateError(mockForm, values);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <PageWrapper>
          <Form>
            <FormSection title="SCREEN.MOCK.TITLE_INVOICE" FORM={mockForm} formik={formik} marginBottom="0" />
            <Button
              className="third"
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty)}>
              {intl('SCREEN.MOCK.SUBMIT_INVOICE')}
            </Button>
          </Form>
        </PageWrapper>
      )}
    </Formik>
  );
};

export default InvoiceSection;
