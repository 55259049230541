export const reassignRank = (list, sourceIndex, destIndex, parentChanged = false) => {
  if (sourceIndex === destIndex && !parentChanged) return null;
  const direction = destIndex > sourceIndex && !parentChanged ? 'DOWN' : 'UP';

  const beforeData = direction === 'UP' ? list[destIndex - 1] : list[destIndex];
  const afterData = direction === 'UP' ? list[destIndex] : list[destIndex + 1];
  const beforeRank = beforeData?.rank || 0;
  const afterRank = afterData?.rank || null;

  const newRank = afterRank === null ? Math.round(beforeRank + 1) : (beforeRank + afterRank) / 2;
  return newRank;
};
