import {
  CLEAN_BATCH,
  CREATE_BATCH_SUCCESS,
  DELETE_BATCH_SUCCESS,
  BatchActionTypes,
  BatchReducerType,
  BATCH_INIT_STATE,
  FETCH_BATCH_SUCCESS,
  FETCH_BATCHES_SUCCESS,
  UPDATE_BATCH_SUCCESS,
} from './types';

export default function batchReducer(
  state: BatchReducerType = BATCH_INIT_STATE,
  action: BatchActionTypes,
): BatchReducerType {
  switch (action.type) {
    case FETCH_BATCH_SUCCESS:
      return {
        ...state,
        batch: action.batch,
      };
    case FETCH_BATCHES_SUCCESS:
      return {
        ...state,
        batches: action.batches,
      };
    case UPDATE_BATCH_SUCCESS:
    case CREATE_BATCH_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case DELETE_BATCH_SUCCESS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case CLEAN_BATCH:
      return {
        ...BATCH_INIT_STATE,
        batches: state.batches,
        refresh: state.refresh,
      };
    default:
      return state;
  }
}
