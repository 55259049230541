import React, { useEffect, useRef } from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';
import { Formik } from 'formik';

import { PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';

import SalesmanModel from '$fbusiness/models/salesman';
import { FormSection } from '$gcomponents/reusables';
import { SALESMAN_FORM, SALESMAN_REGION_FORM } from './salesmanForm';
import { input } from '$gbusiness/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { AppModel } from '$business/redux';
import CommRuleModel from '$fbusiness/models/commRule';
import { userActions } from '$fbusiness/redux/user';
import RegionModel from '$fbusiness/models/region';
import Footer from '$gcomponents/widgets/footer';
import { initActions } from '$business/redux/init';

interface SalesmanDetailsProps {
  onClose: Function;
  onRefresh: Function;
  salesman: SalesmanModel;
  regions: Array<RegionModel>;
  commRules: Array<CommRuleModel>;
}

const SalesmanDetails: React.FC<SalesmanDetailsProps> = ({
  salesman,
  regions,
  commRules,
  onRefresh,
  onClose,
}) => {
  const formRef = useRef<any>();
  const dispatch = useDispatch();
  const isFinished = useSelector((state: AppModel) => state.user.isFinished);

  useEffect(() => {
    if (!isFinished) return;
    dispatch(userActions.resetFinished());
    onRefresh();
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  if (!salesman) return null;

  const { userId: id, regionIds } = salesman;

  const onSubmit = async (values) => {
    const { password, ...restvalues } = values;
    await dispatch(
      userActions.saveUser(id, {
        ...restvalues,
        ...(!id && {
          storeId: 1,
        }),
        ...(password && { password }),
      }),
    );
    await dispatch(initActions.fetchMyFactory());
  };

  const salesmanForm = SALESMAN_FORM(commRules, !salesman?.userId);
  const validateForm = (values) => {
    return input.validateError(salesmanForm, values);
  };
  const { firstName, lastName, email, commission } = salesman;
  const regionEnabled = regions?.length > 0 || false;

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{
        firstName,
        lastName,
        email,
        commId: commission?.id || '0',
        regionIds,
      }}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <PageWrapper>
              <FormSection FORM={salesmanForm} formik={formik} />
              {regionEnabled && <FormSection FORM={SALESMAN_REGION_FORM(regions)} formik={formik} />}
            </PageWrapper>
          </IonContent>
          <Footer justifyContent="center">
            <Button className="half" onClick={formik.handleSubmit} disabled={!formik.isValid}>
              {intl('BUTTON.SUBMIT')}
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default SalesmanDetails;
