import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { defaultUser } from '$gbusiness/models/user';
import { SN, SNC } from './commPayTable';

export const allUsers = {
  defaultUser,
  name: 'All Sales Reps',
};

export const COMM_FILTER_FORM = ({
  periodOptions,
  onChangePeriod,
  users,
  onChangeUser,
}): Array<InputRowModel> => {
  const aUsers = [allUsers, ...users];
  return [
    {
      items: [
        {
          label: SN + 'PERIOD',
          value: 'period',
          disableFastField: true,
          input: INPUT.SELECT,
          options: periodOptions,
          onChange: ({ value }) => onChangePeriod({ period: value }),
          gridSize: 6,
        },
        ...(users?.length
          ? [
              {
                input: INPUT.AUTOCOMPLETE,
                label: SNC + 'SALES_REP',
                value: 'user',
                disableFastField: true,
                defaultValue: allUsers,
                onChange: ({ newValue }) => onChangeUser(newValue),
                gridSize: 5,
                config: {
                  optionProp: {
                    label: 'name',
                    value: 'id',
                  },
                  data: aUsers,
                },
              },
            ]
          : []),
      ],
    },
  ];
};
