import React from 'react';
import intl from '$intl';
import { IonAlert } from '@ionic/react';
import BankModel from '$fbusiness/models/bank';

interface BankInputProps {
  bank: BankModel;
  setBank: Function;
  submitBank: Function;
}

const BankInput: React.FC<BankInputProps> = ({ bank, setBank, submitBank }) => {
  return (
    <IonAlert
      isOpen={!!bank}
      onDidDismiss={() => setBank(null)}
      cssClass="bank-dialog"
      header={'Add Bank'}
      inputs={[
        {
          name: 'bank',
          type: 'text',
        },
      ]}
      buttons={[
        {
          text: intl('BUTTON.CANCEL'),
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: intl('BUTTON.OK'),
          handler: (e) => {
            submitBank(e?.bank);
          },
        },
      ]}
    />
  );
};

export default BankInput;
