import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$fcomponents/hoc';
import { Header } from '$gcomponents/widgets';

import { IonPageWrapper } from './styles';
import { PageWrapper } from '$gstyles/wrapper';
import LabelTable from './labelTable';
import { factoryActions } from '$fbusiness/redux/factory';
import { SERIAL_STATUS } from '$business/enums/enums';

interface LabelsScreenProps {
  updateLabel;
  factory;
}

const LabelsScreen: React.FC<LabelsScreenProps> = ({ factory, updateLabel }) => {
  const type = SERIAL_STATUS;
  const statuses = factory.enums[type] || [];

  const onSubmit = (enums) => {
    updateLabel({
      type,
      enums: enums.map((e) => ({ ...e, type, factoryId: factory.id })),
    });
  };

  return (
    <IonPageWrapper>
      <Header title="SCREEN.LABELS.TITLE" />
      <IonContent>
        <PageWrapper>
          <LabelTable enums={statuses} onSubmit={onSubmit} />
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  updateLabel: factoryActions.updateLabel,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(LabelsScreen));
