import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import {
  storeReport2ExportSettings,
  STORE_REPORT2_TABLE,
  STORE_REPORT2_TABLE_CONFIG,
} from './storeReport2Table';
import PATH from '$business/enums/paths';

import { IonContent } from '@ionic/react';

import { Header, TableView2 } from '$gcomponents/widgets';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import ReportFilter from '$fcomponents/filters/reportFilter';
import { updatedFilter } from '$fbusiness/helpers/util';
import { IonPageWrapper } from '../styles';
import STORE2_FILTER_FORM from './filter';
import { userActions } from '$fbusiness/redux/user';
import FactoryModel from '$fbusiness/models/factory';
import ReportSubheader from '$components/reportSubheader';
import ExportButton from '$gcomponents/reusables/exportButton';
import { Print } from '@mui/icons-material';

interface StoreReport2ScreenProps {
  factory: FactoryModel;
  history;
  dateFilter;
  stores;
  users;
}

const defaultFilter = {
  query: '',
};

const StoreReport2Screen: React.FC<StoreReport2ScreenProps> = ({
  history,
  stores: allStores,
  users: allUsers,
  factory,
  dateFilter,
}) => {
  const [filter, setFilter] = useState<any>({ ...defaultFilter, ...dateFilter });
  const [reps, setReps] = useState<any>(null);
  const [stores, setStores] = useState<any>(null);

  const { shippingMethods, terms, regions, settings } = factory;

  const updateFilter = (newFilter) => {
    setFilter(updatedFilter(filter, newFilter));
  };

  const handleRowClick = (storeId) => {
    history.push(PATH.REPORT_ITEM_DETAILS + '/0/' + storeId + '/0');
  };

  const onTableLoad = (total, newTotal, response) => {
    console.log('ON TABLE LOAD STORE', total, newTotal, response);
    // Display Summary info
  };

  const onChangeRegion = async ({ value, setFieldValue }) => {
    const region = regions.find((r) => value?.id === r.id);

    setReps(region?.salesReps || allUsers);
    const stores = allStores.filter((s) => s.regionId === value?.id);
    setStores(stores.length > 0 ? stores : allStores);
  };

  const onChangeUser = (values, value) => {
    //onSearch({ ...values, userId: value?.userId || null });
  };
  const onChangeStore = (values, value) => {
    //onSearch({ ...values, storeId: value?.id || null });
  };

  const extraFilterForm = STORE2_FILTER_FORM({
    shippingMethods,
    terms,
    regions,
    users: reps ? reps : allUsers,
    onChangeUser,
    onChangeRegion,
    settings,
  });

  const right = (
    <>
      <ExportButton
        filter={filter}
        variant="text"
        fileName="salesFilterReport"
        text="Export"
        color="primary"
        exportSetting={storeReport2ExportSettings}
      />
      <Print onClick={() => window.print()} className="pointer no-print" color="primary" />
    </>
  );

  return (
    <IonPageWrapper>
      <Header title="SCREEN.REPORT.TITLE_SALES_FILTER" />
      <IonContent>
        <TableView2
          filter={filter}
          onTableLoad={onTableLoad}
          tableConfig={STORE_REPORT2_TABLE_CONFIG}
          header={<ReportSubheader right={right} filter={filter} left={null} />}
          TABLE={STORE_REPORT2_TABLE(handleRowClick)}>
          <FilterSection title={null}>
            <ReportFilter
              onChangeStore={onChangeStore}
              stores={stores || allStores}
              excludeQuery
              extraForm={extraFilterForm}
              parentFilter={dateFilter}
              onSearch={updateFilter}
              placeholder="Search"
            />
          </FilterSection>
        </TableView2>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  stores: state.store.stores,
  users: state.user.users,
});

const mapDispatchToProps = {
  onHydrate: () => userActions.fetchUsers({ storeUser: true, storeId: 1 }),
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(StoreReport2Screen));
