import { DATE_FORMATS } from '$gbusiness/enums';
import { datetimeToString, format } from '$gbusiness/helpers/date';
import { pickRandomArr, randomInt, leadingZero } from '$gbusiness/helpers/util';
import { addDays, isFuture, parseISO } from 'date-fns';
import { CARD_TYPE } from '../enums/options/cardType';
import { INVOICE_NUMBER } from '../enums/options/invoiceNumber';
import { INVOICE_STATUS } from '../enums/options/invoiceStatus';
import { MOCK_PAYMENT } from '../enums/options/mockPayment';
import { INVOICE_PAYMENTS, PAYMENT_TYPE } from '../enums/options/paymentType';
import { generateRandomInvoice, recalculateInvoice } from '../models/invoice';
import { generateRandomSentence } from './randomGenerator';
import { getCommissionRate, getTaxRate } from './util';

export const randomDate = (start, end, format = DATE_FORMATS.DATE, allowFuture = false) => {
  let newDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  if (allowFuture && isFuture(newDate)) newDate = new Date();
  return datetimeToString(newDate, format);
};

export const randomDigits = (len = 5) => {
  const number = randomInt(0, Math.pow(10, len) - 1);
  return leadingZero(number, len);
};

export const prepareRandomInvoice = ({
  factory,
  stores,
  items,
  selectedStores,
  dateStart,
  dateEnd,
  nextInvoice,
}) => {
  const { settings, commRules } = factory;

  const storeId = pickRandomArr(selectedStores);
  const store = stores.find((s) => s.id === storeId);
  if (!store) return;
  const invoice = generateRandomInvoice(factory, store, items, nextInvoice);
  const taxRate = getTaxRate(store, factory);
  const newInvoice = recalculateInvoice(
    {
      ...invoice,
      taxRate,
    },
    { fixedQty: true, mutable: true, noTax: false, preventCombine: true },
  );

  const commission = invoice?.user?.commId ? commRules.find((c) => c.id === invoice?.user?.commId) : null;
  const finalInvoice = {
    ...newInvoice,
    commRate: getCommissionRate(newInvoice, commission),
  };

  const { user } = finalInvoice;
  const isCustomInvoice = settings.invoiceStyle === INVOICE_NUMBER.CUSTOM;
  const param = {
    ...finalInvoice,
    created_at: randomDate(dateStart, dateEnd, DATE_FORMATS.MYSQL),
    storeId,
    status: 'OPEN',
    userId: user?.userId || user?.id || 0,
    isCustomInvoice,
    id: 0,
  };
  return param;
};

export const prepareRandomPayment = (invoice, factory, credits, paymentDays, paymentOptions) => {
  const weighedPaymentOptions = paymentOptions.map((o) => {
    if (o === MOCK_PAYMENT.NO_PAYMENT) return { name: o, weight: 20 };
    if (o === MOCK_PAYMENT.PARTIAL_PAYMENT) return { name: o, weight: 30 };
    return { name: o, weight: 50 };
  });

  let param: any = {};
  const params: any = [];

  const randomPayment = pickRandomArr(weighedPaymentOptions, true);

  switch (randomPayment.name) {
    case MOCK_PAYMENT.FULL_PAYMENT:
      if (credits.length && randomInt(0, 1)) {
        param = creditPay(invoice, factory, paymentDays, credits);
        if (param.amount) {
          params.push({ ...param });
        }
      }
      params.push(
        singlePay(
          {
            ...invoice,
            ...(param?.leftover && { balance: param?.leftover }),
          },
          factory,
          paymentDays,
        ),
      );
      break;
    case MOCK_PAYMENT.PARTIAL_PAYMENT:
      params.push(singlePay(invoice, factory, paymentDays, true));
      break;
    default:
      // params.push('nothing');
      break;
  }
  return params;
};

export const creditPay = (invoice, factory, paymentDays, credits) => {
  const credit = credits.find((c) => c.amount <= invoice.balance);
  if (!credit) return { leftover: invoice.balance };
  const filled = (credit?.amount || 0) >= invoice.balance;
  const param = {
    type: INVOICE_PAYMENTS.CREDITS,
    amount: Math.min(credit.amount, invoice.balance),
    storeId: invoice.storeId,
    factoryId: factory.id,
    invoiceId: invoice.id,
    creditId: credit.id,
    bankId: 0,
    leftover: !filled ? invoice.balance - credit.amount : 0,
    paymentDate: randomDate(parseISO(invoice.createdAt), addDays(parseISO(invoice.createdAt), paymentDays)),
  };
  return param;
};

export const singlePay = (invoice, factory, paymentDays, partial = false) => {
  const paymentType = pickRandomArr([...Object.keys(PAYMENT_TYPE)]);
  let settings: any = {};
  let paymentAmount = invoice.balance;
  let bankId = 0;
  let memo = '';
  let leftover = 0;
  let paymentDate = randomDate(
    parseISO(invoice.createdAt),
    addDays(parseISO(invoice.createdAt), paymentDays),
  );

  // console.log('STOP2', paymentType);
  switch (paymentType) {
    case PAYMENT_TYPE.CASH:
      settings.refNo = randomDigits(4);
      break;
    case PAYMENT_TYPE.OTHER:
      memo = pickRandomArr(['', '', '', 'random note', generateRandomSentence(true)]);
      break;
    case PAYMENT_TYPE.CREDIT:
      settings.cardType = pickRandomArr([...Object.keys(CARD_TYPE)]);
      settings.cardNumber = randomDigits(16);
      settings.expiry = randomInt(1, 12) + '/' + randomInt(24, 29);
      break;
    case PAYMENT_TYPE.CHECK:
    // @ts-ignore
    // eslint-disable-next-line no-fallthrough
    case PAYMENT_TYPE.ACH:
      const { banks } = factory;
      const bank = pickRandomArr(banks);
      bankId = bank.id;
      settings.checkNo = randomDigits(3);
      settings.routingNo = randomDigits(9);
      settings.accountNo = randomDigits(10);
      break;
    default:
      break;
  }
  if (partial) {
    paymentAmount = randomInt(1, invoice.balance);
  } else {
    paymentAmount = invoice.balance;
    const randomNum = randomInt(0, 1);
    const hasLeftover = randomNum === 1;
    if (hasLeftover) {
      leftover = randomInt(1, 10) * 10;
    }
  }

  const param = {
    type: paymentType,
    amount: paymentAmount + leftover,
    factoryId: factory.id,
    storeId: invoice.storeId,
    invoiceId: invoice.id,
    leftover,
    bankId,
    memo,
    paymentDate,
    settings,
  };
  return param;
};

export const prepareRandomStatus = (invoice, factory, statusDays, status) => {
  const newStatus = pickRandomArr(status);
  switch (newStatus) {
    case INVOICE_STATUS.REFUNDED:
      return {
        newStatus,
        ...convertInvoiceToRefund(invoice, factory, statusDays),
      };
    default:
      return {
        newStatus,
        id: invoice.id,
      };
  }
};

export const convertInvoiceToRefund = (invoice, factory, statusDays) => {
  const {
    id,
    userId,
    storeId,
    subtotal,
    itemTaxable,
    itemTax,
    itemTotal,
    shipping,
    otherDiscount,
    totalDiscount,
    taxable,
    delivery,
    tip,
    tax,
    total,
    taxRate,
    discountRate,
    commRate,
    qtySent,
    items,
  } = invoice;
  const created_at = randomDate(
    parseISO(invoice.createdAt),
    addDays(parseISO(invoice.createdAt), statusDays),
    DATE_FORMATS.MYSQL,
  );
  const refundDate = format(created_at, DATE_FORMATS.DATE);
  return {
    factoryId: factory.id,
    invoiceId: id,
    userId,
    storeId,
    subtotal,
    itemTaxable,
    itemTax,
    itemTotal,
    shipping,
    otherDiscount,
    taxable,
    delivery,
    tip,
    tax,
    total,
    taxRate,
    discountRate,
    commRate,
    balance: total,
    totalDiscount,
    qty: qtySent,
    refundDate,
    refundedAt: created_at,
    created_at,
    items,
  };
};
