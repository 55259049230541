import MoreVertIcon from '@mui/icons-material/MoreVert';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { Button } from '@mui/material';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
// import mockData from '$business/mocks/getOrders.json';

import { deriveRawToOrder } from '$fbusiness/models/order';
import PATH from '$business/enums/paths';
import { ORDER_TABLE as HISTORY_TABLE } from '$fscreens/accountScreen/historyScreen/ordersTable';

export const ORDER_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.order.general,
    deriveToModel: deriveRawToOrder,
    method: 'GET',
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    autoRefreshInterval: 30,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 900,
    maxWidth: 1200,
    cellPadding: ` 4px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 40,
  },
};

export const ORDER_TABLE = (param): Array<CellModel> => {
  return [
    ...HISTORY_TABLE(param),
    {
      label: 'SCREEN.CATEGORIES.COLS.ACTION',
      value: '',
      align: 'center',
      className: 'action',
      component: (row, actions) => {
        return (
          <Button
            className="left"
            fullWidth
            onClick={(e) => actions.handlePopoverOpen(e, { ...row, route: PATH.CATEGORIES })}>
            <MoreVertIcon />
          </Button>
        );
      },
      width: 100,
    },
  ];
};
