import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonFooter, useIonViewWillLeave } from '@ionic/react';

import intl from '$intl';
import { screen } from '$fcomponents/hoc';
import { Div, SPACE } from '$gstyles';
import { Form, Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import { input } from '$gbusiness/helpers';
import { Flex } from '$gstyles/wrapper';
import Header from '$components/header';
import { factoryActions } from '$fbusiness/redux/factory';
import FactoryModel from '$fbusiness/models/factory';

import { FACTORY_FORM, FACTORY_FEATURES_FORM, FACTORY_LOGO_FORM } from './factoryForm';
import { IonPageWrapper } from './styles';
import { JPEG_TAG, PNG_TAG } from '$gbusiness/enums/general';
import { replaceAll } from '$gbusiness/helpers/util';
import { timestamp } from '$gbusiness/helpers/date';
import PATH from '$business/enums/paths';
import { initActions } from '$business/redux/init';
import DeleteDataModal from '../deleteDataModal';

interface FactoryDetailsScreenProps {
  history: any;
  factory: FactoryModel | null;
  myFactory: FactoryModel;
  saveFactory: Function;
  fetchMyFactory: Function;
  isFinished: boolean;
}

const FactoryDetailsScreen: React.FC<FactoryDetailsScreenProps> = ({
  history,
  factory,
  saveFactory,
  myFactory,
  fetchMyFactory,
  isFinished,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    if (isFinished) closeScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  useIonViewWillLeave(() => {});

  if (!factory) return <IonPageWrapper />;
  const { factoryId, address, updatedAt, settings, ...initialValues } = factory;
  const titleKey = factoryId ? 'EDIT' : 'CREATE_NEW';

  const onSubmit = async (values) => {
    const { formatted_place, formatted, logo, ...newValues } = values;
    await saveFactory(factoryId, {
      ...newValues,
      address: formatted,
      ...(logo?.length > 100 && { logo: replaceAll(logo, { [PNG_TAG]: '', [JPEG_TAG]: '' }) }),
      ...(formatted_place && { placeId: formatted_place.placeId }),
    });
    if (factoryId === myFactory.factoryId) await fetchMyFactory();
  };
  const closeScreen = () => {
    history.push(PATH.FACTORIES);
  };

  const factoryForm = FACTORY_FORM(null, null, true);
  const factoryFeaturesForm = FACTORY_FEATURES_FORM();

  const validateForm = (values) => {
    return input.validateError([...factoryForm, ...factoryFeaturesForm, ...FACTORY_LOGO_FORM], values);
  };

  return (
    <IonPageWrapper>
      <Header titleText={intl('ITEM.' + titleKey, { item: intl('COMMON.FACTORY') })} />
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...initialValues,
          settings: {
            ...settings,
            wholesaleEmail: settings?.wholesaleEmail || factory.email,
            retailEmail: settings?.retailEmail || factory.email,
          },
          updatedAt: timestamp(updatedAt),
        }}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <Div padding={SPACE.LARGE} maxWidth="800px">
                <Form>
                  <FormSection FORM={factoryForm} formik={formik} />
                  <FormSection
                    title="SCREEN.FACTORY.FEATURES.TITLE"
                    FORM={factoryFeaturesForm}
                    formik={formik}
                  />
                  <FormSection title="SCREEN.FACTORY.LOGO" FORM={FACTORY_LOGO_FORM} formik={formik} />
                </Form>
                <div>
                  <Button color="error" variant="contained" onClick={() => setShowDeleteModal(true)}>
                    DELETE FACTORY DATA
                  </Button>
                </div>
              </Div>

              {/* <div dangerouslySetInnerHTML={{ __html: formik?.values.settings.toc }} /> */}
            </IonContent>
            <IonFooter>
              <Flex padding={SPACE.MEDIUM} justifyContent="space-between">
                <Button variant="outlined" onClick={closeScreen}>
                  {intl('BUTTON.CANCEL')}
                </Button>
                <Button
                  disabled={!formik.isValid || !formik.dirty}
                  color="primary"
                  className="submit-button"
                  variant="contained"
                  onClick={formik.handleSubmit}>
                  {intl('BUTTON.SUBMIT')}
                </Button>
              </Flex>
              <DeleteDataModal
                factory={factory}
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
              />
            </IonFooter>
          </>
        )}
      </Formik>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  myFactory: state.factory.factory,
  factory: state.factory.hisFactory,
  isFinished: state.factory.isFinished,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: (params) => factoryActions.fetchFactory(parseInt(params.factoryId)),
  onDehydrate: factoryActions.dehydrate,
  fetchMyFactory: () => initActions.fetchMyFactory(true),
  saveFactory: factoryActions.saveFactory,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(FactoryDetailsScreen));
