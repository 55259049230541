import React from 'react';
import intl from '$intl';
import FileModel from '$fbusiness/models/file';

import { ModalWrapper } from '../styles';
import StoreModel from '$fbusiness/models/store';
import FileEditDetails from './fileEditDetails';

interface FileEditModalProps {
  file: FileModel;
  show: boolean;
  onClose: Function;
  onUpdateFile: Function;
  stores: Array<StoreModel>;
}

const FileEditModal: React.FC<FileEditModalProps> = ({ stores, file, show, onClose, onUpdateFile }) => {
  return (
    <ModalWrapper
      width="400px"
      height="360px"
      titleText={intl('ITEM.EDIT', { item: 'File' })}
      show={show}
      onClose={() => onClose()}
      useCustom>
      {file && <FileEditDetails onUpdateFile={onUpdateFile} file={file} onClose={onClose} stores={stores} />}
    </ModalWrapper>
  );
};

export default FileEditModal;
