import React from 'react';
import intl from '$intl';
import { ModalWrapper } from '../styles';
import RegionDetails from './regionDetails';
import RegionModel from '$fbusiness/models/region';

interface RegionEditModalProps {
  region: RegionModel;
  onClose: Function;
}

const RegionEditModal: React.FC<RegionEditModalProps> = ({ region, onClose }) => {
  const titleText = intl('ITEM.' + (region?.id ? 'EDIT' : 'NEW'), { item: intl('COMMON.REGION') });

  return (
    <ModalWrapper show={!!region} onClose={() => onClose()} titleText={titleText} useCustom>
      {region && <RegionDetails region={region} onClose={onClose} />}
    </ModalWrapper>
  );
};

export default RegionEditModal;
