import React from 'react';
import intl from '$intl';
import { ModalWrapper } from './styles';

import SalesmanModel from '$fbusiness/models/salesman';
import CommRuleModel from '$fbusiness/models/commRule';
import RegionModel from '$fbusiness/models/region';
import SalesmanDetails from './salesmanDetails';

interface SalesmanEditModalProps {
  show: boolean;
  onClose: Function;
  onRefresh: Function;
  salesman: SalesmanModel;
  regions: Array<RegionModel>;
  commRules: Array<CommRuleModel>;
}

const SalesmanEditModal: React.FC<SalesmanEditModalProps> = ({
  show,
  salesman,
  regions,
  commRules,
  onRefresh,
  onClose,
}) => {
  const titleText = intl('ITEM.' + (salesman?.userId ? 'EDIT' : 'NEW'), { item: intl('COMMON.SALESMAN') });

  return (
    <ModalWrapper titleText={titleText} show={show} onClose={() => onClose()} useCustom>
      {salesman && (
        <SalesmanDetails
          salesman={salesman}
          regions={regions}
          commRules={commRules}
          onClose={onClose}
          onRefresh={onRefresh}
        />
      )}
    </ModalWrapper>
  );
};

export default SalesmanEditModal;
