import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { format } from '$gbusiness/helpers/date';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { deriveRawToHistory } from '$fbusiness/models/history';
import { getFullName } from '$fbusiness/models/user';
import { isObject } from '$gbusiness/helpers/util';
import { Delete } from '@mui/icons-material';

export const HISTORY_TABLE_CONFIG = (pageSize = 20): TableModel => ({
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.history.general,
    deriveToModel: deriveRawToHistory,
    method: 'GET',
    defaultSortKey: 'created_at',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 300,
    maxWidth: 800,
    rowHeight: 24,
    cellPadding: ` 2px 4px`,
    borderColor: 'var(--ion-border-color)',
    responsive: true,
    disableSort: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
});

export const HISTORY_TABLE = ({
  showItemCol = false,
  showCreated = false,
  onDeleteInvoiceCharge,
}): Array<CellModel> => [
  ...(showItemCol
    ? [
        {
          label: 'SCREEN.HISTORY.COLS.ITEM',
          value: 'item.name',
          component: (row) => row.item?.name || '',
          alwaysShow: true,
          width: 250,
        },
      ]
    : []),
  {
    label: 'SCREEN.HISTORY.COLS.WHAT',
    value: 'title',
    component: (row) => <div className="title">{row.title || ''}</div>,
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.HISTORY.COLS.CONTENT',
    value: 'settings',
    component: (row) => {
      const renderThis = (settings) => {
        if (!settings) return <>{row.content || ''}</>;
        const isArray = !!settings?.length;
        const titleUpper = (row.title || '').toUpperCase();
        if (isArray) return settings.map((val, i) => <div key={i}>{val}</div>);
        if (row.title === 'Invoice Charge Created') {
          return (
            <>
              {Object.entries(settings).map(([key, val], i) => {
                const value: any = val;
                if (typeof value === 'object') return null;
                return (
                  <div key={i}>
                    {key}: <span className="new">{value || ''}</span>
                  </div>
                );
              })}
            </>
          );
        }
        if (settings?.new && settings?.old) {
          return (
            <>
              <span>{row.content}</span>
              {Object.entries(settings.new).map(([key, val], i) => {
                return (
                  <div key={i}>
                    {key}:<span className="old">{settings.old[key]}</span>
                    <span>→</span>
                    <span className="new">{settings.new[key]}</span>
                  </div>
                );
              })}
            </>
          );
        } else {
          // CREATED
          if ((!showCreated && titleUpper.includes('CREATED')) || !isObject(settings))
            return <>{row.content || ''}</>;

          return (
            <>
              {Object.entries(settings).map(([key, val], i) => {
                const value: any = val;
                if (typeof value === 'object') return null;
                return (
                  <div key={i}>
                    {key}: <span className="new">{value || ''}</span>
                  </div>
                );
              })}
            </>
          );
        }
      };
      return <div className="history-content">{renderThis(row.settings)}</div>;
    },
    width: 300,
  },
  {
    label: 'SCREEN.HISTORY.COLS.WHO',
    value: 'author.id',
    sortable: SORT.ASC,
    component: (row) => getFullName(row.author),
    width: 200,
  },
  {
    label: 'SCREEN.SERIAL.COLS.CREATED_AT',
    value: 'createdAt',
    component: (row) => <>{format(row.createdAt, DATE_FORMATS.DATETIME)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: '',
    value: '',
    isHidden: !onDeleteInvoiceCharge,
    component: (row) =>
      row.invoiceCharge ? (
        <Delete className="pointer" onClick={() => onDeleteInvoiceCharge(row.content)} />
      ) : null,
    width: 60,
  },
];
