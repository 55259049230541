import {
  CLEAN_TRANSACTION,
  CREATE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_SUCCESS,
  FETCH_TRANSACTION_SUCCESS,
  transactionActionTypes,
  TransactionReducerType,
  TRANSACTION_INIT_STATE,
  UPDATE_TRANSACTION_SUCCESS,
} from './types';

export default function transactionReducer(
  state: TransactionReducerType = TRANSACTION_INIT_STATE,
  action: transactionActionTypes,
): TransactionReducerType {
  switch (action.type) {
    case CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.transaction,
        isFinished: true,
      };
    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.transaction,
      };
    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case CLEAN_TRANSACTION:
      return {
        ...TRANSACTION_INIT_STATE,
        refresh: state.refresh,
      };
    default:
      return state;
  }
}
