import styled from 'styled-components';
import { Modal } from '$gcomponents/reusables';
import { FONT, SPACE, WEIGHT } from '$gstyles';

export const ModalWrapper = styled(Modal)`
  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    .item-name {
    }
    .item-name:after {
      content: '»';
      margin: 0 ${SPACE.MEDIUM};
    }
    .name {
      font-size: ${FONT.MEDIUM};
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .modifiers {
      margin-left: ${SPACE.MEDIUM};
      opacity: 0.7;
      font-size: ${FONT.SMALL};
    }
  }
  li.selected {
    color: var(--ion-color-primary-contrast-bw);
    background-color: var(--ion-color-primary);
  }
`;
