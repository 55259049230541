import React from 'react';
import intl from '$intl';

import { SubheaderWrapper } from './styles';
import { DATE_FORMATS } from '$gbusiness/enums';
import { format, today } from '$gbusiness/helpers/date';
import { Print } from '@mui/icons-material';
import { Col } from '$gstyles';
interface ReportSubheaderProps {
  filter: any;
  dateFormat?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
}

const ReportSubheader: React.FC<ReportSubheaderProps> = ({
  filter,
  left,
  right: defaultRight,
  dateFormat = DATE_FORMATS.READABLE,
}) => {
  const print = () => window.print();
  const dateStart = format(filter.dateStart, dateFormat);
  const dateEnd = format(filter.dateEnd || today(), dateFormat);

  const right = defaultRight || <Print onClick={print} className="pointer no-print" color="primary" />;

  return (
    <SubheaderWrapper>
      <Col gridSize="3" className="left flex">
        {left && left}
      </Col>
      {dateStart && dateEnd && (
        <Col gridSize="6" className="title flex">
          {dateStart === dateEnd ? dateStart : intl('SCREEN.REPORT.SUBTITLE_DATE', { dateStart, dateEnd })}
        </Col>
      )}
      {dateStart && !dateEnd && (
        <Col gridSize="6" className="title flex">
          {intl('SCREEN.REPORT.SUBTITLE_DATE_FROM', { dateStart })}
        </Col>
      )}
      {!dateStart && dateEnd && (
        <Col gridSize="6" className="title flex">
          {intl('SCREEN.REPORT.SUBTITLE_DATE_TO', { dateEnd })}
        </Col>
      )}

      <Col gridSize="3" className="right flex">
        {right && right}
      </Col>
    </SubheaderWrapper>
  );
};

export default ReportSubheader;
