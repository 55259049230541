import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';

import intl from '$intl';
import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import ModifierGroupModel from '$fbusiness/models/modifierGroup';

import { MODIFIER_GROUP_FORM } from './modifierGroupForm';
import { itemText } from '$gbusiness/helpers/util';
import { initialModifer } from '$fbusiness/models/modifier';
import { SPACE } from '$gstyles';
import { AppForm, ScreenTitle } from '$styles/general';
import { Flex } from '$gstyles/wrapper';

import { MODIFIER_FORM, validateModifier } from './modifierForm';
import { IconButton } from '@mui/material';
import { IonContent, IonFooter } from '@ionic/react';
import { itemActions } from '$fbusiness/redux/item';

interface ModifierGroupDetailsProps {
  modifierGroup: ModifierGroupModel;
  index: number;
  onClose: Function;
}

const ModifierGroupDetails: React.FC<ModifierGroupDetailsProps> = ({ modifierGroup, index, onClose }) => {
  const dispatch = useDispatch();
  const formRef = useRef<any>();
  const [modifiers, setModifiers] = useState(modifierGroup?.modifiers || []);

  if (!modifierGroup) return null;
  const { createdAt, updatedAt, ...initialValue } = modifierGroup;

  const validateForm = values => {
    const mgValidation = input.validateError(MODIFIER_GROUP_FORM, values);
    const mValidation = validateModifier(values);
    return {
      ...mgValidation,
      ...mValidation,
    };
  };
  const addModifier = () => {
    const values = formRef.current?.values;
    const newModifiers = [...values.modifiers, initialModifer];
    setModifiers(newModifiers);
    formRef.current.setValues({ ...values, modifiers: newModifiers });
  };
  const removeModifier = (mid, index) => {
    const values = formRef.current?.values;
    const newModifiers = !mid
      ? values.modifiers.filter((m, i) => i !== index)
      : values.modifiers.map((m, i) => (i === index ? { ...m, deleted: true } : m));
    setModifiers(newModifiers);
    formRef.current.setValues({ ...values, modifiers: newModifiers });
  };
  const onSubmit = values => {
    dispatch(
      itemActions.updateModifierGroup(
        {
          ...values,
          id: modifierGroup.id,
          updated: true,
        },
        index,
      ),
    );
    onClose(true);
  };

  if (!modifiers) {
    addModifier();
  }

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValue}
      validate={validateForm}
      onSubmit={values => {
        onSubmit(values);
      }}>
      {formik => (
        <>
          <IonContent>
            <AppForm>
              <FormSection FORM={MODIFIER_GROUP_FORM} formik={formik} />
              <div className="modifiers-section">
                <ScreenTitle size="2.0rem">{intl('COMMON.OPTIONS')}</ScreenTitle>
                {modifiers.map((modifier, i) => {
                  if (modifier.deleted) return null;
                  return (
                    <Flex key={i} position="relative">
                      <FormSection
                        marginBottom={SPACE.DEF}
                        FORM={MODIFIER_FORM(i, formik.values?.multiple)}
                        formik={formik}
                      />
                      <IconButton onClick={() => removeModifier(modifier?.id, i)}>
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </Flex>
                  );
                })}
                <Button variant="text" startIcon={<AddIcon />} onClick={addModifier}>
                  {itemText('add', 'option')}
                </Button>
              </div>
            </AppForm>
          </IonContent>
          <IonFooter>
            <Flex padding={SPACE.MEDIUM} justifyContent="flex-end">
              <Button
                disabled={!formik.isValid || !formik.dirty || !formik.values.modifiers.length}
                onClick={formik.handleSubmit}>
                {intl('BUTTON.SUBMIT')}
              </Button>
            </Flex>
          </IonFooter>
        </>
      )}
    </Formik>
  );
};

export default ModifierGroupDetails;
