import React, { useState, useRef, useEffect } from 'react';

import { input } from '$gbusiness/helpers';
import { dialog, FormSection } from '$gcomponents/reusables';
import { Button, HeaderTitle, Text } from '$gcomponents/primitives';
import CategoryModel from '$fbusiness/models/category';
import Footer from '$gcomponents/widgets/footer';
import { PageWrapper } from '$gstyles/wrapper';
import { JPEG_TAG } from '$gbusiness/enums/general';
import intl from '$gintl';

import { CATEGORY_PHOTO_FORM } from '../../inventoryScreen/categories/categoryForm';
import CategoryTreeInput from '../categoryTreeInput';
import { IonContentWrapper } from './styles';
import MULTI_CATEGORY_FORM from './multiCategoryForm';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { categoryActions } from '$fbusiness/redux/category';
import { AppModel } from '$business/redux/';
import FactoryModel from '$fbusiness/models/factory';
import { toNumber } from '$gbusiness/helpers/util';

interface CategoryDetailsProps {
  category: CategoryModel;
  categories: Array<CategoryModel>;
  factory: FactoryModel;
  onClose: Function;
}

const CategoryDetails: React.FC<CategoryDetailsProps> = ({ category, factory, categories, onClose }) => {
  const isFinished = useSelector((state: AppModel) => state.category.isFinished);
  const [checked, setChecked] = useState<Array<number>>(category.parentId ? [category.parentId] : []);
  const dispatch = useDispatch();
  const formRef = useRef<any>();

  const { taxes } = factory;

  const onSubmit = async (values) => {
    const { image, taxId, ...newValues } = values;

    await dispatch(
      categoryActions.saveCategory(category.id, {
        ...newValues,
        taxId: toNumber(taxId) > 1 ? taxId : null,
        parentId: checked.length ? checked[0] : 0,
        ...(image?.length > 100 && { image: image.replace(JPEG_TAG, '') }),
      }),
    );
    await dispatch(categoryActions.fetchCategories());
  };

  const handleSubmit = async (values) => {
    if (toNumber(values?.taxId) > 0) {
      dialog.confirm({
        message: 'MESSAGE.APPLY_TAX_CATEGORY',
        onPress: async () => {
          await onSubmit(values);
        },
      });
    } else onSubmit(values);
  };

  useEffect(() => {
    if (!isFinished) return;
    dispatch(categoryActions.dehydrate());
    onClose();
  }, [isFinished, onClose, dispatch]);

  const categoryForm = MULTI_CATEGORY_FORM(categories, taxes);
  const validateForm = (values) => {
    return input.validateError(categoryForm, values);
  };

  const handleCategoryInput = (id) => {
    if (checked.includes(id)) setChecked([]);
    else setChecked([id]);
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{
        name: category?.name,
        image: category.image?.fileUrl,
        taxId: category.taxId || '0',
      }}
      validate={validateForm}
      onSubmit={(values) => {
        handleSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContentWrapper>
            <PageWrapper>
              <FormSection formik={formik} FORM={categoryForm} />

              <HeaderTitle>
                <div>{intl('SCREEN.ITEM.PARENT')}</div>
              </HeaderTitle>
              {categories.map((cat) => (
                <CategoryTreeInput
                  key={cat.id}
                  level={0}
                  category={cat}
                  checked={checked}
                  onChange={handleCategoryInput}
                />
              ))}
              <FormSection
                title="SCREEN.ITEM.CATEGORY_PHOTO"
                className="form-section"
                FORM={CATEGORY_PHOTO_FORM}
                formik={formik}
              />
            </PageWrapper>
          </IonContentWrapper>
          <Footer justifyContent="center">
            <Button className="half" onClick={formik.handleSubmit} disabled={!formik.isValid}>
              <Text k="BUTTON.SUBMIT" />
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default CategoryDetails;
