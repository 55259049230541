import React from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { PageWrapper } from '$gstyles/wrapper';
import { dialog, Modal } from '$gcomponents/reusables';

import { Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';

import { GENERATE_PAY_FORM } from './generatePayForm';
import { SN } from './commPayTable';
import Footer from '$gcomponents/widgets/footer';
import FactoryModel from '$fbusiness/models/factory';
import { getPayDate, getPeriodRange, getPeriodReportRange } from '$fbusiness/helpers/util';
import { parseISO } from 'date-fns';

interface GeneratePayModalProps {
  show: boolean;
  factory: FactoryModel;
  onClose: Function;
  onGeneratePayout;
  period: string;
  prevPeriod: any;
}

const GeneratePayModal: React.FC<GeneratePayModalProps> = ({
  show,
  factory,
  period,
  onClose,
  prevPeriod,
  onGeneratePayout,
}) => {
  const onSubmit = (values) => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.CONFIRM_PAYOUT',
      onPress: async () => {
        await onGeneratePayout(values);
      },
    });
  };
  const payDate = getPayDate(factory, period);
  const payForm = GENERATE_PAY_FORM();
  const validateForm = (values) => input.validateError(payForm, values);

  const [periodStart, periodEnd] = getPeriodRange(factory, period);

  const renderForm = () => {
    const [reportStartDate, reportEndDate] = getPeriodReportRange(factory, period);
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          payDate,
          periodDate: period || periodEnd,
          periodStartDate: periodStart,
          reportStartDate: parseISO(reportStartDate),
          reportEndDate: parseISO(reportEndDate),
        }}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => {
          return (
            <>
              <IonContent>
                <PageWrapper>
                  <FormSection FORM={payForm} formik={formik} />
                </PageWrapper>
              </IonContent>
              <Footer justifyContent="space-around">
                <Button onClick={() => onClose()} variant="outlined">
                  {intl('BUTTON.CANCEL')}
                </Button>
                <Button className="half" onClick={formik.submitForm}>
                  {intl('BUTTON.SUBMIT')}
                </Button>
              </Footer>
            </>
          );
        }}
      </Formik>
    );
  };

  return (
    <Modal title={SN + 'TITLE_GENERATE'} show={show} onClose={() => onClose()} useCustom>
      {show && renderForm()}
    </Modal>
  );
};

export default GeneratePayModal;
