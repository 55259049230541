import React from 'react';
import intl from '$intl';

import { currency } from '$gbusiness/helpers/util';

import { PaymentSummaryWrapper } from './styles';

interface MultiInvoiceSummaryrops {
  invoices;
}

const MultiInvoiceSummary: React.FC<MultiInvoiceSummaryrops> = ({ invoices }) => {
  const sumTotal = invoices.reduce(
    (acc, i) => {
      return {
        total: acc.total + i.totals.originalTotal,
        balance: acc.balance + i.totals.balance,
        consumed: acc.consumed + (i.newPayment?.amount || 0),
      };
    },
    { total: 0, balance: 0, consumed: 0 },
  );
  const { total, balance, consumed } = sumTotal;

  return (
    <PaymentSummaryWrapper className="multi-invoice">
      <div className="outer">
        <div className="inner">
          <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.SUM_TOTAL')}: </span>
          <span className="">{currency(total)}</span>
        </div>

        <div>
          <span className="inner">
            <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.SUM_BALANCE')}: </span>
            <span className={` outlined`}>{currency(balance)}</span>
          </span>
        </div>

        <div>
          <span className="inner">
            <span className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.SUM_CONSUMED')}: </span>
            <span className={`success outlined`}>{currency(consumed)}</span>
          </span>
        </div>
      </div>
    </PaymentSummaryWrapper>
  );
};

export default MultiInvoiceSummary;
