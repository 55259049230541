import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import { insertEmptyOption } from '$gbusiness/helpers/input';

export const MULTI_CATEGORY_FORM = (categories, taxes): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'INPUT.LABEL.CATEGORY_NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 12,
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    items: [
      {
        label: 'INPUT.LABEL.NONE_TAXABLE',
        value: 'taxId',
        input: INPUT.RADIO,
        options: insertEmptyOption(
          (taxes || []).map((t) => ({
            labelText: t.name,
            value: t.id,
          })),
          'None',
        ),
        gridSize: 12,
        validation: [input.requiredValidation],
      },
    ],
  },
];

export default MULTI_CATEGORY_FORM;
