import React from 'react';
import { Formik } from 'formik';
import { FilterWrapper } from './styles';
import { allUsers, COMM_FILTER_FORM } from './commFilterForm';
import { FormSection } from '$gcomponents/reusables';
import { getCurrentPeriodDate } from '$fbusiness/helpers/util';
import FactoryModel from '$fbusiness/models/factory';
import { Button } from '$gcomponents/primitives';
import intl from '$gintl';
import { format, today } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import UserModel from '$gbusiness/models/user';

interface CommissionFilterProps {
  factory: FactoryModel;
  onChangePeriod: Function;
  onChangeUser?: Function;
  user?: UserModel;
  users?: Array<UserModel>;
  hasCommPay: boolean;
  onGeneratePayout: Function;
  periods: Array<any>;
  excludePayoutButton?: boolean;
  period?: any;
  maxWidth?: number;
  parentFilter?: any;
}

const CommissionFilter: React.FC<CommissionFilterProps> = ({
  factory,
  users,
  user,
  period,
  parentFilter = {},
  periods,
  maxWidth = 1000,
  hasCommPay = false,
  onChangePeriod,
  excludePayoutButton = false,
  onChangeUser,
  onGeneratePayout,
}) => {
  if (!periods.length) return null;
  const initialValues = {
    ...parentFilter,
    user: user || allUsers,
    period: period || getCurrentPeriodDate(factory),
  };

  const todayDate = today();

  const periodOptions = periods.map((o) => ({
    labelText: `Pay Date ${format(o.payDate, DATE_FORMATS.SLASH)} (${format(
      o.range[0],
      DATE_FORMATS.MONTH_DAY,
    )} - ${format(o.range[1], DATE_FORMATS.MONTH_DAY)} ${!o.id ? ') *' : ')'}`,
    value: o.period,
    count: o.id || 0,
  }));
  const filterForm = COMM_FILTER_FORM({ periodOptions, onChangePeriod, users, onChangeUser });

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => {}}>
      {(formik) => {
        const period = formik.values.period;
        return (
          <FilterWrapper maxWidth={maxWidth}>
            <FormSection FORM={filterForm} formik={formik} marginBottom="10px" />
            {!hasCommPay && !excludePayoutButton && period <= todayDate && (
              <Button className="light filled o80" size="small" onClick={() => onGeneratePayout()}>
                {intl('BUTTON.GENERATE_PAYOUT')}
              </Button>
            )}
          </FilterWrapper>
        );
      }}
    </Formik>
  );
};

export default CommissionFilter;
