import React, { useEffect, useState } from 'react';
import intl from '$intl';
import StoreModel from '$fbusiness/models/store';
import { IonContent, IonFab, IonHeader, IonToolbar } from '@ionic/react';
import { IonMenuWrapper } from './styles';
import { useSelector } from 'react-redux';
import STORES_LIST, { STORES_LIST_CONFIG } from './storesList';
import { IconButton, TextField } from '@mui/material';
import { itemText } from '$gbusiness/helpers/util';
import { useHistory } from 'react-router';
import { TableView2 } from '$gcomponents/widgets';
import CurrentStateModel from '$fbusiness/models/currentState';
import { ArrowBackIos, ArrowForwardIos, Close } from '@mui/icons-material';
import { getEl } from '$gbusiness/helpers/input';
import PATH from '$business/enums/paths';

interface StorePanInterface {
  route: string;
  currentState: CurrentStateModel;
}

const targetRoutes = [PATH.INVOICES, PATH.TRANSACTIONS, PATH.BALANCE_DETAILS];

const StorePane: React.FC<StorePanInterface> = ({ route, currentState }) => {
  const targetRoute = targetRoutes.find((r) => route.includes(r)) || '';
  const targetRouteSlash = targetRoute ? targetRoute + '/' : '';
  const shouldDisablePane = !targetRoute || !currentState.settings.storePane;
  const storeParam = route.includes(targetRouteSlash) ? route.replace(targetRouteSlash, '') : '0';

  const [query, setQuery] = useState('');
  const [hidePane, setHidePane] = useState(true);
  const [storeId, setStoreId] = useState(0);
  const history = useHistory();
  const stores: Array<StoreModel> = useSelector((state: any) => state.store.stores);

  useEffect(() => {
    setStoreId(parseInt(storeParam) || 0);
  }, [storeParam]);

  const onSelectStore = (row, isOpenBalance = false) => {
    if (storeId === row.id && !isOpenBalance) {
      history.replace(targetRouteSlash);
      return;
    }
    if (targetRoute === PATH.INVOICES) {
      history.replace(targetRouteSlash + row.id + (isOpenBalance ? '/1' : '/0'));
    }
    history.replace(row.id ? targetRouteSlash + row.id : targetRoute);
  };

  const filterStore = (store) => {
    const { name, formatted, email, phone } = store;
    if ((name || '').toLowerCase().includes(query.toLowerCase())) return true;
    if ((formatted || '').toLowerCase().includes(query.toLowerCase())) return true;
    if ((email || '').toLowerCase().includes(query.toLowerCase())) return true;
    if ((phone || '').includes(query)) return true;
    return false;
  };

  const filtered = query ? stores.filter(filterStore) : stores;

  const inputProps = {
    endAdornment: (
      <IconButton
        className="close-icon"
        onClick={() => {
          setQuery('');
          let closeButton;
          if (targetRoutes.includes(targetRoute)) {
            closeButton = getEl('.invoices-table .store-selector .MuiAutocomplete-clearIndicator');
          }
          if (closeButton) closeButton.click();
        }}>
        <Close />
      </IconButton>
    ),
  };

  return (
    <IonMenuWrapper
      className="no-print"
      hide={hidePane}
      side="end"
      disabled={shouldDisablePane}
      contentId="main"
      menuId="end">
      <IonHeader>
        <IonToolbar>
          <div className="store-filter-wrapper">
            <TextField
              InputProps={inputProps}
              onChange={(e) => setQuery(e.target.value)}
              size="small"
              value={query}
              placeholder={itemText('Search', intl('COMMON.STORE'))}
            />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <TableView2
          className="list-wrapper"
          tableConfig={STORES_LIST_CONFIG(storeId)}
          TABLE={STORES_LIST(onSelectStore)}
          data={filtered}
        />
      </IonContent>
      <IonFab horizontal="end" vertical="center">
        {hidePane ? (
          <div className="arrow-hide" onClick={() => setHidePane(false)}>
            <ArrowBackIos />
          </div>
        ) : (
          <div className="arrow-hide" onClick={() => setHidePane(true)}>
            <ArrowForwardIos />
          </div>
        )}
      </IonFab>
    </IonMenuWrapper>
  );
};

export default StorePane;
