import styled from 'styled-components';
import { Modal } from '$gcomponents/reusables';
import { SPACE } from '$gstyles';
import { IonContent } from '@ionic/react';

export const ModalWrapper = styled(Modal)`
  .content {
  }
`;

export const IonContentWrapper = styled(IonContent)`
  .form-section {
    margin-top: ${SPACE.XLARGE};
  }
`;
