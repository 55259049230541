import styled from 'styled-components';
import { FONT, SPACE, WEIGHT } from '$gstyles';
import { Modal } from '$gcomponents/reusables';
import { IonPage } from '@ionic/react';
import { mobileStyle } from '$gstyles/utils';

export const IonPageWrapper = styled(IonPage)`
  .MuiTab-root {
    background-color: #f6f6f6;
  }
  .title {
    margin: ${SPACE.LARGE};
    margin-bottom: 0;
    font-weight: ${WEIGHT.SEMI_BOLD};
    font-size: ${FONT.LARGE};
  }

  .report-filter {
    max-width: 1200px;
  }
  .inner {
    padding: 2px 6px;
    border-radius: 4px;
    display: flex;
    line-height: 14px;
    align-items: center;
    justify-content: space-between;
    &.credits,
    &.discount {
      background-color: #ffe5aa;
    }
    .refund {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  --height: 740px;
  --max-height: 100vh;
  #ion-react-wrapper {
    height: min(800px, 100vh);
  }
  .payment-footer {
    min-height: 92px;
    > div {
      height: 100%;
    }
    div.multi-invoice {
      font-size: 1.2em;
    }
  }
  .payment-state {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    bottom: 4px;
    height: 46px;
  }
  .leftover {
    font-size: ${FONT.SMALL};
    display: flex;
    justify-content: flex-end;
    span {
      font-weight: ${WEIGHT.SEMI_BOLD};
      margin-left: ${SPACE.SMALL};
    }
    svg {
      margin-right: 4px;
    }
  }
  .consumed {
    margin-left: 8px;
  }
  .fullscreen {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .payment-table {
    flex: 1;
    padding-top: 0;
    .table-wrapper {
      /* overflow: auto; */
    }
    
    .disabled {
      opacity: 0.3;
      background-color: transparent;
    }

    .MuiTableRow-root
      .selected {
        background-color: #ffff00;
      }
      .new-item {
        background-color: #ffff00;
        .MuiTableCell-root {
          font-weight: ${WEIGHT.BOLD};
        }
      }
    }
    
    .MuiTableHead-root {
      border-bottom: 1px solid var(--ion-border-color);
    }
    .MuiTableCell-head {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .small {
      font-size: ${FONT.SMALL};
    }

    .MuiTableRow-root.selected {
      background-color: transparent;
    }
    .MuiTableRow-root.active {
      background-color: #cceeff;
    }
    .sticky-header.table-wrapper
      > .virtualized-table
      > .MuiTableHead-root
      .MuiTableRow-head.MuiTableRow-head.virtualized-row {
      width: calc(100% - 32px);
    }
    .multi-payment-input {
      width: 100px;
      background: #ffffdd;
    }
  }

  .form-container {
    padding-bottom: 0;
  }
  .payment-types {
    justify-content: space-between;
    svg {
      margin-right: 4px;
    }
    button {
      text-transform: initial;
      max-width: 136px;
      line-height: 15px;
    }
    .discounts {
      text-align: right;
    }
  }
  .apply-button {
    margin-left: auto;
    margin-right: auto;
  }
  .discount-button-wrapper {
    text-align: center;
    .discount-button {
      width: 190px;
    }
    .list-item {
      padding: 0;
      padding-top: ${SPACE.SMALL};
      font-size: 1.3rem;
    }
  }

  .credit-list {
    .list-item {
      padding-left: 0;
      padding-right: 30px;
    }
  }
  .discount-row {
    .MuiTableCell-root {
      /* background-color: #ffe5aa; */
    }
  }

  .form-section {
    min-height: 150px;
    margin: ${SPACE.MEDIUM} 0;
    .bank-buttons {
      display: flex;
      align-items: flex-start;
      margin-top: 18px;
      button {
        height: 34px;
        margin-left: 4px;
        padding-right: 10px;
        padding-left: 10px;
      }
      button:first-child {
        margin-left: 10px;
      }
    }
  }
  .discount-selection {
    position: absolute;
    .MuiNativeSelect-root {
      margin: 0 ${SPACE.MEDIUM};
      select {
        text-align: center;
      }
    }
  }
  .extra-line {
    min-height: 72px;
  }
  .credits-section {
    text-align: right;
    min-width: 360px;
    line-height: 14px;
    margin-top: -10px;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: flex-end;

    right: ${SPACE.LARGE};
    .balance {
      margin-right: ${SPACE.LARGE};
    }
    .view-credit {
      margin-right: 10px;
    }
    .credits {
      font-size: 0.9em;
      span {
        font-weight: ${WEIGHT.SEMI_BOLD};
        font-size: ${FONT.SMALL};
      }
    }
  }
  .empty-form {
    height: 60px;
  }
  .main-table-wrapper {
    h4 {
      margin-top: 0;
    }
  }
  .add-button-wrapper {
    position: relative;
    .add-button {
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 100px;
    }
  }

  ${mobileStyle(`
    .payment-table {
      height: auto;
      overflow-y: hidden;
    }
    .payment-types {
      .discounts {
        text-align: left;
      }
    }
    .fullscreen {
      height: auto;
  `)}
`;

export const InnerModalWrapper = styled(Modal)`
  --backdrop-opacity: 0.2 !important;
  --height: calc(600px - 30px);
  --width: calc(800px - 30px);
  .credit-list {
    .list-item {
      padding-left: 0;
    }
    font-size: 16px;
    font-weight: 600;
    max-width: 320px;
  }
  .credit-fill {
    margin-left: 8px;
    margin-top: 16px;
  }
  .remaining-button {
    margin-top: -28px;
    margin-bottom: 16px;
    &.danger {
      margin-left: ${SPACE.LARGE};
    }
  }
  .MuiToggleButton-root {
    text-transform: initial;
    font-weight: ${WEIGHT.SEMI_BOLD};
    border-color: #999;
    .MuiSvgIcon-root {
      margin-right: ${SPACE.MEDIUM};
    }
  }
  .discount-selection {
    .MuiNativeSelect-root {
      margin: 0 ${SPACE.MEDIUM};
      select {
        text-align: center;
      }
    }
  }
`;

export const PaymentSummaryWrapper = styled.div`
  display: flex;
  font-size: 0.9em;
  .list-price {
    text-decoration: line-through;
  }
  .discount-price {
    font-size: ${FONT.MEDIUM};
    color: var(--ion-color-primary);
  }
  .outer {
    padding: 2px;
    flex: 1;
  }
  .right {
    width: 24px;
  }
  .label {
    min-width: 60px;
    display: inline-block;
  }
  .inner {
    padding: 2px 6px;
    border-radius: 4px;
    display: flex;
    line-height: 14px;
    align-items: center;
    justify-content: space-between;
    &.credits,
    &.discount {
      background-color: #ffe5aa;
    }
  }
  .price {
    font-weight: ${WEIGHT.SEMI_BOLD};
    font-size: ${FONT.SMALL};
  }
  .total-tooltip {
    position: absolute;
    top: 0;
    padding: 0;
    z-index: 1;
  }
`;
