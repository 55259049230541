import React, { useEffect, useState } from 'react';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';

import { SPACE } from '$gstyles';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { useDispatch } from 'react-redux';
import { serialActions } from '$fbusiness/redux/serial';
import { SerialSelectModalWrapper } from './styles';
import SerialSelector from './serialSelector';
import SerialModal from './serialModal';

interface SerialSelectModalProps {
  show: boolean;
  onClose: Function;
  onSubmit: Function;
  item: any;
}

const SerialSelectModal: React.FC<SerialSelectModalProps> = ({ show, item, onClose, onSubmit }) => {
  const itemId = item?.itemId;
  const dispatch = useDispatch();
  const [serials, setSerials] = useState<Array<any>>([]);
  const [loaded, setLoaded] = useState(false);
  const maxQty = Math.max(item?.qtySent || 1, item?.requireSerial ? serials.length : 0);
  const titleText = item ? `${item.itemName} ${`(Max Qty: ${maxQty})`}` : 'Select Serials';

  const selections = serials.filter((s) => s.selected);

  useEffect(() => {
    if (!show) return;
    fetchSerials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const fetchSerials = () => {
    dispatch(serialActions.fetchSerials(itemId, 1, ['', 'NEW', 'RETURNED'], item?.id)).then((list) => {
      if (item.serials) {
        const newSerials = list.map((s) => (item.serials.includes(s.serial) ? { ...s, selected: 1 } : s));
        setSerials(newSerials);
        setLoaded(true);
      } else {
        setSerials(list);
        setLoaded(true);
      }
    });
  };
  const onChangeSerials = (newSerials) => {
    setSerials(newSerials);
  };

  const onFinish = () => {
    onSubmit(
      selections.map((s) => s.serial),
      item.requireSerial,
    );
  };

  const closeModal = () => {
    setLoaded(false);
    onClose();
  };

  return (
    <>
      <SerialSelectModalWrapper
        titleText={titleText}
        show={loaded && show && serials?.length > 0}
        onClose={closeModal}
        useCustom>
        <IonContent scrollY={false}>
          <PageWrapper className="full-height">
            <SerialSelector
              serials={serials}
              selections={selections}
              maxQty={maxQty}
              onChangeSerials={onChangeSerials}
            />
          </PageWrapper>
        </IonContent>
        <IonFooter>
          <Flex padding={SPACE.MEDIUM} justifyContent="space-around">
            <Button variant="outlined" onClick={closeModal}>
              {intl('BUTTON.CANCEL')}
            </Button>
            <Button className="third" onClick={onFinish}>
              {intl('BUTTON.OK')}
            </Button>
          </Flex>
        </IonFooter>
      </SerialSelectModalWrapper>

      <SerialModal
        item={{ ...item, id: item?.itemId, name: item?.itemName }}
        show={loaded && show && !serials?.length}
        onRefresh={fetchSerials}
        onClose={closeModal}
      />
    </>
  );
};

export default SerialSelectModal;
