import styled from 'styled-components';
import { Div } from '$gstyles';
import { IconButton } from '@mui/material';

export const IconInputWrapper = styled(Div)`
  .MuiOutlinedInput-root {
    background-color: var(--ion-color-secondary);
    padding-right: initial;
    padding-left: initial;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }
  .MuiInputAdornment-root,
  .MuiIconButton-root {
    color: var(--ion-color-secondary-contrast-bw);
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0px;
  }
  .MuiInputAdornment-positionStart {
    margin-right: 0px;
  }
  .MuiOutlinedInput-inputAdornedStart {
    padding-left: 10px;
  }
  .MuiOutlinedInput-inputAdornedEnd {
    padding-right: 0px;
  }
  input {
    background-color: var(--ion-background-color);
  }
`;

export const InnerAdornment = styled(IconButton)`
  &&.MuiButtonBase-root {
    background-color: var(--ion-color-light);
    color: var(--ion-text-color);
    padding: 10px 4px;
    border-radius: 0;
  }
`;

export const OuterAdornment = styled(IconButton)``;
