import TransactionModel from '../../models/transaction';

export const TRANSACTION_FAILURE = 'TRANSACTION_FAILURE';
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CLEAN_TRANSACTION = 'CLEAN_TRANSACTION';

// Action Types
export interface typeOnlyType {
  type: typeof UPDATE_TRANSACTION_SUCCESS | typeof CLEAN_TRANSACTION | typeof DELETE_TRANSACTION_SUCCESS;
}

export interface failureType {
  type: typeof TRANSACTION_FAILURE;
  err: string;
}

export interface FetchTransactionSuccessType {
  type: typeof FETCH_TRANSACTION_SUCCESS | typeof CREATE_TRANSACTION_SUCCESS;
  transaction: TransactionModel;
}

export interface TransactionReducerType {
  transaction: TransactionModel | null;
  isFinished: boolean;
  refresh: boolean;
}

export type transactionActionTypes = typeOnlyType | failureType | FetchTransactionSuccessType;

export const TRANSACTION_INIT_STATE: TransactionReducerType = {
  transaction: null,
  isFinished: false,
  refresh: true,
};
