import React from 'react';
import CategoryModel from '$fbusiness/models/category';
import { Checkbox, FormControlLabel } from '@mui/material';
import { SubdirectoryArrowRight } from '@mui/icons-material';

import { WrapperItem } from './styles';

interface CategoryTreeInputProps {
  category: CategoryModel;
  checked: Array<number>;
  onChange: Function;
  level: number;
}

const CategoryTreeInput: React.FC<CategoryTreeInputProps> = ({ level, category, checked, onChange }) => {
  const { children, name, id } = category;
  const hasChildren = children && children.length > 0;
  const isChecked = checked.includes(id);
  return (
    <WrapperItem className="wrapper" level={level}>
      {level > 0 && <SubdirectoryArrowRight className="child-arrow" />}
      <FormControlLabel
        className="checkbox-label"
        control={<Checkbox checked={isChecked} onChange={() => onChange(id)} />}
        label={name}
      />
      {hasChildren &&
        (children || []).map(cat => (
          <CategoryTreeInput
            key={cat.id}
            level={level + 1}
            category={cat}
            checked={checked}
            onChange={onChange}
          />
        ))}
    </WrapperItem>
  );
};

export default CategoryTreeInput;
