import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';

const REGION_FILTER_FORM = (regions, onChangeRegion, settings): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.STORES.COLS.REGION',
        disableFastField: true,
        gridSize: 3,
        isHidden: () => !settings?.regions,
        value: 'region',
        className: 'store-selector',
        config: {
          optionProp: {
            label: 'name',
            value: 'id',
          },
          data: regions,
        },
        onChange: ({ newValue, setFieldValue }) => {
          if (onChangeRegion) onChangeRegion(newValue);
          setFieldValue('user', undefined);
          setFieldValue('store', undefined);
        },
      },
    ],
  },
];

export const REP_FILTER_FORM = ({
  items = [],
  regions,
  users,
  onChangeUser,
  onChangeRegion,
  onChangeItem,
  settings,
}): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.STORES.COLS.REGION',
        disableFastField: true,
        gridSize: 3,
        value: 'region',
        className: 'store-selector',
        config: {
          optionProp: {
            label: 'name',
            value: 'id',
          },
          initialThres: 20,
          data: regions,
        },
        onChange: onChangeRegion,
      },
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.STORES.COLS.SALESMAN',
        disableFastField: true,
        gridSize: 3,
        isHidden: () => !settings?.commission,
        value: 'user',
        config: {
          optionProp: {
            label: 'name',
            value: 'userId',
          },
          data: users,
        },
        onChange: ({ newValue }) => onChangeUser(newValue),
      },
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.STORES.COLS.ITEM',
        disableFastField: true,
        gridSize: 5,
        value: 'item',
        config: {
          optionProp: {
            label: 'name',
            labelText: (o) => `${o.sku ? `(${o.sku})` : ''} ${o.name || ''}`,
            value: 'id',
          },
          data: items,
        },
        onChange: ({ newValue }) => onChangeItem(newValue),
      },
    ],
  },
];

export const STORE_REPORT_FILTER_FORM = ({
  items = [],
  regions,
  users,
  onChangeUser = () => {},
  onChangeRegion,
  onChangeItem,
  settings,
}): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.STORES.COLS.REGION',
        disableFastField: true,
        gridSize: 3,
        value: 'region',
        className: 'store-selector',
        config: {
          optionProp: {
            label: 'name',
            value: 'id',
          },
          data: regions,
        },
        onChange: onChangeRegion,
      },
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.STORES.COLS.SALESMAN',
        disableFastField: true,
        gridSize: 3,
        isHidden: () => !settings?.commission,
        value: 'user',
        config: {
          optionProp: {
            label: 'name',
            value: 'userId',
          },
          data: users,
        },
      },
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.STORES.COLS.ITEM',
        disableFastField: true,
        gridSize: 5,
        value: 'item',
        config: {
          optionProp: {
            label: 'name',
            labelText: (o) => `${o.sku ? `(${o.sku})` : ''} ${o.name || ''}`,
            value: 'id',
          },
          data: items,
        },
        onChange: ({ newValue }) => onChangeItem(newValue),
      },
    ],
  },
];

export default REGION_FILTER_FORM;
