import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { Modal } from '$gcomponents/reusables';

export const IonPageWrapper = styled(IonPage)`
  .MuiTableHead-root {
    border-bottom: 1px solid #ccc;
  }
  .table-view {
    height: initial;
  }
`;

export const ModalWrapper = styled(Modal)`
  .discount-rate {
    width: 100px;
    margin-left: 10px;
  }
  .comm-rate {
    width: 100px;
    margin-right: 10px;
  }
`;
