import { INPUT } from '$gbusiness/enums';

export const FILE_EDIT_FORM = [
  {
    items: [
      {
        input: INPUT.TEXT,
        label: 'SCREEN.FILES.COLS.NAME',
        value: 'name',
        gridSize: 12,
      },
      {
        input: INPUT.TEXT,
        label: 'SCREEN.FILES.COLS.DESCRIPTION',
        value: 'description',
        gridSize: 12,
      },
    ],
  },
];

export default FILE_EDIT_FORM;
