import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const VENDOR_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'INPUT.LABEL.VENDOR',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'INPUT.LABEL.VENDOR_CODE',
        value: 'settings.code',
        input: INPUT.TEXT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.GOOGLE_ADDRESS,
        value: 'settings.formatted',
      },
      {
        ...INPUT_TYPES.EMAIL,
        value: 'settings.email',
      },
      {
        ...INPUT_TYPES.PHONE,
        value: 'settings.phone',
      },
      {
        label: 'INPUT.LABEL.SALES_REP',
        value: 'settings.salesRep',
        input: INPUT.TEXT,
      },
      {
        ...INPUT_TYPES.EMAIL,
        label: 'INPUT.LABEL.SALES_EMAIL',
        value: 'settings.salesEmail',
      },
      {
        input: INPUT.TEXTAREA,
        label: 'INPUT.LABEL.NOTES',
        value: 'settings.notes',
        gridSize: 12,
      },
    ],
  },
];
