import React from 'react';
import FactoryModel from '$fbusiness/models/factory';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface FactorySelectorProps {
  selectedId: number;
  factories?: Array<FactoryModel>;
  onChange: any;
}

const FactorySelector: React.FC<FactorySelectorProps> = ({ selectedId, factories, onChange }) => {
  if (!factories) return null;

  const menuItems = factories.map((f, i) => {
    return (
      <MenuItem key={i} value={f.factoryId}>
        {f.name}
      </MenuItem>
    );
  });

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel id="factory-label">My Factory</InputLabel>
      <Select id="factory-label" value={selectedId} label="My Factory" onChange={onChange}>
        {menuItems}
      </Select>
    </FormControl>
  );
};

export default FactorySelector;
