import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Select } from '@mui/material';
import { format } from '$gbusiness/helpers/date';
import { deriveRawToTransaction } from '$fbusiness/models/transaction';
import { toRefundId } from '$fbusiness/models/refund';
import {
  CANCELLED_OPTIONS,
  CLOSE_OPTIONS,
  convertToOption,
  INVOICE_STATUS,
  OPEN_OPTIONS,
  REFUNDED_OPTIONS,
  VOID_PAYMENT_OPTIONS,
} from '$fbusiness/enums/options/invoiceStatus';
import intl from '$gintl';
import { generateSelectOptions } from '$gcomponents/utils/input';
import { CREDIT, INVOICE, PAYMENT, REFUND } from '$fbusiness/enums/transactionType';
import { displayPaymentInfo } from '$fscreens/accountScreen/invoicesScreen/utils';
import { INVOICE_TABLE_CONFIG } from '../invoicesScreen/invoicesTable';
import { toCreditId } from '$fbusiness/models/credit';
import { Link as A } from '$gcomponents/primitives';
import PATH from '$business/enums/paths';
import { Link } from '$gstyles/general';
import ExportModel from '$gbusiness/models/export';
import { deriveAmount, deriveInfo, deriveInvoiceNo, deriveOpenBalance } from './utils';
import { currency } from '$gbusiness/helpers/util';
import { FONT } from '$gstyles';
import { REFUND_STATUS } from '$fbusiness/enums/options/refundStatus';

export const transactionExportSettings = (): ExportModel => {
  // const cols: any = columns.reduce((acc, c) => ((acc[c] = true), acc), {}); // eslint-disable-line no-sequences
  return {
    url: configs.api.transaction.list,
    method: 'POST',
    deriveToModel: deriveRawToTransaction,
    deriveTable: (row) => ({
      Customer: row.store.name || '',
      Type: row.type,
      Date: format(row.date, DATE_FORMATS.DATETIME2),
      DueDate: row.type === INVOICE ? format(row.invoice?.dueDate, DATE_FORMATS.SLASH_SHORT) : '',
      InvoiceNo: deriveInvoiceNo(row),
      Info: deriveInfo(row),
      Amount: deriveAmount(row),
      OpenBalance: deriveOpenBalance(row),
      status: row.refund ? row.refund?.status : row.invoice ? row.invoice.status || '' : '',
    }),
    sortKey: 'createdAt',
    sortOrder: SORT.DSC,
  };
};

export const TRANSACTIONS_TABLE_CONFIG = (isAll = false, isTransaction = false): TableModel => ({
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.transaction.list,
    method: 'POST',
    ...(isTransaction && { defaultParam: { isTransaction } }),
    deriveToModel: deriveRawToTransaction,
    defaultSortKey: 'transactionTime',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: isAll ? 20 : 999,
    autoRefreshInterval: 30,
    innerKey: 'invoice.payments',
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: FONT.SMALL,
    minWidth: 300,
    maxWidth: 1500,
    cellPadding: ` 2px 4px`,
    rowHeight: 24,
    isStripped: false,
    defaultCollapse: true,
    responsive: true,
    setRowClass: (temp) => {
      let str = ' ';
      const { invoice: row } = temp;
      // if (temp?.type === CREDIT) str = 'credit ';
      if (!!temp?.paymentGroup?.rejectedAt) return 'refund';
      if (temp?.type.includes('REFUND') || temp?.credit?.untouchable) str = 'refund';
      if (temp?.credit?.status === REFUND_STATUS.VOID || row?.status === INVOICE_STATUS.CANCELLED)
        return 'void';
      if (temp?.type !== INVOICE) return str;
      if (!row) return str;
      if (row.balance <= 0 && row.status === INVOICE_STATUS.PAID) str += 'success ';
      else if (row.status === INVOICE_STATUS.REFUNDED) str += 'cancelled ';
      else if (row.overdue) str += 'overdue ';
      return str;
    },
  },
  filter: {
    color: COLORS.PRIMARY,
  },
  innerConfig: INVOICE_TABLE_CONFIG.innerConfig,
});

export const TRANSACTIONS_TABLE = ({
  onViewInvoice,
  onViewRefund,
  onChangeStatus,
  onReceivePayment,
  onViewPaymentGroup,
  isEcommerce,
  deleteInvoice = false,
}): Array<CellModel> => [
  // {
  //   label: 'SCREEN.TRANSACTIONS.COLS.ID',
  //   value: 'id',
  //   alwaysShow: true,
  //   // sortable: SORT.ASC,
  //   width: 50,
  // },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.STORE',
    value: 'store.name',
    component: (row) => <A route={`${PATH.STORES}/${row.store.id}`}>{row.store.name}</A>,
    alwaysShow: true,
    // sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.TYPE',
    value: 'type',
    alwaysShow: true,
    // sortable: SORT.ASC,
    width: 120,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.DATE',
    value: 'transactionTime',
    align: 'center',
    component: (row) => <>{format(row.date, DATE_FORMATS.DATETIME2)}</>,
    // sortable: SORT.ASC,
    width: 130,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.DUE_DATE',
    value: 'transactionTime',
    align: 'center',
    component: (row) => (
      <>{row.type === INVOICE ? format(row.invoice?.dueDate, DATE_FORMATS.SLASH_SHORT) : ''}</>
    ),
    // sortable: SORT.ASC,
    width: 130,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.INVOICE_NO',
    value: 'invoiceNumber',
    align: 'center',
    alwaysShow: true,
    component: (row) => (
      <div>
        {(row.type === INVOICE || row.type === REFUND) && (
          <div className="link" onClick={() => onViewInvoice(row.invoice)}>
            {row.invoice?.invoiceNumber}
          </div>
        )}
        {row.type === REFUND && (
          <div className="link" onClick={() => onViewRefund(row)}>
            {toRefundId(row.refund?.id)}
          </div>
        )}
        {row.type === CREDIT && <div>{toCreditId(row.credit?.id)}</div>}
      </div>
    ),
    // sortable: SORT.ASC,
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.INFO',
    value: 'info',
    align: 'left',
    component: (row) => {
      const { paymentGroup, refund, invoice, credit } = row;
      switch (row.type) {
        case INVOICE:
          return <div>{invoice?.user && <div>{invoice.user.name}</div>}</div>;
        case PAYMENT:
          if (!paymentGroup) return null;
          return (
            <div>
              <div>{paymentGroup?.type}</div>
              {displayPaymentInfo(paymentGroup)}
            </div>
          );
        case CREDIT:
          return <div className="small">{credit?.memo}</div>;
        case REFUND:
          if (!refund) return null;
          return (
            <div className="small">
              <div>Requested: {format(refund.requestDate)}</div>
              {refund.refundedAt && <div>Processed: {format(refund.refundedAt)}</div>}
            </div>
          );
        default:
          return null;
      }
    },
    width: 250,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.AMOUNT',
    value: 'amount',
    component: (row) => <>{currency(deriveAmount(row))}</>,
    align: 'center',
    // sortable: SORT.DSC,
    width: 120,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.OPEN_BALANCE',
    value: 'openBalance',
    component: (row) => <>{currency(deriveOpenBalance(row))}</>,
    align: 'center',
    // sortable: SORT.DSC,
    width: 140,
  },
  {
    label: 'SCREEN.ORDERS.COLS.STATUS',
    value: 'status',
    className: 'no-label receive-cell',
    alwaysShow: true,
    component: (row) => {
      const { invoice, refund } = row;
      if (refund) {
        return refund.status;
      } else if (row.type === INVOICE) {
        if (!invoice) return <></>;
        let options;
        switch (invoice?.status) {
          case INVOICE_STATUS.CANCELLED:
            options = CANCELLED_OPTIONS;
            break;
          case INVOICE_STATUS.VOID_PAYMENT:
            options = VOID_PAYMENT_OPTIONS;
            break;
          case INVOICE_STATUS.PAID:
            options = CLOSE_OPTIONS;
            break;
          case INVOICE_STATUS.REFUNDED:
            options = REFUNDED_OPTIONS;
            break;
          default:
            options = [
              ...OPEN_OPTIONS,
              ...(invoice?.paidAmount > 0 || invoice?.creditAmount > 0
                ? [INVOICE_STATUS.VOID_PAYMENT].map(convertToOption)
                : []),
              ...(invoice.balance <= 0 ? [INVOICE_STATUS.PAID].map(convertToOption) : []),
              ...(deleteInvoice ? [INVOICE_STATUS.DELETE].map(convertToOption) : []),
            ];
            break;
        }
        return invoice?.closedAt || isEcommerce ? (
          <>
            <Select
              variant="outlined"
              className="xsmall"
              value={invoice.status}
              onChange={(e) => onChangeStatus(invoice, e.target.value)}>
              {generateSelectOptions(options, invoice.status, !!invoice.payments.length)}
            </Select>
          </>
        ) : (
          <div>{intl('INPUT.OPTION.INVOICE_STATUS.' + invoice.status)}</div>
        );
      }
      return null;
    },
    minWidth: 130,
    width: 130,
  },
  {
    label: '',
    value: '',
    className: 'action vertical',
    alwaysShow: true,
    component: (row) => (
      <>
        {row.type === INVOICE && row.invoice?.status === INVOICE_STATUS.OPEN && (
          <Button
            className="multiline payment-button"
            size="small"
            variant="contained"
            onClick={() => onReceivePayment(row.invoice)}
            color="secondary">
            {intl('BUTTON.RECEIVE_PAYMENT')}
          </Button>
        )}
        {row.type === REFUND && (
          <Button color="secondary" variant="contained" size="small" onClick={() => onViewRefund(row)}>
            {intl('SCREEN.REFUNDS.COLS.DETAILS')}
          </Button>
        )}
        {row.type === PAYMENT && (
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={() => onViewPaymentGroup(row.paymentGroup, row.store)}>
            {intl('SCREEN.REFUNDS.COLS.DETAILS')}
          </Button>
        )}
        {row.type === CREDIT && row.refund && (
          <Link onClick={() => onViewRefund(row)}>{toRefundId(row.refund.id)}</Link>
        )}
      </>
    ),
    minWidth: 64,
    width: 100,
  },
  {
    label: '',
    value: '',
    className: 'action vertical',
    align: 'center',
    alwaysShow: true,
    component: (row, actions) =>
      row.type === INVOICE ? (
        <Button
          className="left"
          color="secondary"
          fullWidth
          onClick={(e) => actions.handlePopoverOpen(e, row.invoice)}>
          <MoreHorizIcon />
        </Button>
      ) : null,

    width: 100,
  },
];
