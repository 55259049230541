import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';

export const NOTIFICATION_FORM = (stores) => [
  {
    items: [
      {
        label: 'SCREEN.NOTIFICATION.LABEL.STORES',
        value: 'stores',
        input: INPUT.MULTI_SELECT,
        selectAllText: '(SELECT ALL STORES)',
        options: stores.map((store) => ({
          labelText: store.name,
          value: store.id,
        })),
        //isMultiline: true,
        disableFastField: true,
        validation: [
          {
            condition: (v) => v.length < 1,
            message: 'REQUIRED',
          },
        ],
        gridSize: 12,
      },
    ],
  },
  {
    items: [
      {
        label: 'SCREEN.NOTIFICATION.LABEL.FROM',
        value: 'from',
        input: INPUT.TEXT,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.EMAIL,
        disabled: true,
      },
      {
        label: 'SCREEN.NOTIFICATION.LABEL.SUBJECT',
        value: 'subject',
        input: INPUT.TEXT,
        validation: [input.requiredValidation],
        gridSize: 12,
      },
      {
        label: 'SCREEN.NOTIFICATION.LABEL.BODY',
        value: 'body',
        input: INPUT.TEXTAREA,
        rows: 14,
        validation: [input.requiredValidation],
        gridSize: 12,
      },
    ],
  },
];
