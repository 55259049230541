import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { deriveRawToRegion } from '$fbusiness/models/region';
import { Button } from '@mui/material';

export const REGION_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.region,
    method: 'GET',
    deriveToModel: deriveRawToRegion,
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 300,
    maxWidth: 800,
    cellPadding: ` 8px 4px`,
    isStripped: true,
    responsive: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const REGION_TABLE = (): Array<CellModel> => [
  // {
  //   label: 'SCREEN.REGIONS.COLS.ID',
  //   value: 'id',
  //   align: 'center',
  //   alwaysShow: true,
  //   sortable: SORT.ASC,
  //   width: 100,
  // },
  {
    label: 'SCREEN.REGIONS.COLS.NAME',
    value: 'name',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 300,
  },
  {
    label: 'SCREEN.REGIONS.COLS.SALES_REPS',
    value: 'salesReps',
    component: (row) => (
      <div className="small">
        {(row.salesReps || []).map((s, i) => (
          <div key={i}>{s.name}</div>
        ))}
      </div>
    ),
    width: 300,
  },
  {
    label: 'SCREEN.REFUNDS.COLS.STORES',
    value: 'numStores',
    // component: (row) => <>{(row.stores || []).map((s) => s.name).join(', ')}</>,
    component: (row) => (
      <div className="small">
        {(row.stores || []).map((s, i) => (
          <div key={i}>{s.name}</div>
        ))}
      </div>
    ),
    alwaysShow: true,
    width: 300,
  },
  {
    label: 'SCREEN.ITEMS.COLS.ACTION',
    value: '',
    className: 'action',
    align: 'center',
    component: (row, actions) => {
      return (
        <Button
          className="left"
          color="secondary"
          fullWidth
          onClick={(e) => actions.handlePopoverOpen(e, row)}>
          <MoreHorizIcon />
        </Button>
      );
    },
    width: 100,
  },
];
