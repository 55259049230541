const KEYS = {
  FIXED: 'FIXED',
  RULE: 'RULE',
};

export const DISCOUNT_RULE = KEYS;

const LIST = Object.keys(KEYS);

const DISCOUNT_RULE_OPTIONS = LIST.map((item) => ({
  label: `DISCOUNT_RULE.${item}`,
  value: item,
}));

export default DISCOUNT_RULE_OPTIONS;
