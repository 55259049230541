export const KEYS = {
  PENDING: 'PENDING',
  PROCESSED: 'PROCESSED',
  // CONFIRMED: 'CONFIRMED',
  VOID: 'VOID',
};

export const REFUND_STATUS = KEYS;

const convertToOption = (item) => ({
  label: `REFUND_STATUS.${item}`,
  value: item,
  disabled: item === KEYS.PENDING,
});

const LIST = Object.keys(KEYS);

export const REFUND_STATUS_OPTIONS = LIST.map(convertToOption);

export default REFUND_STATUS_OPTIONS;

export type REFUND_STATUS_TYPE = typeof LIST[number] | undefined;
