import BatchModel from '../../models/itemBatch';

export const BATCH_FAILURE = 'BATCH_FAILURE';
export const FETCH_BATCH_SUCCESS = 'FETCH_BATCH_SUCCESS';
export const FETCH_BATCHES_SUCCESS = 'FETCH_BATCHES_SUCCESS';
export const CREATE_BATCH_SUCCESS = 'SAVE_BATCH_SUCCESS';
export const UPDATE_BATCH_SUCCESS = 'SAVE_BATCH_SUCCESS';
export const DELETE_BATCH_SUCCESS = 'DELETE_BATCH_SUCCESS';
export const CLEAN_BATCH = 'CLEAN_BATCH';

// Action Types
export interface typeOnlyType {
  type:
    | typeof CREATE_BATCH_SUCCESS
    | typeof UPDATE_BATCH_SUCCESS
    | typeof CLEAN_BATCH
    | typeof DELETE_BATCH_SUCCESS;
}

export interface failureType {
  type: typeof BATCH_FAILURE;
  err: string;
}

export interface FetchBatchSuccessType {
  type: typeof FETCH_BATCH_SUCCESS;
  batch: BatchModel;
}

export interface FetchBatchesSuccessType {
  type: typeof FETCH_BATCHES_SUCCESS;
  batches: Array<BatchModel>;
}

export interface BatchReducerType {
  batches: Array<BatchModel>;
  batch: BatchModel | null;
  isFinished: boolean;
  refresh: boolean;
}

export type BatchActionTypes = typeOnlyType | failureType | FetchBatchSuccessType | FetchBatchesSuccessType;

export const BATCH_INIT_STATE: BatchReducerType = {
  batches: [],
  batch: null,
  isFinished: false,
  refresh: true,
};
