import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { formatDatetime, timestamp } from '$gbusiness/helpers/date';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { deriveRawToItem } from '$fbusiness/models/item';
import intl from '$intl';
import Img from '$gcomponents/primitives/img';
import { Link } from '$gcomponents/primitives';
import PATH from '$business/enums/paths';

export const ITEMS_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.item.general,
    method: 'GET',
    deriveToModel: deriveRawToItem,
    defaultSortKey: 'updatedAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    responsive: true,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 800,
    rowHeight: 44,
    maxWidth: 1200,
    cellPadding: ` 2px 4px`,
    isStripped: false,
    borderColor: 'var(--ion-border-color)',
    disableSort: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const ITEMS_TABLE = (onToggleHide): Array<CellModel> => [
  {
    label: 'SCREEN.ITEMS.COLS.PHOTO',
    value: 'itemId',
    className: 'image',
    align: 'center',
    component: (row) =>
      row.image && (
        <Img
          src={row.image?.sizes?.small || ''}
          cache={timestamp(row.image.updatedAt)}
          width="64px"
          height="64px"
          alt="dept img"
        />
      ),
    width: 150,
  },
  {
    label: 'SCREEN.ITEMS.COLS.NAME',
    value: 'name',
    className: 'main',
    component: (row) => <Link route={PATH.ITEMS + '/' + row.id}>{row.name}</Link>,
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.ITEMS.COLS.BARCODE',
    value: 'barcode',
    className: 'desktop',
    sortable: SORT.ASC,
    width: 160,
  },
  {
    label: 'SCREEN.ITEMS.COLS.DEPT',
    value: 'deptName',
    component: (row) => row?.depts && row.depts.map((d) => <div key={d.id}>{d.name}</div>),
    className: 'desktop',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.ITEMS.COLS.CATEGORY',
    value: 'categoryName',
    className: 'vertical',
    align: 'center',
    component: (row) => row?.categories && row.categories.map((d) => <div key={d.id}>{d.name}</div>),
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.ITEMS.COLS.RETAIL_PRICE',
    value: 'price',
    align: 'center',
    component: (row) => <>{intl('PRICE', { amount: row.retailPrice })}</>,
    sortable: SORT.ASC,
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.PRICE',
    value: 'wholesalePrice',
    align: 'center',
    component: (row) => <>{intl('PRICE', { amount: row.wholesalePrice })}</>,
    sortable: SORT.ASC,
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.RAW_COST',
    value: 'rawCost',
    align: 'center',
    component: (row) => <>{intl('PRICE', { amount: row.rawCost })}</>,
    sortable: SORT.ASC,
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.TAX',
    value: 'tax',
    align: 'center',
    component: (row) => <>{row.tax?.name}</>,
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.QUANTITY',
    value: 'quantity',
    align: 'center',
    className: 'desktop',
    component: (row) => <>{row.quantity == null ? 'Unlimited' : row.quantity}</>,
    sortable: SORT.ASC,
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.VISIBILITY',
    value: 'hide',
    align: 'center',
    className: 'desktop',
    component: (row) => {
      const Icon = row.hide ? (
        <VisibilityOffOutlinedIcon onClick={() => onToggleHide(row)} />
      ) : (
        <VisibilityOutlinedIcon onClick={() => onToggleHide(row)} />
      );
      return Icon;
    },
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.UPDATED',
    value: 'updatedAt',
    className: 'desktop',
    component: (row) => <>{row.updatedAt ? formatDatetime(row.updatedAt, DATE_FORMATS.DATETIME) : ''}</>,
    sortable: SORT.ASC,
    width: 240,
  },
  {
    label: 'SCREEN.ITEMS.COLS.ACTION',
    value: '',
    className: 'action',
    align: 'center',
    alwaysShow: true,
    component: (row, actions) => {
      return (
        <Button
          className="left"
          fullWidth
          onClick={(e) => actions.handlePopoverOpen(e, { ...row, route: PATH.ITEMS })}>
          <MoreHorizIcon />
        </Button>
      );
    },
    width: 100,
  },
];
