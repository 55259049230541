import React from 'react';
import intl from '$intl';
import VendorModel from '$fbusiness/models/vendor';

import { ModalWrapper } from './styles';
import StoreModel from '$fbusiness/models/store';
import VendorDetails from './vendorDetails';

interface VendorModalScreenProps {
  vendor: VendorModel;
  stores: Array<StoreModel>;
  onClose: Function;
}

const VendorModalScreen: React.FC<VendorModalScreenProps> = ({ vendor, stores, onClose }) => {
  return (
    <ModalWrapper
      show={!!vendor}
      onClose={() => onClose()}
      title={intl('SCREEN.FACTORY.VENDOR_TITLE')}
      useCustom>
      {vendor && <VendorDetails vendor={vendor} onClose={onClose} stores={stores} />}
    </ModalWrapper>
  );
};

export default VendorModalScreen;
