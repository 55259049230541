import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const MODIFIER_GROUP_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'INPUT.LABEL.MODIFIER_GROUP_NAME',
        value: 'name',
        input: INPUT.TEXT,
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    items: [
      {
        label: 'INPUT.LABEL.MULTIPLE',
        value: 'multiple',
        input: INPUT.CHECKBOX,
        gridSize: 12,
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    isHidden: values => !values.multiple,
    items: [
      {
        isHidden: values => !values.multiple,
        label: 'INPUT.LABEL.MIN_SELECTION',
        value: 'minQuantity',
        input: INPUT.QUANTITY,
        gridSize: 3,
        validation: [input.requiredValidation],
      },
      {
        isHidden: values => !values.multiple,
        label: 'INPUT.LABEL.MAX_SELECTION',
        value: 'maxQuantity',
        labelText: 'Unlimited',
        input: INPUT.QUANTITY,
        gridSize: 3,
        validation: [input.requiredValidation],
      },
    ],
  },
];

export default MODIFIER_GROUP_FORM;
