import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import intl from '$intl';
import { screen } from '$fcomponents/hoc';
import { Div, SPACE } from '$gstyles';
import { dialog, FormSection } from '$gcomponents/reusables';
import Header from '$components/header';
import { factoryActions } from '$fbusiness/redux/factory';
import FactoryModel from '$fbusiness/models/factory';
import { initialVendor } from '$fbusiness/models/vendor';

import { IonPageWrapper } from './styles';
import { VENDOR_TABLE, VENDOR_TABLE_CONFIG } from '../vendorModalScreen/table';
import VendorModalScreen from '../vendorModalScreen';
import StoreModel from '$fbusiness/models/store';
import { TableView2 } from '$gcomponents/widgets';
import ItemActionModel from '$gbusiness/models/itemAction';
import FabButton from '$fcomponents/fabButton';

interface VendorsScreenProps {
  history: any;
  factory: FactoryModel | null;
  saveFactory: Function;
  deleteVendor: Function;
  isFinished: boolean;
  stores: Array<StoreModel>;
}

const VendorsScreen: React.FC<VendorsScreenProps> = ({ factory, stores, deleteVendor }) => {
  const [vendor, setVendor] = useState<any>(null);

  if (!factory) return <IonPageWrapper />;
  const { vendors } = factory;

  const vendorItemActions: Array<ItemActionModel> = [
    {
      label: 'BUTTON.EDIT',
      eventName: 'editItem',
      onClick: setVendor,
    },
    {
      label: 'BUTTON.DELETE',
      eventName: 'deleteItem',
      onClick: (row) => {
        dialog.confirm({
          message: 'MESSAGE.DELETE_WARNING',
          onPress: async () => {
            await deleteVendor(row.id);
          },
        });
      },
    },
  ];

  return (
    <IonPageWrapper>
      <Header titleText={intl('SCREEN.FACTORY.VENDOR_TITLE')} />
      <IonContent>
        <Div maxWidth="1200px" className="container" padding={SPACE.LARGE}>
          <FormSection className="form-section" FORM={[]} marginBottom="0" formik={null}>
            <TableView2
              data={vendors}
              tableConfig={VENDOR_TABLE_CONFIG}
              TABLE={VENDOR_TABLE}
              itemActions={vendorItemActions}
            />
          </FormSection>
        </Div>
        <Div padding={SPACE.XXLARGE} />
        <VendorModalScreen stores={stores} vendor={vendor} onClose={() => setVendor(null)} />
      </IonContent>
      <FabButton onClick={() => setVendor(initialVendor)} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  stores: state.store.stores,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: () => factoryActions.fetchMyFactory(),
  saveFactory: factoryActions.saveFactory,
  deleteVendor: factoryActions.deleteVendor,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(VendorsScreen));
