import React from 'react';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import CellModel from '$gbusiness/models/cell';
import { deriveRawToDiscount } from '$fbusiness/models/discount';
import { currency, percentage } from '$gbusiness/helpers/util';

export const DISCOUNT_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.discount,
    method: 'GET',
    deriveToModel: deriveRawToDiscount,
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 300,
    maxWidth: 800,
    cellPadding: ` 8px 4px`,
    isStripped: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const DISCOUNT_TABLE = (onClickRow): Array<CellModel> => [
  {
    label: 'SCREEN.DISCOUNTS.COLS.NAME',
    value: 'name',
    component: row => (
      <div className="link" onClick={() => onClickRow(row)}>
        {row.name}
      </div>
    ),
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 400,
  },
  {
    label: 'SCREEN.DISCOUNTS.COLS.RATE',
    value: 'settings.rate',
    component: row => <div>{row.isFlat ? currency(row.amount) : percentage(row.amount)}</div>,
    sortable: SORT.ASC,
    width: 200,
  },
];
