import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import FREQ_OPTIONS, { PAYMENT_FREQ } from '$fbusiness/enums/options/paymentFreq';
import WEEKDAY_OPTIONS from '$gbusiness/enums/options/weekdays';
import { intToArray, rangeToArray } from '$gbusiness/helpers/util';
import { toDayOfMonth } from '$gbusiness/helpers/date';
import { appendEmptyOption, insertEmptyOption } from '$gbusiness/helpers/input';
import DISCOUNT_RULE_OPTIONS, { DISCOUNT_RULE } from '$fbusiness/enums/options/discountRule';

export const COMMRULE_FORM = (onChangeDiscountType): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'SCREEN.COMMRULES.COLS.NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.PERCENT,
        label: 'SCREEN.COMMRULES.COLS.RATE',
        value: 'rate',
        gridSize: 6,
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    items: [
      {
        label: 'SCREEN.COMMRULES.COLS.DISCOUNT',
        value: 'discountType',
        input: INPUT.RADIO,
        options: insertEmptyOption(DISCOUNT_RULE_OPTIONS, 'None', ''),
        gridSize: 12,
        onChange: ({ value }) => onChangeDiscountType(value),
      },
      {
        isHidden: (values) => values.discountType !== DISCOUNT_RULE.FIXED,
        ...INPUT_TYPES.PERCENT,
        value: 'discountRate',
        label: 'SCREEN.COMMRULES.COLS.DISCOUNT_RATE',
        gridSize: 6,
      },
    ],
  },
];

export const COMM_PAY_SETTING_FORM = (values): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'SCREEN.COMMRULES.PAYOUT.FREQ',
        value: 'freq',
        input: INPUT.RADIO,
        gridSize: 12,
        options: FREQ_OPTIONS,
        validation: [input.requiredValidation],
        onChange: ({ value, setFieldValue }) => {
          if (value === PAYMENT_FREQ.WEEKLY) setFieldValue('weekday', 5);
          else if (value === PAYMENT_FREQ.MONTHLY) setFieldValue('startDay', '15');
          else if (value === PAYMENT_FREQ.SEMI_MONTHLY) {
            setFieldValue('startDay', '15');
            setFieldValue('endDay', '30');
          }
        },
      },
      // {
      //   input: INPUT.SELECT,
      //   label: 'SCREEN.COMMRULES.PAYOUT.LAG',
      //   value: 'lag',
      //   options: intToArray(10).map((n, i) => ({
      //     labelText: `${n} period${n > 1 ? 's' : ''}`,
      //     value: n,
      //   })),
      //   gridSize: 4,
      //   validation: [input.requiredValidation],
      // },
      {
        isHidden: (values) => values.freq === PAYMENT_FREQ.WEEKLY,
        input: INPUT.SELECT,
        label: 'SCREEN.COMMRULES.PAYOUT.LAG',
        value: 'lag',
        options: intToArray(12).map((n, i) => ({
          labelText: `${n} month${n > 1 ? 's' : ''}`,
          value: n,
        })),
        gridSize: 4,
        validation: [input.requiredValidation],
      },
      {
        isHidden: (values) => values.freq !== PAYMENT_FREQ.WEEKLY,
        input: INPUT.SELECT,
        label: 'SCREEN.COMMRULES.PAYOUT.LAG',
        value: 'lag',
        options: intToArray(12).map((n, i) => ({
          labelText: `${n} week${n > 1 ? 's' : ''}`,
          value: n,
        })),
        gridSize: 4,
        validation: [input.requiredValidation],
      },
      {
        isHidden: (values) => values.freq !== PAYMENT_FREQ.WEEKLY,
        input: INPUT.SELECT,
        label: 'SCREEN.COMMRULES.PAYOUT.PERIOD_END',
        disableFastField: true,
        value: 'weekday',
        options: WEEKDAY_OPTIONS,
        gridSize: 4,
        validation: [input.requiredValidation],
      },
      {
        isHidden: (values) => values.freq === PAYMENT_FREQ.WEEKLY,
        input: INPUT.SELECT,
        disableFastField: true,
        label: 'SCREEN.COMMRULES.PAYOUT.PERIOD_END',
        value: 'startDay',
        options: appendEmptyOption(
          intToArray(30).map((n, i) => ({
            labelText: toDayOfMonth(i + 1),
            value: i + 1,
          })),
          'End of Month',
          '0',
        ),
        gridSize: 4,
        validation: [input.requiredValidation],
      },
      {
        isHidden: (values) => values.freq !== PAYMENT_FREQ.SEMI_MONTHLY,
        input: INPUT.SELECT,
        disableFastField: true,
        label: 'SCREEN.COMMRULES.PAYOUT.PERIOD_END2',
        value: 'endDay',
        options: appendEmptyOption(
          rangeToArray(parseInt(values?.startDay) + 1 || 1, 30).map((n, i) => ({
            labelText: toDayOfMonth(n),
            value: n,
          })),
          'End of Month',
          '0',
        ),
        gridSize: 4,
        validation: [input.requiredValidation],
      },
      {
        isHidden: (values) => values.freq === PAYMENT_FREQ.SEMI_MONTHLY,
        input: INPUT.HIDDEN,
        label: '',
        value: '',
        gridSize: 4,
      },
      {
        isHidden: (values) => values.freq === PAYMENT_FREQ.WEEKLY,
        input: INPUT.SELECT,
        label: 'SCREEN.COMMRULES.PAYOUT.COMMISSION_LAG',
        value: 'commissionLag',
        options: intToArray(12).map((n, i) => ({
          labelText: `${n} month${n > 1 ? 's' : ''}`,
          value: n,
        })),
        gridSize: 4,
        validation: [input.requiredValidation],
      },
      {
        isHidden: (values) => values.freq !== PAYMENT_FREQ.WEEKLY,
        input: INPUT.SELECT,
        label: 'SCREEN.COMMRULES.PAYOUT.COMMISSION_LAG',
        value: 'commissionLag',
        options: intToArray(12).map((n, i) => ({
          labelText: `${n} week${n > 1 ? 's' : ''}`,
          value: n,
        })),
        gridSize: 4,
        validation: [input.requiredValidation],
      },
      {
        isHidden: (values) => values.freq !== PAYMENT_FREQ.MONTHLY,
        input: INPUT.SELECT,
        disableFastField: true,
        label: 'SCREEN.COMMRULES.PAYOUT.START_DAY',
        value: 'payDay',
        options: appendEmptyOption(
          intToArray(30).map((n, i) => ({
            labelText: toDayOfMonth(i + 1),
            value: i + 1,
          })),
          'End of Month',
          '0',
        ),
        gridSize: 4,
        validation: [input.requiredValidation],
      },
      {
        isHidden: (values) => values.freq !== PAYMENT_FREQ.SEMI_MONTHLY,
        input: INPUT.SELECT,
        disableFastField: true,
        label: 'SCREEN.COMMRULES.PAYOUT.START_DAY',
        value: 'payDay',
        options: [
          ...rangeToArray(1, parseInt(values?.startDay) || 1).map((n, i) => ({
            labelText: toDayOfMonth(n),
            value: n,
          })),
          ...rangeToArray((parseInt(values?.endDay) || 30) + 1, 30).map((n, i) => ({
            labelText: toDayOfMonth(n),
            value: n,
          })),
        ],
        gridSize: 4,
        validation: [input.requiredValidation],
      },
      {
        isHidden: (values) => values.freq !== PAYMENT_FREQ.SEMI_MONTHLY,
        input: INPUT.SELECT,
        disableFastField: true,
        label: 'SCREEN.COMMRULES.PAYOUT.END_DAY',
        value: 'payDay2',
        options: [
          ...rangeToArray(parseInt(values?.startDay) || 30, parseInt(values?.endDay) || 30).map((n, i) => ({
            labelText: toDayOfMonth(n),
            value: n,
          })),
        ],
        gridSize: 4,
        validation: [input.requiredValidation],
      },
    ],
  },
];
