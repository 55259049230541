import React from 'react';

import { IonToolbarWrapper } from './styles';
import { IonButtons, IonMenuButton } from '@ionic/react';
import OptionModel from '$gbusiness/models/option';
import { TabSimple } from '$gcomponents/reusables';

interface ItemsHeaderProps {
  TABS: Array<OptionModel>;
  index: number;
  onChangeIndex: Function;
}

const ItemsHeader: React.FC<ItemsHeaderProps> = ({ TABS, index, onChangeIndex }) => {
  return (
    <IonToolbarWrapper index={index}>
      <IonButtons slot="start">
        <IonMenuButton />
      </IonButtons>
      <TabSimple TABS={TABS} index={index} onChangeIndex={onChangeIndex} maxWidth="500px" />
    </IonToolbarWrapper>
  );
};

export default ItemsHeader;
