import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .open-balance-section {
    padding-right: 32px;
  }

  .MuiTableHead-root {
    border-bottom: 1px solid #ccc;
    .credit
  }
  .MuiTableRow-root {
    &.credit {
      background-color: #eeffee;
    }
    &.refund {
      background-color: #eee;
    }
    .MuiButtonBase-root.MuiButton-root {
      min-width: auto;
    }
    .MuiSelect-select {
      padding-left: 10px !important;
      padding-right: 28px !important;
      font-size: 1.3rem;
    }
  }
  .small {
    font-size: 13px;
  }
`;
