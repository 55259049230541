import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const DEPARTMENT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'INPUT.LABEL.DEPARTMENT_NAME',
        value: 'name',
        gridSize: 12,
        input: INPUT.TEXT,
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.EMAIL,
        gridSize: 12,
      },
    ],
  },
];

export const DEPARTMENT_PHOTO_FORM = [
  {
    items: [
      {
        ...INPUT_TYPES.IMAGE,
        gridHeight: '200px',
        gridWidth: '200px',
        size: 'stretch',
        gridSize: 12,
      },
    ],
  },
];

export default DEPARTMENT_FORM;
