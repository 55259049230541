import { Div, SPACE } from '$gstyles';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)``;

export const LabelTableWrapper = styled(Div)`
  width: ${(p) => p.width || '100%'};

  .submit-wrapper {
    text-align: right;
    padding-right: ${SPACE.XLARGE};
  }
`;

export const LabelHeader = styled(Div)`
  display: flex;
  font-weight: 600;
  border-bottom: 1px solid var(--ion-border-color);
`;

export const LabelRow = styled(Div)`
  display: flex;
`;

export const LabelCol = styled(Div)`
  flex: 1;
  padding: ${SPACE.MEDIUM};
`;

export const LabelInput = styled.input`
  flex: 1;
  padding: ${SPACE.MEDIUM};
`;
