import styled from 'styled-components';
import { Div, SPACE, WEIGHT } from '$gstyles';
import { Form } from 'formik';

export const ScreenTitle = styled(Div)`
  font-weight: ${WEIGHT.SEMI_BOLD};
  font-size: ${(p) => p.size || '2.4rem'};
  padding: ${SPACE.MEDIUM} 0;
`;

export const FormStyle = `
  padding: ${SPACE.MEDIUM};

  .form-group {
    margin: 0 -${SPACE.MEDIUM};
  }
  .col {
    padding: 8px 8px !important;
  }

  .radio-group {
    .MuiFormControl-root {
      margin-top: -${SPACE.SMALL};
    }
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
  }
  .left {
    flex: 1;
  }

  .form-content {
    display: flex;
    // flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: top;
  }

  .MuiInput-underline:before,
  .MuiInput-underline.Mui-focused:before {
    border-bottom: none !important;
  }

  .MuiOutlinedInput-root {
    background-color: #fff;
  }

  .form-section {
    .header-title {
      font-size: 1.2em !important;
      margin: 8px 0 !important;
    }
    padding: ${SPACE.LARGE};
    background-color: #f6f6f6;
    margin: ${SPACE.MEDIUM};
    border-radius: 5px;
  }
`;

export const AppForm = styled(Form)`
  ${FormStyle}
`;

export const FormContainer = styled(Div)`
  ${FormStyle}
`;
