import styled from 'styled-components';
import { IonMenu } from '@ionic/react';
import { configs } from '$configs';
import { WEIGHT } from '$gstyles';

export const IonMenuWrapper = styled(IonMenu)`
  ${(p) =>
    p.hide
      ? `
    @media screen and (min-width: ${configs.display.desktopMinThreshold}) {
      --side-max-width: 30px;
      --side-min-width: 30px;
    } 
    ion-header, ion-content {
      opacity: 0.5;
      pointer-events: none;
    }
  `
      : `
    @media screen and (min-width: ${configs.display.desktopMinThreshold}) {
      --side-max-width: 300px;
      --side-min-width: 300px;
    }
  `}
  transition: max-width 0.2s ease-out, min-width 0.2s ease-out;

  .arrow-hide {
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-bk);
    border: 1px solid #999;
    position: relative;
    right: -3px;
    width: 24px;
    top: -20px;
    padding: 40px 5px;
    cursor: pointer;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
    svg {
      color: #fff;
      font-size: 1.6rem;
    }
  }

  ion-header {
    .store-filter-wrapper {
      .MuiFormControl-root {
        margin-bottom: 0;
        padding: 0 12px;
        width: 100%;
      }
    }
    .col {
      margin-top: 0 !important;
    }
    .close-icon {
      margin-right: -16px;
    }
  }

  .list-wrapper {
    padding: 0;
  }
  .main-table-wrapper {
    height: 100%;
    .virtualized-row {
      border-top: 1px solid var(--ion-border-color);
    }
    .name {
      margin-bottom: 2px;
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .subtitle {
      font-size: 0.8em;
      color: #666;
      line-height: 10px;
    }
    .note {
      font-size: 0.8em;
      color: #000;
    }
    .MuiTableCell-root {
      display: block;
      width: 100%;
    }
    .selected {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
      .MuiTableCell-root,
      .link,
      .subtitle,
      .note {
        color: var(--ion-color-primary-contrast);
      }
    }
  }
`;
