const KEYS = {
  SUN: 'SUN',
  MON: 'MON',
  TUE: 'TUE',
  WED: 'WED',
  THU: 'THU',
  FRI: 'FRI',
  SAT: 'SAT',
};

const LIST = Object.keys(KEYS);

export default LIST.map((item, i) => ({
  label: `WEEKDAY.${item}`,
  value: i,
}));

export type WEEKDAY_TYPE = typeof LIST[number] | undefined;
