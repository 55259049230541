import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { IonContent, useIonViewWillEnter } from '@ionic/react';
import { Formik } from 'formik';

import { screen } from '$gcomponents/hoc';
import { Header } from '$gcomponents/widgets';
import { input } from '$gbusiness/helpers';
import { IonPageWrapper } from './styles';
import { PageWrapper } from '$gstyles/wrapper';
import { FormSection } from '$gcomponents/reusables';
import { NOTIFICATION_FORM } from './notificationForm';
import { Button, Text } from '$gcomponents/primitives';
import StoreModel from '$fbusiness/models/store';
import Footer from '$gcomponents/widgets/footer';
import { factoryActions } from '$fbusiness/redux/factory';
import { storeActions } from '../../../factory/business/redux/store';

interface NotificationScreenProps {
  emailNotification;
  factory;
  resetFinished;
  isFinished: boolean;
  stores: Array<StoreModel>;
}

const NotificationScreen: React.FC<NotificationScreenProps> = ({
  stores,
  factory,
  resetFinished,
  isFinished,
  emailNotification,
}) => {
  const notificationForm = NOTIFICATION_FORM(stores);
  const formRef = useRef<any>();

  useIonViewWillEnter(() => {
    clearForm();
  });

  useEffect(() => {
    if (!isFinished) return;
    resetFinished();
    clearForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  const clearForm = () => {
    formRef.current.resetForm();
  };

  const onSubmit = values => {
    emailNotification({
      ...values,
      body: values.body.replace(/\n/g, '<br>'),
    });
  };

  const validateForm = values => input.validateError(notificationForm, values);

  return (
    <IonPageWrapper>
      <Header title="SCREEN.NOTIFICATION.TITLE" />
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          from: factory.name,
          stores: [],
          subject: '',
          email: factory.email,
          body: '',
        }}
        validate={validateForm}
        onSubmit={values => {
          onSubmit(values);
        }}>
        {formik => (
          <>
            <IonContent>
              <PageWrapper maxWidth="800px">
                <FormSection FORM={notificationForm} formik={formik} />
              </PageWrapper>
            </IonContent>
            <Footer justifyContent="space-around">
              <Button className="third" variant="outlined" onClick={clearForm}>
                <Text k="BUTTON.CANCEL" />
              </Button>
              <Button
                className="half"
                onClick={formik.handleSubmit}
                disabled={!(formik.isValid && formik.dirty)}>
                <Text k="BUTTON.SUBMIT" />
              </Button>
            </Footer>
          </>
        )}
      </Formik>
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  isFinished: state.factory.isFinished,
  stores: state.store.stores,
  factory: state.factory.factory,
});

const mapDispatchToProps = {
  onHydrate: storeActions.fetchStores,
  emailNotification: factoryActions.emailNotification,
  resetFinished: factoryActions.resetFinished,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(NotificationScreen));
