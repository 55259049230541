import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { currency } from '$gbusiness/helpers/util';
import { deriveRawToAvailableCredit } from '$fbusiness/models/credit';
import { format } from '$gbusiness/helpers/date';
import intl from '$gintl';
import { Radio } from '@mui/material';

export const CREDIT_LIST_CONFIG = (storeId, disabledCredits, openBalance): TableModel => ({
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.store.availableCredits + '/' + storeId,
    deriveToModel: deriveRawToAvailableCredit,
    method: 'GET',
    defaultSortKey: 'updatedAt',
    defaultSortOrder: SORT.DSC,
    pageSize: 50,
    emptyText: intl('MESSAGE.NO_CREDIT'),
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 700,
    maxWidth: 1200,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 44,
    hideSelectAll: true,
    setRowClass: (row) => (disabledCredits.includes(row?.id) ? 'disabled o50' : ''),
  },
});

export const CREDIT_LIST = ({
  selectedCredit,
  selectedPayment,
  onCheckCredit,
  onCheckPayment,
}): Array<CellModel> => [
  {
    label: '',
    value: '',
    component: (row) => (
      <>
        {row.type ? (
          <Radio checked={selectedPayment?.id === row.id} onClick={() => onCheckPayment(row)} />
        ) : (
          <Radio checked={selectedCredit?.id === row.id} onClick={() => onCheckCredit(row)} />
        )}
      </>
    ),
    width: 60,
  },
  {
    label: 'SCREEN.CREDIT.COLS.NO',
    value: 'paymentType',
    component: (row) => <>{row.display}</>,
    width: 300,
  },
  {
    label: 'SCREEN.CREDIT.COLS.DATE',
    value: 'creditDate',
    component: (row) => <>{format(row.createdAt, DATE_FORMATS.SLASH) || ''}</>,
    width: 200,
  },
  {
    label: 'SCREEN.CREDIT.COLS.AMOUNT',
    value: 'amount',
    align: 'center',
    component: (row) => <span>{currency(row.amount)}</span>,
    alwaysShow: true,
    width: 180,
  },
  {
    label: 'SCREEN.CREDIT.COLS.BALANCE',
    value: 'balance',
    align: 'center',
    component: (row) => (
      <span className={`price semi-bold outlined ${row.amount < 0 ? 'danger' : 'success'}`}>
        {currency(row.balance)}
      </span>
    ),
    alwaysShow: true,
    width: 220,
  },
  {
    label: 'SCREEN.CREDIT.COLS.MEMO',
    value: 'memo',
    alwaysShow: true,
    width: 400,
  },
];
