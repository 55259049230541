import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import DATA_TYPE_OPTIONS from '$fbusiness/enums/options/dataTypes';

export const DELETE_DATA_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: '',
        value: 'type',
        input: INPUT.RADIO,
        options: DATA_TYPE_OPTIONS,
        isMultiline: true,
        gridSize: 12,
        validation: [input.requiredValidation],
      },
    ],
  },
];
