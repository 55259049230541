import React from 'react';
import { RegisterScreen as GRegisterScreen } from '$gbox/screens';
import { Div } from '$gstyles';

const RegisterScreen: React.FC = () => {
  const privacyPolicy = (
    <Div margin="10px 0">
      Your data will be used to process your purchase and communicate with you. We do not use your data for
      any other purpose. By registering you agree to the{' '}
      <a href="/tos" target="_blank">
        Terms & Conditions
      </a>
      . and to receive communications from us via text or email. (
      <a href="/tos" target="_blank">
        Read our privacy policy for details.
      </a>
      )
    </Div>
  );
  return <GRegisterScreen privacyPolicy={privacyPolicy} />;
};

export default RegisterScreen;
