import React, { useEffect, useState } from 'react';
import { IonContent } from '@ionic/react';
import { connect } from 'react-redux';

import { screen } from '$components/hoc';
import { Header } from '$gcomponents/widgets';
import { IonPageWrapper } from '../styles';
import ExportButton from '$gcomponents/reusables/exportButton';
import { PageWrapper } from '$gstyles/wrapper';
import { reportActions } from '$fbusiness/redux/report';
import { FactorySnapshotModel } from '$fbusiness/models/report';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import ReportFilter from '$fcomponents/filters/reportFilter';
import { updatedFilter } from '$fbusiness/helpers/util';
import { InfoItem } from '$gcomponents/reusables';
import { currency } from '$gbusiness/helpers/util';
import intl from '$intl';

interface CompanySnapshotScreenProps {
  factory;
  history;
  snapshot: FactorySnapshotModel;
  dateFilter;
  fetchFactorySnapshot;
}

const CompanySnapshotScreen: React.FC<CompanySnapshotScreenProps> = ({
  snapshot,
  fetchFactorySnapshot,
  dateFilter,
}) => {
  const [filter, setFilter] = useState<any>({ ...dateFilter });
  const [exportData, setExportData] = useState<Array<any>>([]);

  const updateFilter = (newFilter) => {
    setFilter(updatedFilter(filter, newFilter));
  };

  useEffect(() => {
    if (!filter) return;
    fetchFactorySnapshot(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (!snapshot) return;
    setExportData(Object.entries(snapshot));
  }, [snapshot]);

  const fileName = `companySnapshot_${filter.dateStart || 'beginning'}-${filter.dateEnd || 'present'}`;

  const right = (
    <ExportButton data={exportData} color="primary" variant="text" fileName={fileName} filter={filter} />
  );

  return (
    <IonPageWrapper>
      <Header title="SCREEN.REPORT.TITLE_SNAPSHOT" />
      <IonContent>
        <PageWrapper>
          <FilterSection title={null}>
            <ReportFilter
              includeReportType
              excludeQuery
              parentFilter={filter}
              onSearch={updateFilter}
              placeholder="Search"
            />
          </FilterSection>
          {snapshot && (
            <div className="item-list">
              <div className="right">{right}</div>
              <InfoItem label={intl('SCREEN.REPORT.COLS.SUBTOTALS')} value={currency(snapshot.subtotal)} />
              <InfoItem label={intl('SCREEN.REPORT.COLS.DISCOUNTS')} value={currency(snapshot.discounts)} />
              <InfoItem label={intl('SCREEN.REPORT.COLS.TAX')} value={currency(snapshot.tax)} />
              <InfoItem label={intl('SCREEN.REPORT.COLS.RECEIVABLE')} value={currency(snapshot.receivable)} />
              <InfoItem label={intl('SCREEN.REPORT.COLS.PO_TOTAL')} value={currency(snapshot.poTotal)} />
              <InfoItem label={intl('SCREEN.REPORT.COLS.PAYMENTS')} value={currency(snapshot.payments)} />
              <InfoItem
                className="indent"
                label={intl('INPUT.OPTION.PAYMENT_TYPE.CASH')}
                value={currency(snapshot.paymentCash)}
              />
              <InfoItem
                className="indent"
                label={intl('INPUT.OPTION.PAYMENT_TYPE.CREDIT')}
                value={currency(snapshot.paymentCredit)}
              />
              <InfoItem
                className="indent"
                label={intl('INPUT.OPTION.PAYMENT_TYPE.CHECK')}
                value={currency(snapshot.paymentCheck)}
              />
              <InfoItem
                className="indent"
                label={intl('INPUT.OPTION.PAYMENT_TYPE.ACH')}
                value={currency(snapshot.paymentAch)}
              />
              <InfoItem
                className="indent"
                label={intl('INPUT.OPTION.PAYMENT_TYPE.OTHER')}
                value={currency(snapshot.paymentOther)}
              />
              <InfoItem label={intl('SCREEN.REPORT.COLS.REFUNDS')} value={currency(snapshot.refunds)} />
              <InfoItem
                label={intl('SCREEN.REPORT.COLS.AVAILABLE_CREDITS')}
                value={currency(snapshot.credits)}
              />
              <InfoItem label={intl('SCREEN.REPORT.COLS.CHARGES')} value={currency(snapshot.charges)} />
            </div>
          )}
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  snapshot: state.report.snapshot,
});

const mapDispatchToProps = {
  fetchFactorySnapshot: reportActions.fetchFactorySnapshot,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CompanySnapshotScreen));
