import { SPACE } from '$gstyles';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .submit-button {
    min-width: 200px;
    width: 40%;
    margin-left: ${SPACE.XLARGE};
    margin-right: ${SPACE.XLARGE};
  }

  .radio-group {
    .MuiFormControl-root {
      margin-top: ${SPACE.MEDIUM};
    }
  }
`;
