import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import { repReportExportSettings, REP_REPORT_TABLE, REP_REPORT_TABLE_CONFIG } from './repReportTable';
import ReportComponent from '../reportComponent';
import PATH from '$business/enums/paths';
import { REP_FILTER_FORM } from '../storeReportScreen/filters';
import { userActions } from '$fbusiness/redux/user';
import { itemActions } from '$fbusiness/redux/item';
import { renderTableSummary } from '../utils';
interface RepReportScreenProps {
  factory;
  history;
  users;
  items;
  dateFilter;
}

const RepReportScreen: React.FC<RepReportScreenProps> = ({
  history,
  factory,
  users: allUsers,
  items,
  dateFilter,
}) => {
  const [filter, setFilter] = useState<any>({
    ...dateFilter,
    itemId: undefined,
  });
  const [reps, setReps] = useState<any>(null);
  const [tableSummary, setTableSummary] = useState<any>(null);

  const { settings, regions } = factory;
  const handleRowClick = (userId) => {
    history.push(
      `${PATH.REPORT_STORE}/${filter.regionId || 0}/${userId}${filter.itemId > 0 ? '/' + filter.itemId : ''}`,
    );
  };

  const onTableLoad = (total, newTotal, response) => {
    console.log('ON TABLE LOAD REP', total, newTotal, response);
    setTableSummary(response.summary);
  };

  const onChangeRegion = async ({ value, newValue, setFieldValue }) => {
    const region = regions.find((r) => newValue?.id === r.id || value?.id === r.id);

    setReps(region?.salesReps || allUsers);

    // Adjust store and user
    setFieldValue('user', undefined);
    setFilter({ ...filter, user: undefined, userId: undefined, region, regionId: region?.id || 0 });
  };

  const onChangeItem = (item) => {
    setFilter({ ...filter, itemId: item?.id || 0 });
  };

  const onChangeUser = (user) => {
    if (!user) {
      setFilter({ ...filter, userId: 0 });
    }
    setFilter({ ...filter, user: user?.id || 0 });
  };

  const extraForm = REP_FILTER_FORM({
    items,
    regions,
    users: reps ? reps : allUsers,
    onChangeRegion,
    onChangeItem,
    onChangeUser,
    settings,
  });

  return (
    <ReportComponent
      title="SCREEN.REPORT.TITLE_REP"
      table={REP_REPORT_TABLE(handleRowClick)}
      tableConfig={REP_REPORT_TABLE_CONFIG}
      includeReportType
      onTableLoad={onTableLoad}
      dateFilter={dateFilter}
      tableSummary={renderTableSummary(tableSummary)}
      extraForm={extraForm}
      exportSetting={repReportExportSettings}
    />
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  items: state.item.list,
  users: state.user.users,
});

const mapDispatchToProps = (dispatch) => ({
  onHydrate: () => {
    dispatch(userActions.fetchUsers({ storeUser: true, storeId: 1 }));
    dispatch(itemActions.fetchItemList());
  },
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(RepReportScreen));
