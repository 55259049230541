import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button } from '@mui/material';

import { configs } from '$configs';
import { Link } from '$gcomponents/primitives';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { deriveRawToFactory } from '$fbusiness/models/factory';
import PATH from '$business/enums/paths';

export const AUTO_COMPLETE = {
  endpoint: configs.api.users.search,
  renderSuggestion: (e) => `${e.name} ${e.address} ${e.phone}`,
  selectedValue: (e) => e.firstName,
  onSelect: (e, history) => {
    history.push(`${PATH.FACTORIES}/${e.userId}`);
  },
};

export const FACTORY_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.factory.general,
    method: 'GET',
    deriveToModel: deriveRawToFactory,
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.PRIMARY,
    fontSize: '1em',
    minWidth: 800,
    maxWidth: 1300,
    cellPadding: ` 8px 4px`,
    isStripped: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

const FACTORY_TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.FACTORIES.COLS.ID',
    value: 'factoryId',
    align: 'center',
    alwaysShow: true,
    width: 80,
  },
  {
    label: 'SCREEN.FACTORIES.COLS.NAME',
    value: 'name',
    component: (row) => <Link route={`${PATH.FACTORIES}/${row.factoryId}`}>{row.name}</Link>,
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.FACTORIES.COLS.CODE',
    value: 'code',
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 150,
  },
  {
    label: 'SCREEN.FACTORIES.COLS.TYPE',
    value: 'type',
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 120,
  },
  {
    label: 'SCREEN.FACTORIES.COLS.ADDRESS',
    value: 'address',
    component: (row) => (
      <>
        {row.address && (
          <div>
            <div>{row.address.street}</div>
            <div>{`${row.address.city}, ${row.address.state} ${row.address.zip}`}</div>
          </div>
        )}
      </>
    ),
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 250,
  },
  {
    label: 'SCREEN.FACTORIES.COLS.PHONE',
    value: 'phone',
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 160,
  },
  {
    label: 'SCREEN.FACTORIES.COLS.EMAIL',
    value: 'email',
    sortable: SORT.ASC,
    width: 300,
  },
  {
    label: 'SCREEN.INVOICE.COLS.ACTION',
    value: '',
    align: 'center',
    component: (row, actions) => {
      return (
        <Button className="left" fullWidth onClick={(e) => actions.handlePopoverOpen(e, row)}>
          <MoreHorizIcon />
        </Button>
      );
    },
    width: 80,
  },
];

export default FACTORY_TABLE;
