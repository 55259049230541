import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { requiredValidation, validateEmail, validatePassword } from '$gbusiness/helpers/input';

export const USER_FORM = (isMe, roles, factory): Array<InputRowModel> => [
  {
    items: [
      {
        ...INPUT_TYPES.FIRST_NAME,
        validation: [requiredValidation],
      },
      {
        ...INPUT_TYPES.LAST_NAME,
        validation: [requiredValidation],
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.EMAIL,
        gridSize: 6,
        disabled: isMe,
        validation: [
          requiredValidation,
          {
            condition: (v) => !validateEmail(v),
            message: 'INVALID_EMAIL',
          },
        ],
      },
      {
        ...INPUT_TYPES.PASSWORD,
        gridSize: 6,
        validation: [
          {
            condition: (v) => !validatePassword(v),
            message: 'INVALID_PASSWORD',
          },
        ],
      },
      {
        input: INPUT.SELECT,
        label: 'INPUT.LABEL.USER_LEVEL',
        value: 'roleId',
        disabled: isMe,
        options: roles.map((l, i) => ({ label: 'LEVELS.' + l.label, value: l.id })),
        gridSize: 6,
        validation: [requiredValidation],
      },
      {
        input: INPUT.CHECKBOX,
        isHidden: () => !factory?.settings?.messaging,
        label: 'INPUT.LABEL.RECEIVE_MESSAGE',
        value: 'settings.receiveMessage',
        gridSize: 6,
      },
    ],
  },
  {
    items: [
      {
        input: INPUT.SWITCH,
        label: 'INPUT.LABEL.EMAIL_NOTIFICATION',
        value: 'settings.emailNotification',
        gridSize: 6,
      },
    ],
  },
];

export default USER_FORM;
