import styled from 'styled-components';
import { Modal } from '$gcomponents/reusables';
import { SPACE } from '$gstyles';

export const ModalWrapper = styled(Modal)`
  .content {
    padding: ${SPACE.LARGE};
  }
  .footer {
    padding: 0 ${SPACE.LARGE};
  }
`;
