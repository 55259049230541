export const KEYS = {
  AMEX: 'AMEX',
  DISCOVER: 'DISCOVER',
  MASTERCARD: 'MASTERCARD',
  VISA: 'VISA',
  DEBIT: 'PAID',
  OTHER: 'OTHER',
};

export const CARD_TYPE = KEYS;

const convertToOption = (item) => ({
  label: `CARD_TYPE.${item}`,
  value: item,
});

const LIST = Object.keys(KEYS);

export default LIST.map(convertToOption);

export type CARD_TYPE_TYPES = typeof LIST[number] | undefined;
